import React, { useState, useEffect } from 'react';
import { Form, Button, Select, Spin } from 'antd';
import CourseService from '@services/courseService';
import DraggableList from '@components/draggableList/DraggableList';
import LessonService from '@services/lessonService';
import ItemModalForm from '@components/forms/ItemModalForm';
import ObjectiveService from '@services/objectiveService';
import ApiService from '@services/apiService';

const { Option } = Select;

const servicesMap = {
  course: CourseService,
  lesson: LessonService,
  objective: ObjectiveService,
};

const labelsMap = {
  degree: 'Degrees',
  course: 'Courses',
  lesson: 'Lessons',
  objective: 'Objectives',
};

type ItemTypes = Degree | Course | Lesson | Objective;

type SearchService<T> = {
  search: (
    searchText: string,
    page: number,
    excludeIds: number[],
  ) => Promise<T>;
};

interface ChildrenItemsFormProps<T extends ItemTypes> {
  items: T[];
  setItems: any;
  setFieldsValue: any;
  type: 'degree' | 'course' | 'lesson' | 'objective';
  title?: string;
  showCreate?: boolean;
  lessionAI?: [] | any;
}

const ChildrenItemsForm: <T extends ItemTypes>(
  props: ChildrenItemsFormProps<T>,
) => React.ReactElement<ChildrenItemsFormProps<T>> = ({
  items,
  setFieldsValue,
  setItems,
  type,
  title,
  showCreate,
  lessionAI,
}) => {
  const searchService = servicesMap[
    type as keyof typeof servicesMap
  ] as SearchService<any>;

  const label = labelsMap[type as keyof typeof labelsMap];

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState<typeof items>([]);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  useEffect(() => {
    if (lessionAI && lessionAI.length != 0) {
      createItem();
    }
  }, [lessionAI]);

  const createItem = async () => {
    if (lessionAI.lessionNameAI) {
      const sendData = {
        name: lessionAI.lessionNameAI,
        shortDescription: '',
        longDescription: '',
      };
      try {
        if (type == 'lesson') {
          const response = await ApiService.post('/lessons', sendData);
          if (response.status === 200) {
            onItemCreate(response.data);
          }
        }
        if (type == 'objective') {
          const response = await ApiService.post('/objectives', sendData);
          if (response.status === 200) {
            onItemCreate(response.data);
          }
        }
      } catch (err) {
        // console.log('rrrrrrrrrr', err);
      }
    }
  };

  const handleAddItem = (value: number) => {
    if (value) {
      const item = searchData.find(item => item.id === value);
      if (item) {
        setItems([...items, item]);
        setFieldsValue({ item: -1 });
      }
    }
  };

  const handleSearch = (searchText: string) => {
    if (searchText && searchText.length >= 2) {
      const childrenIds = items.map(c => c.id);
      setLoading(true);
      searchService.search(searchText, 1, childrenIds).then(searchData => {
        setSearchData(searchData as any);
        setLoading(false);
      });
    }
  };

  const onItemCreate = (item: any) => {
    setItems([...items, item]);
  };

  const options = searchData.map(course => (
    <Option value={course.id} key={course.id}>
      {course.name}({course.id})
    </Option>
  ));

  return (
    <Form.Item name="item" label={title || label}>
      <Select
        placeholder={`Search ${type}s`}
        showSearch
        onSearch={handleSearch}
        onChange={(value: string) => handleAddItem(Number(value))}
        onSelect={() => setFieldsValue({ item: null })}
        loading={loading}
        filterOption={false}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {options}
      </Select>
      {showCreate && (
        <Button onClick={() => setCreateModalVisible(true)}>
          Create new {type}
        </Button>
      )}
      <DraggableList items={items} setItems={setItems} type={type} />
      <ItemModalForm
        visible={createModalVisible}
        type={type}
        onCreate={onItemCreate}
        onClose={() => setCreateModalVisible(false)}
      />
    </Form.Item>
  );
};

export default ChildrenItemsForm;
