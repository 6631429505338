export const SUBSCRIPTIONS = [
  {
    name: 'Monthly subscription',
    id: 'monthly',
    description:
      '$249 one time registration fee + $39.95 a month(Starting in 30 days)',
    price: 24900,
  },
  // {
  //   name: 'Lifetime',
  //   id: 'lifetime',
  //   description: '$1000',
  //   price: 100000,
  // },
];
