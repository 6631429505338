import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import DegreeForm from '@components/degreeForm/DegreeForm';
import withDegree from '@hoc/withDegree';

interface EditDegreeProps {
  user?: User;
  degree: Degree;
}

const EditDegree: React.FC<EditDegreeProps> = ({ user, degree }) => {
  if (!user) {
    return null;
  }

  return <DegreeForm degree={degree} />;
};

const selectEditDegreeState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedEditDegree = withDegree(
  connect(selectEditDegreeState)(EditDegree),
);

const Page = () => <ConnectedEditDegree />;

export default Page;
