import React, { useState, ReactNode } from 'react';
import moment from 'moment';
import { Comment, Tooltip, Avatar, Typography, Button } from 'antd';
import { DocumentNode, useMutation } from '@apollo/client';
import { isUserAdmin } from '@helpers';
import { UPDATE_REPLY, DELETE_REPLY } from './queries';

const { Paragraph } = Typography;

interface ReplyProps {
  reply: Reply;
  user: User;
  refetchQuery: string | DocumentNode;
  onReply: () => void;
}

const Reply: React.FC<ReplyProps> = ({
  reply,
  user,
  onReply,
  refetchQuery,
}) => {
  const [text, setText] = useState(reply.text);
  const [editing, setEditing] = useState(false);
  const [updateReply, { data, loading }] = useMutation(UPDATE_REPLY);
  const [deleteReply, { loading: deleteLoading }] = useMutation(DELETE_REPLY);

  const onChange = (value: string) => setText(value);
  const editReply = () => setEditing(true);

  const saveReply = async () => {
    await updateReply({
      variables: { text, id: reply.id },
      refetchQueries: [{ query: refetchQuery as any }],
    });
    setEditing(false);
  };

  const onDeleteReply = async () => {
    await deleteReply({
      variables: { id: reply.id },
      refetchQueries: [{ query: refetchQuery as any }],
    });
    setEditing(false);
  };

  let actions: ReactNode[] = [];

  if (user.id === reply.createdBy.id) {
    if (editing || loading) {
      actions = [
        <Button
          size="small"
          key="comment-basic-reply-to"
          type="primary"
          ghost
          loading={loading}
          onClick={() => saveReply()}
        >
          Save
        </Button>,
      ];
    } else {
      actions = [
        <Button
          size="small"
          key="comment-basic-reply-to"
          type="ghost"
          onClick={() => editReply()}
        >
          Edit
        </Button>,
      ];
    }

    // actions.push(
    //   <Button
    //     size="small"
    //     key="comment-basic-reply-to"
    //     type="danger"
    //     ghost={true}
    //     loading={deleteLoading}
    //     onClick={() => onDeleteReply()}
    //   >
    //     Delete
    //   </Button>,
    // );
  }
  if (user.id === reply.createdBy.id || isUserAdmin(user)) {
    actions.push(
      <Button
        size="small"
        key="comment-basic-reply-to"
        danger
        ghost
        loading={deleteLoading}
        onClick={() => onDeleteReply()}
      >
        Delete
      </Button>,
    );
  }
  return (
    <Comment
      actions={actions}
      author={
        <a>{`${reply.createdBy.firstName} ${reply.createdBy.lastName}`}</a>
      }
      avatar={
        <Avatar
          src={
            reply.createdBy.avatar
              ? reply.createdBy.avatar.url
              : `https://eu.ui-avatars.com/api/?name=${reply.createdBy.firstName}+${reply.createdBy.lastName}`
          }
          alt={`${reply.createdBy.firstName} ${reply.createdBy.lastName}`}
        />
      }
      content={<Paragraph editable={{ editing, onChange }}>{text}</Paragraph>}
      datetime={
        <Tooltip title={moment(reply.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
          <span>{moment(reply.createdAt).fromNow()}</span>
        </Tooltip>
      }
    />
  );
};

export default Reply;
