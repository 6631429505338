import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Input, Button, Form } from 'antd';

interface GoalsFormProps {
  goals: Goal[];
  onUpdate: (goals: Goal[]) => void;
}

const GoalsForm: React.FC<GoalsFormProps> = ({ goals, onUpdate }) => {
  const [goal, setGoal] = useState<string>();

  const handleAddGoal = (value: string) => {
    if (value && value.length) {
      onUpdate([
        {
          id: -1,
          text: value,
        },
        ...goals,
      ]);

      setGoal('');
    }
  };

  const handleRemoveGoal = (index: number) => {
    const newData = goals;
    if (newData[index]) {
      newData.splice(index, 1);
    }

    onUpdate([...newData]);
  };

  return (
    <Form.Item label="Goals">
      <Input.Search
        placeholder="Goal text"
        enterButton="Add goal"
        value={goal}
        onChange={event => setGoal(event.target.value)}
        onSearch={(value: string) => handleAddGoal(value)}
      />
      <ul>
        {goals.map((goal, index) => (
          <li key={`${goal.id}#${index}`}>
            <>
              {goal.text}{' '}
              <Button
                danger
                size="small"
                ghost
                onClick={() => {
                  handleRemoveGoal(index);
                }}
              >
                <CloseOutlined />
              </Button>
              {/* <Button className="ai-comman-btn-pulse" size="small">
                <Icon
                  style={{ color: '#46a5e5' }}
                  className="key_pluse"
                  type="plus"
                />
              </Button> */}
              {/* <Button className="ai-comman-btn-pulse" size="small">
                <Icon
                  style={{ color: '#46a5e5' }}
                  className="key_pluse"
                  type="edit"
                />
              </Button>
              <Input /> */}
            </>
          </li>
        ))}
      </ul>
    </Form.Item>
  );
};

export default GoalsForm;
