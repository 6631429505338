import Spinner from '@components/Spinner';
import ProjectService from '@services/projectService';
import { Row, Col, Rate, Divider, Tag } from 'antd';
import React, { useEffect, useState } from 'react';

interface StudentProjectReviewProps {
  userProjectId: number;
}

export const StudentProjectReview: React.FC<StudentProjectReviewProps> = ({
  userProjectId,
}) => {
  const [userProject, setUserProject] = useState<UserProject>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userProject && !loading) {
      setLoading(true);
      ProjectService.getUserProject(userProjectId).then(userProject => {
        setUserProject(userProject);
        setLoading(false);
      });
    }
  }, [loading, userProject, userProjectId]);

  if (loading || !userProject) return <Spinner />;

  if (!userProject.review) return null;

  return (
    <div style={{ marginTop: '16px' }}>
      <h3>Project review</h3>
      {userProject.review.grades.map(grade => (
        <Row>
          <Col span={12}>
            <p style={{ margin: 0 }}>{grade.goal.text}</p>
          </Col>
          <Col span={12}>
            <Rate
              value={grade.grade}
              disabled={true}
              style={{ fontSize: '16px' }}
            />
          </Col>
          <Col span={24}>
            <p style={{ margin: '8px 0' }}>
              Note: <br /> {grade.note ? grade.note : 'No note.'}
            </p>
            <hr />
          </Col>
        </Row>
      ))}
      <Divider dashed={true} />
      <h3>Notes</h3>
      {userProject.note ? <p>{userProject.note}</p> : null}
      {/* <Divider /> */}
    </div>
  );
};
