import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Modal, Input, Button, Alert, Select } from 'antd';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import AnnouncementService from '@services/announcementService';
import { isUserAdmin } from '@helpers';
import { AppState } from '@redux/reducers';

interface AnnouncementModalFormProps {
  visible: boolean;
  onClose: () => void;
  user?: User;
  announcementData?: Announcement;
}

const AnnouncementModalForm: React.FC<AnnouncementModalFormProps> = ({
  announcementData = { courseId: undefined },
  visible,
  onClose,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [announcement, setAnnouncement] =
    useState<Partial<Announcement>>(announcementData);
  const [courses, setCourses] = useState<Course[]>();

  const saveAnnouncement = async () => {
    if (user && !isUserAdmin(user) && !announcement.courseId) {
      setError('You must select a course.');
      return;
    }

    if (!announcement.title || !announcement.text) {
      setError('You must enter announcement title and text.');
      return;
    }

    setLoading(true);

    try {
      if (announcement.id) {
        await AnnouncementService.update(
          announcement.id,
          announcement as Announcement,
        );
      } else {
        await AnnouncementService.create(announcement);
      }

      setError(undefined);
      setLoading(false);
      onClose();
    } catch (err: any) {
      setLoading(false);
      if (err && err.data) {
        setError(`Error occured. ${err.data.message}`);
      } else {
        setError('Error occured.');
      }
    }
  };

  useEffect(() => {
    if (!loading && !courses) {
      AnnouncementService.getCourses().then(c =>
        setCourses(_.sortBy(c, 'name')),
      );
    }
  }, [loading, courses]);

  return (
    <Modal
      visible={visible}
      footer={false}
      destroyOnClose
      title="Create announcement"
      onCancel={() => {
        onClose();
        setError(undefined);
      }}
    >
      <div>
        {error && (
          <Alert style={{ marginBottom: 20 }} type="error" message={error} />
        )}

        <Select<number>
          placeholder="Select course"
          loading={loading}
          value={announcement.courseId}
          onSelect={value =>
            setAnnouncement({ ...announcement, courseId: value })
          }
          style={{ width: '100%' }}
        >
          {courses &&
            courses.map(course => (
              <Select.Option key={course.id} value={course.id}>
                {course.code} - {course.name}
              </Select.Option>
            ))}
        </Select>
        {user && isUserAdmin(user) ? (
          <p style={{ marginTop: '16px' }}>
            If you don't select a course, announcement will be emailed to all
            the users.
          </p>
        ) : null}

        <Input
          placeholder="Title"
          value={announcement.title}
          onChange={e =>
            setAnnouncement({ ...announcement, title: e.target.value })
          }
        />
        <br />
        <br />

        <ReactQuill
          placeholder="Announcement text"
          defaultValue={announcement.text}
          onChange={value => setAnnouncement({ ...announcement, text: value })}
        />
        <br />
        <br />
        <Button type="primary" onClick={saveAnnouncement} loading={loading}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default connect(
  (state: AppState) => ({
    user: state.user.user,
  }),
  {},
)(AnnouncementModalForm);
