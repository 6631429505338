import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '@components/schedule/constants';
import { isUserSubscribed } from '@helpers';

interface SubscriptionStatusProps {
  user: User;
  subscription?: StripeSubscription;
}

export const SubscriptionStatus: React.FC<SubscriptionStatusProps> = ({
  user,
  subscription,
}) => {
  return (
    <>
      <p>
        Subscription status:{' '}
        {isUserSubscribed(user) ? (
          <span className="text-success">ACTIVE</span>
        ) : (
          <span className="text-danger">NOT SUBSCRIBED</span>
        )}
      </p>
      {subscription && subscription.cancel_at_period_end ? (
        <p style={{ color: 'red' }}>
          Subscription will be paused on:{' '}
          {moment.unix(subscription.current_period_end).format(DATE_FORMAT)}
        </p>
      ) : null}{' '}
    </>
  );
};
