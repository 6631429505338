import React, { useEffect, useState } from 'react';
import { Divider, Tag, Rate } from 'antd';
import Spinner from '@components/Spinner';
import CourseService from '@services/courseService';

interface CourseReviewProps {
  userCourseId: number;
}

export const CourseReview: React.FC<CourseReviewProps> = ({ userCourseId }) => {
  const [userCourse, setUserCourse] = useState<UserCourse | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userCourse && !loading) {
      setLoading(true);
      CourseService.getUserCourse(userCourseId).then(userCourse => {
        setUserCourse(userCourse);
        setLoading(false);
      });
    }
  }, [loading, userCourse, userCourseId]);

  if (loading || !userCourse) return <Spinner />;

  if (!userCourse.review) return null;

  return (
    <div>
      {userCourse.review.grades.map((grade, index) => (
        <div key={index}>
          <div>
            <p style={{ margin: 0 }}>{grade.goal.text}</p>
          </div>
          <div>
            {/* {grade.approved ? (
              <p>
                <Icon type="check" /> Approved
              </p>
            ) : (
              <p>
                <Icon type="close" /> Not yet
              </p>
            )} */}
            <Rate
              value={grade.grade}
              disabled={true}
              style={{ fontSize: '16px' }}
            />
          </div>
        </div>
      ))}
      <Divider dashed={true} />
      <h3>Notes</h3>
      {!userCourse.notes ? (
        <p>No notes.</p>
      ) : (
        userCourse.notes.map((note, index) => (
          <div key={index}>
            {note.approved ? (
              <Tag color="green">Approved</Tag>
            ) : (
              <Tag color="red">Not Approved</Tag>
            )}
            <p style={{ marginTop: 16 }}>{note.text}</p>
            <p>
              Approver: {note.approver.firstName} {note.approver.lastName}
            </p>
            <Divider />
          </div>
        ))
      )}
    </div>
  );
};
