import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Card, Button, Radio } from 'antd';
import { toast } from 'react-toastify';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { SUBSCRIPTIONS } from '@constants/general';
import ApiService from '@services/apiService';
import { useHistory } from 'react-router';
import { acTrackEvent } from '@services/ActiveCampaign';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const formatPrice = (price: number) => (price / 100).toFixed(2);

const SubscribeFormComponent: React.FC<FormComponentProps> = ({
  // stripe,
  // elements,
  form,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [couponMessage, setCouponMessage] = useState<string>();
  const [subPrice, setSubPrice] = useState(3995);
  const [price, setPrice] = useState(24900);
  const [subscription, setSubscription] = useState(SUBSCRIPTIONS[0]);

  const stripe = useStripe();
  const elements = useElements();

  const { getFieldsValue } = form;

  const onChangeSubscription = (e: any) => {
    const s = SUBSCRIPTIONS.find(sub => sub.id === e.target.value);
    s && setSubscription(s);
  };

  const handleSubmit = async (ev: any) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    if (!elements || !stripe) {
      return null;
    }

    setLoading(true);

    const fields = getFieldsValue();

    try {
      await new Promise((resolve, reject) => {
        form.validateFields((err: any, values: any) => {
          if (err) {
            reject(err);
          }

          resolve(values);
        });
      });
    } catch (err) {
      setLoading(false);
      return;
    }

    // Use Elements to get a reference to the Card Element mounted somewhere
    // in your <Elements> tree. Elements will know how to find your Card Element
    // becase only one is allowed.
    // See our getElement documentation for more:
    // https://stripe.com/docs/stripe-js/reference#elements-get-element
    const cardElement = elements.getElement('card');

    // From here we cal call createPaymentMethod to create a PaymentMethod
    // See our createPaymentMethod documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
    // stripe
    //   .createPaymentMethod({
    //     type: 'card',
    //     card: cardElement,
    //     billing_details: { name: 'Jenny Rosen' },
    //   })
    //   .then(({ paymentMethod }: any) => {
    //     console.log('Received Stripe PaymentMethod:', paymentMethod);
    //   });

    // // You can also use confirmCardPayment with the PaymentIntents API automatic confirmation flow.
    // // See our confirmCardPayment documentation for more:
    // // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
    // stripe.confirmCardPayment('{PAYMENT_INTENT_CLIENT_SECRET}', {
    //   payment_method: {
    //     card: cardElement,
    //   },
    // });

    // // You can also use confirmCardSetup with the SetupIntents API.
    // // See our confirmCardSetup documentation for more:
    // // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-setup
    // stripe.confirmCardSetup('{PAYMENT_INTENT_CLIENT_SECRET}', {
    //   payment_method: {
    //     card: cardElement,
    //   },
    // });

    // You can also use createToken to create tokens.
    // See our tokens documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-token
    // With createToken, you will not need to pass in the reference to
    // the Element. It will be inferred automatically.

    if (price === 0 && subPrice === 0) {
      const currentUser = await ApiService.post(
        '/users/current-user/subscription',
        {
          billingInfo: fields,
          coupon: fields.coupon,
        },
      );

      toast.success('You successfully subscribed!');

      await acTrackEvent('enroll', currentUser.email);

      setTimeout(() => {
        history.push('/degrees');
        window.location.reload();
      });

      return true;
    }

    try {
      if (!cardElement) {
        return false;
      }

      const response = await stripe.createToken(cardElement, {
        name: fields.billingName,
      });

      if (response.error) {
        toast.error(response.error.message);
        setLoading(false);
        return null;
      }

      if (!response.token) {
        setLoading(false);
        return null;
      }

      const currentUser = await ApiService.post(
        '/users/current-user/subscription',
        {
          billingInfo: fields,
          token: response.token.id,
          coupon: fields.coupon,
        },
      );

      toast.success('You successfully subscribed!');

      await acTrackEvent('enroll', currentUser.email);

      setTimeout(() => {
        history.push('/degrees');
        window.location.reload();
      });
    } catch (err: any) {
      if (err.message) {
        setError(err.message);
        setLoading(false);
        toast.error(err.message);
      }
    }

    // setLoading(false);
  };

  const checkCoupon = async () => {
    const fields = getFieldsValue();

    const couponCode = fields.coupon;
    try {
      const response = await ApiService.post('/coupons/check', {
        code: couponCode,
      });

      setPrice(response.data.newPrice);
      setSubPrice(response.data.newMonthlyPrice);

      if (!response.data.coupon || !response.data.coupon.valid) {
        setCouponMessage('Your coupon is not valid');
        return;
      }

      setCouponMessage('Your coupon is valid');
    } catch (err) {
      setCouponMessage('Wrong coupon code.');
    }
  };

  const { getFieldDecorator } = form;

  return (
    <Card>
      <Form onSubmit={handleSubmit} className="subscribe-form">
        <Row gutter={16}>
          <Col span={24}>
            <h3>Billing Address</h3>
            <Form.Item>
              {getFieldDecorator('billingName', {
                rules: [{ required: true, message: 'Required field!' }],
              })(<Input placeholder="Full Name" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('address', {
                rules: [{ required: true, message: 'Required field!' }],
              })(<Input placeholder="Address" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('address2', {
                rules: [{ required: false, message: 'Required field!' }],
              })(<Input placeholder="Address 2" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('city', {
                rules: [{ required: true, message: 'Required field!' }],
              })(<Input placeholder="City" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('state', {
                rules: [{ required: true, message: 'Required field!' }],
              })(<Input placeholder="State" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('zip', {
                rules: [{ required: true, message: 'Required field!' }],
              })(<Input placeholder="Zip" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('country', {
                rules: [{ required: true, message: 'Required field!' }],
              })(<Input placeholder="Country" />)}
            </Form.Item>
            {price === 0 && subPrice === 0 ? null : (
              <>
                <h3>Payment Info</h3>
                <CardElement
                // style={{
                //   base: {
                //     fontSize: '16px',
                //     letterSpacing: '0.025em',
                //     '::placeholder': {
                //       color: '#aab7c4',
                //     },
                //   },
                // }}
                />
              </>
            )}
          </Col>
        </Row>
        <div style={{ marginTop: 16 }}>
          <Form.Item>
            {getFieldDecorator('coupon')(
              <Input placeholder="Coupon code" style={{ width: '200px' }} />,
            )}
            <div style={{ textAlign: 'center' }}>
              <p>{couponMessage}</p>
              <Button type="primary" onClick={() => checkCoupon()}>
                Apply coupon
              </Button>
            </div>
          </Form.Item>
        </div>
        <div style={{ textAlign: 'center' }}>
          <div>
            <Form.Item>
              {getFieldDecorator('subscriptionType', {
                rules: [{ required: true, message: 'Required field!' }],
                initialValue: subscription.id,
              })(
                <Radio.Group onChange={onChangeSubscription}>
                  {SUBSCRIPTIONS.map(subscription => (
                    <Radio
                      style={{ ...radioStyle, whiteSpace: 'normal' }}
                      key={subscription.id}
                      value={subscription.id}
                    >
                      {subscription.name}:{' '}
                      {`$${formatPrice(
                        price,
                      )} one time registration installment + $${formatPrice(
                        subPrice,
                      )} a month (Starting in 30 days)`}
                    </Radio>
                  ))}
                </Radio.Group>,
              )}
            </Form.Item>
          </div>
          <Button
            type="primary"
            loading={loading}
            htmlType="submit"
            disabled={loading}
            style={{ marginTop: 16 }}
          >
            Register
          </Button>
        </div>
      </Form>
    </Card>
  );
};

const SubscribeForm = SubscribeFormComponent;

export default Form.create({ name: 'subscribe' })(SubscribeForm);
