import React, { useState } from 'react';
import { Modal, Input, Select, Button } from 'antd';
import ApiService from '@services/apiService';
import { CreateChatPayload } from '@redux/reducers/chat';
import { isUserInstructor } from '@helpers';
import CourseService from '@services/courseService';
interface NewChatModalProps {
  send: (chat: CreateChatPayload) => void;
  onClose: () => void;
  chatUserId: any;
  courseId: any;
  userRole: any;
}

const NewChatModal: React.FC<NewChatModalProps> = ({
  send,
  onClose,
  chatUserId,
  courseId,
  userRole,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [teachers, setTeachers] = useState<User[]>();
  const [chat, setChat] = useState<Partial<CreateChatPayload>>({});
  //const [storeStudentId, setStoreStudentId] = useState<any>();
  const fetchTeachers = async () => {
    setIsLoading(true);
    //let response = (await ApiService.get(`/teachers`)) as any;

    // if (isUserInstructor(user)) {
    //   response = (await ApiService.get(`/students`)) as any;
    // }
    if (userRole == 'instructor') {
      const storeId: any = [];
      CourseService.getStudentsCourseHearing(courseId).then(student => {
        for (let value of Object.values(student)) {
          storeId.push(value.studentId);
        }
        //setStoreStudentId(storeId);
        if (storeId.length > 0) {
          CourseService.getStudents(courseId).then(user => {
            const newArray = [];
            for (var i = 0; i < user.length; i++) {
              var index = storeId.indexOf(user[i].id);
              if (index !== -1) {
                newArray.push(user[i]);
              } else {
              }
            }
            setTeachers(newArray);
            setIsLoading(false);
            setChat({ userId: chatUserId != undefined ? chatUserId : '' });
          });
        }
      });
    } else {
      CourseService.getInstructors(courseId).then(user => {
        setTeachers(user);
        setIsLoading(false);
        setChat({ userId: chatUserId != undefined ? chatUserId : '' });
      });
    }

    // const json = response.data;
    // setTeachers(json);
  };

  const sendMessage = () => {
    if (chat.userId && chat.text && chat.text.length > 1) {
      send({ userId: chat.userId, text: chat.text });
    }
  };

  if (!teachers && !isLoading) {
    fetchTeachers();
  }

  return (
    <Modal
      visible={true}
      footer={false}
      destroyOnClose={true}
      title="New chat"
      onCancel={onClose}
    >
      {isLoading && <div />}
      {!isLoading && (
        <div>
          <Select
            placeholder="Select user"
            style={{ width: '300px' }}
            onSelect={(value: any) =>
              setChat({ ...chat, userId: Number(value) })
            }
            defaultValue={chatUserId != undefined ? Number(chatUserId) : ''}
          >
            {teachers &&
              teachers.map(user => (
                <Select.Option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </Select.Option>
              ))}
          </Select>
          <br />
          <br />
          <Input.TextArea
            onChange={e => setChat({ ...chat, text: e.target.value })}
          />
          <br />
          <br />
          <Button type="primary" onClick={sendMessage}>
            Send message
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default NewChatModal;
