import React, { useState } from 'react';
import { Modal, Input, Button, Alert } from 'antd';
import ApiService from '@services/apiService';

interface ItemModalFormProps {
  onCreate: (itemId: number) => void;
  onClose: () => void;
  type: 'objective' | 'lesson' | 'course' | 'degree';
  visible: boolean;
}

const apiPathsMap = {
  objective: '/objectives',
  lesson: '/lessons',
  course: '/courses',
  degree: '/degrees',
};

const ItemModalForm: React.FC<ItemModalFormProps> = ({
  onCreate,
  onClose,
  type,
  visible,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [item, setItem] = useState<
    Pick<Lesson, 'name' | 'shortDescription' | 'longDescription'>
  >({
    name: '',
    shortDescription: '',
    longDescription: '',
  });

  const createItem = async () => {
    if (item.name) {
      setIsLoading(true);
      try {
        const response = await ApiService.post(apiPathsMap[type], item);
        if (response.status === 200) {
          onCreate(response.data);
          onClose();
        }
        setError(undefined);
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        if (err && err.data) {
          setError(`Error occured. ${err.data.message}`);
        } else {
          setError('Error occured.');
        }
      }
    }
  };

  return (
    <Modal
      visible={visible}
      footer={false}
      destroyOnClose
      title={`Create ${type}`}
      onCancel={() => {
        onClose();
        setError(undefined);
      }}
    >
      <div>
        {error && (
          <Alert style={{ marginBottom: 20 }} type="error" message={error} />
        )}
        <Input
          placeholder="Name"
          onChange={e => setItem({ ...item, name: e.target.value })}
        />
        <br />
        <br />

        {type !== 'objective' && (
          <>
            <Input
              placeholder="Short description"
              onChange={e =>
                setItem({ ...item, shortDescription: e.target.value })
              }
            />
            <br />
            <br />
            <Input
              placeholder="Long description"
              onChange={e =>
                setItem({ ...item, longDescription: e.target.value })
              }
            />
            <br />
            <br />
          </>
        )}
        <Button type="primary" onClick={createItem} loading={isLoading}>
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default ItemModalForm;
