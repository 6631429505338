import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VideoCallType } from '@types';

export interface VideoCallState {
  isLoading: boolean;
  error: boolean;
  videoCalls: VideoCall[];
  token?: string;
  videoCall?: VideoCall;
  errors?: ApiError['errors'];
}

export interface StartVideoCallPayload {
  courseId?: number;
  degreeId?: number;
  type: VideoCallType;
}

export interface StartVideoCallResponse {
  videoCall: VideoCall;
  token: string;
}

const initialState: VideoCallState = {
  isLoading: false,
  error: false,
  videoCalls: [],
};

const UserSlice = createSlice({
  initialState,
  name: 'videoCall',
  reducers: {
    start(state, action: PayloadAction<StartVideoCallPayload>) {
      return state;
    },
    startSuccess(state, action: PayloadAction<StartVideoCallResponse>) {
      return {
        ...state,
        videoCall: action.payload.videoCall,
        token: action.payload.token,
      };
    },
    startError(state, action: PayloadAction<ApiError['errors']>) {
      return { ...state, ...action.payload, isLoading: false, error: true };
    },
    stopCall(state, action: PayloadAction<VideoCall>) {},
    stopCallSuccess(state) {
      return {
        ...state,
        videoCall: undefined,
        token: undefined,
      };
    },
    fetchVideoCalls(state) {
      return { ...state };
    },
    fetchVideoCallsSuccess(state, action: PayloadAction<VideoCall[]>) {
      return { ...state, videoCalls: action.payload };
    },
    acceptVideoCall(state, action: PayloadAction<string>) {
      return { ...state };
    },
    joinVideoCall(state, action: PayloadAction<string>) {
      return { ...state };
    },
    setVideoCall(state, action: PayloadAction<VideoCall>) {
      return {
        ...state,
        videoCall: action.payload,
      };
    },
  },
});

const { actions, reducer } = UserSlice;

export const {
  start,
  startError,
  startSuccess,
  stopCall,
  stopCallSuccess,
  fetchVideoCalls,
  fetchVideoCallsSuccess,
  acceptVideoCall,
  joinVideoCall,
  setVideoCall,
} = actions;
export default reducer;
