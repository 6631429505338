import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AddNoteData {
  note: string;
  objective: number;
}

interface UpdateNoteData {
  id: number;
  note: string;
  objective?: number;
}

interface GetNoteData {
  relId: number;
  rel: string;
}

interface Note {
  id?: number;
  name?: string;
  userNotes?: UserNote[];
  objectives?: {
    id: number;
    name: string;
    userNotes: UserNote[];
  }[];
  lessons?: {
    id: number;
    name: string;
    userNotes: UserNote[];
  }[];
}

export interface UserNoteState {
  notes: Note;
  isLoading?: boolean;
  errors?: ApiError['errors'];
}

const initialState: UserNoteState = {
  isLoading: false,
  errors: [],
  notes: {},
};

const removeNote = (state: UserNoteState, action: PayloadAction<number>) => {
  let filteredNotes;
  if (state.notes.userNotes) {
    filteredNotes = [...state.notes.userNotes].filter(
      (note: UserNote) => note.id !== action.payload,
    );
  }
  return {
    ...state,
    notes: {
      ...state.notes,
      userNotes: filteredNotes || state.notes.userNotes,
    },
    isLoading: false,
  };
};

// const updateNote = (state: UserNoteState, action: PayloadAction<UserNote>) => {
//   let updatedNotes;
//   if (state.notes.userNotes) {
//     updatedNotes = [...state.notes.userNotes].map((note: UserNote) => {
//       if (note.id !== action.payload.id) {
//         note = { ...action.payload };
//       }
//       return note;
//     });
//   }
//   return {
//     ...state,
//     notes: {
//       ...state.notes,
//       userNotes: updatedNotes ? updatedNotes : state.notes.userNotes,
//     },
//     isLoading: false,
//   };
// };

const UserNoteState = createSlice({
  initialState,
  name: 'userNotes',
  reducers: {
    addUserNote(state, action: PayloadAction<AddNoteData>) {
      state.isLoading = true;
      return state;
    },
    addUserNoteSuccess(state, action: PayloadAction<UserNote>) {
      return {
        ...state,
        notes: {
          ...state.notes,
          userNotes: Array.prototype.concat(
            state.notes.userNotes,
            action.payload,
          ),
        },
        isLoading: false,
      };
    },
    addUserNoteFail(state, action: PayloadAction<ApiError['errors']>) {
      return { ...state, ...action.payload, isLoading: false };
    },
    getUserNotes(state, action: PayloadAction<GetNoteData>) {
      state.isLoading = true;
      return state;
    },
    getUserNotesSuccess(state, action: PayloadAction<Note>) {
      const notes = action.payload;
      notes.userNotes = notes.userNotes
        ? notes.userNotes.sort((a, b) =>
            `${a.createdAt}`.localeCompare(`${b.createdAt}`),
          )
        : [];
      return { ...state, notes, isLoading: false };
    },
    getUserNotesFail(state) {
      return { ...state, isLoading: false };
    },
    removeUserNote(state, action: PayloadAction<number>) {
      state.isLoading = true;
      return state;
    },
    removeUserNotesSuccess(state, action: PayloadAction<number>) {
      return removeNote(state, action);
    },
    removeUserNotesFail(state) {
      return { ...state, isLoading: false };
    },
    updateUserNote(state, action: PayloadAction<UpdateNoteData>) {
      return { ...state, isLoading: true };
    },
    updateUserNotesSuccess(state, action: PayloadAction<UserNote>) {
      if (!state.notes.userNotes) {
        return state;
      }
      const updatedNoteIndex = state.notes.userNotes.findIndex(
        un => un.id === action.payload.id,
      );
      if (updatedNoteIndex && updatedNoteIndex >= 0) {
        state.notes.userNotes[updatedNoteIndex] = action.payload;
      }
      return { ...state, isLoading: false };
    },
    updateUserNotesFail(state) {
      return { ...state, isLoading: false };
    },
  },
});

const { actions, reducer } = UserNoteState;

export const {
  addUserNote,
  addUserNoteFail,
  addUserNoteSuccess,
  getUserNotes,
  getUserNotesFail,
  getUserNotesSuccess,
  removeUserNote,
  removeUserNotesFail,
  removeUserNotesSuccess,
  updateUserNote,
  updateUserNotesFail,
  updateUserNotesSuccess,
} = actions;
export default reducer;
