import React, { memo, useEffect, useMemo, useState } from 'react';
import { Menu, Progress } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { parsePath } from '@components/breadcrumbs/Breadcrumbs';
import DegreeService from '@services/degreeService';
import {
  getCourseCompletedPercentage,
  getLessonCompletedPercentage,
} from '@utils/helpers';

const { Item, SubMenu } = Menu;

type DegreeMenuProps = {
  degree: Degree;
  user: User;
};

export const DegreeMenu: React.FC<DegreeMenuProps> = memo(
  ({ degree, user }) => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [courses, setCourses] = useState<Course[]>();

    const pathItems = useMemo(
      () => parsePath(location.pathname),
      [location.pathname],
    );
    const degreePath = `/degrees/${degree.id}`;

    useEffect(() => {
      if (!loading && !courses) {
        setLoading(true);
        DegreeService.getDegreeContent(degree.id)
          .then(c => {
            setCourses(c);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
          });
      }
    }, []);

    useEffect(() => {
      const newKeys = [];
      if (pathItems.degreeId) {
        newKeys.push(`degree#${pathItems.degreeId}`);
      }
      if (pathItems.courseId) {
        newKeys.push(`course#${pathItems.courseId}`);
      }
      if (pathItems.lessonId) {
        newKeys.push(`lesson#${pathItems.lessonId}`);
      }
      if (pathItems.objectiveId) {
        newKeys.push(`objective#${pathItems.objectiveId}`);
      }
      setOpenKeys(newKeys);
    }, [pathItems]);

    if (loading) {
      return null;
    }

    return (
      <Menu
        openKeys={openKeys}
        mode="inline"
        inlineIndent={2}
        multiple
        onOpenChange={setOpenKeys}
      >
        <SubMenu
          key={`degree#${degree.id}`}
          className="progress-menu"
          title={
            <span
              className={`nav-text ${
                Number(pathItems.degreeId) === degree.id
                  ? 'item-degree-active'
                  : ''
              }`}
            >
              <Link to={degreePath}>{degree.name}</Link>
            </span>
          }
          style={{
            paddingLeft: '4px',
            borderLeft: '3px solid #faad14',
            borderBottom: '3px solid #faad14',
            paddingBottom: '4px',
          }}
        >
          {courses?.map(course => (
            <SubMenu
              key={`course#${course.id}`}
              title={
                <span>
                  <span
                    className={`nav-text ${
                      Number(pathItems.courseId) === course.id
                        ? 'item-course-active'
                        : ''
                    }`}
                  >
                    <Link to={`${degreePath}/courses/${course.id}`}>
                      {course.name}
                    </Link>
                  </span>
                  <Progress
                    percent={getCourseCompletedPercentage(course, user)}
                    style={{ display: 'block' }}
                    strokeWidth={5}
                    showInfo={false}
                  />
                </span>
              }
              style={{
                paddingLeft: '4px',
                borderLeft: '3px solid #52c41a',
                borderBottom: '3px solid #52c41a',
                paddingBottom: '4px',
              }}
            >
              {course.lessons?.map(lesson => (
                <SubMenu
                  key={`lesson#${lesson.id}`}
                  title={
                    <span>
                      <span
                        className={`nav-text ${
                          Number(pathItems.lessonId) === lesson.id
                            ? 'item-lesson-active'
                            : ''
                        }`}
                      >
                        <Link
                          to={`${degreePath}/courses/${course.id}/lessons/${lesson.id}`}
                        >
                          {lesson.name}
                        </Link>
                      </span>
                      <Progress
                        percent={getLessonCompletedPercentage(lesson, user)}
                        style={{ display: 'block' }}
                        strokeWidth={5}
                        showInfo={false}
                      />
                    </span>
                  }
                  style={{
                    paddingLeft: '4px',
                    borderLeft: '3px solid #13c2c2',
                    borderBottom: '3px solid #13c2c2',
                    paddingBottom: '4px',
                  }}
                >
                  {lesson.objectives &&
                    lesson.objectives.map((objective: Objective) => (
                      <Item
                        key={`objective#${objective.id}`}
                        style={{
                          lineHeight: '40px',
                          overflow: 'hidden',
                          borderLeft: '3px solid #46A5E5',
                          paddingLeft: '4px',
                        }}
                      >
                        <div>
                          <span
                            className={`nav-text ${
                              Number(pathItems.objectiveId) === objective.id
                                ? 'item-objective-active'
                                : ''
                            }`}
                          >
                            {objective.name}
                          </span>
                        </div>
                        <Link
                          to={`${degreePath}/courses/${course.id}/lessons/${lesson.id}/objectives/${objective.id}`}
                        />
                      </Item>
                    ))}
                </SubMenu>
              ))}
            </SubMenu>
          ))}
        </SubMenu>
      </Menu>
    );
  },
);
