import React from 'react';
import { Row, Col, Card, Progress } from 'antd';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';

import { useRouter } from '@utils/routerHooks';
import Projects from '@components/projects/Projects';
import Readings from '@components/readings/Readings';
import { getCompletedPercentage } from '@utils/helpers';
import LessonDetails from '@components/lessonList/LessonDetails';
import { isUserSubscribed, isUserInstructor } from '@helpers';
import withLesson from '@hoc/withLesson';
import { markCompleted } from '@redux/reducers/user';
import Notes from '@components/notes/Notes';

interface TParams {
  id?: string;
}

interface LessonProps {
  user?: User;
  lesson: Lesson;
  markCompleted: typeof markCompleted;
  markCompletedLoading: boolean;
}

const Lesson: React.FC<LessonProps> = ({
  user,
  lesson,
  markCompleted,
  markCompletedLoading,
}) => {
  const { history } = useRouter<TParams>();

  if (!user) {
    return null;
  }

  if (!isUserSubscribed(user)) {
    history.push('/subscribe');
  }
  return (
    <>
      <Row gutter={[16, { xs: 16, sm: 16, md: 0 }]}>
        <Col xs={24} sm={24} md={16}>
          <LessonDetails
            lesson={lesson}
            user={user}
            markCompleted={markCompleted}
            markCompletedLoading={markCompletedLoading}
          />
        </Col>
        <Col xs={24} sm={24} md={8}>
          {!isUserInstructor(user) && (
            <Card size="small" title="Progress" className="sidebar-widget">
              <Progress
                percent={getCompletedPercentage(lesson.objectives, user)}
              />
            </Card>
          )}
          <Card size="small" title="Exercises" className="sidebar-widget">
            <Projects projects={lesson.projects} isLesson />
          </Card>
          <Card size="small" title="Readings" className="sidebar-widget">
            <Readings readings={lesson.readings} isLesson />
          </Card>
        </Col>
      </Row>
      <Notes
        relId={lesson.id}
        rel="lesson"
        canEdit={false}
        canRemove={false}
        canAdd={false}
      />
    </>
  );
};

const selectLessonState = (state: AppState) => ({
  user: state.user.user,
  markCompletedLoading: state.user.markCompletedLoading,
});

const ConnectedLesson = withLesson(
  connect(selectLessonState, { markCompleted })(Lesson),
);

const Page = () => <ConnectedLesson />;

export default Page;
