import * as React from 'react';
import { List } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { updateNotification } from '@redux/reducers/notifications';

export interface INotificationListProps {
  notifications?: ApiNotification[];
  updateNotification: typeof updateNotification;
  isLoading: boolean;
}

export default function NotificationList({
  notifications,
  updateNotification,
  isLoading,
}: INotificationListProps) {
  return (
    <List
      header={<div>Notifications</div>}
      style={{ background: '#fff' }}
      bordered
      loading={isLoading}
      dataSource={notifications}
      renderItem={notification => (
        <List.Item
          style={{ backgroundColor: notification.read ? '#fff' : '#caeaff' }}
          key={notification.id}
        >
          <List.Item.Meta
            title={
              <Link
                to={notification.data.path || `/`}
                onClick={() => updateNotification(notification.id)}
              >
                {notification.text}
              </Link>
            }
            description={moment(notification.createdAt).fromNow()}
          />
        </List.Item>
      )}
    />
  );
}
