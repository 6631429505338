import React from 'react';
import { Row, Col, Progress, Button } from 'antd';
import YouTube from 'react-youtube-embed';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import { useRouter } from '@utils/routerHooks';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import './CourseList.less';
import { isUserEnrolled, getCourseCompletedPercentage } from '@utils/helpers';
import { useLocation } from 'react-router';
import { parsePath } from '@components/breadcrumbs/Breadcrumbs';
import UserService from '@services/userService';
import { isUserInstructor } from '@helpers';

interface CourseListProps {
  courses: Course[];
  degree?: Degree;
  user?: User;
  hideVideo?: boolean;
  assignCourses?: number;
  passedCoursesCount?: number;
  startCoursesCount?: number;
  notStartCoursesCount?: number;
}

export const CourseList: React.FC<CourseListProps> = ({
  degree,
  courses,
  user,
  hideVideo,
  assignCourses,
  passedCoursesCount,
  startCoursesCount,
  notStartCoursesCount,
}) => {
  const location = useLocation();
  const { history } = useRouter();
  const { degreeId } = parsePath(location.pathname);
  if (!user) {
    return null;
  }

  const courseCompletions: any = {};

  courses.forEach(course => {
    const percentage = getCourseCompletedPercentage(course, user);
    // console.log('percentage', percentage);
    courseCompletions[course.id] = percentage;
  });
  const startCourse = async (courseId: any) => {
    // console.log('startCoursesCount', startCoursesCount);
    if (startCoursesCount && startCoursesCount >= 3) {
      toast.info('Please complete started course');
    } else {
      await UserService.startCourseForStudent(
        Number(user.id),
        Number(courseId),
      );
      toast.success('Course started successfully!');
      history.push(`/courses/${courseId}`);
    }
  };
  const startCourseFilter = (courseId: number) => {
    const startCount = user.userCourses.filter(
      (c: any) => c.courseId == courseId,
    );
    if (startCount && startCount.length > 0) {
      return startCount[0].startCourse == 'started';
    }
    return true;
  };
  return (
    <>
      {!isUserInstructor(user) && (
        <div style={{ marginBottom: '15px', textAlign: 'center' }}>
          ALL ({assignCourses}) COMPLETED ({passedCoursesCount}) STARTED (
          {startCoursesCount}) NOT STARTED ({notStartCoursesCount})
        </div>
      )}
      {courses.map((course: Course, i: number) => (
        <article key={(i + 1).toString()} className="degree-card-list mb-4">
          <Row gutter={10}>
            <Col span={hideVideo ? 0 : 8}>
              {!course.image && !course.introVideo && (
                <div>
                  <p style={{ margin: '30px 0', textAlign: 'center' }}>
                    No image
                  </p>
                </div>
              )}
              {!course.image && course.introVideo && (
                <YouTube id={course.introVideo} />
              )}
              {course.image && <img src={course.image.url} alt={course.name} />}
            </Col>
            <Col span={hideVideo ? 24 : 16}>
              <div className="degree-card__body">
                <Link
                  to={
                    degreeId
                      ? `/degrees/${degreeId}/courses/${course.id}`
                      : `/courses/${course.id}`
                  }
                  className="no-link-style"
                >
                  <h2 className="degree-card__title">{course.name}</h2>
                </Link>
                <div>
                  <p>{course.shortDescription}</p>
                  {course &&
                    isUserEnrolled('course', Number(course.id), user) && (
                      <span>
                        {(startCourseFilter(course.id) ||
                          courseCompletions[course.id] != 0) && (
                          <Progress percent={courseCompletions[course.id]} />
                        )}
                      </span>
                    )}
                  {/* {!isUserEnrolled(course.id, user) && (
                    <div>
                      <div className="degree-card__content">
                        <p>{course.shortDescription}</p>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          type="primary"
                          onClick={(e: any) => enrollCourse(course.id)}
                        >
                          Enroll
                        </Button>
                      </div>
                    </div>
                  )} */}
                  {/* {isUserEnrolled(course.id, user) && (
                    <div style={{ textAlign: 'center' }}>
                      <Progress type="circle" percent={30} width={80} />
                      <div style={{ marginTop: 10 }}>
                        <Button type="default">Continue</Button>
                      </div>
                    </div>
                  )} */}
                </div>
                <div style={{ marginTop: 10, textAlign: 'center' }}>
                  {!isUserInstructor(user) &&
                    courseCompletions[course.id] == 0 &&
                    !startCourseFilter(course.id) && (
                      <Button
                        onClick={() => startCourse(course.id)}
                        type="primary"
                      >
                        <span>START</span>
                      </Button>
                    )}
                </div>
              </div>
            </Col>
          </Row>
        </article>
      ))}
    </>
  );
};

const selectCourseListState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedCourseList = connect(selectCourseListState)(CourseList);

export default ConnectedCourseList;
