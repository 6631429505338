import React from 'react';

const colorsMap = {
  primary: '#1890ff',
  success: '#52c41a',
  warning: '#faad14',
  danger: '#ff4d4f',
};

interface MessageProps {
  type: 'primary' | 'success' | 'danger' | 'warning';
  text: string;
}

export const getHex = (type: MessageProps['type']) => colorsMap[type];

const Message: React.FC<MessageProps> = ({ type, text }) => (
  <span style={{ color: colorsMap[type] }}>{text}</span>
);

export default Message;
