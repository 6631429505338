import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { AppState } from '@redux/reducers';
import Onboarding from '@components/onboarding/Onboarding';
import { start } from '@redux/reducers/videoCall';
import { isUserOnboarded } from '@utils/helpers';

interface OnboardingProps {
  start: typeof start;
  user?: User;
}

const OnboardingPage: React.FC<OnboardingProps> = ({ user, start }) => {
  const history = useHistory();

  if (!user || !user.onboarding) {
    return null;
  }

  if (user && user.onboarding && isUserOnboarded(user.onboarding)) {
    history.push('/');
  }

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Onboarding
          user={user}
          onboarding={user.onboarding}
          startCall={start}
          studentId={user.id}
        />
      </Col>
    </Row>
  );
};

const selectOnboardingState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedOnboarding = connect(selectOnboardingState, { start })(
  OnboardingPage,
);

const Page = () => <ConnectedOnboarding />;

export default Page;
