export const acTrackEvent = async (
  eventName: string,
  email: string,
): Promise<void> => {
  // const contactParams = {
  //   "contact": {
  //     "email": email,
  //   }
  // };

  // await fetch('https://newlaneuniversity.api-us1.com/api/3/contacts', {
  //   headers: {
  //     "Api-Token": "55b03f2047dd81cd2cfa63648312d9016a78e7271521d0aba4a0f480c313dab5b46ee3e2"
  //   },
  //   method: 'POST',
  //   body: JSON.stringify(contactParams),
  // })
  // .catch(err => {
  //   console.error(err);
  // });

  // debugger;

  await Promise.resolve(true);

  // const params = {
  //   "actid": '253328679',
  //   "key": 'd6ddf95e5d0557ff39bfcb1939cde87b1219a872',
  //   "event": eventName,
  //   "eventdata": 'data',
  //   "visit": {
  //     "email": email,
  //   },
  // };

  // await fetch('https://trackcmp.net/event', {
  //   method: 'POST',
  //   body: JSON.stringify(params),
  //   mode: 'no-cors'
  // })
  //   .catch(err => {
  //     console.error(err);
  //   });
};
