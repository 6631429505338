import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Row,
  Col,
  Card,
  Divider,
  Progress,
  Tag,
  Button,
  Typography,
  Input,
  Checkbox,
  Modal,
  Rate,
} from 'antd';
import { AppState } from '@redux/reducers';
import { useRouter } from '@utils/routerHooks';
import { getCompletedPercentage, getSumByKey } from '@utils/helpers';
import Goals from '@components/goals/Goals';
import ApiService from '@services/apiService';
import QuizReviewModal from '@components/quiz/QuizReviewModal';
import Spinner from '@components/Spinner';
import { StudentProjectReview } from '@components/projectReview';
import { CourseReviewForm } from '@components/courseReview';
import CourseService from '@services/courseService';
import { toast } from 'react-toastify';

interface TParams {
  courseId?: string;
  studentId?: string;
}

interface CourseProps {
  user?: User;
  courseId?: number;
  studentId?: number;
}

interface StudentCourseData {
  student: Student;
  course: Course;
  userCourse: UserCourse;
  notes: Note[];
}

const StudentCourseProgressComponent: React.FC<CourseProps> = ({
  user,
  courseId: propCourseId = 0,
  studentId: propStudentId = 0,
}) => {
  const [data, setData] = useState<StudentCourseData>();
  const [loading, setLoading] = useState(true);
  const [noteText, setNoteText] = useState('');
  const [quizReview, setQuizReview] = useState<Quiz>();
  const [courseReview, setCourseReview] = useState<CourseReview>();

  const { match } = useRouter<TParams>();

  const courseId = match.params.courseId || propCourseId;
  const studentId = match.params.studentId || propStudentId;
  const [visible, setVisible] = React.useState(false);
  const [confirmDisabled, setConfirmDisabled] = React.useState(true);
  const [averageStar, setAverageStar] = useState<any>(0);
  const [averageStarFill, setAverageStarFill] = useState<any>(0);
  const approveDisabled = useMemo(() => {
    // calculate Star
    if (courseReview && courseReview.grades.length) {
      const total = getSumByKey(courseReview.grades, 'grade');
      const totalLength = courseReview.grades.length;
      const calculation = Number(total / totalLength).toFixed(1);
      setAverageStar(calculation);

      const c = calculation.toString().split('.');
      let aaaa = Number(c[0]);
      if (Number(c[1]) > 0) {
        aaaa += 0.5;
      }
      setAverageStarFill(aaaa);
    }

    if (!courseReview) return true;

    return !!courseReview.grades.find(grade => !grade.approved);
  }, [courseReview]);

  useEffect(() => {
    const getData = async (
      studentId: string | number,
      courseId: string | number,
    ) => {
      setLoading(true);
      const response = (await ApiService.get(
        `/courses/${courseId}/students/${studentId}`,
        data,
      )) as any;

      const json = response.data;
      setCourseReview(json.userCourse.review);
      setData(json);
      setLoading(false);
      // if (
      //   json.userCourse.review.grades &&
      //   json.userCourse.review.grades.length > 0
      // ) {
      //   const total = getSumByKey(json.userCourse.review.grades, 'grade');
      //   const totalLength = json.userCourse.review.grades.length;
      //   console.log('json', total, json.userCourse.review.grades);
      //   setAverageStar(total / totalLength);
      // }
    };
    if (!data) {
      getData(studentId, courseId);
    }
  }, [courseId, data, studentId]);

  if (loading || !data) {
    return <Spinner />;
  }

  if (!user) {
    return null;
  }

  const { course, student, notes, userCourse } = data;

  const courseObjectives: Objective[] = [];

  course.lessons &&
    course.lessons.forEach(lesson => {
      lesson.objectives.forEach(o => courseObjectives.push(o));
    });

  const percentCompleted = getCompletedPercentage(courseObjectives, student);

  const openQuizReview = (quiz: Quiz) => {
    setQuizReview(quiz);
  };

  const closeQuizReview = () => {
    setQuizReview(undefined);
  };
  const confirmReview = (value: any) => {
    setConfirmDisabled(!value);
  };

  const saveNote = async (approved: boolean) => {
    try {
      await ApiService.post(
        `/courses/${courseId}/students/${studentId}/notes`,
        { approved, text: noteText },
      );
      courseReview &&
        (await CourseService.updateCourseReview(userCourse.id, courseReview));
      setData(undefined);
      setNoteText('');
      toast.success('Student course review updated!');
    } catch (err) {
      toast.error('An error occured!');
    }
  };

  const studentProjects = student.userProjects.filter(
    up => course.projects && course.projects.find(p => p.id === up.projectId),
  );
  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    // console.log('handleOk');
    setVisible(false);
    saveNote(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <Row gutter={16}>
        <Col span={16}>
          <Tag color="green">Course</Tag>
          <h2 style={{ margin: '8px 0', color: '#333' }}>{course.name}</h2>
          <Card>
            <div>
              <h3>Short description</h3>
              <p>{course.shortDescription}</p>
            </div>
            {studentProjects.length > 0 &&
              studentProjects[0].status == 'approved' && (
                <>
                  <h3>Course review</h3>
                  <Row>
                    <Col span={24}>
                      <CourseReviewForm
                        userCourse={{ ...userCourse, course }}
                        onReviewUpdate={setCourseReview}
                      />
                    </Col>
                    {/* <Col span={12}>
              <div>
                <Input.TextArea onChange={e => setNoteText(e.target.value)} />
                <div style={{ marginTop: 16 }}>
                  <Button
                    type="danger"
                    onClick={() => saveNote(false)}
                    style={{ marginRight: 8 }}
                  >
                    Not Yet
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => saveNote(true)}
                    disabled={approveDisabled}
                  >
                    Approve
                  </Button>
                </div>
                {approveDisabled && (
                  <div style={{ marginTop: 16 }}>
                    <p>
                      You can approve the course after student scored 5 stars on
                      each rubric in the course review
                    </p>
                  </div>
                )}
              </div>
            </Col> */}
                  </Row>
                  <div>
                    <h4>General note</h4>
                    <Input.TextArea
                      onChange={e => setNoteText(e.target.value)}
                    />
                    <h4 style={{ margin: '10px 0px' }}>Average</h4>
                    <Rate
                      disabled
                      allowHalf
                      value={averageStarFill}
                      style={{ fontSize: '16px' }}
                    />
                    <span style={{ marginLeft: '20px' }}>{averageStar}</span>
                    <div style={{ marginTop: 16 }}>
                      <Button
                        danger
                        onClick={() => saveNote(false)}
                        style={{ marginRight: 8 }}
                      >
                        Not Yet
                      </Button>
                      <Button
                        type="primary"
                        disabled={approveDisabled}
                        ghost
                        onClick={showModal}
                      >
                        Approve
                      </Button>
                    </div>
                    {approveDisabled && (
                      <div style={{ marginTop: 16 }}>
                        <p>
                          You can approve the course after student got approved
                          on each goal and their average rating is 5 star.
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <Divider dashed />
                    <h3>Notes</h3>
                    {!notes.length && <p>No notes.</p>}
                    {notes.map(note => (
                      <div>
                        {note.approved && <Tag color="green">Approved</Tag>}
                        {!note.approved && <Tag color="red">Not Approved</Tag>}
                        <p style={{ marginTop: 16 }}>{note.text}</p>
                        <p>
                          Approver: {note.approver.firstName}{' '}
                          {note.approver.lastName}
                        </p>
                        <Divider />
                      </div>
                    ))}
                  </div>
                </>
              )}
          </Card>
        </Col>
        <Col span={8}>
          <Card size="small" title="Student Info" className="sidebar-widget">
            <p
              style={{ margin: '0' }}
            >{`${student.firstName} ${student.lastName}`}</p>
            <p>
              <b>Student ID:</b> {student.studentId}
            </p>
            <a href={`mailto:${student.email}`}>{student.email}</a>
          </Card>
          <Card size="small" title="Course Progress" className="sidebar-widget">
            <Progress percent={percentCompleted} />
          </Card>
          <Card size="small" title="Course Exam" className="sidebar-widget">
            {student.quizzes && student.quizzes.length ? null : (
              <div>
                <p>No student exams.</p>
              </div>
            )}
            <div>
              {student.quizzes &&
                student.quizzes.map(quiz => (
                  <div>
                    <a onClick={() => openQuizReview(quiz)}>
                      {moment(quiz.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                      : {quiz.score}%
                    </a>
                  </div>
                ))}
            </div>
          </Card>
          <Card size="small" title="Course projects">
            <>
              {(!course.projects || !course.projects.length) && (
                <p>No projects.</p>
              )}
              {course.projects &&
                course.projects.map(project => (
                  <>
                    {project.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: project.description,
                        }}
                      />
                    )}
                    <Typography.Text>Goals:</Typography.Text>
                    <Goals goals={project.goals} />
                    <h4 style={{ marginBottom: 0 }}>Student project</h4>
                    {studentProjects.length === 0 && (
                      <p style={{ marginTop: 8 }}>
                        Student did not submit this project.
                      </p>
                    )}
                    <div>
                      {studentProjects.map(up => (
                        <a href={up.file.url} target="_blank" rel="noreferrer">
                          {up.file.pid}
                        </a>
                      ))}
                    </div>
                    {studentProjects[0] && (
                      <StudentProjectReview
                        userProjectId={studentProjects[0].id}
                      />
                    )}
                  </>
                ))}
            </>
          </Card>
        </Col>
        {quizReview && (
          <QuizReviewModal
            quiz={quizReview}
            visible={!!quizReview}
            onOk={closeQuizReview}
            onCancel={closeQuizReview}
          />
        )}
      </Row>
      <Modal
        title="Verify Steps"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={confirmDisabled}
            onClick={handleOk}
          >
            Confirm
          </Button>,
        ]}
      >
        <div>
          <div style={{ margin: '16px 0' }}>
            <div style={{ margin: '16px 0', display: 'flex' }}>
              <span style={{ margin: '0 16px' }}>
                I have assessed enough objectives, including any I have reason
                to doubt the student’s mastery (e.g., missed questions on prior
                course exams, or prior Course Hearings), to be confident in my
                overall assessment.
              </span>
            </div>
            <div style={{ margin: '16px 0', display: 'flex' }}>
              <span style={{ margin: '0 16px' }}>
                Using the standards and rubrics for this course, I have
                determined that this student has mastered all the course goals
                and objectives.
              </span>
            </div>
            <div style={{ margin: '16px 0', display: 'flex' }}>
              <span style={{ margin: '0 16px' }}>
                I have written notes explaining my overall assessment.
              </span>
            </div>
            <div style={{ margin: '16px 0', display: 'flex' }}>
              <Checkbox
                onChange={(e: any) => confirmReview(e.target.checked)}
              />
              <span style={{ margin: '0 16px' }}>I confirm.</span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const selectCourseState = (state: AppState) => ({
  user: state.user.user,
});

export const StudentCourseProgress = connect(
  selectCourseState,
  {},
)(StudentCourseProgressComponent);

const Page = () => <StudentCourseProgress />;

export default Page;
