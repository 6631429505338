import React, { useEffect, useState } from 'react';
import { Row, Col, Select } from 'antd';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import DegreeList from '@components/degreeList/degreeList';
import './Degrees.less';
import Spinner from '@components/Spinner';
import DegreeService from '@services/degreeService';
import { isUserAdmin } from '@helpers';

interface DegreesProps {
  user?: User;
}

const Degrees: React.FC<DegreesProps> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [degreesStore, setDegreesStore] = useState<Degree[]>();
  const [degrees, setDegrees] = useState<Degree[]>();
  const { Option } = Select;
  useEffect(() => {
    if (!degrees && !loading) {
      setLoading(true);
      DegreeService.getAll().then(d => {
        // if (user.role == 'admin') {
        //   const filterDegrees = d.filter((a: any) => a.visibility == 'public');
        //   setDegrees(filterDegrees);
        // } else {
        //   setDegrees(d);
        // }
        const filterDegrees = d.filter((a: any) => a.visibility == 'public');
        setDegrees(filterDegrees);
        setDegreesStore(d);
        setLoading(false);
      });
    }
  }, [degrees, degreesStore, loading]);

  if (loading) {
    return <Spinner />;
  }
  if (!user || !degreesStore) {
    return null;
  }
  if (!user || !degrees) {
    return null;
  }
  const degreeFilterByType = (value: any) => {
    if (value != 'all') {
      const filterDegrees = degreesStore.filter(
        (D: any) => D.visibility == value,
      );
      setDegrees(filterDegrees);
    } else {
      setDegrees(degreesStore);
    }
  };

  return (
    <>
      <Row>
        <Col md={6} lg={{ span: 6, offset: 4 }} xl={{ span: 6, offset: 4 }}>
          <h1 style={{ margin: '15px 0' }}>Degrees</h1>
        </Col>
        {isUserAdmin(user) && (
          <Col md={{ offset: 1, span: 6 }}>
            <Select
              defaultValue="public"
              style={{ width: '120px', marginTop: '20px' }}
              onChange={(value: any) => {
                degreeFilterByType(value);
              }}
            >
              <Option value="all">ALL</Option>
              <Option value="public">PUBLISHED</Option>
              <Option value="private">PRIVATE</Option>
              <Option value="archived">ARCHIVED</Option>
            </Select>
          </Col>
        )}
      </Row>
      <DegreeList degrees={degrees} />
      {degrees.length === 0 && <p>No enrolled degrees.</p>}
    </>
  );
};

const selectDegreesState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedDegrees = connect(selectDegreesState)(Degrees);

const Page = () => <ConnectedDegrees />;

export default Page;
