import React, { useState, useEffect } from 'react';
import { List, Spin, Row, Col, Button, Progress, Tag } from 'antd';

import YouTube from 'react-youtube-embed';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import { isObjectiveCompleted } from '@utils/helpers';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';
import { markCompleted } from '@redux/reducers/user';
import { VideoCallType, Role } from '@types';
import { isUserAdmin } from '@helpers';
import { parsePath } from '@components/breadcrumbs/Breadcrumbs';
import NewlaneTutorialsService from '@services/newlaneTutorialsService';
import Spinner from '@components/Spinner';
import { toast } from 'react-toastify';
import './NewlaneTutorials.less';

interface NewlaneTutorialsProps {
  user?: User;
  NewlaneTutorials?: NewlaneTutorials;
}
const NewlaneTutorialsList: React.FC<NewlaneTutorialsProps> = ({
  user,
  NewlaneTutorials,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [newlaneTutorialsData, setNewlaneTutorials] = useState<any>();

  useEffect(() => {
    if (!newlaneTutorialsData && !loading) {
      setLoading(true);
      NewlaneTutorialsService.getAll().then((d: any) => {
        setNewlaneTutorials(d);
        setLoading(false);
      });
    }
  }, [newlaneTutorialsData, loading]);

  if (loading) {
    return <Spinner />;
  }
  if (!user) {
    return null;
  }
  const DeleteNewlaneTutorials = (id: any) => {
    // setLoading(true);
    NewlaneTutorialsService.remove(id).then((d: any) => {
      if (d.success) {
        NewlaneTutorialsService.getAll().then((d: any) => {
          toast.success('Delete successful!');
          setTimeout(() => {
            setNewlaneTutorials(d);
            // setLoading(false);
          }, 1000);
        });
      } else {
        toast.error('Delete failed!');
      }
    });
  };
  return (
    <div>
      {newlaneTutorialsData &&
        newlaneTutorialsData.map((newlane: any) => (
          <article className="degree-card-list mb-4">
            {isUserAdmin(user) && (
              <Row>
                <Col style={{ textAlign: 'end', margin: '8px' }}>
                  <Button
                    style={{ marginLeft: '5px' }}
                    onClick={() =>
                      history.push(`/newlane-tutorials/${newlane.id}/edit`)
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    style={{ marginLeft: '8px' }}
                    danger
                    onClick={() => DeleteNewlaneTutorials(newlane.id)}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            )}
            <Row gutter={[10, { sm: 10 }]}>
              {newlane.url && (
                <Col md={14}>
                  <YouTube id={newlane.url} />
                </Col>
              )}
              <Col md={newlane.url ? 10 : 24}>
                <div className="degree-card__body">
                  <h2 className="degree-card__title">{newlane.name}</h2>
                  <div>
                    <div>
                      <div className="degree-card__content">
                        <p>{newlane.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        ))}
    </div>
  );
};

export default connect(
  (state: AppState) => ({
    user: state.user.user,
  }),
  {},
)(NewlaneTutorialsList);
