import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Row, Col, Modal, Button, Table, Card } from 'antd';
import { toast } from 'react-toastify';
import { ColumnProps } from 'antd/lib/table';
import AnnouncementService from '@services/announcementService';
import AnnouncementModalForm from '@components/announcements/AnnouncementFormModal';

interface AnnouncementsScreenProps {}

const AnnouncementsScreen: React.FC<AnnouncementsScreenProps> = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>();
  const [editedAnnouncement, setEditedAnnouncement] = useState<Announcement>();
  const [deletedAnnouncement, setDeletedAnnouncement] =
    useState<Announcement>();

  const [announcementModalVisible, setAnnouncementModalVisible] =
    useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const getAnnouncements = useCallback(() => {
    setLoading(true);
    AnnouncementService.getAll()
      .then(response => {
        setLoading(false);
        setAnnouncements(response);
      })
      .catch(err => {
        console.error(err);
        setError(err);
        setLoading(false);
      });
  }, []);

  const updateAnnouncement = () => {
    if (!editedAnnouncement) {
      return false;
    }
    setLoading(true);
    AnnouncementService.update(editedAnnouncement.id, editedAnnouncement)
      .then(() => {
        setAnnouncementModalVisible(false);
        setEditedAnnouncement(undefined);
        setLoading(false);
        getAnnouncements();
        toast.success('Announcement updated successfully!');
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setError(err.message);
      });
  };

  const deleteAnnouncement = () => {
    if (!deletedAnnouncement) {
      return false;
    }
    setLoading(true);
    AnnouncementService.delete(deletedAnnouncement.id)
      .then(() => {
        setDeleteModalVisible(false);
        setDeletedAnnouncement(undefined);
        setLoading(false);
        getAnnouncements();
        toast.success('Announcement deleted successfully!');
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setError(err.message);
      });
  };

  useEffect(() => {
    if (!announcements && !loading) {
      getAnnouncements();
    }
  }, [announcements, loading, getAnnouncements]);

  const announcementColumns: ColumnProps<Announcement>[] = [
    {
      key: 'title',
      dataIndex: 'title',
      title: 'Title',
      sorter: true,
    },
    // {
    //   key: 'text',
    //   dataIndex: 'text',
    //   title: 'Text',
    //   sorter: true,
    // },
    {
      key: 'course',
      dataIndex: 'course',
      title: 'Course',
      sorter: true,
      render: (course?: Course) => {
        if (course) {
          return course.name;
        }
        return 'Global announcement';
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt?: Date) =>
        createdAt ? moment(createdAt).format('L') : '-',
      sorter: true,
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      defaultSortOrder: 'descend',
      render: (updatedAt?: Date) =>
        updatedAt ? moment(updatedAt).format('L') : '-',
      sorter: true,
    },
    {
      key: 'actions',
      render: (text, announcement) => (
        <div>
          <Button
            type="default"
            onClick={() => {
              setEditedAnnouncement(announcement);
              setAnnouncementModalVisible(true);
            }}
          >
            Edit
          </Button>

          <Button
            danger
            style={{ marginLeft: 8 }}
            onClick={() => {
              setDeletedAnnouncement(announcement);
              setDeleteModalVisible(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Announcements"
          size="small"
          extra={[
            <Button
              key="create"
              onClick={() => setAnnouncementModalVisible(true)}
            >
              Create announcement
            </Button>,
          ]}
        >
          <Table
            dataSource={announcements}
            columns={announcementColumns}
            rowKey="id"
            loading={loading}
          />
        </Card>
      </Col>

      {announcementModalVisible && (
        <AnnouncementModalForm
          announcementData={editedAnnouncement}
          visible={announcementModalVisible}
          onClose={() => {
            setAnnouncementModalVisible(false);
            getAnnouncements();
          }}
        />
      )}

      {deletedAnnouncement && (
        <Modal
          title="Are you sure you want to delete announcement?"
          width={550}
          visible={deleteModalVisible}
          okText="Delete announcement"
          okButtonProps={{ loading, danger: true }}
          onOk={() => deleteAnnouncement()}
          onCancel={() => setDeleteModalVisible(false)}
        >
          <p>
            <b>{`${deletedAnnouncement.title}`}</b>
          </p>
          <p>You will not be able to revert this action</p>
        </Modal>
      )}
    </Row>
  );
};

const Page = () => <AnnouncementsScreen />;

export default Page;
