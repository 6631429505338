import * as React from 'react';
import { Input } from 'antd';
import { useHistory } from 'react-router-dom';

const { Search } = Input;

const HeaderSearch: React.FC = () => {
  const history = useHistory();
  const handleSearch = async (value: string) => {
    history.push(`/search?searchText=${value}`);
  };

  return (
    <Search
      placeholder="Search"
      onSearch={handleSearch}
      className="app-header__search"
    />
  );
};

export default HeaderSearch;
