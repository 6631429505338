import React, { useRef, useState } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Progress, Tag, Divider, Carousel } from 'antd';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';

import './LessonCarousel.less';
import Objective from '@components/objectives/Objective';
import { getCompletedPercentage } from '@utils/helpers';
import { CarouselRef } from 'antd/lib/carousel';

interface LessonCarouselProps {
  lessons: Lesson[];
  user?: User;
}

const LessonCarousel: React.FC<LessonCarouselProps> = ({ lessons, user }) => {
  const [lessonIndex, setLessonIndex] = useState<number>(0);

  const carousel = useRef<CarouselRef>();

  if (!user) {
    return null;
  }

  return (
    <>
      <Carousel
        adaptiveHeight
        dots={false}
        arrows={false}
        afterChange={i => setLessonIndex(i)}
        ref={ref => {
          carousel.current = ref || undefined;
        }}
      >
        {lessons.map((lesson: Lesson) => (
          <div key={lesson.id}>
            <Tag color="cyan">Lesson</Tag>
            <h1>
              {lessonIndex + 1}/{lessons.length} {lesson.name}
            </h1>
            <Progress
              percent={getCompletedPercentage(lesson.objectives, user)}
            />
            <Divider dashed />
            <p>{lesson.shortDescription}</p>
            <Divider dashed />
            {lesson.objectives.map(objective => (
              <>
                <Objective objective={objective} />
                <Divider dashed />
              </>
            ))}
          </div>
        ))}
      </Carousel>
      <div style={{ textAlign: 'center' }}>
        {lessons[lessonIndex - 1] && (
          <Button
            type="default"
            onClick={() => {
              setLessonIndex(lessonIndex - 1);
              carousel.current && carousel.current.prev();
            }}
          >
            <ArrowLeftOutlined /> {lessons[lessonIndex - 1].name}
          </Button>
        )}
        {lessons[lessonIndex + 1] && (
          <Button
            type="default"
            onClick={() => {
              setLessonIndex(lessonIndex + 1);
              carousel.current && carousel.current.next();
            }}
          >
            <ArrowRightOutlined /> {lessons[lessonIndex + 1].name}
          </Button>
        )}
      </div>
    </>
  );
};

const selectLessonCarouselState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedLessonCarousel = connect(selectLessonCarouselState)(
  LessonCarousel,
);

export default ConnectedLessonCarousel;
