import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table, Select, Button, Checkbox, Tag, Space } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import CourseService from '@services/courseService';
import DegreeService from '@services/degreeService';
import UserService from '@services/userService';

interface InstructorListProps {
  type: 'course' | 'degree';
  itemId: number;
  item?: Course;
  onUpdate?: () => void;
}

const InstructorList: React.FC<InstructorListProps> = ({
  itemId,
  type,
  item,
  onUpdate,
}) => {
  const [instructors, setInstructors] = useState<User[]>();
  const [allInstructors, setAllInstructors] = useState<User[]>();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState<number>();

  const mainInstructorId = item?.mainInstructor?.id || undefined;

  const deleteInstructor = async (instructorId: number) => {
    if (!instructorId) {
      return false;
    }

    if (type === 'course') {
      await CourseService.deleteInstructor(itemId, instructorId);
    }

    if (type === 'degree') {
      await DegreeService.deleteInstructor(itemId, instructorId);
    }
    setUserId(undefined);
    return fetchInstructors();
  };

  const addMainInstructor = async (instructorId: number) => {
    if (!instructorId) {
      return false;
    }
    if (type === 'course') {
      const data = {
        userId: instructorId,
        addRemove: mainInstructorId !== instructorId,
      };
      await CourseService.addMainInstructor(itemId, data);
    }
    return onUpdate?.();
  };

  const instructorColumns: ColumnProps<User>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: any, record: User) =>
        `${record.firstName} ${record.lastName}(${record.email})`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Last active',
      dataIndex: 'lastActive',
      key: 'lastActive',
      render: (lastActive?: Date) =>
        lastActive ? moment(lastActive).format('L') : '-',
      sorter: (a: any, b: any) =>
        moment(a.lastActive).unix() - moment(b.lastActive).unix(),
    },
    {
      title: 'Signup date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt?: Date) =>
        createdAt ? moment(createdAt).format('L') : '-',
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (value: any, item) => (
        <Space>
          {type === 'course' ? (
            <Button
              onClick={() => addMainInstructor(item.id)}
              type="primary"
              style={{ marginRight: 8 }}
            >
              {item.id !== mainInstructorId
                ? 'Set main instructor'
                : 'Remove main instructor'}
            </Button>
          ) : null}
          <Button onClick={() => deleteInstructor(item.id)} ghost danger>
            Remove
          </Button>
        </Space>
      ),
    },
  ];

  const fetchInstructors = async () => {
    let data: User[] = [];
    setLoading(true);
    if (type === 'course') {
      data = await CourseService.getInstructors(itemId);
    }

    if (type === 'degree') {
      data = await DegreeService.getInstructors(itemId);
    }

    const allData = await UserService.getInstructors();

    setAllInstructors(allData.filter(u => !data.find(d => d.id === u.id)));
    setInstructors(data);
    setLoading(false);
  };

  const handleAddInstructor = async () => {
    if (!userId) {
      return false;
    }

    if (type === 'course') {
      await CourseService.addInstructor(itemId, { userId });
    }

    if (type === 'degree') {
      await DegreeService.addInstructor(itemId, { userId });
    }
    setUserId(undefined);
    return fetchInstructors();
  };

  useEffect(() => {
    if (!instructors && !loading) {
      fetchInstructors();
    }
  });

  return (
    <div>
      <Select<number>
        placeholder="Select user"
        style={{ width: '300px' }}
        onSelect={value => setUserId(Number(value))}
        value={userId}
      >
        {allInstructors &&
          allInstructors.map(user => (
            <Select.Option key={user.id} value={user.id}>
              {user.firstName} {user.lastName} ({user.email})
            </Select.Option>
          ))}
      </Select>
      <Button
        onClick={() => handleAddInstructor()}
        type="primary"
        style={{ marginLeft: 16, marginBottom: 16 }}
      >
        Add as instructor
      </Button>
      <Table
        dataSource={instructors}
        loading={loading}
        columns={instructorColumns}
      />
    </div>
  );
};

export default InstructorList;
