import React, { useState, useEffect } from 'react';
import CourseService from '@services/courseService';
import { Link } from 'react-router-dom';
import DegreeService from '@services/degreeService';

interface StudentListProps {
  id: number;
  type: 'course' | 'degree';
}

const StudentList: React.FC<StudentListProps> = ({ id, type }) => {
  const [students, setStudents] = useState<User[]>();

  useEffect(() => {
    if (!students) {
      type === 'course'
        ? CourseService.getStudents(id).then(setStudents)
        : DegreeService.getStudents(id).then(setStudents);
    }
  }, [students, type, id]);

  return (
    <ul style={{ padding: '0 16px' }}>
      {students && !students.length ? <li>No students enrolled.</li> : null}
      {students && students.length
        ? students.map(student => (
            <li>
              <Link
                to={
                  type === 'course'
                    ? `/courses/${id}/students/${student.id}`
                    : `/students/${student.id}`
                }
              >
                {`${student.firstName} ${student.lastName}`}
              </Link>
            </li>
          ))
        : null}
    </ul>
  );
};

export default StudentList;
