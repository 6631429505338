import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';

import ObjectiveForm from '@components/objectives/ObjectiveForm';
import withObjective from '@hoc/withObjective';

interface EditObjectiveProps {
  user?: User;
  objective: Objective;
}

const EditObjective: React.FC<EditObjectiveProps> = ({ user, objective }) => {
  if (!user) {
    return null;
  }

  return <ObjectiveForm objective={objective} />;
};

const selectEditObjectiveState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedEditObjective = withObjective(
  connect(selectEditObjectiveState)(EditObjective),
);

const Page = () => <ConnectedEditObjective />;

export default Page;
