import { getFileUrl } from '@helpers';
import ProjectService from '@services/projectService';
import { UserProjectStatus } from '@types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Input, Modal, Rate } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { StudentProjectReview } from './StudentProjectReview';

interface ProjectReviewData extends ProjectReview {
  id?: number;
}

interface StudentProjectReviewModalProps {
  userProject: UserProject;
  onClose: () => void;
  visible: boolean;
}

export const StudentProjectReviewModal: React.FC<
  StudentProjectReviewModalProps
> = ({ userProject, onClose, visible }) => {
  const { project, user, review } = userProject;
  const [noteText, setNoteText] = useState(userProject.note);
  const [projectReview, setReview] = useState<ProjectReviewData>(
    review || {
      grades: project.goals.map(goal => ({
        goal,
        goalId: goal.id,
      })),
      userProjectId: userProject.id,
    },
  );

  const [userProjects, setUserProjects] = useState<UserProject[]>();

  const approveDisabled = useMemo(() => {
    if (!projectReview) return true;

    if (project.goals.length !== projectReview.grades.length) {
      return true;
    }

    return !!projectReview.grades.find(
      grade => !grade.grade || grade.grade < 5,
    );
  }, [project.goals.length, projectReview]);

  useEffect(() => {
    ProjectService.getAllUserProjectsForProject(
      userProject.userId,
      userProject.projectId,
    ).then(data =>
      setUserProjects(data.filter(up => up.id !== userProject.id)),
    );
  }, [userProject.userId, userProject.projectId, userProject.id]);

  const handleGradeChange = (goalId: number) => (value: number) => {
    const newReview = { ...projectReview };
    const gradeIndex = newReview.grades.findIndex(g => g.goalId === goalId);

    if (gradeIndex === -1) {
      const goal = project.goals.find(g => g.id === goalId);
      goal &&
        newReview.grades.push({
          goalId,
          goal,
          grade: value,
        });
    } else {
      newReview.grades[gradeIndex].grade = value;
    }

    setReview(newReview);
  };

  const handleNoteChange = (goalId: number) => (value: string) => {
    const newReview = { ...projectReview };
    const gradeIndex = newReview.grades.findIndex(g => g.goalId === goalId);

    if (gradeIndex === -1) {
      const goal = project.goals.find(g => g.id === goalId);
      goal &&
        newReview.grades.push({
          goalId,
          goal,
          note: value,
        });
    } else {
      newReview.grades[gradeIndex].note = value;
    }

    setReview(newReview);
  };

  const updateProjectReview = async (approved: boolean) => {
    try {
      await ProjectService.updateProjectReview(userProject.id, {
        review: projectReview,
        userProject: {
          ...userProject,
          status: approved
            ? UserProjectStatus.Approved
            : UserProjectStatus.NotYet,
          note: noteText,
        },
      });
      toast.success('Student project review updated!');
      onClose();
    } catch (err) {
      toast.error('An error occured!');
    }
  };

  const getGradeNote = (goalId: number) => {
    const grade = projectReview.grades.find(grade => grade.goalId === goalId);

    if (grade) {
      return grade.note;
    }

    return undefined;
  };

  const getGradeValue = (goalId: number) => {
    const grade = projectReview.grades.find(grade => grade.goalId === goalId);

    if (grade) {
      return grade.grade;
    }

    return undefined;
  };

  return (
    <Modal
      visible={visible}
      footer={false}
      destroyOnClose
      title="Student project review"
      onCancel={onClose}
    >
      <p>Project: {project.description}</p>
      <p>Student: {`${user.firstName} ${user.lastName}`}</p>
      <p>
        Project file:{' '}
        <a
          href={getFileUrl(userProject.file.url)}
          target="_blank"
          rel="noreferrer"
        >
          {userProject.file.pid || userProject.file.url}
        </a>
      </p>
      {project.goals.length === 0 ? (
        <p style={{ color: 'red' }}>
          This project does not have any grade rubric. Please add goals to the
          project.
        </p>
      ) : null}
      {project.goals.map(goal => (
        <div style={{ margin: '16px 0' }}>
          <p style={{ margin: 0 }}>{goal.text}</p>
          <Rate
            value={getGradeValue(goal.id)}
            onChange={handleGradeChange(goal.id)}
            tooltips={[
              'NOT YET: Student did not attempt to satisfy this requirement.',
              'NOT YET: Student attempted to satisfy this requirement but is doing it in the wrong way.',
              'NOT YET: Student has started to satisfy this requirement but needs further development.',
              'NOT YET: Student is close to satisfying this requirement but needs a little more work.',
              'APPROVED: Student has clearly satisfied this requirement.',
            ]}
          />
          <Input
            style={{ margin: '8px 0 0 0' }}
            placeholder="Note"
            value={getGradeNote(goal.id)}
            onChange={e => handleNoteChange(goal.id)(e.target.value)}
          />
        </div>
      ))}

      <h4>General note</h4>
      <Input.TextArea
        value={noteText}
        defaultValue={userProject.note}
        onChange={e => setNoteText(e.target.value)}
      />
      <div style={{ marginTop: 16 }}>
        <Button
          danger
          onClick={() => updateProjectReview(false)}
          style={{ marginRight: 8 }}
        >
          Not Yet
        </Button>
        <Button
          type="primary"
          ghost
          onClick={() => updateProjectReview(true)}
          disabled={approveDisabled}
        >
          Approve
        </Button>
      </div>
      {approveDisabled && (
        <div style={{ marginTop: 16 }}>
          <p>
            You can approve the course after student got approved on each goal.
          </p>
        </div>
      )}

      <h3 style={{ marginTop: '16px' }}>Other student project submissions</h3>

      {userProjects && userProjects.length === 0 ? (
        <p>No other submissions.</p>
      ) : null}

      {userProjects &&
        userProjects.map(up => (
          <Card>
            <div style={{ marginBottom: '16px' }}>
              {up && (
                <a
                  key={up.id}
                  href={getFileUrl(up.file.url)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {up.file.pid}
                </a>
              )}
              <p>
                {up.status === UserProjectStatus.Approved && (
                  <span className="text-success">
                    <CheckOutlined /> APPROVED
                  </span>
                )}
                {up.status === UserProjectStatus.NotYet && (
                  <span className="text-error">
                    <CloseOutlined /> NOT YET
                  </span>
                )}
                {up.status === UserProjectStatus.Pending && (
                  <span className="text-error">
                    <CloseOutlined /> APPROVAL PENDING
                  </span>
                )}
              </p>
              <p>Submitted on: {moment(up.file.createdAt).format()}</p>
            </div>
            {up && up.projectReviewId ? (
              <StudentProjectReview userProjectId={up.id} />
            ) : (
              <p>Review pending.</p>
            )}
          </Card>
        ))}

      {/* <div style={{ marginTop: '16px' }}>
        <Button type="primary" onClick={() => updateProjectReview()}>
          Save
        </Button>
      </div> */}
    </Modal>
  );
};
