import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Table, Select, Input, PageHeader } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import moment from 'moment';
// import deleteIcon1 from '@assets/delete1.png';
// import deleteIcon2 from '@assets/delete2.jpg';
// import deleteIcon3 from '@assets/delete3.jpg';
// import deleteIcon4 from '@assets/delete4.png';
import { AppState } from '@redux/reducers';
import UserService, { IUserSorter } from '@services/userService';
import './SingleStudent.less';

const { Search } = Input;

interface StudentsPageProps {
  user?: User;
}

const StudentsPage: React.FC<StudentsPageProps> = ({ user }) => {
  const { Option } = Select;
  const [users, setUsers] = useState<User[]>();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
  });
  const [sorter, setSorter] = useState<IUserSorter>({
    field: 'createdAt',
    order: 'DESC',
  });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>();
  const [error, setError] = useState<string>();
  const [subscriptionStatusUser, setSubscriptionStatusUser] = useState<any>([
    'enrolled',
  ]);
  const handleSearch = (value: any) => {
    const pager = { ...pagination };
    pager.current = 1;
    setPagination(pager);
    setSearchTerm(value);
    getUsers(pager, sorter, value, subscriptionStatusUser);
  };

  const getUsers = useCallback(
    (p: any, s: any, st?: string, subscriptionStatusUser?: any) => {
      setLoading(true);
      UserService.getAllStudents({
        searchTerm: st,
        page: p.current || 1,
        sort: s,
        subscriptionStatusUser,
      })
        .then(response => {
          setLoading(false);
          setTotal(response.total);
          setPagination({ ...p, total: response.total });
          setUsers(response.data);
        })
        .catch(err => {
          console.error(err);
          setError(err);
          setLoading(false);
        });
    },
    [],
  );

  useEffect(() => {
    if (!users && !loading) {
      getUsers(pagination, sorter, searchTerm, subscriptionStatusUser);
    }
  }, [users, loading, getUsers, pagination, sorter]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    const newSorter: IUserSorter = {
      field: sorter.field,
      order: sorter.order === 'descend' ? 'DESC' : 'ASC',
    };
    setPagination(pager);
    setSorter(newSorter);
    getUsers(pager, newSorter, searchTerm, subscriptionStatusUser);
  };
  const handleChange = (value: any) => {
    if (
      value.indexOf('enrolled') == -1 &&
      value.indexOf('trial') == -1 &&
      value.indexOf('registered') == -1 &&
      value.indexOf('archived') == -1 &&
      value.indexOf('graduate') == -1
    ) {
      setSubscriptionStatusUser(['all']);
      getUsers(pagination, sorter, searchTerm, ['all']);
    } else {
      const filtered = value.filter((a: any) => a != 'all');
      setSubscriptionStatusUser(filtered);
      getUsers(pagination, sorter, searchTerm, filtered);
    }
    // setUsers([]);
  };
  const userColumns: ColumnProps<User>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      // sorter: true,
      render: (name: any, record: User) => (
        <Link to={`/students/${record.id}`}>
          {`${record.firstName} ${record.lastName}(${record.email})`}
        </Link>
      ),
    },
    {
      title: 'Student id',
      dataIndex: 'studentId',
      key: 'studentId',
      render: (studentId: any, record: User) => studentId,
    },
    {
      title: 'Subscription status',
      dataIndex: 'subscriptionStatus',
      key: 'subscriptionStatus',
      render: (subscriptionStatus: any) =>
        subscriptionStatus == 'active'
          ? 'Active'
          : subscriptionStatus == 'trialing'
          ? 'Trialing'
          : subscriptionStatus == 'canceled'
          ? 'Canceled'
          : subscriptionStatus == 'past_due'
          ? 'Past due'
          : '',
      sorter: true,
    },
    {
      // title: 'Registered / Enrolled',
      title: 'Status',
      dataIndex: 'studentEnrolledType',
      key: 'studentEnrolledType',
      render: (studentEnrolledType: any) =>
        studentEnrolledType == 'registered'
          ? 'Registered'
          : studentEnrolledType == 'enrolled'
          ? 'Enrolled'
          : studentEnrolledType == 'trial'
          ? 'Trial'
          : studentEnrolledType == 'on_leave'
          ? 'On Leave'
          : studentEnrolledType == 'archived'
          ? 'Archived'
          : studentEnrolledType == 'graduate'
          ? 'Graduate'
          : '',
      sorter: true,
    },
    {
      title: 'Subscription type',
      dataIndex: 'subscriptionType',
      key: 'subscriptionType',
      render: (subscriptionType: any) =>
        subscriptionType == 'free'
          ? 'Free'
          : '' || subscriptionType == 'monthly'
          ? 'Monthly'
          : '',
      sorter: true,
    },
    {
      title: 'Enrolled degrees',
      dataIndex: 'enrolledDegrees',
      key: 'enrolledDegrees',
      render: (enrolledDegrees: any, record: User) => (
        <div className="enrolledDegrees">
          {enrolledDegrees.enrolledDegrees.map((d: any, i: any) => (
            <span>
              {i + 1}: {d.degree.name} <br />
            </span>
          ))}
        </div>
      ),
      // sorter: (a: any, b: any) => a.enrolledDegreesCount - b.enrolledDegreesCount,
    },
    {
      title: 'PT/FT Status',
      dataIndex: 'studyType',
      key: 'studyType',
      render: (studyType: any) =>
        studyType == 'part_time'
          ? 'Part Time'
          : '' || studyType == 'full_time'
          ? 'Full Time'
          : '',
      sorter: true,
    },
    {
      title: 'Enrolled courses',
      dataIndex: 'enrolledCoursesCount',
      key: 'enrolledCoursesCount',
      sorter: true,
    },
    {
      title: 'Passed courses',
      dataIndex: 'passedCoursesCount',
      key: 'passedCoursesCount',
      sorter: true,
    },
    {
      title: 'Completed objectives',
      dataIndex: 'completedObjectivesCount',
      key: 'completedObjectivesCount',
      defaultSortOrder: 'descend',
      sorter: true,
    },
    {
      title: 'Last active',
      dataIndex: 'lastActive',
      key: 'lastActive',
      render: (lastActive?: Date) =>
        lastActive ? moment(lastActive).format('L') : '-',
      sorter: true,
    },
    {
      title: 'Signup date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt?: Date) =>
        createdAt ? moment(createdAt).format('L') : '-',
      sorter: true,
    },
    {
      title: 'Academic progress',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
    },
    // {
    //   key: 'firstName',
    //   dataIndex: 'firstName',
    //   title: 'First name',
    //   sorter: true,
    // },
    // {
    //   key: 'lastName',
    //   dataIndex: 'lastName',
    //   title: 'Last name',
    //   sorter: true,
    // },
    // {
    //   key: 'role',
    //   dataIndex: 'role',
    //   title: 'Role',
    //   sorter: true,
    // },
    // {
    //   key: 'canOnboardStudents',
    //   dataIndex: 'canOnboardStudents',
    //   title: 'Can onboard students?',
    //   sorter: true,
    //   render: text => <Icon type={text ? 'check' : 'close'} />,
    // },
    // {
    //   title: 'Last active',
    //   dataIndex: 'lastActive',
    //   key: 'lastActive',
    //   render: (lastActive?: Date) =>
    //     lastActive ? moment(lastActive).format('L') : '-',
    //   sorter: true,
    // },
    // {
    //   title: 'Signup date',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   defaultSortOrder: 'descend',
    //   render: (createdAt?: Date) =>
    //     createdAt ? moment(createdAt).format('L') : '-',
    //   sorter: true,
    // },
  ];

  return (
    <Row gutter={16}>
      <Col span={24}>
        {/* <Card title="Users" size="small">
        <div style={{ marginBottom: 16 }}>
          <Search
            placeholder="Search users by email, first name and last name"
            onSearch={handleSearch}
            loading={loading}
          />
        </div>
        <Table
          dataSource={users}
          columns={userColumns}
          rowKey="id"
          onChange={handleTableChange}
          pagination={pagination}
        />
        </Card> */}
        <PageHeader
          style={{
            background: '#fff',
            padding: '16px 16px 0',
          }}
          title={
            <>
              <h2 style={{ margin: '8px 0' }}>Students (Total: {total})</h2>
            </>
          }
        />
        <div style={{ padding: 16, background: '#fff' }}>
          <Search
            placeholder="Search users by email, first name and last name"
            onSearch={handleSearch}
            loading={loading}
          />
        </div>
        <div
          style={{
            background: '#fff',
            padding: '4px 16px 0',
          }}
        >
          <Select
            mode="multiple"
            className="select-filter"
            placeholder=""
            value={subscriptionStatusUser}
            onChange={handleChange}
          >
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="registered">Registered</Select.Option>
            <Select.Option value="enrolled">Enrolled</Select.Option>
            <Select.Option value="trial">Trial</Select.Option>
            <Select.Option value="on_leave">On leave</Select.Option>
            <Select.Option value="archived">Archived</Select.Option>
            <Select.Option value="graduate">Graduate</Select.Option>
          </Select>
        </div>
        <div
          style={{
            background: '#fff',
            padding: '16px 16px 0',
          }}
        >
          <Table
            className="ant-table-responsive"
            dataSource={users}
            columns={userColumns}
            rowKey="id"
            onChange={handleTableChange}
            pagination={pagination}
          />
        </div>
      </Col>
    </Row>
  );
};

const selectStudentsPageState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedStudentsPage = connect(
  selectStudentsPageState,
  {},
)(StudentsPage);

const Page = () => <ConnectedStudentsPage />;

export default Page;
