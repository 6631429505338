export const getChatUserName = (user: User, users: User[]) => {
  if (!user) {
    return '';
  }

  const chatUser = users.find(u => u.id !== user.id);

  if (chatUser) {
    return `${chatUser.firstName} ${chatUser.lastName}`;
  }
};

export const getChatUser = (user: User, users: User[]) => {
  if (!user) {
    return null;
  }

  const chatUser = users.find(u => u.id !== user.id);

  return chatUser;
};
