import React, { useState } from 'react';
import moment from 'moment';
import { Button, Modal, DatePicker, TimePicker, Alert } from 'antd';
import CourseService from '@services/courseService';
import { DATE_FORMAT, DATETIME_FORMAT, TIME_FORMAT } from './constants';
import UserService from '@services/userService';

interface ScheduleModalProps {
  courseId?: number;
  visible: boolean;
  onSubmit: any;
  onCancel: any;
}

const ScheduleModal: React.FC<ScheduleModalProps> = ({
  courseId,
  visible,
  onSubmit,
  onCancel,
}) => {
  const [error, setError] = useState<String>();
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add(1, 'week').isoWeekday(1),
  );
  const [startTime, setStartTime] = useState<moment.Moment>(
    moment('9:00 am', TIME_FORMAT),
  );

  const onChangeDate = (value: moment.Moment | null) => {
    value && setStartDate(value);
  };

  const onOkDate = (value: moment.Moment) => {
    //console.log('onOk: ', value);
  };

  const onChangeTime = (value: moment.Moment | null) => {
    value && setStartTime(value);
  };

  const submit = () => {
    if (!startDate || !startTime) {
      setError('Please select date & time');
    }
    const dateString = `${startDate.format(DATE_FORMAT)} ${startTime.format(
      TIME_FORMAT,
    )}}`;
    const timestamp = moment(dateString, DATETIME_FORMAT).unix();
    //console.log(timestamp);
    if (courseId) {
      CourseService.scheduleCall(courseId, timestamp).then(onSubmit);
    } else {
      UserService.scheduleCall(timestamp).then(onSubmit);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Schedule Call"
      footer={null}
      onCancel={onCancel}
    >
      <DatePicker
        placeholder="Select Date"
        onChange={onChangeDate}
        onOk={onOkDate}
        allowClear={false}
        value={startDate}
        disabledDate={(date?: moment.Moment) => {
          if (!date) {
            return false;
          }

          return date.isoWeekday() === 6 || date.isoWeekday() === 7;
        }}
      />
      <TimePicker
        allowClear={false}
        placeholder="Select Time"
        onChange={onChangeTime}
        format={TIME_FORMAT}
        minuteStep={30}
        value={startTime}
        hideDisabledOptions={true}
      />
      {error && <Alert type="error" message={error} />}
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button type="primary" onClick={() => submit()}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default ScheduleModal;
