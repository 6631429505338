import React, { ReactNode, useState } from 'react';
import {
  Button,
  Tag,
  PageHeader,
  Divider,
} from 'antd';
import FilePreview from '@components/files/FilePreview';
import { markCompleted, objectiveMedia } from '@redux/reducers/user';
import { connect } from 'react-redux';
import { AppState } from '@redux/reducers';
import { isObjectiveCompleted } from '@utils/helpers';
import { useHistory } from 'react-router';
import { isUserInstructor } from '@helpers';
import AssessmentList from './AssessmentsList';
// import objectiveIcon from '@assets/4194667.png';
// import './Objective.less';

interface ObjectiveProps {
  objective: Objective;
  user?: User;
  markCompleted: typeof markCompleted;
  objectiveMedia: typeof objectiveMedia;
  markCompletedLoading: boolean;
}
const Objective: React.FC<ObjectiveProps> = ({
  objective,
  markCompleted,
  objectiveMedia,
  user,
  markCompletedLoading,
}) => {
  const history = useHistory();
  const [objectiveDetails, setObjectiveDetails] = useState(objective);
  //setObjectiveDetails(objective);
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState('');

  const [formData, setFormData] = useState({
    description: '',
    mediaNotWorking: false,
    mediaIncorrect: false,
    mediaOffensive: false,
    objectiveId: objective.id,
    objectiveName: objective.name,
  });
  const [description, setTitle] = React.useState('');
  const [mediaNotWorking, setMediaNotWorking] = React.useState(false);
  const [mediaIncorrect, setMediaIncorrect] = React.useState(false);
  const [mediaOffensive, setMediaOffensive] = React.useState(false);
  const [massage, setMassage] = React.useState(false);

  const [modalTitle, setModalTitle] = React.useState(
    'Objective name - ' + objective.name,
  );

  if (!user) {
    return null;
  }

  let extra: ReactNode[] = [];

  const isCompleted = isObjectiveCompleted(objective.id, user);
  if (isUserInstructor(user)) {
    extra = [
      <Button
        key="edit"
        onClick={() => history.push(`/objectives/${objective.id}/edit`)}
      >
        Edit
      </Button>,
    ];
  }
  const content = (
    <div>
      <p>{objective.name}</p>
    </div>
  );
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    if (formData.description != '') {
      objectiveMedia(formData);
      setTimeout(() => {
        setMassage(true);
        setConfirmLoading(false);
        handleCancel1();
      }, 2000);
    } else {
     // console.log(formData);
    }
  };

  const handleCancel1 = () => {
    setTimeout(() => {
      setMassage(false);
      setVisible(false);
    }, 2000);
    // setMediaNotWorking(false);
    // setFormData({ ...formData, mediaNotWorking: false });
    // setFormData({ ...formData, mediaIncorrect: false });
    // setFormData({ ...formData, mediaOffensive: false });
    setFormData({ ...formData, description: '' });
  };
  const handleCancel = () => {
    setMassage(false);
    setVisible(false);
    setFormData({ ...formData, description: '' });
  };
  return (
    <div style={{ background: '#fff' }}>
      <PageHeader
        style={{
          background: '#fff',
          padding: '16px 16px 0',
        }}
        extra={extra}
        title={
          <>
            <Tag color="blue" style={{ fontWeight: 400 }}>
              Objective
            </Tag>
            <h2 style={{ margin: '8px 0' }}>{objective.name}</h2>
          </>
        }
      />
      <div style={{ padding: '16px' }}>
        <Divider style={{ margin: '0 0 20px' }} />
        {/*<h3>Objective media</h3>*/}
        {/*<span>
          <Popover content={content} title="Objective name">
            <img
              onClick={showModal}
              src={objectiveIcon}
              className="objective-icone-img"
              alt="Newlane University"
            />
          </Popover>
          <Modal
            title={modalTitle}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={confirmLoading}
                onClick={handleOk}
              >
                Submit
              </Button>,
            ]}
          >
            {massage ? (
              <div>
                <p style={{ textAlign: 'center', color: '#52c41a' }}>
                  Thanks, we will get it fixed as soon as possible.
                </p>
              </div>
            ) : (
              ''
            )}
            <p>
              <Checkbox
                defaultChecked={mediaNotWorking}
                onChange={e =>
                  setFormData({
                    ...formData,
                    mediaNotWorking: e.target.checked,
                  })
                }
              />{' '}
              Media is missing or not working
            </p>
            <p>
              <Checkbox
                defaultChecked={mediaIncorrect}
                onChange={e =>
                  setFormData({ ...formData, mediaIncorrect: e.target.checked })
                }
              />{' '}
              Media is incorrect
            </p>
            <p>
              <Checkbox
                defaultChecked={mediaOffensive}
                onChange={e =>
                  setFormData({ ...formData, mediaOffensive: e.target.checked })
                }
              />{' '}
              Media is offensive
            </p>

            <p>Media description</p>
            <p>
              <Input
                onChange={e =>
                  setFormData({ ...formData, description: e.target.value })
                }
                value={formData.description}
                type="text"
                name="description"
                id="description"
                placeholder="Media description"
              />
            </p>
          </Modal>
        </span>*/}
        {!objective.media || !objective.media.length ? <p>No media.</p> : null}
        {objective.media.map(media => (
          <div key={media.id} style={{ margin: '16px 0' }}>
            <FilePreview
              objectiveDetails={objectiveDetails}
              file={
                media.file
                  ? {
                      ...(media.file as ApiFile),
                      description: media.description,
                      time: media.time,
                    }
                  : (media as ApiFile)
              }
            />
            <Divider />
          </div>
        ))}

        {isUserInstructor(user) && objective.assessments ? (
          <AssessmentList assessments={objective.assessments} />
        ) : null}

        {!isCompleted && !isUserInstructor(user) ? (
          <div style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              loading={markCompletedLoading}
              onClick={() => markCompleted(objective.id)}
            >
              Mark completed
            </Button>
          </div>
        ) : null}
        {isCompleted && !isUserInstructor(user) ? (
          <div style={{ textAlign: 'center' }}>
            <Button disabled={true}>Completed</Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const selectObjectiveState = (state: AppState) => {
  return {
    user: state.user.user,
    markCompletedLoading: state.user.markCompletedLoading,
  };
};

const ConnectedObjective = connect(selectObjectiveState, {
  markCompleted,
  objectiveMedia,
})(Objective);

export default ConnectedObjective;
