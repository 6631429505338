import React, { FunctionComponent } from 'react';
import { Input, Button, Form } from 'antd';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import { addUserNote } from '@redux/reducers/userNotes';
import { useRouter } from '@utils/routerHooks';

const { TextArea } = Input;

interface DispatchProps {
  addUserNote: typeof addUserNote;
}

interface TParams {
  id?: number;
}

type NotesFormProps = DispatchProps;

const NotesForm: FunctionComponent<NotesFormProps> = ({
  addUserNote: createUserNote,
}) => {
  const [form] = Form.useForm();
  const { resetFields } = form;
  const { match } = useRouter<TParams>();
  const objectiveId = Number(match.params.id);

  // Notes Form

  const handleSubmit = (values: any) => {
    try {
      createUserNote({ ...values, objective: objectiveId });
      resetFields();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <div className="notes--form">
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="note"
          label="Note"
          hasFeedback
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please input a note',
            },
          ]}
        >
          <TextArea placeholder="Note" autoSize={{ minRows: 2, maxRows: 5 }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save note
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default connect(null, { addUserNote })(NotesForm);
