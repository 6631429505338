import React, { useEffect } from 'react';
import { Progress, Collapse } from 'antd';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { AppState } from '@redux/reducers';
import { markCompleted } from '@redux/reducers/user';
import { getCompletedPercentage } from '@utils/helpers';
import ObjectiveList from '@components/objectives/ObjectiveList';

import './LessonList.less';
import { isUserSubscribed, isUserInstructor } from '@helpers';
interface LessonListProps {
  lessons: Lesson[];
  markCompleted: typeof markCompleted;
  markCompletedLoading: boolean;
  user?: User;
  hideShowDetails?: any;
  startCourses?: any;
  percentCompleted?: any;
}

const LessonList: React.FC<LessonListProps> = ({
  lessons,
  markCompletedLoading,
  markCompleted,
  user,
  hideShowDetails,
  startCourses,
  percentCompleted,
}) => {
  useEffect(() => {}, []);
  const location = useLocation();
  if (!user) {
    return null;
  }
  if (lessons.length === 0) {
    return <p>No lessons.</p>;
  }
  const calculationMediaForLeason = (lesson: any) => {
    let madia = 0;
    if (lesson.objectives && lesson.objectives.length > 0) {
      for (var i = 0; i <= lesson.objectives.length; i++) {
        var element = lesson.objectives[i];
        if (element && element.media && element.media.length >= 0) {
          madia += element.media.length;
        }
      }
      return madia;
    } else {
      return madia;
    }
  };
  const calculationassessmentForLeason = (lesson: any) => {
    let assessments = 0;
    if (lesson.objectives && lesson.objectives.length > 0) {
      for (var i = 0; i <= lesson.objectives.length; i++) {
        var element = lesson.objectives[i];
        if (element && element.assessments && element.assessments.length >= 0) {
          assessments += element.assessments.length;
        }
      }
      return assessments;
    } else {
      return assessments;
    }
  };
  const disabledCollapse = () => {
    if (!isUserSubscribed(user)) {
      return true;
    } else {
      if (!isUserInstructor(user)) {
        if (percentCompleted == 0 && startCourses) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };
  return (
    <Collapse>
      {lessons.map((lesson: Lesson, i: number) => (
        <Collapse.Panel
          header={
            <div className="lessons">
              <div className="lessons-tittie">
                {isUserInstructor(user) ? (
                  <Link to={`${location.pathname}/lessons/${lesson.id}`}>
                    {lesson.name}
                  </Link>
                ) : (
                  <span>
                    {percentCompleted > 0 || startCourses == false ? (
                      <Link to={`${location.pathname}/lessons/${lesson.id}`}>
                        {lesson.name}
                      </Link>
                    ) : (
                      <span>{lesson.name}</span>
                    )}
                  </span>
                )}

                {/*<Link to={`${location.pathname}/lessons/${lesson.id}`}>
                  {lesson.name}
                </Link>*/}
              </div>
              {hideShowDetails && isUserInstructor(user) && (
                <div className="calculation">
                  <div className="tittie">
                    O:&nbsp;&nbsp;
                    <span>
                      {lesson.objectives &&
                        (lesson.objectives.length > 0 ? (
                          <span>{lesson.objectives.length}</span>
                        ) : (
                          '0'
                        ))}
                    </span>
                  </div>
                  <div className="tittie">
                    M:&nbsp;&nbsp;
                    {lesson.objectives &&
                      (lesson.objectives.length > 0 ? (
                        <span>
                          {calculationMediaForLeason(lesson) > 0
                            ? calculationMediaForLeason(lesson)
                            : '0'}
                        </span>
                      ) : (
                        '0'
                      ))}
                  </div>
                  <div className="tittie">
                    A:&nbsp;&nbsp;
                    {lesson.objectives &&
                      (lesson.objectives.length > 0 ? (
                        <span>
                          {calculationassessmentForLeason(lesson) > 0
                            ? calculationassessmentForLeason(lesson)
                            : '0'}
                        </span>
                      ) : (
                        '0'
                      ))}
                  </div>
                  <div className="tittie">
                    E:&nbsp;&nbsp;
                    <span>
                      {lesson.projects &&
                        (lesson.projects.length > 0 ? (
                          <span>{lesson.projects.length}</span>
                        ) : (
                          '0'
                        ))}
                    </span>
                  </div>
                </div>
              )}
            </div>
          }
          key={lesson.id}
          disabled={disabledCollapse()}
        >
          {/*disabled={!isUserSubscribed(user) || startCourses}*/}
          <p>{lesson.shortDescription}</p>
          <Progress percent={getCompletedPercentage(lesson.objectives, user)} />
          <h3 style={{ margin: '16px 0' }}>Objectives</h3>
          <ObjectiveList
            hideShowDetails={hideShowDetails}
            user={user}
            objectives={lesson.objectives}
            markCompleted={markCompleted}
            markCompletedLoading={markCompletedLoading}
            baseUrl={`${location.pathname}/lessons/${lesson.id}`}
          />
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

const selectLessonListState = (state: AppState) => {
  return {
    user: state.user.user,
    markCompletedLoading: state.user.markCompletedLoading,
  };
};

const ConnectedLessonList = connect(selectLessonListState, { markCompleted })(
  LessonList,
);

export default ConnectedLessonList;
