import React, { useMemo } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Input, Upload, Button, Divider, Form } from 'antd';
import ApiService from '@services/apiService';

interface ObjectiveMediaFormProps {
  media: ObjectiveMedia[];
}

const ObjectiveMediaForm: React.FC<ObjectiveMediaFormProps> = ({ media }) => {
  const initialValues = useMemo(
    () =>
      media.map(m => ({
        ...m,
        file: m.file
          ? [
              {
                uid: m.file.id,
                name: m.file.pid || m.file.url,
                status: 'done',
                ...m.file,
              },
            ]
          : [],
      })),
    [media],
  );

  const normFile = (e: any) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <h3>Objective Media</h3>

      <Form.List name="media" initialValue={initialValues}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Form.Item name={`${name}.id`} {...restField} hidden>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[name, 'time']}
                  label="Time (in minutes)"
                  {...restField}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required!',
                    },
                  ]}
                >
                  <Input type="number" min={1} />
                </Form.Item>
                <Form.Item
                  name={[name, 'description']}
                  label="Description"
                  {...restField}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required!',
                    },
                  ]}
                >
                  <Input.TextArea rows={2} />
                </Form.Item>
                <Form.Item {...restField} name={[name, 'url']} label="Url">
                  <Input />
                </Form.Item>
                <h4>OR</h4>
                <Form.Item
                  isListField
                  name={[name, 'file']}
                  label="File"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    name="file"
                    action={ApiService.getUrl('/files')}
                    headers={ApiService.getHeaders()}
                    listType="picture"
                    // onChange={e => onUploadChange(e, index)}
                  >
                    <Button>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </Form.Item>
                <Button danger onClick={() => remove(name)}>
                  Delete media
                </Button>
                <Divider />
              </div>
            ))}
            <Button type="primary" onClick={() => add()}>
              Add new media
            </Button>
          </>
        )}
      </Form.List>
    </>
  );
};

export default ObjectiveMediaForm;
