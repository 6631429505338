import React, { useState, useEffect } from 'react';
import { useRouter } from '@utils/routerHooks';
import Spinner from '@components/Spinner';
import ObjectiveService from '@services/objectiveService';

interface TParams {
  id?: number;
}

export default function withObjective(WrappedComponent: any) {
  return () => {
    const { match, history } = useRouter<TParams>();
    const [loading, setLoading] = useState(false);
    const [objective, setObjective] = useState<Objective>();

    const id = match.params.id || 0;

    useEffect(() => {
      if (
        !loading &&
        (!objective || (objective && objective.id !== Number(match.params.id)))
      ) {
        setLoading(true);
        ObjectiveService.getOne(id).then((o: any) => {
          setObjective(o);
          setLoading(false);
        });
      }
    }, [objective, id, loading, match.params.id]);

    if (!id) {
      history.push('/objectives');
    }

    if (loading) {
      return <Spinner />;
    }

    if (!objective) {
      return null;
    }

    return <WrappedComponent objective={objective} />;
  };
}
