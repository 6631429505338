import React, { useMemo, useState } from 'react';
import { Button, Upload, Divider, Col, Row, Card, Modal } from 'antd';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import { getCurrentUser } from '@redux/reducers/user';

// import Goals from '@components/goals/Goals';
import { getUserReadingFiles } from '@utils/helpers';
import ApiService from '@services/apiService';
import FilePreview from '@components/files/FilePreview';
import { getFileUrl, isUserInstructor, isUserAdmin } from '@helpers';
import { UploadChangeParam } from 'antd/lib/upload';
import ReadingService from '@services/readingService';
// import { StudentProjectReview } from '@components/projectReview';
import { UserProjectStatus } from '@types';
import { toast } from 'react-toastify';

interface ReadingProps {
  user: User;
  reading: Reading;
  isLesson?: boolean;
  getCurrentUser: typeof getCurrentUser;
}

interface ReadingsProps {
  user?: User;
  readings?: Reading[];
  isLesson?: boolean;
  getCurrentUser: typeof getCurrentUser;
}

const Readings: React.FC<ReadingsProps> = ({
  readings,
  user,
  isLesson,
  getCurrentUser,
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [readingId, setReadingId] = useState();
  const [fileId, setFileId] = useState();
  if (!user) {
    return null;
  }

  /* const fileRemoveReading = (readingId: any, file: any) => {
   const aaa = ReadingService.deleteFileReading(readingId, file.id)
      .then(d => {
        console.log('ccc', d);
        toast.success('Delete file successful!');
        //history.push(`/lessons/${d.id}`);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(err => {
        console.log('err', err);
        //toast.error(err.message);
      });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }; */

  const fileDeleteReading = () => {
    if (!readingId || !fileId) {
      return false;
    }
    setLoading(true);
    const aaa = ReadingService.deleteFileReading(readingId, fileId)
      .then(d => {
        setDeleteModalVisible(false);
        toast.success('Delete file successful!');
        // history.push(`/lessons/${d.id}`);
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 2000);
      })
      .catch(err => {
        // console.log('err', err);
        // toast.error(err.message);
      });
  };
  return (
    <>
      {(!readings || !readings.length) && (
        /* <p>No {isLesson ? 'exercises' : 'projects'}.</p> */
        <p>No Reading.</p>
      )}
      {readings &&
        readings.map((reading: any) => (
          <Row>
            <Col span={24}>
              {reading.name && <h3>{reading.name}</h3>}
              <h4>Readings description</h4>
              <p>{reading.description}</p>
            </Col>
            <Col span={24}>
              {reading.files && (
                <>
                  <h3>Files</h3>
                  {reading.exampleFileUrl && (
                    <FilePreview
                      file={{
                        id: -1,
                        url: reading.exampleFileUrl,
                      }}
                    />
                  )}
                  {reading.files.map((file: any) => (
                    <>
                      {isLesson && isUserAdmin(user) && (
                        <div style={{ textAlign: 'end', marginBottom: '8px' }}>
                          <Button
                            danger
                            onClick={() => {
                              setDeleteModalVisible(true);
                              setReadingId(reading.id);
                              setFileId(file.id);
                            }}
                          >
                            {/* onClick={() => fileRemoveReading(reading.id, file)} */}
                            Delete
                          </Button>
                        </div>
                      )}
                      <FilePreview file={file} />
                    </>
                  ))}
                  {!reading.exampleFileUrl && reading.files.length === 0 ? (
                    <p>No reading files.</p>
                  ) : null}
                </>
              )}
            </Col>
            <Divider />
          </Row>
        ))}
      <Modal
        title="Are you sure you want to delete file?"
        width={550}
        visible={deleteModalVisible}
        okText="Delete file"
        okButtonProps={{ loading, danger: true }}
        onOk={() => fileDeleteReading()}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <p>You will not be able to revert this action</p>
      </Modal>
    </>
  );
};

const selectReadingsState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedReadings = connect(selectReadingsState, { getCurrentUser })(
  Readings,
);

export default ConnectedReadings;
