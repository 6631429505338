import React, { useState, ReactNode, useRef } from 'react';
import { Button, Avatar, Tooltip, Comment, Input, Form } from 'antd';
import moment from 'moment';
import { DocumentNode, useMutation } from '@apollo/client';
import { isUserAdmin, scrollToRef } from '@helpers';
import NewReplyForm from './NewReply';
import Reply from './Reply';
import { UPDATE_DISCUSSION, DELETE_DISCUSSION } from './queries';

interface DiscussionProps {
  user: User;
  discussion: Discussion;
  query: string | DocumentNode;
}

const DiscussionComponent: React.FC<DiscussionProps> = ({
  discussion,
  user,
  query,
}) => {
  const [newReply, setNewReply] = useState<Partial<Reply>>();
  const [text, setText] = useState(discussion.text);
  const [title, setTitle] = useState(discussion.title);
  const [editing, setEditing] = useState(false);
  const [updateDiscussion, { loading }] = useMutation(UPDATE_DISCUSSION);
  const [deleteDiscussion, { loading: deleteLoading }] =
    useMutation(DELETE_DISCUSSION);
  const newReplyRef = useRef(null);

  const executeScroll = () => scrollToRef(newReplyRef);
  const editDiscussion = () => setEditing(true);

  const saveDiscussion = async () => {
    await updateDiscussion({
      variables: { title, text, id: discussion.id },
      refetchQueries: [{ query: query as any }],
    });
    setEditing(false);
  };

  const onDeleteDiscussion = async () => {
    await deleteDiscussion({
      variables: { id: discussion.id },
      refetchQueries: [{ query: query as any }],
    });
    setEditing(false);
  };

  const onReply = () => {
    setNewReply({ discussionId: discussion.id });
    setTimeout(executeScroll);
  };

  const actions: ReactNode[] = [
    <Button
      type="ghost"
      size="small"
      key="comment-basic-reply-to"
      onClick={onReply}
    >
      Reply
    </Button>,
  ];

  if (user.id === discussion.createdBy.id) {
    if (editing || loading) {
      actions.push(
        <Button
          size="small"
          key="comment-basic-reply-to"
          type="primary"
          ghost
          loading={loading}
          onClick={() => saveDiscussion()}
        >
          Save
        </Button>,
      );
    } else {
      actions.push(
        <Button
          size="small"
          key="comment-basic-reply-to"
          type="ghost"
          onClick={() => editDiscussion()}
        >
          Edit
        </Button>,
      );
    }
    // actions.push(
    //   <Button
    //     size="small"
    //     key="comment-basic-reply-to"
    //     type="danger"
    //     ghost={true}
    //     loading={deleteLoading}
    //     onClick={() => onDeleteDiscussion()}
    //   >
    //     Delete
    //   </Button>,
    // );
  }
  if (user.id === discussion.createdBy.id || isUserAdmin(user)) {
    actions.push(
      <Button
        size="small"
        key="comment-basic-reply-to"
        danger
        ghost
        loading={deleteLoading}
        onClick={() => onDeleteDiscussion()}
      >
        Delete
      </Button>,
    );
  }
  const content = editing ? (
    <div>
      <Form.Item style={{ margin: '4px 0' }}>
        <Input
          placeholder="Discussion title"
          autoFocus
          onChange={(e: any) => {
            setTitle(e.target.value);
          }}
          value={title}
        />
      </Form.Item>
      <Form.Item style={{ margin: '4px 0' }}>
        <Input.TextArea
          rows={2}
          placeholder="Discussion text"
          onChange={(e: any) => {
            setText(e.target.value);
          }}
          value={text}
        />
      </Form.Item>
    </div>
  ) : (
    <div>
      <h3>{discussion.title}</h3>
      <p>{discussion.text}</p>
    </div>
  );

  return (
    <div style={{ margin: '16px 0 0', border: '1px solid #eee' }}>
      <Comment
        actions={actions}
        author={
          <a>{`${discussion.createdBy.firstName} ${discussion.createdBy.lastName}`}</a>
        }
        avatar={
          <Avatar
            src={
              discussion.createdBy.avatar
                ? discussion.createdBy.avatar.url
                : `https://eu.ui-avatars.com/api/?name=${discussion.createdBy.firstName}+${discussion.createdBy.lastName}`
            }
            alt={`${discussion.createdBy.firstName} ${discussion.createdBy.lastName}`}
          />
        }
        content={content}
        datetime={
          <Tooltip
            title={moment(discussion.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          >
            <span>{moment(discussion.createdAt).fromNow()}</span>
          </Tooltip>
        }
      />
      <div style={{ paddingLeft: 24 }}>
        {discussion.replies.map(reply => (
          <Reply
            key={reply.id}
            user={user}
            reply={reply}
            onReply={() => {}}
            refetchQuery={query}
          />
        ))}
        {newReply && newReply.discussionId === discussion.id && (
          <div ref={newReplyRef}>
            <NewReplyForm
              refetchQuery={query}
              discussionId={discussion.id}
              user={user}
              onCreate={() => setNewReply(undefined)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscussionComponent;
