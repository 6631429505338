import React, { useState } from 'react';
import { Input, Button, Divider, Form } from 'antd';
import { toast } from 'react-toastify';
import UserService from '@services/userService';

interface StudentNotesFormProps {
  user: User;
  onSubmit: any;
}

const StudentNotesForm: React.FC<StudentNotesFormProps> = ({
  user,
  onSubmit,
}) => {
  const [documents, setDocuments] = useState<UserNotesCreateAdminForStudent[]>(
    [],
  );
  const [updateLoading, setUpdateLoading] = useState(false);
  const [form] = Form.useForm();
  const { validateFields } = form;

  const handleSubmit = (e: any) => {
    e.preventDefault();

    validateFields().then(values => {
      setUpdateLoading(true);

      /* const normalizedDocuments = values.documents
          ? normalizeDocuments(values.documents)
          : []; */
      const normalizedDocuments = values.documents;
      UserService.addNotesCreateAdminForStudent(user.id, normalizedDocuments)
        .then(d => {
          setUpdateLoading(false);
          toast.success('Notes Added  successful!');
          onSubmit();
        })
        .catch(() => {
          toast.error('Add failed!');
          setUpdateLoading(false);
        });
    });
  };

  const handleAddNotes = () => {
    setDocuments([
      ...documents,
      {
        id: -1,
        description: '',
      },
    ]);
  };

  const handleRemoveNote = (index: number) => {
    const newData = documents;
    if (newData[index]) {
      newData.splice(index, 1);
    }

    setDocuments([...newData]);
  };

  // const onUploadChange = (
  //   { fileList, file }: UploadChangeParam,
  //   index: number,
  // ) => {
  //   if (fileList.length > 1) {
  //     fileList.splice(0, 1);
  //   }

  //   if (file.percent === 100) {
  //     const uploadedFile = file.response;

  //     const newData = documents;
  //     if (newData[index]) {
  //       newData[index].file = uploadedFile;
  //     }

  //     setDocuments([...newData]);
  //   }
  // };

  /* const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }; */

  return (
    <Form onFinish={handleSubmit} form={form}>
      {documents.map((document, index) => (
        <>
          <Form.Item
            hidden
            name={`documents[${index}].id`}
            initialValue={document.id}
          >
            <Input hidden />
          </Form.Item>
          <Form.Item
            name={`documents[${index}].description`}
            label="Description"
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
            ]}
            initialValue={document.description}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          {/*          <Form.Item label="Example file">
            {getFieldDecorator(`documents[${index}].file`, {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              initialValue: document.file
                ? [
                    {
                      uid: document.file.id,
                      name: document.file.pid || document.file.url,
                      status: 'done',
                      ...document.file,
                    },
                  ]
                : [],
            })(
              <Upload
                name="file"
                action={ApiService.getUrl('/files')}
                headers={ApiService.getHeaders()}
                listType="picture"
                onChange={e => onUploadChange(e, index)}
              >
                <Button>
                  <Icon type="upload" /> Click to upload
                </Button>
              </Upload>,
            )}
          </Form.Item> */}
          <Button danger onClick={() => handleRemoveNote(index)}>
            Delete notes
          </Button>
          <Divider />
        </>
      ))}
      <Button type="primary" ghost onClick={() => handleAddNotes()}>
        Add new notes
      </Button>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={updateLoading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default StudentNotesForm;
