import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { AppState } from '@redux/reducers';
import SubscribeForm from './SubscribeForm';

interface SubscribePageProps {
  user?: User;
}

const SubscribePage: React.FC<SubscribePageProps> = ({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <Row gutter={16}>
      <Col span={24}>
        <h2>Register</h2>
        <p>To access all features please register</p>
        <SubscribeForm />
      </Col>
    </Row>
  );
};

const selectSubscribePageState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedSubscribePage = connect(
  selectSubscribePageState,
  {},
)(SubscribePage);

const Page = () => <ConnectedSubscribePage />;

export default Page;
