import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { List, Divider } from 'antd';

const AssessmentList: React.FC<{ assessments: Assessment[] }> = ({
  assessments,
}) => {
  return <>
    <h3>Assessments</h3>
    {assessments.map(assessment => (
      <>
        <p>
          <b>Question: </b>
          {assessment.question}
        </p>
        <p>
          <b>Answers</b>
        </p>
        <List
          size="small"
          dataSource={assessment.answers}
          renderItem={(answer, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  answer.correct ? (
                    <CheckCircleOutlined style={{ fontSize: 20, color: '#52c41a' }} />
                  ) : (
                    <CloseCircleOutlined style={{ fontSize: 20, color: '#333' }} />
                  )
                }
                title={answer.text}
              />
            </List.Item>
          )}
        />
        <Divider />
      </>
    ))}
  </>;
};

export default AssessmentList;
