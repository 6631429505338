import ApiService from './apiService';

const CourseService = {
  search: async (
    searchText: string,
    page = 1,
    excludeIds?: number[],
  ): Promise<Course[]> => {
    const response = await ApiService.get('/courses', {
      searchText,
      page,
      excludeIds,
    });
    return response.data;
  },

  searchBooks: async (searchText: string): Promise<AmazonBook[]> => {
    const response = await ApiService.get('/books', {
      searchText,
    });
    return response.data;
  },

  getOne: async (id: number): Promise<Course> => {
    const response = await ApiService.get(`/courses/${id}`);
    return response.data;
  },

  getAll: async (): Promise<Course[]> => {
    const response = await ApiService.get(`/courses`);
    return response.data;
  },

  create: async (data: Course): Promise<Course> => {
    const response = await ApiService.post(`/courses`, data);
    return response.data;
  },

  update: async (id: number, data: Course): Promise<Course> => {
    const response = await ApiService.put(`/courses/${id}`, data);
    return response.data;
  },

  getCourseLessons: async (id: number): Promise<Lesson[]> => {
    const response = await ApiService.get(`/courses/${id}/lessons`);
    return response.data;
  },

  getStudents: async (id: number): Promise<User[]> => {
    const response = await ApiService.get(`/courses/${id}/students`);
    return response.data;
  },

  getStudentsCourseHearing: async (id: number): Promise<User[]> => {
    const response = await ApiService.get(
      `/courses/${id}/scheduled-calls-course`,
    );
    return response.data;
  },
  getInstructors: async (id: number): Promise<User[]> => {
    const response = await ApiService.get(`/courses/${id}/instructors`);
    return response.data;
  },

  addInstructor: async (
    id: number,
    data: { userId: number },
  ): Promise<User> => {
    const response = await ApiService.post(`/courses/${id}/instructors`, data);
    return response.data;
  },

  deleteInstructor: async (id: number, userId: number): Promise<User> => {
    const response = await ApiService.delete(
      `/courses/${id}/instructors/${userId}`,
    );
    return response.data;
  },

  addMainInstructor: async (
    id: number,
    data: {
      userId: number;
      addRemove: boolean;
    },
  ): Promise<User> => {
    const response = await ApiService.put(
      `/courses/${id}/main-instructors`,
      data,
    );
    return response.data;
  },

  getScheduledCalls: async (id: number): Promise<ScheduledCall[]> => {
    const response = await ApiService.get(`/courses/${id}/scheduled-calls`);
    return response.data;
  },

  scheduleCall: async (
    id: number,
    timestamp: number,
  ): Promise<ScheduledCall> => {
    const response = await ApiService.post(`/courses/${id}/scheduled-calls`, {
      timestamp,
    });
    return response.data;
  },

  deleteScheduledCall: async (callId: string): Promise<ScheduledCall[]> => {
    const response = await ApiService.delete(`/scheduled-calls/${callId}`);
    return response.data;
  },

  updateScheduledCall: async (
    callId: string,
    data: {
      confirmed: boolean;
      rejected: boolean;
      instructorId: number;
    },
  ): Promise<ScheduledCall> => {
    const response = await ApiService.put(`/scheduled-calls/${callId}`, data);
    return response.data;
  },

  deleteExams: async (id: number): Promise<User> => {
    const response = await ApiService.delete(`/courses/${id}/exams`);
    return response.data;
  },

  getUserCourse: async (userCourseId: number): Promise<UserCourse> => {
    const response = await ApiService.get(`/user-courses/${userCourseId}`);
    return response.data;
  },

  updateCourseReview: async (
    userCourseId: number,
    data: CourseReview,
  ): Promise<CourseReview> => {
    const response = await ApiService.put(
      `/user-courses/${userCourseId}/review`,
      data,
    );
    return response.data as CourseReview;
  },
  discussionsNotifications: async (data: any): Promise<any> => {
    const response = await ApiService.post(
      `/course/discussions/notifications`,
      data,
    );
    return response.data;
  },
  getCourseSyllabusAI: async (data: any): Promise<any> => {
    const response = await ApiService.postAI(
      `/newlane/v1/course-syllabus-generator`,
      data,
    );
    return response.data;
  },
  getCourseSortDesAI: async (data: any): Promise<any> => {
    const response = await ApiService.postAI(
      `/newlane/v1/course-short-description-generator`,
      data,
    );
    return response.data;
  },
  getCourseLongDesAI: async (data: any): Promise<any> => {
    const response = await ApiService.postAI(
      `/newlane/v1/course-long-description-generator`,
      data,
    );
    return response.data;
  },
  getCourseLessonSyllabusAI: async (data: any): Promise<any> => {
    const response = await ApiService.postAI(
      `/newlane/v1/lesson-syllabus-generator`,
      data,
    );
    return response.data;
  },
  getCourseLessonSortDesAI: async (data: any): Promise<any> => {
    // console.log('course Servise call')
    const response = await ApiService.postAI(
      `/newlane/v1/lesson-short-description-generator`,
      data,
    );
    return response.data;
  },
  getCourseLessonLongDesAI: async (data: any): Promise<any> => {
    // console.log('course Servise call')
    const response = await ApiService.postAI(
      `/newlane/v1/lesson-long-description-generator`,
      data,
    );
    return response.data;
  },
  getCourseLessonTopicDesAI: async (data: any): Promise<any> => {
    // console.log('course Servise call')
    const response = await ApiService.postAI(
      `/newlane/v1/topic-description-generator`,
      data,
    );
    return response.data;
  },
  getCourseLessonTopicAsessmentsAI: async (data: any): Promise<any> => {
    // console.log('course Servise call')
    const response = await ApiService.postAI(
      `/newlane/v1/assessment-generator`,
      data,
    );
    return response.data;
  },
};

export default CourseService;
