import React, { useEffect } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, Alert, Form } from 'antd';
import { connect } from 'react-redux';
import { AppState } from '@redux/reducers';
import { login } from '@redux/reducers/auth';
import { Link, useHistory } from 'react-router-dom';

interface StateProps {
  errors?: ApiError['errors'];
  isLoading: boolean;
  accessToken?: string;
  authError: boolean;
}

interface DispatchProps {
  login: typeof login;
}

type LoginFormProps = StateProps & DispatchProps;

const LoginForm: React.FC<LoginFormProps> = ({
  isLoading,
  errors,
  accessToken,
  login,
  authError,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const handleSubmit = () => {
    form.validateFields().then((values: any) => {
      login(values);
    });
  };

  useEffect(() => {
    if (accessToken && !authError) {
      history.push('/', { accessToken });
    }

    if (authError) {
      // toast.error('Authentication error.');
    }
  }, [accessToken, authError, history]);

  return (
    <section className="form-v1-container">
      <h2>Login to Continue</h2>
      <p className="lead">Welcome back, sign in with your Newlane account</p>
      {errors?.map(error => (
        <Alert message={error.message} type="error" />
      ))}

      <Alert
        style={{ margin: '16px 0' }}
        message={
          <p style={{ margin: 0, padding: 0 }}>
            If you are having trouble logging in with your Newlane account
            please try
            <br />
            <Link to="/forgot-password">Reset password</Link>
            <br />
            <br />
            You can also contact us{' '}
            <a href="mailto:contact@newlaneuniversity.com">
              contact@newlaneuniversity.com
            </a>
          </p>
        }
      />

      <Form form={form} onFinish={handleSubmit} className="form-v1">
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input
            size="large"
            prefix={<UserOutlined style={{ fontSize: 13 }} />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            size="large"
            prefix={<LockOutlined style={{ fontSize: 13 }} />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-cta btn-block"
            disabled={isLoading}
            loading={isLoading}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
      <p className="additional-info">
        Don't have an account yet? <Link to="/signup">Sign up</Link>
      </p>
      <p className="additional-info">
        Forgot your username or password?{' '}
        <Link to="/forgot-password">Reset password</Link>
      </p>
    </section>
  );
};

const selectLoginState = (state: AppState) => {
  const { errors, isLoading, accessToken, authError } = state.auth;
  return {
    errors,
    isLoading,
    accessToken,
    authError,
  };
};

export default connect(selectLoginState, { login })(LoginForm);
