import ApiService from './apiService';

const NewlaneTutorialsService = {
  search: async (
    searchText: string,
    page = 1,
    excludeIds?: Number[],
  ): Promise<NewlaneTutorials[]> => {
    const response = await ApiService.get('/newlane-tutorials', {
      searchText,
      page,
      excludeIds,
    });
    return response.data;
  },
  getAll: async (): Promise<NewlaneTutorials> => {
    const response = await ApiService.get(`/newlane-tutorials`);
    return response.data;
  },
  getOne: async (id: number): Promise<NewlaneTutorials> => {
    const response = await ApiService.get(`/newlane-tutorials/${id}`);
    return response.data;
  },

  create: async (data: NewlaneTutorials): Promise<NewlaneTutorials> => {
    const response = await ApiService.post(`/newlane-tutorials`, data);
    return response.data;
  },

  update: async (id: number, data: NewlaneTutorials): Promise<NewlaneTutorials> => {
    const response = await ApiService.put(`/newlane-tutorials/${id}`, data);
    return response.data;
  },
  remove: async (id: number): Promise<NewlaneTutorials> => {
    const response = await ApiService.delete(`/newlane-tutorials/${id}`);
    return response.data;
  },
};

export default NewlaneTutorialsService;
