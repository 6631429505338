import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';

import LessonForm from '@components/lessonForm/LessonForm';

interface EditLessonProps {
  user?: User;
}

const EditLesson: React.FC<EditLessonProps> = ({ user }) => {
  if (!user) {
    return null;
  }

  return <LessonForm lesson={{ id: -1 }} />;
};

const selectEditLessonState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedEditLesson = connect(selectEditLessonState)(EditLesson);

const Page = () => <ConnectedEditLesson />;

export default Page;
