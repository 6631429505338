import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';

import NewlaneTutorialsForm from '@components/newlane-tutorials/NewlaneTutorialsForm';
interface CreateNewlaneTutorialsProps {
  user?: User;
}

const CreateNewlaneTutorials: React.FC<CreateNewlaneTutorialsProps> = ({
  user,
}) => {
  if (!user) {
    return null;
  }
  return <NewlaneTutorialsForm newlaneTutorial={{ id: -1 }} />;
};

const selectCreateNewlaneTutorialsState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedCreateNewlaneTutorials = connect(
  selectCreateNewlaneTutorialsState,
)(CreateNewlaneTutorials);

const Page = () => <ConnectedCreateNewlaneTutorials />;

export default Page;
