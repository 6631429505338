import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useRouteMatch } from 'react-router';
import moment from 'moment';
import AgreementService from '@services/AgreementService';
import Spinner from '@components/Spinner';

const SingleAgreement = () => {
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState<Agreement>();
  const match = useRouteMatch<{ type: Agreement['type'] }>();

  useEffect(() => {
    if (!agreement && match && !loading) {
      AgreementService.getOne(match.params.type).then(data => {
        setAgreement(data);
        setLoading(false);
      });
    }
  }, [agreement, loading, match]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Row style={{ padding: 20 }} className="agreement-page">
      <Col span={24}>
        {agreement && (
          <div dangerouslySetInnerHTML={{ __html: agreement.text }} />
        )}
        {agreement && (
          <div>
            <p>Last updated: {moment(agreement.updatedAt).format('L')}</p>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default SingleAgreement;
