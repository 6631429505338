import React, { useState, useRef, useEffect } from 'react';
import {
  Participant as TwilioParticipant,
  AudioTrackPublication,
  VideoTrackPublication,
} from 'twilio-video';

interface ParticipantProps {
  participant: TwilioParticipant;
  remote?: boolean;
}

const Participant = ({ participant, remote }: ParticipantProps) => {
  const [videoTracks, setVideoTracks] = useState<VideoTrackPublication[]>([]);
  const [audioTracks, setAudioTracks] = useState<AudioTrackPublication[]>([]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const trackSubscribed = (
      track: VideoTrackPublication | AudioTrackPublication,
    ) => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (
      track: VideoTrackPublication | AudioTrackPublication,
    ) => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };

    setVideoTracks(Array.from(participant.videoTracks.values()));
    setAudioTracks(Array.from(participant.audioTracks.values()));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0] as any;
    if (videoTrack && videoTrack.track) {
      videoTrack.track.attach(videoRef.current);
      return () => {
        videoTrack.track && videoTrack.track.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0] as any;
    if (audioTrack && audioTrack.track) {
      audioTrack.track.attach(audioRef.current);
      return () => {
        audioTrack.track && audioTrack.track.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className="participant">
      <h3>{participant.identity}</h3>
      <video
        id={remote ? 'remote-video' : 'local-video'}
        ref={videoRef}
        autoPlay
        style={{ width: '100%' }}
      />
      <audio ref={audioRef} autoPlay muted={false} />
    </div>
  );
};

export default Participant;
