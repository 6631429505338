import React, { useState, useEffect, useCallback } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Modal,
  Table,
  Select,
  Card,
  Alert,
  Checkbox,
  Input,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import moment from 'moment';
import deleteIcon5 from '@assets/delete5.webp';
import { AppState } from '@redux/reducers';

import UserService, { IUserSorter } from '@services/userService';
import './Admin.less';
import { SorterResult } from 'antd/lib/table/interface';

const { Search } = Input;

interface AdminUsersProps {
  user?: User;
}

const AdminUsers: React.FC<AdminUsersProps> = ({ user }) => {
  const { Option } = Select;
  const [users, setUsers] = useState<User[]>();
  const [editedUser, setEditedUser] = useState<User>();
  const [deletedUser, setDeletedUser] = useState<User>();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
  });
  const [sorter, setSorter] = useState<IUserSorter>({
    field: 'createdAt',
    order: 'DESC',
  });
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [activeUser, setActiveUser] = useState<string>('active');
  const handleSearch = (value: string) => {
    const pager = { ...pagination };
    pager.current = 1;
    setPagination(pager);
    setSearchTerm(value);
    getUsers(pager, sorter, value, activeUser);
  };

  const getUsers = useCallback(
    (p: any, s: any, st?: string, activeUser?: string) => {
      setLoading(true);
      UserService.getAll({
        searchTerm: st,
        page: p.current || 1,
        sort: s,
        activeUser,
      })
        .then(response => {
          setLoading(false);
          setPagination({ ...p, total: response.total });
          setUsers(response.data);
        })
        .catch(err => {
          console.error(err);
          setError(err);
          setLoading(false);
        });
    },
    [],
  );

  const updateUser = () => {
    if (!editedUser) {
      return false;
    }
    setLoading(true);
    UserService.update(editedUser.id, editedUser)
      .then(() => {
        setUserModalVisible(false);
        setEditedUser(undefined);
        setLoading(false);
        getUsers(pagination, sorter, searchTerm, activeUser);
        toast.success('User updated successfully!');
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setError(err.message);
      });
  };

  const deleteUser = () => {
    if (!deletedUser) {
      return false;
    }
    setLoading(true);
    // UserService.delete(deletedUser.id)
    //   .then(() => {
    //     setDeleteModalVisible(false);
    //     setDeletedUser(undefined);
    //     setLoading(false);
    //     getUsers(pagination, sorter, searchTerm, activeUser);
    //     toast.success('User deleted successfully!');
    //   })
    //   .catch(err => {
    //     console.error(err);
    //     setLoading(false);
    //     setError(err.message);
    //   });
    UserService.deleteSoft(deletedUser.id)
      .then(() => {
        setDeleteModalVisible(false);
        setDeletedUser(undefined);
        setLoading(false);
        getUsers(pagination, sorter, searchTerm, activeUser);
        toast.success('User deleted successfully!');
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setError(err.message);
      });
  };

  useEffect(() => {
    if (!users && !loading) {
      getUsers(pagination, sorter, searchTerm, 'active');
    }
  }, [users, loading, getUsers, pagination, sorter]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    const newSorter: IUserSorter = {
      field: sorter.field as string,
      order: sorter.order === 'descend' ? 'DESC' : 'ASC',
    };
    setPagination(pager);
    setSorter(newSorter);
    getUsers(pager, newSorter, searchTerm, activeUser);
  };
  const handleChange = (value: string) => {
    setActiveUser(value);
    getUsers(pagination, sorter, searchTerm, value);
    // setUsers([]);
  };
  const userColumns: ColumnProps<User>[] = [
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      sorter: true,
    },
    {
      key: 'firstName',
      dataIndex: 'firstName',
      title: 'First name',
      sorter: true,
    },
    {
      key: 'lastName',
      dataIndex: 'lastName',
      title: 'Last name',
      sorter: true,
    },
    {
      key: 'role',
      dataIndex: 'role',
      title: 'Role',
      sorter: true,
    },
    {
      key: 'canOnboardStudents',
      dataIndex: 'canOnboardStudents',
      title: 'Can onboard students?',
      sorter: true,
    },
    {
      title: 'Last active',
      dataIndex: 'lastActive',
      key: 'lastActive',
      render: (lastActive?: Date) =>
        lastActive ? moment(lastActive).format('L') : '-',
      sorter: true,
    },
    {
      title: 'Signup date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      render: (createdAt?: Date) =>
        createdAt ? moment(createdAt).format('L') : '-',
      sorter: true,
    },
    {
      key: 'actions',
      title: (
        <Select
          // defaultValue="active"
          value={activeUser}
          onChange={handleChange}
          style={{ width: '100px' }}
        >
          <Option value="active">Active</Option>
          <Option value="archived">Archived</Option>
          <Option value="all">All</Option>
        </Select>
      ),
      render: (text, user) => (
        <div className="action-icones">
          {/* <Button
            type="default"
            onClick={() => {
              setEditedUser(user);
              setUserModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Button
            type="danger"
            style={{ marginLeft: 8 }}
            onClick={() => {
              setDeletedUser(user);
              setDeleteModalVisible(true);
            }}
          >
            Delete
          </Button> */}
          <EditOutlined
            onClick={() => {
              setEditedUser(user);
              setUserModalVisible(true);
            }}
            className="edit-icone"
          />
          {/* <Tooltip placement="bottom" title="Soft delete"> */}
          <DeleteOutlined
            onClick={() => {
              setDeletedUser(user);
              setDeleteModalVisible(true);
            }}
            className="delete-icone"
          />
          {/* </Tooltip> */}
          <Tooltip placement="bottom" title="Soft delete">
            <img
              onClick={() => {
                setDeletedUser(user);
                setDeleteModalVisible(true);
              }}
              className="delete-icone"
              src={deleteIcon5}
              style={{
                height: '21px',
                marginTop: '-4px',
                marginLeft: '20px',
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card title="Users" size="small">
          <div style={{ marginBottom: 16 }}>
            <Search
              placeholder="Search users by email, first name and last name"
              onSearch={handleSearch}
              loading={loading}
            />
          </div>
          <Table
            dataSource={users}
            columns={userColumns}
            rowKey="id"
            onChange={handleTableChange}
            pagination={pagination}
          />
        </Card>
      </Col>

      {deletedUser && (
        <Modal
          title="Are you sure you want to delete user?"
          width={550}
          visible={deleteModalVisible}
          okText="Delete user"
          okButtonProps={{ loading, danger: true }}
          onOk={() => deleteUser()}
          onCancel={() => setDeleteModalVisible(false)}
        >
          <p>
            <b>{`${deletedUser.firstName} ${deletedUser.lastName} (${deletedUser.email}`}</b>
          </p>
          <p>You will not be able to revert this action</p>
        </Modal>
      )}

      <Modal
        title="Edit user"
        width={400}
        visible={userModalVisible}
        okText="Update user"
        okButtonProps={{ loading }}
        onOk={() => updateUser()}
        onCancel={() => setUserModalVisible(false)}
      >
        {error && (
          <Alert message={error} type="error" style={{ marginBottom: 16 }} />
        )}
        {editedUser && (
          <div>
            <div>
              <p>
                <b>User name:</b> {editedUser.firstName} {editedUser.lastName}
              </p>
              <p>
                <b>User email:</b> {editedUser.email}
              </p>
            </div>
            <div style={{ marginTop: 16 }}>
              <p style={{ margin: '0 0 8px 0' }}>User role:</p>
              <Select
                placeholder="User role"
                value={editedUser.role}
                onChange={(value: any) => {
                  setEditedUser({ ...editedUser, role: value });
                }}
              >
                <Select.Option key="student" value="student">
                  Student
                </Select.Option>
                <Select.Option key="instructor" value="instructor">
                  Instructor
                </Select.Option>
                <Select.Option key="admin" value="admin">
                  Admin
                </Select.Option>
              </Select>
            </div>
            <div style={{ marginTop: 16 }}>
              <p style={{ margin: '0 0 8px 0' }}>
                User can accept onboarding calls:
              </p>
              <Checkbox
                checked={editedUser.canOnboardStudents}
                value
                onChange={(e: any) => {
                  setEditedUser({
                    ...editedUser,
                    canOnboardStudents: e.target.checked,
                  });
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    </Row>
  );
};

const selectAdminUsersState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedAdminUsers = connect(selectAdminUsersState, {})(AdminUsers);

const Page = () => <ConnectedAdminUsers />;

export default Page;
