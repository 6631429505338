import React, { useState, useEffect } from 'react';
import { useRouter } from '@utils/routerHooks';
import Spinner from '@components/Spinner';
import DegreeService from '@services/degreeService';

interface TParams {
  id?: number;
}

export default function withDegree(WrappedComponent: any) {
  return () => {
    const { match, history } = useRouter<TParams>();
    const [loading, setLoading] = useState(false);
    const [degree, setDegree] = useState<Degree>();

    const degreeId = match.params.id || 0;

    useEffect(() => {
      if (
        !loading &&
        (!degree || (degree && degree.id !== Number(match.params.id)))
      ) {
        setLoading(true);
        DegreeService.getOne(degreeId).then(d => {
          setDegree(d);
          setLoading(false);
        });
      }
    }, [degree, degreeId, loading, match.params.id]);

    if (!degreeId) {
      history.push('/degrees');
    }

    if (loading) {
      return <Spinner />;
    }

    if (!degree) {
      return null;
    }

    return <WrappedComponent degree={degree} />;
  };
}
