import ApiService from './apiService';

interface GetUserProjectsOptions {
  courseId?: number;
}

const ReadingService = {
  getUserProjects: async({ courseId }: GetUserProjectsOptions) => {
    const response = await ApiService.get(`/user-projects${courseId ? '?courseId=' + courseId : '' }`);
    return response.data as UserProject[];
  },
  getUserProject: async(userProjectId: number) => {
    const response = await ApiService.get(`/user-projects/${userProjectId}`);
    return response.data;
  },

  saveUserProject: async(projectId: number, file: ApiFile) => {
    const response = await ApiService.put(`/user-projects`, {
      projectId,
      file,
    });
    return response.data;
  },

  updateProjectReview: async(userProjectId: number, data: {
    review: ProjectReview,
    userProject: UserProject
  }) => {
    const response = await ApiService.put(`/user-projects/${userProjectId}/review`, data);
    return response.data as ProjectReview;
  },

  getAllUserProjectsForProject: async (userId: number, projectId: number) => {
    const response = await ApiService.get(`/users/${userId}/project/${projectId}`);
    return response.data as UserProject[];
  },
  deleteFileReading: async (readingId: number, fileId: number): Promise<User> => {
    const response = await ApiService.delete(
      `/user-reading/${readingId}/file/${fileId}`,
    );
    return response.data;
  },
}

export default ReadingService;