import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Row, Col, Input, List, Card } from 'antd';
import { Link } from 'react-router-dom';

import { useQuery } from '@utils/routerHooks';
import SearchService, { ISearchData } from '@services/searchService';
import Spinner from '@components/Spinner';

const { Search } = Input;

export interface ISearchPageProps {}

const NoDataMessage = (
  <div style={{ textAlign: 'center', padding: 32 }}>
    No items match your search.
  </div>
);

export default function SearchPage(props: ISearchPageProps) {
  const { searchText: initialSearchText } = useQuery<{
    searchText: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>(initialSearchText);
  const [searchData, setSearchData] = useState<ISearchData>();

  const handleSearch = async (value: string) => {
    // _.debounce(value => {
    //   setSearchData(undefined);
    //   setSearchText(value);
    // });
    setLoading(true);
    setSearchText(value);
    SearchService.search(value, 10).then(results => {
      setSearchData(results);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (searchText && searchText.length >= 3 && !loading && !searchData) {
      setLoading(true);
      setSearchText(initialSearchText);
      SearchService.search(searchText, 5).then(results => {
        setSearchData(results);
        setLoading(false);
      });
    }
  }, [searchText, loading, searchData, initialSearchText]);

  useEffect(() => {
    setSearchText(initialSearchText);
    setSearchData(undefined);
  }, [initialSearchText]);

  return (
    <div>
      {/* <Row gutter={16}>
        <Col span={24}>
          <Search
            placeholder="Search degres, courses, lessons and objectives"
            onSearch={handleSearch}
            style={{ width: 200 }}
            loading={loading}
            value={initialSearchText}
            enterButton={true}
            onChange={(e: any) => setSearchText(e.target.value)}
          />
        </Col>
      </Row> */}
      {loading && <Spinner />}
      {searchData && (
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={12}>
            <Card title="Degrees" size="small">
              {searchData.degrees.length ? (
                <List>
                  {searchData.degrees.map(item => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <Link to={`/degrees/${item.id}`}>{item.name}</Link>
                        }
                      />
                    </List.Item>
                  ))}
                </List>
              ) : (
                NoDataMessage
              )}
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Courses" size="small">
              {searchData.courses.length ? (
                <List>
                  {searchData.courses.map(item => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <Link to={`/courses/${item.id}`}>{item.name}</Link>
                        }
                      />
                    </List.Item>
                  ))}
                </List>
              ) : (
                NoDataMessage
              )}
            </Card>
          </Col>
        </Row>
      )}
      {searchData && (
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={12}>
            <Card title="Lessons" size="small">
              {searchData.courses.length ? (
                <List>
                  {searchData.courses.map(item => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <Link to={`/lessons/${item.id}`}>{item.name}</Link>
                        }
                      />
                    </List.Item>
                  ))}
                </List>
              ) : (
                NoDataMessage
              )}
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Objectives" size="small">
              {searchData.objectives.length ? (
                <List>
                  {searchData.objectives.map(item => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <Link to={`/objectives/${item.id}`}>{item.name}</Link>
                        }
                      />
                    </List.Item>
                  ))}
                </List>
              ) : (
                NoDataMessage
              )}
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}
