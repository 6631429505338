import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isCourseExamPassed } from '@utils/helpers';

export interface UserPayload {
  success: boolean;
  message?: string;
  errors?: ApiError['errors'];
}

export interface UserState {
  isLoading: boolean;
  isQuizLoading: boolean;
  billingInfo?: any;
  errors?: ApiError['errors'];
  user?: User;
  quiz?: Quiz;
  quizzes?: Quiz[];
  error: boolean;
  markCompletedLoading: boolean;
  scheduledCalls: ScheduledCall[];
  callsLoading?: boolean;
  courseExamPassed?: boolean;
}

interface LoginData {
  email: string;
  password: string;
}

const initialState: UserState = {
  isLoading: false,
  isQuizLoading: false,
  scheduledCalls: [],
  error: false,
  markCompletedLoading: false,
  callsLoading: false,
};

const UserSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    enrollDegree(state, action: PayloadAction<number>) {
      state.isLoading = true;
      return state;
    },
    enrollDegreeSuccess(state, action: PayloadAction<User>) {
      return { ...state, user: action.payload, isLoading: false };
    },
    enrollDegreeFail(state, action: PayloadAction<ApiError['errors']>) {
      return { ...state, ...action.payload, isLoading: false };
    },
    getCurrentUser(state) {
      state.isLoading = true;
      return state;
    },
    getCurrentUserSuccess(state, action: PayloadAction<User>) {
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        markCompletedLoading: false,
        scheduledCalls: action.payload.scheduledCalls,
      };
    },
    getCurrentUserFail(state) {
      return {
        ...state,
        authError: true,
        isLoading: false,
        markCompletedLoading: false,
      };
    },
    getQuiz(state, action: PayloadAction<Number>) {
      state.isQuizLoading = true;
      return state;
    },
    getQuizSuccess(state, action: PayloadAction<Quiz>) {
      return { ...state, quiz: action.payload, isQuizLoading: false };
    },
    getQuizzes(state, action: PayloadAction<Number>) {
      return { ...state, quizzes: undefined };
    },
    getQuizzesSuccess(state, action: PayloadAction<Quiz[]>) {
      const courseExamPassed = isCourseExamPassed(action.payload);
      return { ...state, courseExamPassed, quizzes: action.payload };
    },
    submitQuiz(state, action: PayloadAction<any>) {
      state.isQuizLoading = false;
      state.quiz = undefined;
      return state;
    },
    getSubmitQuizSuccess(state, action: PayloadAction<Quiz>) {
      state.quizzes = [action.payload, ...(state.quizzes || [])];
      state.courseExamPassed = isCourseExamPassed(state.quizzes);
      return state;
    },
    markCompleted(state, action: PayloadAction<number>) {
      state.markCompletedLoading = true;
      return state;
    },
    objectiveMedia(state, action: PayloadAction<any>) {
      return state;
    },
    getBillingInfo(state) {
      return state;
    },
    getBillingInfoSuccess(state, action: PayloadAction<any>) {
      state.billingInfo = action.payload;
      return state;
    },
    getScheduledCalls(state) {
      return { ...state, callsLoading: true };
    },

    getScheduledCallsSuccess(state, action: PayloadAction<ScheduledCall[]>) {
      return { ...state, callsLoading: false, scheduledCalls: action.payload };
    },
  },
});

const { actions, reducer } = UserSlice;

export const {
  enrollDegree,
  enrollDegreeSuccess,
  enrollDegreeFail,
  getCurrentUser,
  getCurrentUserFail,
  getCurrentUserSuccess,
  getQuiz,
  getQuizSuccess,
  getQuizzes,
  getQuizzesSuccess,
  submitQuiz,
  getSubmitQuizSuccess,
  markCompleted,
  objectiveMedia,
  getBillingInfo,
  getBillingInfoSuccess,
  getScheduledCalls,
  getScheduledCallsSuccess,
} = actions;
export default reducer;
