import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Input, Button, Menu, Layout } from 'antd';
import { toast } from 'react-toastify';
import { AppState } from '@redux/reducers';
import {
  createChat,
  getChats,
  sendMessage,
  selectChat,
  getChat,
  CreateChatPayload,
} from '@redux/reducers/chat';
import Spinner from '@components/Spinner';
import { useQuery, useUpdateQuery } from '@utils/routerHooks';
import { getChatUserName } from './helpers';
import NewChatModal from './NewChatModal';

const { Header, Footer, Sider, Content } = Layout;

interface ChatProps {
  isLoading: boolean;
  getChats: typeof getChats;
  createChat: typeof createChat;
  sendMessage: typeof sendMessage;
  selectChat: typeof selectChat;
  getChat: typeof getChat;
  user?: User;
  chats?: Chat[];
  selectedChat?: Chat;
}

const Chat: React.FC<ChatProps> = ({
  user,
  chats,
  selectedChat,
  isLoading,
  getChats,
  getChat,
  createChat,
  sendMessage,
  selectChat,
}) => {
  const query = useQuery<{ chatId: string }>();
  const updateQuery = useUpdateQuery<{ chatId: string }>();

  const queryUserId = useQuery<{ userId: string }>();
  const updateQueryUserId = useUpdateQuery<{ userId: string }>();
  const [chatUserId, setChatUserId] = useState<any>();
  const [aaaaa, setAaaa] = useState(false);
  const [newChatVisible, setNewChatVisible] = useState(false);
  const [message, setMessage] = useState<any>();
  const [interval, setIntervalRef] = useState<any>();

  if (query.chatId && chats) {
    const chat = chats.find(c => c.id === Number(query.chatId));
    if (chat) {
      selectChat(chat);
      updateQuery({ chatId: undefined });
    }
  }

  if (queryUserId.userId && chats) {
    if (chats.length > 0) {
      /* for (var i = 0; i < chats.length; i++) {
        let loginUserId = user ? user.id : '';
        if (
          chats[i].users[0].id == Number(loginUserId) &&
          chats[i].users[1].id == Number(queryUserId.userId)
        ) {
          if (aaaaa) {
            setAaaa(true);
            //setChatUserId('');
          }
          const chat = chats.find(c => c.id === Number(chats[i].id));
          if (chat) {
            console.log('chat', chat);
            selectChat(chat);
          }
        } else {
          console.log('else nagar', chats[i].id);
          //setChatUserId(chats[i].id)
        }
      } */
      // updateQueryUserId({ userId: undefined });
      const loginUserId = user ? user.id : '';
      const aaaa = chats.filter((e: any) => {
        if (
          e.users.length >= 2 &&
          e.users[0].id == Number(loginUserId) &&
          e.users[1].id == Number(queryUserId.userId)
        ) {
          return e;
        }
        return null;
      });
      if (aaaa && aaaa.length > 0) {
        selectChat(aaaa[0]);
      } else if (!aaaaa) {
        setAaaa(true);
        setChatUserId(queryUserId.userId);
        setNewChatVisible(true);
      }
      updateQueryUserId({ userId: undefined });
    }
  }

  useEffect(() => {
    if (!chats) {
      getChats();
    }
  }, [chats, getChats]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!user) {
    return null;
  }

  if (selectedChat && !interval) {
    const int = setInterval(() => {
      getChat(selectedChat.id);
    }, 5000);
    setIntervalRef(int);
  }

  const handleSelectChat = ({ key }: { key: string }) => {
    const newChat = chats && chats.find(c => c.id === Number(key));
    if (interval) {
      clearInterval(interval);
      setIntervalRef(undefined);
    }
    if (newChat) {
      // console.log('newChat', newChat);
      selectChat(newChat);
    }
  };

  const isMyMessage = (message: Message) => message.userId === user.id;

  const send = (chat: CreateChatPayload) => {
    createChat(chat);
    setNewChatVisible(false);
  };

  const handleSendMessage = (value: string) => {
    if (selectedChat) {
      if (selectedChat.users && selectedChat.users.length == 2) {
        const newArray = selectedChat.users.filter(el => el.id != user.id);
        const sendChatUserId = newArray[0].id;
        setMessage(undefined);
        sendMessage({
          chatId: selectedChat.id,
          text: value,
          sendChatUserId,
        });
      } else {
        toast.error('Please select user!');
      }
    }
  };

  return (
    <Layout style={{ minHeight: '85vh' }}>
      <Sider
        style={{
          background: '#fff',
          borderRight: '1px solid #ddd',
          overflow: 'auto',
        }}
      >
        <div style={{ padding: 12 }}>
          <Button
            type="default"
            block
            onClick={() => {
              setNewChatVisible(true);
            }}
          >
            Start new chat
          </Button>
          {chats && !chats.length && (
            <h4 style={{ textAlign: 'center', marginTop: 8 }}>No chats</h4>
          )}
        </div>
        <Menu
          mode="inline"
          onSelect={handleSelectChat}
          selectedKeys={selectedChat && [String(selectedChat.id)]}
        >
          {chats &&
            chats.map(chat => (
              <Menu.Item key={chat.id}>
                <span className="nav-text">
                  {getChatUserName(user, chat.users)}
                </span>
              </Menu.Item>
            ))}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            background: '#fff',
            paddingLeft: 12,
            borderBottom: '1px solid #ddd',
            position: 'fixed',
            zIndex: 1,
            width: '100%',
          }}
        >
          {selectedChat && <h2>{getChatUserName(user, selectedChat.users)}</h2>}
        </Header>
        <Content
          style={{
            padding: 12,
            paddingTop: 72,
            background: '#fff',
            overflow: 'auto',
          }}
        >
          {!selectedChat && (
            <div style={{ textAlign: 'center', marginTop: '150px' }}>
              <h3>Please select chat on the left to see messages.</h3>
            </div>
          )}
          {selectedChat &&
            selectedChat.messages &&
            selectedChat.messages.map(message => (
              <div
                key={message.id}
                style={{
                  margin: '6px 0',
                  textAlign: isMyMessage(message) ? 'right' : 'left',
                }}
              >
                <div
                  style={{
                    padding: '6px 12px',
                    border: '1px solid #ddd',
                    background: isMyMessage(message) ? '#46A5E4' : '#bbb',
                    display: 'inline-block',
                    borderRadius: 8,
                    color: '#fff',
                  }}
                >
                  <p style={{ margin: 0 }}>{message.text}</p>
                  <span>
                    <small>{moment(message.createdAt).fromNow()}</small>
                  </span>
                </div>
              </div>
            ))}
        </Content>
        <Footer
          style={{
            background: '#fff',
            paddingLeft: 12,
            borderTop: '1px solid #ddd',
            zIndex: 1,
            width: '100%',
          }}
        >
          {selectedChat && (
            <Input.Search
              placeholder="Enter message"
              enterButton="Send"
              value={message}
              onChange={e => setMessage(e.target.value)}
              onSearch={handleSendMessage}
              width="100%"
            />
          )}
        </Footer>
      </Layout>
      {newChatVisible && (
        <NewChatModal
          send={send}
          onClose={() => {
            setNewChatVisible(false);
            setMessage(undefined);
          }}
          chatUserId={chatUserId}
        />
      )}
    </Layout>
  );
};

const selectChatState = (state: AppState) => ({
  user: state.user.user,
  chats: state.chat.chats,
  selectedChat: state.chat.selectedChat,
  isLoading: state.chat.isLoading,
});

const ConnectedChat = connect(selectChatState, {
  createChat,
  getChats,
  sendMessage,
  selectChat,
  getChat,
})(Chat);

const Page = () => <ConnectedChat />;

export default Page;
