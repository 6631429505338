import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import { stopCall, setVideoCall } from '@redux/reducers/videoCall';
import { Role, VideoCallType } from '@types';
import { useHistory } from 'react-router';
import { getCurrentUser } from '@redux/reducers/user';
import VideoChatModal from './VideoChatModal';

interface VideoChatProps {
  user?: User;
  videoCall?: VideoCall;
  token?: string;
  stopCall: typeof stopCall;
  getCurrentUser: typeof getCurrentUser;
  setVideoCall: typeof setVideoCall;
}

const VideoChat: React.FC<VideoChatProps> = ({
  user,
  videoCall,
  token,
  stopCall,
  getCurrentUser,
  setVideoCall,
}) => {
  const history = useHistory();
  const handleLogout = () => {
    if (
      user &&
      videoCall &&
      user.role !== Role.Student &&
      videoCall.type === VideoCallType.CourseCall
    ) {
      history.push(
        `/courses/${videoCall.course.id}/students/${videoCall.student.id}`,
      );
    }

    if (
      user &&
      videoCall &&
      user.role === Role.Student &&
      videoCall.type === VideoCallType.OnboardingCall
    ) {
      getCurrentUser();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }

    videoCall && stopCall(videoCall);
  };

  if (!user) {
    return null;
  }

  return (
    <>
      {videoCall && token ? (
        <VideoChatModal
          user={user}
          videoCall={videoCall}
          token={token}
          handleLogout={handleLogout}
          setVideoCall={setVideoCall}
        />
      ) : null}
    </>
  );
};

const selectVideoChatState = (state: AppState) => ({
  user: state.user.user,
  videoCall: state.videoCall.videoCall,
  token: state.videoCall.token,
});

const ConnectedVideoChat = connect(selectVideoChatState, {
  stopCall,
  getCurrentUser,
  setVideoCall,
})(VideoChat);

export default ConnectedVideoChat;
