import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { toast } from 'react-toastify';
import { Rate, Modal, Button } from 'antd';
import CourseService from '@services/courseService';

interface CourseReviewData extends CourseReview {
  id?: number;
}

interface CourseReviewFormProps {
  userCourse: UserCourse;
  onReviewUpdate?: (review: CourseReview) => void;
}

export const CourseReviewForm: React.FC<CourseReviewFormProps> = ({
  userCourse,
  onReviewUpdate = () => {},
}) => {
  const { course, review } = userCourse;
  const { goals = [] } = course || { goals: [] };
  const [courseReview, setReview] = useState<CourseReviewData>(
    review || {
      grades: goals.map(goal => {
        //console.log('111111111111', goal);
        return {
          goal: goal,
          goalId: goal.id,
          approved: false,
          grade: 0,
        };
      }),
      userCourseId: userCourse.id,
    },
  );

  const handleGradeChangeOld = (index: number, value: boolean) => {
    const newReview = { ...courseReview };
    newReview.grades[index].approved = value;
    setReview(newReview);
    onReviewUpdate(newReview);
  };

  const handleGradeChange = (index: number, valueRate: number) => {
    const newReview = { ...courseReview };
    newReview.grades[index].grade = valueRate;
    if (valueRate == 5) {
      newReview.grades[index].approved = true;
    } else {
      newReview.grades[index].approved = false;
    }
    setReview(newReview);
    onReviewUpdate(newReview);
  };

  // const handleNoteChange = (index: number) => (value: string) => {
  //   const newReview = { ...courseReview };
  //   newReview.grades[index].note = value;
  //   setReview(newReview);
  //   onReviewUpdate(newReview);
  // };

  const updateCourseReview = async () => {
    try {
      await CourseService.updateCourseReview(userCourse.id, courseReview);
      toast.success('Student course review updated!');
    } catch (err) {
      toast.error('An error occured!');
    }
  };

  return (
    <div>
      {courseReview.grades.length === 0 ? (
        <p style={{ color: 'red' }}>
          This course does not have any grade rubric. Please add goals to the
          course.
        </p>
      ) : null}
      {courseReview.grades.map((grade, index) => (
        <div style={{ margin: '16px 0' }}>
          <p style={{ margin: 0 }}>{grade.goal.text}</p>
          {/*<Checkbox
            onChange={e => handleGradeChangeOld(index, e.target.checked)}
            checked={grade.approved}
          >
            Approved
          </Checkbox>*/}
          <Rate
            value={grade.grade}
            style={{ fontSize: '16px' }}
            onChange={(valueRate: any) => {
              handleGradeChange(index, valueRate);
            }}
          />
        </div>
      ))}
      {/* <div>
        <Button type="primary" onClick={() => updateCourseReview()}>
          Save
        </Button>
      </div> */}
    </div>
  );
};
