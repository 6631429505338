import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Row,
  Col,
  Card,
  Divider,
  Progress,
  Tag,
  Button,
  Typography,
  Input,
} from 'antd';
import { Link } from 'react-router-dom';
import { AppState } from '@redux/reducers';
import { useRouter } from '@utils/routerHooks';
import { getCompletedPercentage } from '@utils/helpers';
import Goals from '@components/goals/Goals';
import ApiService from '@services/apiService';
import QuizReviewModal from '@components/quiz/QuizReviewModal';
import Spinner from '@components/Spinner';
import { StudentProjectReview } from '@components/projectReview';
import { CourseReviewForm } from '@components/courseReview';
import CourseService from '@services/courseService';
import { toast } from 'react-toastify';
import LessonList from '@components/lessonList/LessonList';
interface TParams {
  courseId?: string;
  studentId?: string;
}

interface CourseProps {
  user?: User;
  courseId?: number;
  studentId?: number;
}

interface StudentCourseData {
  student: Student;
  course: Course;
  userCourse: UserCourse;
  notes: Note[];
}

const StudentCourseReviewComponent: React.FC<CourseProps> = ({
  user,
  courseId: propCourseId = 0,
  studentId: propStudentId = 0,
}) => {
  const [data, setData] = useState<StudentCourseData>();
  const [loading, setLoading] = useState(true);
  const [noteText, setNoteText] = useState('');
  const [quizReview, setQuizReview] = useState<Quiz>();
  const [courseReview, setCourseReview] = useState<CourseReview>();

  const { match, location } = useRouter<TParams>();

  const courseId = match.params.courseId || propCourseId;
  const studentId = match.params.studentId || propStudentId;

  const approveDisabled = useMemo(() => {
    if (!courseReview) return true;

    return !!courseReview.grades.find(grade => !grade.approved);
  }, [courseReview]);

  useEffect(() => {
    const getData = async (
      studentId: string | number,
      courseId: string | number,
    ) => {
      setLoading(true);
      const response = (await ApiService.get(
        `/courses/${courseId}/students/${studentId}`,
        data,
      )) as any;

      const json = response.data;
      setCourseReview(json.userCourse.review);
      setData(json);
      setLoading(false);
    };
    if (!data) {
      getData(studentId, courseId);
    }
  }, [courseId, data, studentId]);

  if (loading || !data) {
    return <Spinner />;
  }

  if (!user) {
    return null;
  }

  const { course, student, notes, userCourse } = data;

  const courseObjectives: Objective[] = [];

  course.lessons &&
    course.lessons.forEach(lesson => {
      lesson.objectives.forEach(o => courseObjectives.push(o));
    });

  const percentCompleted = getCompletedPercentage(courseObjectives, student);

  const openQuizReview = (quiz: Quiz) => {
    setQuizReview(quiz);
  };

  const closeQuizReview = () => {
    setQuizReview(undefined);
  };

  const saveNote = async (approved: boolean) => {
    try {
      await ApiService.post(
        `/courses/${courseId}/students/${studentId}/notes`,
        { approved, text: noteText },
      );
      courseReview &&
        (await CourseService.updateCourseReview(userCourse.id, courseReview));
      setData(undefined);
      setNoteText('');
      toast.success('Student course review updated!');
    } catch (err) {
      toast.error('An error occured!');
    }
  };

  const studentProjects = student.userProjects.filter(
    up => course.projects && course.projects.find(p => p.id === up.projectId),
  );

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Tag color="green">Course</Tag>
        <h2 style={{ margin: '8px 0', color: '#333' }}>{course.name}</h2>
        <Card>
          {course.lessons && (
            <div>
              <Link to={`${location.pathname}/lessons-overview`}>
                <h3>Lessons</h3>
              </Link>
              <LessonList lessons={course.lessons} />
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

const selectCourseState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

export const StudentCourseReview = connect(
  selectCourseState,
  {},
)(StudentCourseReviewComponent);

const Page = () => <StudentCourseReview />;

export default Page;
