import React, { ReactNode } from 'react';
import { Tag, Divider, PageHeader, Button } from 'antd';
import YouTubeEmbed from 'react-youtube-embed';
import ObjectiveList from '@components/objectives/ObjectiveList';
import { useRouter } from '@utils/routerHooks';
import { isUserInstructor } from '@helpers';
import { markCompleted } from '@redux/reducers/user';

interface LessonDetailsProps {
  lesson: Lesson;
  user: User;
  markCompleted: typeof markCompleted;
  markCompletedLoading: boolean;
}

const LessonDetails: React.FC<LessonDetailsProps> = ({
  lesson,
  user,
  markCompleted,
  markCompletedLoading,
}) => {
  const { history } = useRouter();

  let extra: ReactNode[] = [];

  if (isUserInstructor(user)) {
    extra = [
      <Button
        key="edit"
        onClick={() => history.push(`/lessons/${lesson.id}/edit`)}
      >
        Edit
      </Button>,
    ];
  }
  return (
    <div style={{ background: '#fff' }}>
      <PageHeader
        style={{
          background: '#fff',
          padding: '16px 16px 0',
        }}
        extra={extra}
        title={
          <>
            <Tag color="cyan" style={{ fontWeight: 400 }}>
              Lesson
            </Tag>
            <h2 style={{ margin: '8px 0' }}>{lesson.name}</h2>
          </>
        }
      />
      <div style={{ padding: '16px' }}>
        <Divider style={{ margin: '0 0 20px' }} />
        {lesson.introVideo && <YouTubeEmbed id={lesson.introVideo} />}
        <div>
          <h3>Short description</h3>
          <p>{lesson.shortDescription}</p>
        </div>
        {lesson.longDescription && (
          <div>
            <Divider dashed={true} />
            <h3>Long description</h3>
            <div dangerouslySetInnerHTML={{ __html: lesson.longDescription }} />
          </div>
        )}
        {lesson.objectives && (
          <div>
            <Divider dashed={true} />
            <h3>Objectives</h3>
            <ObjectiveList
              user={user}
              objectives={lesson.objectives}
              markCompleted={markCompleted}
              markCompletedLoading={markCompletedLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonDetails;
