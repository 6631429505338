import React, { useState } from 'react';
import { Checkbox, Button, Alert } from 'antd';
import { isUserInstructor } from '@helpers';

interface Props {
  user: User;
  loading: boolean;
  confirmCourseHearing: () => void;
}

export const ConfirmCourseHearingForm: React.FC<Props> = ({
  user,
  confirmCourseHearing,
  loading,
}) => {
  const [confirm1, setConfirm1] = useState(false);
  const [confirm2, setConfirm2] = useState(false);
  const [confirm3, setConfirm3] = useState(false);
  const [confirm4, setConfirm4] = useState(false);
  const [error, setError] = useState<string>();

  const validateForm = () => {
    if (isUserInstructor(user)) {
      return confirm1;
    }

    return confirm1 && confirm2 && confirm3 && confirm4;
  };

  const handleSubmit = () => {
    const valid = validateForm();

    if (valid) {
      confirmCourseHearing();
    } else {
      setError('Please confirm all checkboxes.');
    }
  };

  const form = isUserInstructor(user) ? (
    <div>
      <Checkbox
        checked={confirm1}
        onChange={e => setConfirm1(e.target.checked)}
      >
        {/*I understand that I am entering a course hearing. I understand that my
        role in a course hearing is to use the provided rubric and my best
        judgment to verify the student’s mastery of the course goals and
        objectives as they are currently stated, and not to depart from the
        course goals and objectives in my assessment. I also understand that I’m
        not permitted to instruct or tutor students during the assessment
        portion of the course hearing. To the best of my ability, I promise to
        conduct this course hearing objectively and fairly.*/}
        I understand that I am entering a course hearing. I understand that my
        role in a course hearing is to use the provided rubric and my subject
        matter expertise to verify the student’s mastery of the course goals and
        objectives as they are currently stated, and not to depart from the
        course goals and objectives in my assessment. I also understand that I’m
        not permitted to instruct or tutor students during the assessment
        portion of the course hearing. To the best of my ability, I promise to
        conduct this course hearing objectively and fairly.
      </Checkbox>
    </div>
  ) : (
    <div>
      <p>
        {/* Congratulations! You are entering the last step of completing a course:
        a course hearing. In a course hearing, you will meet with an expert who
        will ask you questions about the course goals and objectives to
        determine your mastery. The task of the expert is to use their best
        judgment to determine what goals and objectives you have mastered in the
        course, and if there are goals or objectives that you have not yet
        mastered, to tell you what they are, and to point you to review
        resources and make sure you are clear on what your next steps would be.
        A course hearing is designed to verify your current mastery of the
        course goals and objectives. It is not a tutoring session. By entering
        the course hearing, you give your consent to being recorded, and having
        that recording stored indefinitely by Newlane University for training
        and mastery-verification purposes.*/}
        Congratulations! You are entering the last step of completing a course:
        a course hearing. In a course hearing, you will meet with an expert who
        will ask you questions about the course goals and objectives to
        determine your mastery. The task of the expert is to use their subject
        matter expertise to determine what goals and objectives you have
        mastered in the course, and if there are goals or objectives that you
        have not yet mastered, to tell you what they are, and to point you to
        review resources and make sure you are clear on what your next steps
        would be. A course hearing is designed to verify your current mastery of
        the course goals and objectives. It is not a tutoring session. By
        entering the course hearing, you give your consent to being recorded,
        and having that recording stored indefinitely by Newlane University for
        training and mastery-verification purposes.
        <br />
        <br />
        If you disagree with the assessment, you can contest it by emailing:
        contact@newlaneuniversity.com with the subject ‘contest course hearing’.
        This will trigger a review process.
      </p>
      <div>
        <Checkbox
          checked={confirm1}
          onChange={e => setConfirm1(e.target.checked)}
        >
          I understand that I am entering a course hearing, and that the purpose
          of a course hearing is to verify my current mastery of the course
          goals and objectives.
        </Checkbox>
      </div>
      <div>
        <Checkbox
          checked={confirm2}
          onChange={e => setConfirm2(e.target.checked)}
        >
          I understand that by entering this course hearing, I am giving my
          consent to Newlane University to record, review, and to store any
          recordings of this session for training and/or mastery verification
          purposes.
        </Checkbox>
      </div>
      <div>
        <Checkbox
          checked={confirm3}
          onChange={e => setConfirm3(e.target.checked)}
        >
          I verify that I am who I am presenting myself to be.
        </Checkbox>
      </div>
      <div>
        <Checkbox
          checked={confirm4}
          onChange={e => setConfirm4(e.target.checked)}
        >
          I verify that I have not received, nor will I receive any unauthorized
          assistance for this course hearing.
        </Checkbox>
      </div>
    </div>
  );

  return (
    <div>
      {form}
      {error && (
        <Alert message={error} type="error" style={{ marginTop: 16 }} />
      )}
      <div style={{ marginTop: 16, textAlign: 'center' }}>
        <Button onClick={handleSubmit} loading={loading} disabled={loading}>
          Submit
        </Button>
      </div>
    </div>
  );
};
