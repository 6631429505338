import ProjectService from '@services/projectService';
import { useCallback, useEffect, useState } from 'react';

export const useGetStudentProjects = (courseId: number) => {
  const [data, setData] = useState<UserProject[]>();
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    () =>
      ProjectService.getUserProjects({ courseId }).then(data => {
        setData(data);
        setLoading(false);
      }),
    [courseId],
  );

  useEffect(() => {
    if (!data && !loading) {
      setLoading(true);
      fetchData();
    }
  }, [data, fetchData, loading]);

  return { data, loading, fetchData };
};
