import * as React from 'react';
import { List, Divider } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getChatUser, getChatUserName } from '@screens/chat/helpers';
import { getUserAvatar } from '@utils/helpers';

export interface IChatListProps {
  user: User;
  chats?: Chat[];
}

export default function ChatList({ chats, user }: IChatListProps) {
  /* if (chats && !chats.length) {
    return <h4 style={{ textAlign: 'center', marginTop: 8 }}>No chats</h4>;
  }*/

  return (
    <List
      itemLayout="horizontal"
      header={
        <div>
          <Link to="/chat">Chats</Link>
        </div>
      }
      bordered
      dataSource={chats}
      style={{ backgroundColor: '#fff', minWidth: 350 }}
      renderItem={chat => {
        const chatUser = getChatUser(user, chat.users);

        let avatar = null;

        if (chatUser) {
          avatar = getUserAvatar(chatUser, 60);
        }

        return (
          <>
            <List.Item style={{ margin: 0 }}>
              <List.Item.Meta
                avatar={<div style={{ paddingTop: '4px' }}>{avatar}</div>}
                title={
                  <Link to={`/chat?chatId=${chat.id}`}>
                    <b>{getChatUserName(user, chat.users)}</b>
                  </Link>
                }
                description={
                  <p style={{ margin: 0 }}>
                    {chat.messages[chat.messages.length - 1].text}
                    <br />
                    <small>
                      {moment(
                        chat.messages[chat.messages.length - 1].createdAt,
                      ).fromNow()}
                    </small>
                  </p>
                }
              />
            </List.Item>
            {/*<Divider style={{ margin: '4px 0' }} />*/}
          </>
        );
      }}
    />
  );
}
