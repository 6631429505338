import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Alert, Input, Button, Comment, Avatar, Form } from 'antd';
import CourseService from '@services/courseService';
import { CREATE_DISCUSSION, discussionsQuery } from './queries';

const NewDiscussionForm: React.FC<{
  degreeId?: number;
  courseId?: number;
  user: User;
  onCreate: () => void;
}> = ({ user, degreeId, courseId, onCreate }) => {
  const [text, setText] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [error, setError] = useState<string>();
  const [createDiscussion, { data, loading }] = useMutation(CREATE_DISCUSSION);

  const handleCreateDiscussion = () => {
    // return;
    if (!text || !title) {
      return setError('Please fill out all the fields.');
    }
    createDiscussion({
      variables: { text, title, degreeId, courseId, createdById: user.id },
      refetchQueries: [
        {
          query: courseId
            ? discussionsQuery(degreeId, courseId)
            : discussionsQuery(degreeId),
        },
      ],
    }).then(() => {
      setText(undefined);
      setTitle(undefined);
      onCreate();
      Notifications();
    });
  };
  const Notifications = async () => {
    const data = {
      courseId,
    };
    await CourseService.discussionsNotifications(data);
  };
  return (
    <div style={{ margin: '16px 0' }}>
      <Comment
        author={<a>{`${user.firstName} ${user.lastName}`}</a>}
        avatar={
          <Avatar
            src={
              user.avatar
                ? user.avatar.url
                : `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`
            }
            alt={`${user.firstName} ${user.lastName}`}
          />
        }
        content={
          <div>
            {error && <Alert type="error" message={error} />}
            <Form.Item style={{ margin: '4px 0' }}>
              <Input
                placeholder="Discussion title"
                onChange={(e: any) => {
                  setTitle(e.target.value);
                  setError(undefined);
                }}
                value={title}
              />
            </Form.Item>
            <Form.Item style={{ margin: '4px 0' }}>
              <Input.TextArea
                rows={2}
                placeholder="Discussion text"
                onChange={(e: any) => {
                  setText(e.target.value);
                  setError(undefined);
                }}
                value={text}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                onClick={handleCreateDiscussion}
                type="primary"
                loading={loading}
              >
                Create discussion
              </Button>
            </Form.Item>
          </div>
        }
      />
    </div>
  );
};

export default NewDiscussionForm;
