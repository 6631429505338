import { takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import UserNoteService from '@services/userNotesService';
import {
  addUserNoteFail,
  addUserNoteSuccess,
  getUserNotesFail,
  getUserNotesSuccess,
  removeUserNotesFail,
  removeUserNotesSuccess,
  updateUserNotesFail,
  updateUserNotesSuccess,
} from '../reducers/userNotes';

function* addUserNote({
  payload: data,
}: PayloadAction<any>): Generator<any, any, any> {
  try {
    const response = yield UserNoteService.create(data) as Promise<unknown>;

    const json = response;

    yield put(addUserNoteSuccess(json));
  } catch (err: any) {
    console.error(err);
    yield put(addUserNoteFail(err.response.data));
  }
}

function* removeUserNote({
  payload: data,
}: PayloadAction<any>): Generator<any, any, any> {
  try {
    const response = yield UserNoteService.remove(data) as Promise<unknown>;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const json = response;

    yield put(removeUserNotesSuccess(data));
  } catch (err) {
    console.error(err);
    yield put(removeUserNotesFail());
  }
}

function* getUserNotes({
  payload: data,
}: PayloadAction<any>): Generator<any, any, any> {
  try {
    let response;
    switch (data.rel) {
      case 'objective':
        response = yield UserNoteService.getAllForObjective(
          data.relId,
        ) as Promise<unknown>;
        break;
      case 'lesson':
        response = yield UserNoteService.getAllForLesson(
          data.relId,
        ) as Promise<unknown>;
        break;
      case 'course':
        response = yield UserNoteService.getAllForCourse(
          data.relId,
        ) as Promise<unknown>;
        break;
    }

    const json = response;
    yield put(getUserNotesSuccess(json));
  } catch (err) {
    console.error(err);
    yield put(getUserNotesFail());
  }
}

function* updateUserNote({
  payload: data,
}: PayloadAction<any>): Generator<any, any, any> {
  try {
    const response = yield UserNoteService.update(
      data.id,
      data,
    ) as Promise<unknown>;

    const json = response;

    yield put(updateUserNotesSuccess(json));
  } catch (err) {
    console.error(err);
    yield put(updateUserNotesFail());
  }
}

function* watchForRequestData() {
  yield takeLatest('userNotes/addUserNote', addUserNote);
  yield takeLatest('userNotes/removeUserNote', removeUserNote);
  yield takeLatest('userNotes/getUserNotes', getUserNotes);
  yield takeLatest('userNotes/updateUserNote', updateUserNote);
}

export default watchForRequestData;
