import React from 'react';
import { Link } from 'react-router-dom';
import {
  PlusOutlined,
  QuestionCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { Layout, Dropdown, Avatar, Button, Menu } from 'antd';

import './styles.less';
import {
  isUserSubscribed,
  isUserInstructor,
  isUserSubscriptionCanceled,
} from '@helpers';
import logo from '@assets/logo.png';
import logoIcon from '@assets/logo-icon.webp';
import NotificationsDropdown from '@components/Notifications/NotificationsDropdown';
import ChatDropdown from '@components/chat/ChatDropdown';
import HeaderSearch from './Search';
import AvatarDropdown from './AvatarDropdown';
import { isSalesPerson } from '..';

interface HeaderProps {
  user: User;
  collapsed: boolean;
  onCollapse: () => void;
}

const Header: React.FC<HeaderProps> = ({ user, collapsed, onCollapse }) => {
  const avatarDropdown = AvatarDropdown(user);

  const ToggleIcon = collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;

  return (
    <Layout.Header
      className={`app-header ${collapsed ? 'collapsed' : ''}`}
      style={{ backgroundColor: '#fff', padding: '0 8px' }}
    >
      <div className="app-header-inner bg-white">
        <div className="logo">
          <img src={logo} className="logo-img" alt="Newlane University" />
          <img
            src={logoIcon}
            className="small-logo-img"
            alt="Newlane University"
          />
        </div>
        <>
          <ToggleIcon
            onClick={onCollapse}
            type={collapsed ? 'menu-unfold' : 'menu-fold'}
            style={{ marginRight: 8 }}
          />
          {isUserInstructor(user) && <HeaderSearch />}
          {!isUserSubscribed(user) && !isUserSubscriptionCanceled(user) ? (
            <span style={{ marginLeft: 8 }}>
              To access all features please register{' '}
              <Link to="/subscribe">Register here</Link>
            </span>
          ) : null}
          {isUserSubscriptionCanceled(user) ? (
            <span style={{ marginLeft: 8 }}>
              To access all features please resume your subscription{' '}
              <Link to="/edit-profile">Resume subscription</Link>
            </span>
          ) : null}
        </>
        <div className="header-right app-header--right">
          <div className="list-unstyled list-inline app-header--list-test">
            <a
              target="_blank"
              href="https://newlaneuniversity.activehosted.com/f/14"
              style={{ marginRight: '10px' }}
              rel="noreferrer"
            >
              <QuestionCircleOutlined style={{ fontSize: '20px' }} />
            </a>
            {isUserInstructor(user) && !isSalesPerson(user) ? (
              <Dropdown
                className="list-inline-item"
                overlay={
                  <Menu className="app-header-dropdown">
                    <Menu.Item key="0">
                      <Link to="/new-degree">
                        <PlusOutlined /> Degree
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="0">
                      <Link to="/new-course">
                        <PlusOutlined /> Course
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="0">
                      <Link to="/new-lesson">
                        <PlusOutlined /> Lesson
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="0">
                      <Link to="/new-objective">
                        <PlusOutlined /> Objective
                      </Link>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                placement="bottomRight"
              >
                <Button type="primary" ghost style={{ marginRight: '10px' }}>
                  <PlusOutlined style={{ margin: 0 }} />
                  <span className="xs--hidden">Create</span>
                </Button>
              </Dropdown>
            ) : null}

            <ChatDropdown />

            <NotificationsDropdown />

            <Dropdown
              className="list-inline-item"
              overlay={avatarDropdown}
              trigger={['click']}
              placement="bottomRight"
            >
              <a
                className="ant-dropdown-link no-link-style"
                href=""
                style={{ color: '#333', marginRight: '8px' }}
              >
                <Avatar
                  src={
                    user.avatar
                      ? user.avatar.url
                      : `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`
                  }
                  alt={`${user.firstName} ${user.lastName}`}
                  style={{ marginRight: '8px' }}
                />
                <span className="xs--hidden"> {`${user.firstName}`}</span>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </Layout.Header>
  );
};

export default Header;
