import * as React from 'react';
import { Radio, Checkbox } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import CourseService from '@services/courseService';
import DegreeService from '@services/degreeService';

interface IVisibilityToggleProps {
  item: Course | Degree;
  type: 'course' | 'degree';
  onChange: (item: Course | Degree) => void;
}

const VisibilityToggle: React.FunctionComponent<IVisibilityToggleProps> = ({
  item,
  type,
  onChange,
}) => {
  const updateItem = async (updatedItem: Degree | Course) => {
    if (type === 'course') {
      await CourseService.update(updatedItem.id, updatedItem as Course);
    }

    if (type === 'degree') {
      await DegreeService.update(updatedItem.id, updatedItem as Degree);
    }

    onChange(updatedItem);
  };

  const onRadioChange = async (e: RadioChangeEvent) => {
    const newValue = e.target.value;

    await updateItem({ ...item, visibility: newValue });
  };

  const forCreditChange = async (e: RadioChangeEvent) => {
    const newValue = e.target.checked;

    await updateItem({ ...item, forCredit: newValue });
  };

  return (
    <div>
      <Radio.Group onChange={onRadioChange} value={item.visibility}>
        <Radio value="private">Private</Radio>
        <Radio value="public">Public</Radio>
        <Radio value="comingSoon">Coming Soon</Radio>
        <Radio value="archived">Archived</Radio>
      </Radio.Group>
      <Checkbox defaultChecked={item.forCredit} onChange={forCreditChange}>
        For Credit
      </Checkbox>
    </div>
  );
};

export default VisibilityToggle;
