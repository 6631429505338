import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Spinner from '@components/Spinner';
import { Button, Row, Col, Card, Alert } from 'antd';
import UserService from '@services/userService';
import { connect } from 'react-redux';
import { getCurrentUser } from '@redux/reducers/user';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

interface SignAgreementProps {
  getCurrentUser: typeof getCurrentUser;
}

const SignAgreement: React.FC<SignAgreementProps> = ({ getCurrentUser }) => {
  const [loading, setLoading] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState<UserAgreement>();
  const [userAgreements, setUserAgreements] = useState<UserAgreement[]>();

  useEffect(() => {
    if (!userAgreements && !loading) {
      UserService.getUserAgreements().then(data => {
        setUserAgreements(data);
        setSelectedAgreement(data[0]);
        setLoading(false);
      });
    }
  }, [loading, userAgreements]);

  const signAgreement = (id: number) => {
    UserService.signAgreement(id).then(() => {
      setUserAgreements(undefined);
      getCurrentUser();
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <Alert
            type="warning"
            message="You must sign all the contract before you can continue to the site."
            style={{ marginBottom: 16 }}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={4}>
          <Card title="Agreements" size="small">
            {userAgreements &&
              userAgreements.map(userAgreement => (
                <div key={userAgreement.id}>
                  <Button
                    type="link"
                    onClick={() => setSelectedAgreement(userAgreement)}
                  >
                    userAgreement && userAgreement.signedAt ?{' '}
                    <CheckCircleOutlined /> : <CloseCircleOutlined />
                    {userAgreement.agreement.title}
                  </Button>
                </div>
              ))}
          </Card>
        </Col>
        <Col span={16}>
          <Card size="small">
            {selectedAgreement && (
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedAgreement.agreement.text,
                }}
              />
            )}
          </Card>
        </Col>
        <Col span={4}>
          <Card size="small">
            {selectedAgreement && (
              <>
                <p>
                  Signed at:{' '}
                  {selectedAgreement.signedAt
                    ? moment(selectedAgreement.signedAt).format('LLL')
                    : 'Not signed'}
                </p>
                {!selectedAgreement.signedAt && (
                  <Button
                    type="primary"
                    block
                    onClick={() => signAgreement(selectedAgreement.id)}
                  >
                    Sign
                  </Button>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const ConnectedSignAgreement = connect(() => {}, {
  getCurrentUser,
})(SignAgreement);

export default () => <ConnectedSignAgreement />;
