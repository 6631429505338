import React from 'react';
import QueueAnim from 'rc-queue-anim';
import LoginForm from './LoginForm';
import './Login.less';

const Cover = () => (
  <div className="login">
    <section className="cover cover-page">
      <div className="cover-form-container">
        <div className="cover-form-wrap z-index">
          <LoginForm />
        </div>
      </div>
      <div className="cover-bg-img" />
    </section>
  </div>
);

const Page = () => (
  <QueueAnim type="bottom" className="ui-animate">
    <div key="1">
      <Cover />
    </div>
  </QueueAnim>
);

export default Page;
