import React, { useEffect, useState, useCallback } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { AppState } from '@redux/reducers';
import { getCurrentUser, getScheduledCalls } from '@redux/reducers/user';
import { addNotification } from '@redux/reducers/notifications';
import VideoChat from '@components/videoChat/VideoChat';
import Breadcrumbs, { parsePath } from '@components/breadcrumbs/Breadcrumbs';
import connectToSockets from '@services/socketService';
import { fetchVideoCalls } from '@redux/reducers/videoCall';
import { Role } from '@types';
import Spinner from '@components/Spinner';
import DegreeService from '@services/degreeService';
import {
  COLLAPSED_WIDTH,
  NORMAL_WIDTH,
  COLLAPSED_ON,
  SM_ML_ADJ,
} from './constants';

import 'react-toastify/dist/ReactToastify.min.css';
import './styles.less';
import { isUserOnboarded } from '@utils/helpers';
import { isUserSubscribed } from '@helpers';
import { sortBy } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import Header from './header';
import Sidebar from './Sidebar';

const { Content } = Layout;

export const isSalesPerson = (user?: User) =>
  user && user.email === 'sales@newlaneuniversity.com';

interface AppLayoutProps {
  user?: User;
  isLoading: boolean;
  children: any;
  getCurrentUser: typeof getCurrentUser;
  authError: boolean;
  fetchVideoCalls: typeof fetchVideoCalls;
  getScheduledCalls: typeof getScheduledCalls;
  addNotification: typeof addNotification;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  user,
  isLoading,
  children,
  getCurrentUser,
  authError,
  fetchVideoCalls,
  getScheduledCalls,
  addNotification,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [collapsed, setCollapsed] = useState(COLLAPSED_ON >= window.innerWidth);
  const [degrees, setDegrees] = useState<Degree[]>([]);
  const [loaded, setLoaded] = useState(false);

  const pathItems = parsePath(location.pathname);
  const handleCollapse = useCallback(() => {
    if (COLLAPSED_ON >= window.innerWidth) {
      setCollapsed(true);
    } else {
      setCollapsed(collapsed);
    }
  }, [collapsed]);

  useEffect(() => {
    if (selectedKeys.indexOf(location.pathname) === -1) {
      setSelectedKeys(
        location.pathname.indexOf('/admin') !== -1
          ? ['/admin', location.pathname]
          : [location.pathname],
      );
    }
  }, [pathItems, openKeys, setOpenKeys, location.pathname, selectedKeys]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      DegreeService.getEnrolledDegrees().then(d => {
        const sortedDegrees = d.map(degree => ({
          ...degree,
          courses:
            degree.courses &&
            sortBy(
              degree.courses.map(course => ({
                ...course,
                lessons:
                  course.lessons &&
                  sortBy(
                    course.lessons.map(lesson => ({
                      ...lesson,
                      objectives:
                        lesson.objectives &&
                        sortBy(lesson.objectives, 'position'),
                    })),
                    'position',
                  ),
              })),
              'position',
            ),
        }));
        setDegrees(sortedDegrees);
      });
    }
  }, [loaded]);

  useEffect(() => {
    if (!user && !authError) {
      getCurrentUser();
      fetchVideoCalls();
    }
  }, [authError, fetchVideoCalls, getCurrentUser, user]);

  useEffect(() => {
    if (authError) {
      // toast.error('Authentication error.');
      history.push('/login');
    }
  }, [authError, history]);

  useEffect(() => {
    window.addEventListener('resize', handleCollapse);
    // handleCollapse();
    return () => window.removeEventListener('resize', handleCollapse);
  }, [handleCollapse]);

  const handleSubMenuOpen = (keys: string[]) => {
    setOpenKeys([...keys]);
  };

  if (user) {
    connectToSockets(fetchVideoCalls, getScheduledCalls, addNotification);
  }

  if (user && !isUserSubscribed(user) && location.pathname !== '/subscribe') {
    history.push('/subscribe');
    window.location.reload();
  }

  if (
    user &&
    user.onboarding &&
    !isUserOnboarded(user.onboarding) &&
    isUserSubscribed(user) &&
    location.pathname !== '/onboarding' &&
    user.role === Role.Student &&
    !isSalesPerson(user)
  ) {
    history.push('/onboarding');
  }

  // if (
  //   user &&
  //   hasUnsignedAgreements(user.agreements) &&
  //   location.pathname !== '/user-agreements' &&
  //   location.pathname !== '/onboarding' &&
  //   !isSalesPerson(user)
  // ) {
  //   history.push('/user-agreements');
  // }

  if (isSalesPerson(user) && location.pathname !== '/sales-pages') {
    history.push('/sales-pages');
  }

  if (isLoading && !user) {
    return <Spinner showLogo />;
  }

  if (!user) {
    return null;
  }

  const normalWidth =
    window.innerWidth <= COLLAPSED_ON ? SM_ML_ADJ : NORMAL_WIDTH;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        user={user || ({} as User)}
        onCollapse={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
      />
      <Layout
        style={{
          marginLeft: collapsed ? COLLAPSED_WIDTH : normalWidth,
        }}
      >
        <Sidebar
          user={user}
          collapsed={collapsed}
          handleSubMenuOpen={handleSubMenuOpen}
          degrees={degrees}
          pathItems={pathItems}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          history={history}
        />
        <Content className="main-content">
          <Breadcrumbs />
          {children}
        </Content>
        <VideoChat />
      </Layout>
    </Layout>
  );
};

const selectLayoutState = (state: AppState) => ({
  user: state.user.user,
  isLoading: state.user.isLoading,
  authError: state.auth.authError,
});

export const ConnectedAppLayout = connect(selectLayoutState, {
  getCurrentUser,
  fetchVideoCalls,
  getScheduledCalls,
  addNotification,
})(AppLayout);
