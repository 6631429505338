import ApiService from './apiService';

const CouponService = {
  getAll: async (): Promise<{ has_more: boolean; data: Coupon[] }> => {
    const response = await ApiService.get(`/coupons`);
    return response.data;
  },

  create: async (data: Coupon): Promise<Coupon> => {
    const response = await ApiService.post(`/coupons`, data);
    return response.data;
  },
  deletecoupon: async (id: number): Promise<Coupon> => {
    const response = await ApiService.delete(`/delete-coupon/${id}`);
    return response.data;
  }
};

export default CouponService;
