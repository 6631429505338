import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Button, Alert, Form } from 'antd';
import { Link } from 'react-router-dom';

import UserService from '@services/userService';

const ForgotPasswordForm: React.FC = () => {
  const [error, setError] = useState<{ message: string }>();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<{ message: string }>();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then((values: any) => {
      setLoading(true);
      UserService.forgotPassword(values.email)
        .then(res => {
          setResponse(res);
          setLoading(false);
        })
        .catch(err => {
          err.data &&
            err.data.message &&
            setError({
              message: err.data.message,
            });
          setLoading(false);
        });
    });
  };

  return (
    <div className="login">
      <section className="cover cover-page">
        <div className="cover-form-container">
          <div className="cover-form-wrap z-index">
            <section className="form-v1-container">
              <h2>Forgot password?</h2>
              <p className="lead">
                Enter your email below and request reset password link.
              </p>
              {error && (
                <Alert
                  message={error.message}
                  style={{ margin: '16px 0' }}
                  type="error"
                />
              )}
              {response && (
                <Alert
                  message={response.message}
                  style={{ margin: '16px 0' }}
                  type="success"
                />
              )}
              <Form onFinish={handleSubmit} className="form-v1" form={form}>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<UserOutlined style={{ fontSize: 13 }} />}
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-cta btn-block"
                    disabled={loading}
                    loading={loading}
                  >
                    Request password reset link
                  </Button>
                </Form.Item>
              </Form>
              <p className="additional-info">
                Don't have an account yet? <Link to="/signup">Sign up</Link>
              </p>
            </section>
          </div>
        </div>
        <div className="cover-bg-img" />
      </section>
    </div>
  );
};

export default ForgotPasswordForm;
