import React, { useState, useEffect } from 'react';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, message, Collapse, Checkbox, Upload, Modal } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { useHistory } from 'react-router';

import ApiService from '@services/apiService';
// import UserAgreement from '@screens/docs/UserAgreement';
// import TermsOfUse from '@screens/docs/TermsOfUse';
import { Role } from '@types';
import { start } from '@redux/reducers/videoCall';
// import ScheduleWidget from '@components/schedule/ScheduleWidget';

import { isUserOnboarded } from '@utils/helpers';
import Agreement from '@components/agreements/Agreement';
import { isUserInstructor, isUserAdmin, isUserStudent } from '@helpers';

const { Panel } = Collapse;

interface OnboardingProps {
  onboarding: Onboarding;
  user: User;
  startCall?: typeof start;
  studentId?: any;
}

const OnboardingComponent: React.FC<OnboardingProps> = ({
  user,
  onboarding,
  startCall,
  studentId,
}) => {
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [openModal, setOpenModal] = useState<string>();
  const [userOnboarding, setUserOnboarding] = useState(onboarding);

  useEffect(() => {
    if (onboarding.scheduledCallId !== userOnboarding.scheduledCallId) {
      setUserOnboarding(onboarding);
    }
  }, [onboarding, userOnboarding.scheduledCallId]);

  const saveUserOnboarding = async (data: Onboarding) => {
    await ApiService.put(`/users/${user.id}/onboarding`, data);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const onChangeCheckbox = (checked: boolean, key: string) => {
    setUserOnboarding({ ...userOnboarding, [key]: checked });
    saveUserOnboarding({ ...userOnboarding, [key]: checked });
  };

  const handleOk = (e: any) => {
    const key =
      openModal === 'userAgreement'
        ? 'signedStudentAgreement'
        : 'signedTermsOfUse';

    const on = { ...userOnboarding };
    on[key] = true;
    setUserOnboarding(on);
    saveUserOnboarding(on);
    setTimeout(() => setOpenModal(undefined), 100);
  };

  const handleCancel = (e: any) => {
    setOpenModal(undefined);
  };

  const uploadProps = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/users/current-user/images/1/${studentId}`,
    headers: ApiService.getHeaders(),
    onChange(info: UploadChangeParam<UploadFile>) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setUserOnboarding({
          ...userOnboarding,
          idImage1: info.file.response,
        });
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const upload2Props = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/users/current-user/images/2/${studentId}`,
    headers: ApiService.getHeaders(),
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setUserOnboarding({
          ...userOnboarding,
          idImage2: info.file.response,
        });
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const {
    confirmedSecondarySchoolCompletion,
    confirmedMyAddressIsOutside,
    confirmedAge,
    idImage1,
    idImage2,
    // signedStudentAgreement,
    // signedTermsOfUse,
    watchedVideo,
    welcomed,
  } = userOnboarding;

  const isInstructor = user.role !== Role.Student;

  return (
    <div>
      {!isInstructor && (
        <>
          <h2>Onboarding</h2>
          <h4>
            Let’s get you registered and on your way to earning a college
            degree! The registration process includes these 5 simple steps:
          </h4>
        </>
      )}
      <Collapse defaultActiveKey={['1']} onChange={next}>
        <Panel
          header={
            <span>
              {confirmedAge &&
              confirmedSecondarySchoolCompletion &&
              confirmedMyAddressIsOutside ? (
                <CheckCircleOutlined className="text-success" />
              ) : (
                <CloseCircleOutlined />
              )}{' '}
              Confirm that you are eligible to enroll.
            </span>
          }
          key="1"
        >
          <div style={{ textAlign: 'center' }}>
            <Checkbox
              onChange={e => onChangeCheckbox(e.target.checked, 'confirmedAge')}
              checked={confirmedAge}
              disabled={isInstructor || welcomed}
            >
              I verify that I am 18 years of age or older.
            </Checkbox>
            <br />
            <br />
            <br />
            <Checkbox
              onChange={e =>
                onChangeCheckbox(
                  e.target.checked,
                  'confirmedSecondarySchoolCompletion',
                )
              }
              checked={confirmedSecondarySchoolCompletion}
              disabled={isInstructor || welcomed}
            >
              I verify that I have completed Secondary School (high school, GED,
              or similar).
            </Checkbox>
            <br />
            <br />
            <br />
            <Checkbox
              onChange={e =>
                onChangeCheckbox(
                  e.target.checked,
                  'confirmedMyAddressIsOutside',
                )
              }
              checked={confirmedMyAddressIsOutside}
              disabled={isInstructor || welcomed}
            >
              I certify that English is my first language OR that I speak
              English at a secondary school level or higher.
            </Checkbox>
          </div>
        </Panel>
        <Panel
          header={
            <span>
              {idImage1 && idImage2 ? (
                <CheckCircleOutlined className="text-success" />
              ) : (
                <CloseCircleOutlined />
              )}{' '}
              Scan and upload 2 forms of government- issued ID to our secure
              site(at least one with a recent picture--like a driver's license
              or passport).
            </span>
          }
          key="2"
        >
          <h3 style={{ textAlign: 'center' }}>
            Scan and upload two forms of government-issued ID{' '}
            <small>
              (at least one with a recent picture) Note: these will be confirmed
              with the enrollment counselor.
            </small>
          </h3>
          {idImage1 && (
            <p>
              ID #1:{' '}
              <a href={idImage1.url} target="_blank" rel="noreferrer">
                View details
              </a>{' '}
            </p>
          )}
          {(isUserAdmin(user) || isUserStudent(user)) && (
            <Upload {...uploadProps}>
              <Button style={{ marginBottom: '16px' }}>
                <UploadOutlined /> Click to Upload ID #1
              </Button>
            </Upload>
          )}
          {idImage2 && (
            <p>
              ID #2:{' '}
              <a
                href={idImage2 && idImage2.url}
                target="_blank"
                rel="noreferrer"
              >
                View details
              </a>{' '}
            </p>
          )}
          {(isUserAdmin(user) || isUserStudent(user)) && (
            <Upload {...upload2Props}>
              <Button style={{ marginBottom: '16px' }}>
                <UploadOutlined /> Click to Upload ID #2
              </Button>
            </Upload>
          )}
        </Panel>
        {/* <Panel
          header={
            <span>
              {signedStudentAgreement && signedTermsOfUse ? (
                <Icon type="check-circle" className="text-success" />
              ) : (
                <Icon type="close-circle" />
              )}{' '}
              Sign the student agreement(s) and Terms of Use.
            </span>
          }
          key="3"
        >
          <div style={{ textAlign: 'center' }}>
            <Checkbox
              onChange={e =>
                onChangeCheckbox(e.target.checked, 'signedStudentAgreement')
              }
              checked={signedStudentAgreement}
              disabled={isInstructor || welcomed}
            >
              Sign the{' '}
              <a
                href="/app#/userAgreement"
                onClick={e => {
                  e.preventDefault();
                  setOpenModal('userAgreement');
                }}
              >
                Student Agreement
              </a>
            </Checkbox>
            <br />
            <br />
            <br />
            <Checkbox
              onChange={e =>
                onChangeCheckbox(e.target.checked, 'signedTermsOfUse')
              }
              checked={signedTermsOfUse}
              disabled={isInstructor || welcomed}
            >
              Sign the{' '}
              <a
                href="/app#/termsAndConditions"
                onClick={e => {
                  e.preventDefault();
                  setOpenModal('termsAndConditions');
                }}
              >
                Terms of Use
              </a>
            </Checkbox>
          </div>
        </Panel> */}
        <Panel
          key="4"
          header={
            <span>
              {watchedVideo ? (
                <CheckCircleOutlined className="text-success" />
              ) : (
                <CloseCircleOutlined />
              )}{' '}
              Watch a short(8 min) Newlane University orientation video.
            </span>
          }
        >
          <div style={{ textAlign: 'center' }}>
            {!isInstructor && (
              <>
                <iframe
                  title="Onboarding video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/mONwcZL0N4g"
                  frameBorder="0"
                  allowFullScreen
                />
                <br />
                <br />
                <br />
              </>
            )}

            <Checkbox
              onChange={e => onChangeCheckbox(e.target.checked, 'watchedVideo')}
              checked={watchedVideo}
              disabled={isInstructor || welcomed}
            >
              I have watched the video
            </Checkbox>
          </div>
        </Panel>
        {/* {!isInstructor && (
          <Panel
            key="5"
            header={
              <span>
                {welcomed ? (
                  <Icon type="check-circle" className="text-success" />
                ) : (
                  <Icon type="close-circle" />
                )}{' '}
                Schedule a meet in a video chat with an enrollment counselor.
              </span>
            }
          >
            <div style={{ textAlign: 'center' }}>
              <ScheduleWidget type="onboarding" />
            </div>
          </Panel>
        )} */}
      </Collapse>
      {!isUserInstructor(user) && (
        <div style={{ margin: '32px 0', textAlign: 'center' }}>
          <Button
            type="default"
            disabled={!isUserOnboarded(userOnboarding)}
            onClick={() => {
              history.push('/');
              window.location.reload();
            }}
          >
            Finish onboarding
          </Button>
        </div>
      )}
      <Modal
        visible={!!openModal}
        okText="Sign"
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
      >
        {openModal === 'userAgreement' ? (
          <Agreement type="userAgreement" />
        ) : (
          <Agreement type="termsOfUse" />
        )}
      </Modal>
    </div>
  );
};

export default OnboardingComponent;
