import React, { useState, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Modal,
  Button,
  Table,
  Input,
  Select,
  Card,
  Alert,
} from 'antd';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { AppState } from '@redux/reducers';
import CouponService from '@services/couponService';
import { ColumnProps } from 'antd/lib/table';

// const duration = 'forever' | 'once' | 'repeating';

const defaultCoupon: Coupon = {
  duration: 'once',
};

interface AdminProps {
  user?: User;
}

const Admin: React.FC<AdminProps> = ({ user }) => {
  const [newCoupon, setNewCoupon] = useState<Coupon>(defaultCoupon);
  const [coupons, setCoupons] = useState<Coupon[]>();
  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [permissionModalVisible1, setPermissionModalVisible1] = useState(false);
  const [ids, setIds] = useState<number | null>(null);

  const [error, setError] = useState<string>();

  const couponColumns: ColumnProps<any>[] = [
    { key: 'id', dataIndex: 'id', title: 'Code' },
    { key: 'duration', dataIndex: 'duration', title: 'Duration' },
    {
      key: 'durationInMonths',
      dataIndex: 'duration_in_months',
      title: 'Duration in months',
    },
    { key: 'percentOff', dataIndex: 'percent_off', title: 'percentOff' },
    {
      key: 'Amount Off',
      dataIndex: 'amount_off',
      title: 'Amount Off',
      render: (text, item) => text && `$${Number(text / 100).toFixed(2)}`,
    },
    {
      key: 'Max redemptions',
      dataIndex: 'max_redemptions',
      title: 'Max redemptions',
    },
    {
      key: 'Times redeemed',
      dataIndex: 'times_redeemed',
      title: 'Times redeemed',
    },
    {
      key: 'valid',
      dataIndex: 'valid',
      title: 'Valid',
      render: text => (text ? 'true' : 'false'),
    },
    {
      key: '',
      dataIndex: '',
      title: 'Action',
      render: (text, record) => (
        <div className="action-icons" style={{ fontSize: '20px' }}>
          <DeleteOutlined
            className="delete-icon"
            onClick={() => {
              setIds(record.id);
              setPermissionModalVisible1(true);
            }}
          />
        </div>
      ),
    },
  ];

  const handleDeleteCoupon = (id: any) => {
    CouponService.deletecoupon(ids!)
      .then(() => {
        setIds(null);
        setPermissionModalVisible1(false);
        toast.success('Coupan is Deleted successfully!');
        getCoupons();
      })
      .catch(err => {
        console.error('An error occurred while deleting the coupon:', err);
      });
  };

  const getCoupons = () => {
    setLoading(true);
    CouponService.getAll()
      .then(response => {
        setLoading(false);
        setCoupons(response.data);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };

  const createCoupon = () => {
    if (!newCoupon.amountOff && !newCoupon.percentOff) {
      setError('Please enter amount off or percent off');
      return;
    }
    setLoading(true);
    CouponService.create(newCoupon)
      .then(() => {
        setCouponModalVisible(false);
        setNewCoupon(defaultCoupon);
        setLoading(false);
        getCoupons();
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setError(err.message);
      });
  };

  useEffect(() => {
    if (!coupons && !loading) {
      getCoupons();
    }
  }, [coupons, loading]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Coupons"
          size="small"
          extra={
            <Button type="primary" onClick={() => setCouponModalVisible(true)}>
              Add coupon
            </Button>
          }
        >
          <Table dataSource={coupons} columns={couponColumns} />
        </Card>
      </Col>
      <Modal
        title="Create coupon"
        width={400}
        visible={couponModalVisible}
        okText="Create coupon"
        okButtonProps={{ loading }}
        onOk={() => createCoupon()}
        onCancel={() => setCouponModalVisible(false)}
      >
        {error && (
          <Alert message={error} type="error" style={{ marginBottom: 16 }} />
        )}
        <div>
          <p style={{ margin: '0 0 8px 0' }}>Coupon code:</p>
          <Input
            placeholder="Coupon code"
            value={newCoupon.id}
            onChange={(e: any) => {
              setNewCoupon({ ...newCoupon, id: e.target.value });
            }}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <p style={{ margin: '0 0 8px 0' }}>Coupon duration:</p>
          <Select
            placeholder="Coupon duration"
            value={newCoupon.duration}
            onSelect={(value: any) => {
              setNewCoupon({ ...newCoupon, duration: value });
            }}
          >
            <Select.Option key="once" value="once">
              Once
            </Select.Option>
            <Select.Option key="forever" value="forever">
              Forever
            </Select.Option>
            <Select.Option key="repeating" value="repeating">
              Repeating
            </Select.Option>
          </Select>
        </div>
        {newCoupon.duration === 'repeating' && (
          <div style={{ marginTop: 16 }}>
            <p style={{ margin: '0 0 8px 0' }}>Duration in months:</p>
            <Input
              placeholder="Duration in months"
              type="number"
              min={1}
              value={newCoupon.durationInMonths}
              onChange={(e: any) => {
                setNewCoupon({
                  ...newCoupon,
                  durationInMonths: e.target.value,
                });
              }}
            />
          </div>
        )}
        <div style={{ marginTop: 16 }}>
          <p style={{ margin: '0 0 8px 0' }}>Percent off:</p>
          <Input
            placeholder="Percent off"
            type="number"
            min={1}
            value={newCoupon.percentOff}
            onChange={(e: any) => {
              setNewCoupon({
                ...newCoupon,
                percentOff: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <p style={{ margin: '0 0 8px 0' }}>
            Amount off <small>(Enter amount in cents( $1 = 100 ))</small>:
          </p>
          <Input
            placeholder="Amount off"
            type="number"
            min={1}
            value={newCoupon.amountOff}
            onChange={(e: any) => {
              setNewCoupon({
                ...newCoupon,
                amountOff: e.target.value,
              });
            }}
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <p style={{ margin: '0 0 8px 0' }}>Max redemptions:</p>
          <Input
            placeholder="Max redemptions"
            type="number"
            min={1}
            value={newCoupon.maxRedemptions}
            onChange={(e: any) => {
              setNewCoupon({
                ...newCoupon,
                maxRedemptions: e.target.value,
              });
            }}
          />
        </div>
      </Modal>
      <Modal
        title="Are you sure you want to  Delete Coupan?"
        width={450}
        visible={permissionModalVisible1}
        okText=" Delete Coupan"
        okButtonProps={{ loading, danger: true }}
        onOk={handleDeleteCoupon}
        onCancel={() => setPermissionModalVisible1(false)}
      >
        <p>You will not be able to revert this action</p>
      </Modal>
    </Row>
  );
};

const selectAdminState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedAdmin = connect(selectAdminState, {})(Admin);

const Page = () => <ConnectedAdmin />;

export default Page;
