import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Button, Input, Row, Col } from 'antd';
import ReactQuill from 'react-quill';
// import CourseInfoService from '@services/userService';
import ApiService from '@services/apiService';
import { toast } from 'react-toastify';
import { isUserInstructor } from '@helpers';

interface CourseInfoProps {
  CourseInfo: CourseInfo;
  itemId: any;
  user?: User;
}
const InfoList: React.FC<any> = ({ CourseInfo, itemId, user }) => {
  const [visible, setVisible] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [formDataAdd, setFormDataAdd] = useState({
    title: '',
    text: '',
    courseId: itemId,
  });
  const [formDataEdit, setFormDataEdit] = useState({
    id: '',
    title: '',
    text: '',
  });
  const [infoListData, setInfoListData] = React.useState<any>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllInfo();
  }, []);

  const showModalAdd = () => {
    setVisible(true);
  };
  const addInfo = () => {
    if (!formDataAdd.title) {
      return;
    }
    if (!formDataAdd.text) {
      return;
    }
    setLoading(true);
    setConfirmLoading(true);
    ApiService.post(`/course-info`, formDataAdd)
      .then(response => {
        setTimeout(() => {
          setVisible(false);
          setLoading(false);
          setConfirmLoading(false);
          window.location.reload();
          // getAllInfo();
        }, 2000);
        setFormDataAdd({
          ...formDataAdd,
          title: '',
          text: '',
        });
        toast.success('Info add successfully!');
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setError(err.message);
      });
  };

  const handleCancelAdd = () => {
    setFormDataAdd({
      ...formDataAdd,
      title: '',
      text: '',
    });
    setVisible(false);
  };

  const showModalEdit = (info: any) => {
    setFormDataEdit({
      ...formDataEdit,
      id: info.id,
      title: info.title,
      text: info.text,
    });
    setVisibleEdit(true);
  };
  const handleCancelEdit = () => {
    setFormDataEdit({
      ...formDataEdit,
      id: '',
      title: '',
      text: '',
    });
    setVisibleEdit(false);
  };
  const editInfo = () => {
    if (!formDataEdit.title) {
      return;
    }
    if (!formDataEdit.text) {
      return;
    }
    const sendData = {
      title: formDataEdit.title,
      text: formDataEdit.text,
    };
    setLoading(true);
    setConfirmLoading(true);
    ApiService.put(`/course-info/${formDataEdit.id}`, sendData)
      .then(response => {
        setTimeout(() => {
          setVisibleEdit(false);
          setLoading(false);
          // getAllInfo();
          setConfirmLoading(false);
          window.location.reload();
        }, 2000);
        setFormDataEdit({
          ...formDataEdit,
          id: '',
          title: '',
          text: '',
        });
        toast.success('Info update successfully!');
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setError(err.message);
      });
  };

  const deleteInfo = (info: any) => {
    ApiService.delete(`/course-info/${info.id}`)
      .then(response => {
        toast.success('Info deleted successfully!');
        getAllInfo();
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setError(err.message);
      });
  };
  const getAllInfo = () => {
    ApiService.get(`/course-info/${itemId}`)
      .then(response => {
        setInfoListData(response.data);
      })
      .catch(err => {
        console.error(err);
      });
  };
  return (
    <>
      {isUserInstructor(user) && (
        <div style={{ textAlign: 'right' }}>
          <Button
            size="small"
            type="ghost"
            icon={<PlusOutlined />}
            onClick={showModalAdd}
          >
            New info
          </Button>
        </div>
      )}
      <Modal
        title="New info"
        visible={visible}
        okText="Save"
        onOk={addInfo}
        confirmLoading={confirmLoading}
        onCancel={handleCancelAdd}
      >
        <p>
          <div style={{ marginBottom: '8px' }}>Title</div>
          <Input
            onChange={e =>
              setFormDataAdd({ ...formDataAdd, title: e.target.value })
            }
            value={formDataAdd.title}
            type="text"
            name="title"
            placeholder="Title"
          />
        </p>
        <p>
          <div style={{ marginBottom: '8px' }}>Text</div>
          <ReactQuill
            onChange={value => setFormDataAdd({ ...formDataAdd, text: value })}
            value={formDataAdd.text}
            placeholder="Text"
          />
        </p>
      </Modal>
      <Modal
        title="Edit info"
        visible={visibleEdit}
        okText="Save"
        onOk={editInfo}
        confirmLoading={confirmLoading}
        onCancel={handleCancelEdit}
      >
        <p>
          <div style={{ marginBottom: '8px' }}>Title</div>
          <Input
            onChange={e =>
              setFormDataEdit({ ...formDataEdit, title: e.target.value })
            }
            value={formDataEdit.title}
            type="text"
            name="title"
            placeholder="Title"
          />
        </p>
        <p>
          <div style={{ marginBottom: '8px' }}>Text</div>
          <ReactQuill
            onChange={value =>
              setFormDataEdit({ ...formDataEdit, text: value })
            }
            value={formDataEdit.text}
            placeholder="Text"
          />
        </p>
      </Modal>
      <div style={{ marginBottom: '15px', marginTop: '20px' }}>
        {infoListData &&
          infoListData.map((info: any) => (
            <div style={{ marginBottom: '10px', marginTop: '10px' }}>
              <Row>
                <Col span={21}>
                  <div>
                    {' '}
                    <h3>{info.title}</h3>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: info.text,
                    }}
                  />
                </Col>
                <Col span={3} style={{ textAlign: 'end' }}>
                  {isUserInstructor(user) && (
                    <span>
                      <Button
                        size="small"
                        type="ghost"
                        onClick={() => showModalEdit(info)}
                      >
                        Edit
                      </Button>
                      <Button
                        style={{ marginLeft: '8px' }}
                        size="small"
                        danger
                        ghost
                        onClick={() => deleteInfo(info)}
                      >
                        Delete
                      </Button>
                    </span>
                  )}
                </Col>
              </Row>
            </div>
          ))}
        {/* <div style={{ marginBottom: '15px' }}>
        test 11{' '}
        <Button size="small" type="ghost" onClick={showModalEdit}>
          Edit
        </Button>
      </div>
      <div style={{ marginBottom: '15px' }}>
        test 22{' '}
        <Button size="small" type="ghost" onClick={showModalEdit}>
          Edit
        </Button>
      </div>
      <div style={{ marginBottom: '15px' }}>
        test 333{' '}
        <Button size="small" type="ghost" onClick={showModalEdit}>
          Edit
        </Button>
      </div> */}
      </div>
    </>
  );
};

export default InfoList;
