import React, { useState } from 'react';
import { Row, Col, Spin, Card, Tag } from 'antd';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import LessonCarousel from '@components/lessonCarousel/LessonCarousel';
import { Link, useHistory } from 'react-router-dom';
import { isUserSubscribed } from '@helpers';
import withCourse from '@hoc/withCourse';

interface CourseLessonsProps {
  course: Course;
  user?: User;
}

const CourseLessons: React.FC<CourseLessonsProps> = ({ user, course }) => {
  const history = useHistory();

  if (!user) {
    return null;
  }

  if (!isUserSubscribed(user)) {
    history.goBack();
  }

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={12}>
            <Tag color="70c040">Course</Tag>
            <Link to={`/courses/${course.id}`}>
              <h1>{course.name}</h1>
            </Link>
          </Col>
        </Row>
        <Card>
          {course.lessons && (
            <div>
              <LessonCarousel lessons={course.lessons} />
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

const selectCourseLessonsState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedCourseLessons = withCourse(
  connect(selectCourseLessonsState)(CourseLessons),
);

const Page = () => <ConnectedCourseLessons />;

export default Page;
