import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Card,
  Input,
  Button,
  Upload,
  message,
  Select,
  Form,
} from 'antd';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import {
  isUserInstructor,
  isUserSubscribed,
  isUserSubscriptionCanceled,
} from '@helpers';
import { toast } from 'react-toastify';
// import { StripeProvider, Elements } from 'react-stripe-elements';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getBillingInfo, getCurrentUser } from '@redux/reducers/user';
import ApiService from '@services/apiService';
import moment from 'moment';
import UserService from '@services/userService';
import { SubscriptionStatus } from '@components/subscription';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import CreditCardForm from './CreditCardForm';

interface EditProfileProps {
  user?: User;
  getBillingInfo: typeof getBillingInfo;
  billingInfo: any;
  getCurrentUser: typeof getCurrentUser;
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const Avatar: React.FC<{
  user?: User;
  getCurrentUser: typeof getCurrentUser;
}> = ({ getCurrentUser, user }) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      getCurrentUser();
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={`${ApiService.getUrl('/avatar')}`}
      headers={ApiService.getHeaders()}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {user && user.avatar ? (
        <img src={user.avatar.url} alt="avatar" style={{ width: '100%' }} />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

const EditProfile: React.FC<EditProfileProps> = ({
  user,
  getBillingInfo,
  billingInfo,
  getCurrentUser,
}) => {
  const [showCardForm, setShowCardForm] = useState(false);
  const [billingDayFormVisible, setBillingDayFormVisible] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [form] = Form.useForm();

  let subscription;

  if (billingInfo) {
    subscription = billingInfo.customer.subscriptions.data[0];
  }

  const [billingDay, setBillingDay] = useState(
    subscription
      ? Number(moment.unix(subscription.billing_cycle_anchor).format('D'))
      : Number(moment().format('D')),
  );

  const daysOfMonth = Array.from(Array(30), (_, i) => i + 1);

  useEffect(() => {
    if (!billingInfo) {
      getBillingInfo();
    }
  }, [billingInfo, getBillingInfo]);

  if (!user) {
    return null;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields().then(values => {
      ApiService.put(`/users/${user.id}`, values)
        .then(() => {
          toast.success('Profile saved!');
          getCurrentUser();
        })
        .catch(err => {
          toast.error('Error happened saving your profile.');
        });
    });
  };

  const saveBillingDay = () => {
    setUpdating(true);
    ApiService.put('/users/current-user/subscription', {
      billingDay,
    })
      .then(() => {
        getBillingInfo();
        setUpdating(false);
        setBillingDayFormVisible(false);
      })
      .catch(() => {
        setUpdating(false);
        toast.error('Error happened. Please try again.');
      });
  };

  const deleteCard = async (cardId: string) => {
    await UserService.deleteCard(cardId);
    getBillingInfo();
    toast.success('Card deleted!');
  };

  const cancelSubscription = async () => {
    await UserService.cancelSubscription();
    toast.success('Subscription cancelled!');
    getBillingInfo();
    getCurrentUser();
  };

  const resumeSubscription = async () => {
    await UserService.resumeSubscription();
    toast.success('Subscription resumed!');
    getBillingInfo();
    getCurrentUser();
  };

  return (
    <Row gutter={[16, { xs: 16, sm: 16, md: 0 }]}>
      <Col span={24}>
        <h1 style={{ marginBottom: 0 }}>Edit profile</h1>
      </Col>
      <div className="clearfix" />
      <Col md={12}>
        <Card size="small" title="General information">
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item label="Avatar">
              <Avatar getCurrentUser={getCurrentUser} user={user} />
            </Form.Item>

            {!isUserInstructor(user) && (
              <p>
                <b>Student ID:</b> {user.studentId}
              </p>
            )}

            <Form.Item name="email" label="Email" initialValue={user.email}>
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="firstName"
              label="First name"
              initialValue={user.firstName}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last name"
              initialValue={user.lastName}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!form.isFieldsTouched()}
                onClick={e => handleSubmit(e)}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      {!isUserInstructor(user) ? (
        <Col md={12}>
          <Card size="small" title="Subscription details">
            <SubscriptionStatus user={user} subscription={subscription} />

            <div style={{ marginBottom: '16px' }}>
              {/* {isUserSubscribed(user) &&
              !(subscription && subscription.cancel_at_period_end) ? (
                <Popconfirm
                  title="Are you sure to cancel your subscription?"
                  onConfirm={cancelSubscription}
                  okText="Pause subscription"
                  cancelText="No"
                >
                  <Button type="danger" size="small">
                    Pause subscription
                  </Button>
                </Popconfirm>
              ) : null} */}
              {isUserSubscribed(user) &&
              !(subscription && subscription.cancel_at_period_end) ? (
                <span />
              ) : null}
              {isUserSubscriptionCanceled(user) ||
              (subscription && subscription.cancel_at_period_end) ? (
                <Button type="primary" onClick={resumeSubscription}>
                  Resume subscription
                </Button>
              ) : null}
            </div>

            {subscription && (
              <p>
                You are charged on{' '}
                {moment.unix(subscription.billing_cycle_anchor).format('Do')} of
                each month.
                <Button
                  type="link"
                  onClick={() => setBillingDayFormVisible(true)}
                >
                  Change
                </Button>
              </p>
            )}

            {subscription && billingDayFormVisible ? (
              <div style={{ marginBottom: 12 }}>
                <p>Day of month</p>
                <Select<number>
                  onChange={value => {
                    setBillingDay(value);
                  }}
                  defaultValue={Number(
                    moment.unix(subscription.billing_cycle_anchor).format('D'),
                  )}
                  style={{ width: '100px' }}
                >
                  {daysOfMonth.map(value => (
                    <Select.Option value={value}>{value}</Select.Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  style={{ marginLeft: 12 }}
                  onClick={() => saveBillingDay()}
                  loading={updating}
                >
                  Save
                </Button>
              </div>
            ) : null}

            <h3>Billing info</h3>
            <p style={{ margin: '4px 0' }}>Billing name: {user.billingName}</p>
            <p style={{ margin: '4px 0' }}>Address: {user.address}</p>
            <p style={{ margin: '4px 0' }}>Address2: {user.address2}</p>
            <p style={{ margin: '4px 0' }}>City: {user.city}</p>
            <p style={{ margin: '4px 0' }}>State: {user.state}</p>
            <p style={{ margin: '4px 0' }}>Zip: {user.zip}</p>
            <p style={{ margin: '4px 0' }}>Country: {user.country}</p>

            <h3 style={{ marginTop: 16 }}>
              Credit cards{' '}
              <Button
                size="small"
                type="primary"
                onClick={() => setShowCardForm(true)}
              >
                Add new card
              </Button>
            </h3>

            {!billingInfo && <Spin />}

            {billingInfo && billingInfo.cards.length === 0 ? (
              <p>No cards added yet.</p>
            ) : null}

            {billingInfo &&
              billingInfo.cards.map((card: any) => (
                <p>
                  **** **** **** {card.card.last4}{' '}
                  <Button
                    size="small"
                    danger
                    onClick={() => deleteCard(card.id)}
                  >
                    Remove
                  </Button>
                </p>
              ))}

            <div>{showCardForm && <CreditCardForm />}</div>

            <h3>Invoices</h3>

            <table style={{ width: '100%' }}>
              <tr style={{ borderBottom: '1px solid #333' }}>
                <td>Invoice #</td>
                <td>Amount</td>
                <td>Status</td>
              </tr>
              {billingInfo &&
                billingInfo.invoices.map((invoice: any) => (
                  <tr style={{ borderBottom: '1px dashed #333' }}>
                    <td>
                      <a
                        href={invoice.hosted_invoice_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {invoice.number}
                      </a>
                    </td>
                    <td>${invoice.amount_due / 100}</td>
                    <td>
                      {invoice.paid ? (
                        <span className="text-success">{invoice.status}</span>
                      ) : (
                        <span>{invoice.status}</span>
                      )}
                    </td>
                  </tr>
                ))}
            </table>
          </Card>
        </Col>
      ) : null}
    </Row>
  );
};

const selectEditProfileState = (state: AppState) => ({
  user: state.user.user,
  billingInfo: state.user.billingInfo,
});

const ConnectedEditProfile = connect(selectEditProfileState, {
  getBillingInfo,
  getCurrentUser,
})(EditProfile);

const Page = () => <ConnectedEditProfile />;

export default Page;
