import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import withMediaReview from '@hoc/withMediaReview';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Row, Col, PageHeader, Modal, Button, Table } from 'antd';
import UserService from '@services/userService';
import { DATETIME_FORMAT } from '@components/schedule/constants';
import { toast } from 'react-toastify';

interface MediaFeedbackPageProps {
  mediaFeedback: User[];
}

const ObjectiveMediaReview: React.FC<MediaFeedbackPageProps> = ({
  mediaFeedback,
}) => {
  const [loading, setLoading] = useState(false);
  const [deletedFeedback, setDeletedFeedback] = useState<any>();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const columns: ColumnProps<any>[] = [
    {
      title: 'Student',
      dataIndex: 'userName',
      key: 'userName',
      render: (userName: any, record: User) => userName,
    },
    {
      title: 'Course name',
      dataIndex: 'courseName',
      key: 'courseName',
      render: (courseName: any, record: any) => courseName,
    },
    {
      title: 'Objective name',
      dataIndex: 'objectiveName',
      key: 'objectiveName',
      // render: (objectiveName: any, record: User) => objectiveName,
      render: (objectiveName: any, record: any) => (
        <Link
          to={`/objectives/${record.objectiveId}`}
        >{`${record.objectiveName}`}</Link>
      ),
    },
    {
      title: 'Media name',
      dataIndex: 'mediaDescription',
      key: 'mediaDescription',
      render: (mediaDescription: any, record: User) => mediaDescription,
    },
    {
      title: 'Comment',
      dataIndex: 'description',
      key: 'description',
      render: (description: any, record: User) => description,
    },
    {
      title: 'Media is missing or not working',
      dataIndex: 'mediaNotWorking',
      key: 'mediaNotWorking',
      render: (mediaNotWorking: any, record: User) =>
        mediaNotWorking === true ? (
          <CheckCircleOutlined style={{ fontSize: 20, color: '#52c41a' }} />
        ) : (
          ''
        ),
      sorter: (a: any, b: any) => a.mediaNotWorking - b.mediaNotWorking,
    },
    {
      title: 'Media is incorrect',
      dataIndex: 'mediaIncorrect',
      key: 'mediaIncorrect',
      render: (mediaIncorrect: any, record: User) =>
        mediaIncorrect === true ? (
          <CheckCircleOutlined style={{ fontSize: 20, color: '#52c41a' }} />
        ) : (
          ''
        ),
      sorter: (a: any, b: any) => a.mediaIncorrect - b.mediaIncorrect,
    },
    {
      title: 'Media is offensive',
      dataIndex: 'mediaOffensive',
      key: 'mediaOffensive',
      render: (mediaOffensive: any, record: User) =>
        mediaOffensive === true ? (
          <CheckCircleOutlined style={{ fontSize: 20, color: '#52c41a' }} />
        ) : (
          ''
        ),
      sorter: (a: any, b: any) => a.mediaOffensive - b.mediaOffensive,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any, record: any) =>
        moment(createdAt).format(DATETIME_FORMAT),
      width: '160px',
    },
    {
      key: 'actions',
      render: (id: any, record: User) => (
        <div>
          <Button
            danger
            style={{ marginLeft: 8 }}
            onClick={() => {
              setDeletedFeedback(id);
              setDeleteModalVisible(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  const deleteFeedback = () => {
    if (!deletedFeedback) {
      return false;
    }
    setLoading(true);
    UserService.deleteMediaFeedback(deletedFeedback.id)
      .then(() => {
        setDeleteModalVisible(false);
        setDeletedFeedback(undefined);
        setLoading(false);
        // getUsers(pagination, sorter, searchTerm);
        toast.success('Media feedback deleted successfully!');

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };
  return (
    <Row gutter={16}>
      <Col span={24}>
        <PageHeader
          style={{
            background: '#fff',
            padding: '16px 16px 0',
          }}
          title={
            <>
              <h2 style={{ margin: '8px 0' }}>Objective Media Review</h2>
            </>
          }
        />
        {/* <div style={{ padding: 16, background: '#fff' }}>
          <Search
            placeholder="Search users by email, first name and last name"
            onSearch={handleSearch}
            loading={loading}
          />
        </div> */}
        <div
          style={{
            background: '#fff',
            padding: '16px 16px 0',
          }}
        >
          {/* <MediaFeedbackTable mediaFeedback={data} /> */}
          <div>
            <Table
              className="ant-table-responsive"
              dataSource={mediaFeedback}
              columns={columns}
            />
            {deletedFeedback && (
              <Modal
                title="Are you sure you want to delete media feedback?"
                width={550}
                visible={deleteModalVisible}
                okText="Delete"
                okButtonProps={{ loading, danger: true }}
                onOk={() => deleteFeedback()}
                onCancel={() => setDeleteModalVisible(false)}
              >
                <p>
                  <b>{`${deletedFeedback.mediaDescription}`}.</b>
                </p>
                <p>You will not be able to revert this action</p>
              </Modal>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

const selectMediaReviewPageState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedMediaReviewPage = withMediaReview(
  connect(selectMediaReviewPageState)(ObjectiveMediaReview),
);

const Page = () => <ConnectedMediaReviewPage />;

export default Page;
