import React from 'react';
import { Link } from 'react-router-dom';
import { isUserSubscribed } from '../../helpers';

interface SubscribeMessageProps {
  user: User;
}

const SubscribeMessage: React.FC<SubscribeMessageProps> = ({ user }) => {
  const isNotSubscribed = !isUserSubscribed(user);

  if (isNotSubscribed) {
    return (
      <>
        <h4>
          To access all features please register <br />
          <Link to="/subscribe">Register here</Link>
        </h4>
      </>
    );
  }

  return null;
};

export default SubscribeMessage;
