import React, { useState } from 'react';
import { Modal, Input, Select, Button } from 'antd';
import ApiService from '@services/apiService';
import { CreateChatPayload } from '@redux/reducers/chat';
import { isUserInstructor } from '@helpers';

interface NewChatModalProps {
  send: (chat: CreateChatPayload) => void;
  onClose: () => void;
  chatUserId: any;
}

const NewChatModal: React.FC<NewChatModalProps> = ({
  send,
  onClose,
  chatUserId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [teachers, setTeachers] = useState<User[]>();
  const [chat, setChat] = useState<Partial<CreateChatPayload>>({});

  const fetchTeachers = async () => {
    setIsLoading(true);
    let response = (await ApiService.get(`/teachers`)) as any;

    // if (isUserInstructor(user)) {
    //   response = (await ApiService.get(`/students`)) as any;
    // }
    const json = response.data;
    setTeachers(json);
    setIsLoading(false);
    setChat({ userId: chatUserId != undefined ? chatUserId : '' });
  };

  const sendMessage = () => {
    if (chat.userId && chat.text && chat.text.length > 1) {
      send({ userId: chat.userId, text: chat.text });
    }
  };

  if (!teachers && !isLoading) {
    console.log('chatUserId', chatUserId);
    fetchTeachers();
  }

  return (
    <Modal
      visible={true}
      footer={false}
      destroyOnClose={true}
      title="New chat"
      onCancel={onClose}
    >
      {isLoading && <div />}
      {!isLoading && (
        <div>
          <Select
            placeholder="Select user"
            style={{ width: '300px' }}
            onSelect={(value: any) =>
              setChat({ ...chat, userId: Number(value) })
            }
            defaultValue={chatUserId != undefined ? Number(chatUserId) : ''}
          >
            {teachers &&
              teachers.map(user => (
                <Select.Option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </Select.Option>
              ))}
          </Select>
          <br />
          <br />
          <Input.TextArea
            onChange={e => setChat({ ...chat, text: e.target.value })}
          />
          <br />
          <br />
          <Button type="primary" onClick={sendMessage}>
            Send message
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default NewChatModal;
