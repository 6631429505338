import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { DeepPartial } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootReducer, { AppState } from './reducers';
import rootSaga from './sagas';

export default function configureAppStore(
  preloadedState: DeepPartial<AppState>,
): EnhancedStore {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore<AppState>({
    middleware: [sagaMiddleware],
    // preloadedState,
    reducer: rootReducer,
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
