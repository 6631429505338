import React, { Component } from 'react';

declare global {
  interface Window {
    embedly: any;
  }
}

// one-time init of the embedly platform.js, hidden behind
// typeof check not to blow up on server-side render
if (typeof window !== 'undefined') {
  let init_embedly = function (w: any, d: any) {
    var id = 'embedly-platform',
      n = 'script';
    if (!d.getElementById(id)) {
      w.embedly =
        w.embedly ||
        function () {
          (w.embedly.q = w.embedly.q || []).push(arguments);
        };
      var e = d.createElement(n);
      e.id = id;
      e.async = 1;
      e.src =
        ('https:' === document.location.protocol ? 'https' : 'http') +
        '://cdn.embedly.com/widgets/platform.js';
      var s = d.getElementsByTagName(n)[0];
      s.parentNode.insertBefore(e, s);
    }
  };
  init_embedly(window, document);
}

export interface IAppProps {
  data: {
    url: string;
    description: string;
  };
}

class Embed extends Component<IAppProps> {
  constructor(props: any) {
    super(props);
    this.embed = this.embed.bind(this);
  }
  embed(el: any) {
    // invoke embedly from the ref callback, which
    // isn't called back on server-side render so
    // we're all good.
    if (el && window.embedly) {
      window.embedly('card', el);
    }
  }
  render() {
    const {
      data: { url, description },
    } = this.props as {
      data: {
        url: string;
        description: string;
      };
    };
    return (
      <div>
        <blockquote
          data-card-key="a49200781e12407d87df4bcf3a593fe8"
          data-card-controls="0"
          data-card-recommend="0"
          ref={this.embed}
        >
          <h4>
            <a href={url}>{description}</a>
          </h4>
        </blockquote>
      </div>
    );
  }
}

export default Embed;
