import React, { useState, Fragment } from 'react';
import { Row, Col, Card, Table } from 'antd';
import './Home.less';
import { joinVideoCall } from '@redux/reducers/videoCall';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ScheduleCalendar from '@components/schedule/ScheduleCalendar';
import ScheduleDayAgenda from '@components/schedule/ScheduleDayAgenda';
import { DATE_FORMAT } from '@components/schedule/constants';
import { getDayCalls } from '@components/schedule/helpers';
import { getScheduledCalls } from '@redux/reducers/user';
// import { acTrackEvent } from '@services/ActiveCampaign';

const degreeColumns: ColumnProps<Degree>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    render: (value, record) => (
      <Link to={`/degrees/${record.id}`}>{record.name}</Link>
    ),
  },
  // {
  //   title: 'Created at',
  //   dataIndex: 'createdAt',
  //   render: value => moment(value).format('LLL'),
  // },
];

const courseColumns: ColumnProps<Course>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    render: (value, record) => (
      <Link to={`/courses/${record.id}`}>{record.name}</Link>
    ),
  },
  // {
  //   title: 'Created at',
  //   dataIndex: 'createdAt',
  //   render: value => moment(value).format('LLL'),
  // },
];

const lessonColumns: ColumnProps<Lesson>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    render: (value, record) => (
      <Link to={`/lessons/${record.id}`}>{record.name}</Link>
    ),
  },
  // {
  //   title: 'Created at',
  //   dataIndex: 'createdAt',
  //   render: value => moment(value).format('LLL'),
  // },
];

const objectiveColumns: ColumnProps<Objective>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    render: (value, record) => (
      <Link to={`/objectives/${record.id}`}>{record.name}</Link>
    ),
  },
  // {
  //   title: 'Created at',
  //   dataIndex: 'createdAt',
  //   render: value => moment(value).format('LLL'),
  // },
];

interface InstructorHomeProps {
  videoCalls: VideoCall[];
  user?: User;
  scheduledCalls: ScheduledCall[];
  getScheduledCalls: typeof getScheduledCalls;
  joinVideoCall: typeof joinVideoCall;
}

const InstructorHome: React.FC<InstructorHomeProps> = ({
  user,
  videoCalls,
  getScheduledCalls,
  scheduledCalls,
  joinVideoCall,
}) => {
  const [selectedDay, setSelectedDay] = useState(moment());

  const loadCalls = () => {
    getScheduledCalls();
  };

  const onAcceptCall = (call: VideoCall) => {
    joinVideoCall(call.id);
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={16}>
          {/* <Card size="small" title="Pending video calls">
            {!videoCalls.length && <p>No video calls pending.</p>}
            {videoCalls.map(call => (
              <div>
                <p>
                  {call.student.firstName} {call.student.lastName}(
                  {call.student.firstName})
                </p>
                {call.course && <p>Course Hearing: {call.course.name}</p>}
                {call.degree && <p>Degree Hearing: {call.degree.name}</p>}
                {call.type === VideoCallType.OnboardingCall && (
                  <p>Onboarding call</p>
                )}
                <div>
                  <Button
                    type="primary"
                    onClick={() => {
                      onAcceptCall(call);
                    }}
                  >
                    Accept call
                  </Button>
                </div>
                <Divider />
              </div>
            ))}
          </Card> */}
          <Card title="Calendar" size="small">
            <ScheduleCalendar
              calls={scheduledCalls}
              fullscreen
              onChangeDay={(day: moment.Moment) => setSelectedDay(day)}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card
            title={`Agenda for ${selectedDay.format(DATE_FORMAT)}`}
            size="small"
          >
            <ScheduleDayAgenda
              user={user}
              calls={getDayCalls(selectedDay, scheduledCalls)}
              loadCalls={loadCalls}
              joinVideoCall={joinVideoCall}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 15 }}>
        <Col md={12}>
          <Card size="small" title="Instructor degrees">
            <Table
              dataSource={user.instructorDegrees || []}
              columns={degreeColumns}
            />
          </Card>
        </Col>
        <Col md={12}>
          <Card size="small" title="Instructor courses">
            <Table
              dataSource={user.instructorCourses || []}
              columns={courseColumns}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 15 }}>
        <Col md={12}>
          <Card size="small" title="Created degrees">
            <Table
              dataSource={user.createdDegrees || []}
              columns={degreeColumns}
            />
          </Card>
        </Col>
        <Col md={12}>
          <Card size="small" title="Created courses">
            <Table
              dataSource={user.createdCourses || []}
              columns={courseColumns}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 15 }}>
        <Col md={12}>
          <Card size="small" title="Created lessons">
            <Table
              dataSource={user.createdLessons || []}
              columns={lessonColumns}
            />
          </Card>
        </Col>
        <Col md={12}>
          <Card size="small" title="Created objectives">
            <Table
              dataSource={user.createdObjectives || []}
              columns={objectiveColumns}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InstructorHome;
