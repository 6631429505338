import * as React from 'react';
import { NotificationOutlined } from '@ant-design/icons';
import { Dropdown, Badge } from 'antd';
import { connect } from 'react-redux';
import {
  fetchNotifications,
  updateNotification,
} from '@redux/reducers/notifications';
import { AppState } from '@redux/reducers';

import NotificationList from './NotificationList';

export interface INotificationsDropdownProps {
  notifications?: ApiNotification[];
  fetchNotifications: typeof fetchNotifications;
  newNotifications: boolean;
  isLoading: boolean;
  updateNotification: typeof updateNotification;
}
const NotificationsDropdown = ({
  notifications,
  fetchNotifications,
  newNotifications,
  isLoading,
  updateNotification,
}: INotificationsDropdownProps) => {
  const [notReadNotification, setNotReadNotification] = React.useState<any>(0);
  React.useEffect(() => {
    if (!notifications && !isLoading) {
      fetchNotifications();
    }
    if (notifications && notifications.length > 0) {
      const notRead = notifications.filter((n: any) => n.read == false);
      setNotReadNotification(notRead.length);
    }
  });
  return (
    <Dropdown
      overlay={
        <NotificationList
          notifications={notifications}
          updateNotification={updateNotification}
          isLoading={isLoading}
        />
      }
    >
      <a
        className="ant-dropdown-link"
        style={{ marginRight: '20px' }}
        onClick={e => e.preventDefault()}
      >
        {/* <Badge dot> */}
        {/* <Icon type="notification" /> */}
        {/* </Badge> */}

        {/*{newNotifications ? (
          <Badge dot>
            <Icon type="notification" />
          </Badge>
        ) : (
          <Icon type="notification" />
        )}*/}

        {/*{newNotifications ? (
          <>
            <Icon type="notification" style={{ fontSize: '21px' }} />
            <span
              style={{
                fontSize: '12px',
                position: 'relative',
                right: '20px',
                top: '-5px',
              }}
            >
              2
            </span>
          </>
        ) : (
          <Icon type="notification" />
        )}*/}

        {newNotifications ? (
          <Badge
            count={notReadNotification}
            style={{
              backgroundColor: '#52c41a',
              top: '-3px',
              height: '20px',
              width: '22px',
              minWidth: '22px',
              borderRadius: '10px',
              lineHeight: '20px',
              fontSize: '12px',
              padding: '0px 0px',
              right: '-4px',
            }}
            overflowCount={9}
          >
            <NotificationOutlined style={{ fontSize: '21px' }} />
          </Badge>
        ) : (
          <NotificationOutlined style={{ fontSize: '21px' }} />
        )}
      </a>
    </Dropdown>
  );
};

const selectNotificationsState = (state: AppState) => {
  return {
    user: state.user.user,
    notifications: state.notifications.notifications,
    isLoading: state.notifications.isLoading,
    newNotifications: state.notifications.newNotifications,
  };
};

export default connect(selectNotificationsState, {
  fetchNotifications,
  updateNotification,
})(NotificationsDropdown);
