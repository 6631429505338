import React, { ReactNode, useState, useEffect } from 'react';
// import YouTubeEmbed from 'react-youtube-embed';
import {
  Button,
  Tag,
  PageHeader,
  Divider,
  Popover,
  Modal,
  Input,
  Checkbox,
} from 'antd';
import { getFileType } from '@utils/helpers';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
// @ts-ignore
// import GoogleDocsViewer from 'react-google-docs-viewer';
import { getFileUrl } from '@helpers';
import objectiveIcon from '@assets/512.png';
import { markCompleted, objectiveMedia } from '@redux/reducers/user';
import Embedly from './Embedly';

interface FilePreviewProps {
  file: ApiFile & { time?: string };
  objectiveDetails?: any;
  objectiveMedia: typeof objectiveMedia;
}

const FilePreview: React.FC<FilePreviewProps> = ({
  file,
  objectiveDetails,
  objectiveMedia,
}) => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState('');
  const [formData, setFormData] = useState({
    description: '',
    mediaNotWorking: false,
    mediaIncorrect: false,
    mediaOffensive: false,
    // objectiveId: objectiveDetails.id,
    // objectiveName: objectiveDetails.name,
    objectiveId: objectiveDetails ? objectiveDetails.id : null,
    objectiveName: objectiveDetails ? objectiveDetails.name : null,
    courseId: objectiveDetails ? objectiveDetails.courseId : null,
    // courseName: objectiveDetails ? objectiveDetails.name : null,
    mediaId: '',
    mediaDescription: '',
  });
  const [description, setTitle] = React.useState('');
  const [mediaNotWorking, setMediaNotWorking] = React.useState(false);
  const [mediaIncorrect, setMediaIncorrect] = React.useState(false);
  const [mediaOffensive, setMediaOffensive] = React.useState(false);
  const [mediaId, setMediaId] = React.useState('');
  const [mediaDescription, setMediaDescription] = React.useState('');
  const [massage, setMassage] = React.useState(false);

  const [modalTitle, setModalTitle] = React.useState(
    objectiveDetails ? objectiveDetails.name : null,
  );

  const [mediaTitle, setMediaTitle] = React.useState('');
  const url = getFileUrl(file.url);
  const fileType = getFileType(url);
  const showModal = (mediaData: any) => {
    setMediaTitle(mediaData.description);
    setFormData({
      ...formData,
      mediaId: mediaData.id,
      mediaDescription: mediaData.description,
    });
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    if (formData.description != '') {
      objectiveMedia(formData);
      setTimeout(() => {
        setMassage(true);
        setConfirmLoading(false);
        handleCancel1();
      }, 2000);
    } else {
    }
  };

  const handleCancel1 = () => {
    setTimeout(() => {
      setMassage(false);
      setVisible(false);
    }, 2000);
    // setMediaNotWorking(false);
    setFormData({
      ...formData,
      description: '',
      mediaNotWorking: false,
      mediaIncorrect: false,
      mediaOffensive: false,
      mediaDescription: '',
      mediaId: '',
    });
  };
  const handleCancel = () => {
    setMassage(false);
    setVisible(false);
    setFormData({
      ...formData,
      description: '',
      mediaNotWorking: false,
      mediaIncorrect: false,
      mediaOffensive: false,
      mediaDescription: '',
      mediaId: '',
    });
  };
  const content = (
    <div>
      {/* <p>{objectiveDetails.name}</p> */}
      {objectiveDetails ? <p>{objectiveDetails.name}</p> : null}
    </div>
  );
  const modelHtml = (
    <span>
      <Modal
        title={
          <span>
            <div style={{ marginBottom: '6px' }}>
              <b>Objective name -&nbsp;&nbsp;</b>
              <span>{modalTitle}</span>
            </div>
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '450px',
              }}
            >
              <b>Media description -&nbsp;&nbsp;</b>
              <span>{mediaTitle}</span>
            </div>
          </span>
        }
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={confirmLoading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        {massage ? (
          <div>
            <p style={{ textAlign: 'center', color: '#52c41a' }}>
              Thanks, we will get it fixed as soon as possible.
            </p>
          </div>
        ) : (
          ''
        )}
        <p>
          <Checkbox
            defaultChecked={mediaNotWorking}
            onChange={e =>
              setFormData({
                ...formData,
                mediaNotWorking: e.target.checked,
              })
            }
          />{' '}
          &nbsp;&nbsp;Media is missing or not working
        </p>
        <p>
          <Checkbox
            defaultChecked={mediaIncorrect}
            onChange={e =>
              setFormData({
                ...formData,
                mediaIncorrect: e.target.checked,
              })
            }
          />{' '}
          &nbsp;&nbsp;Media is incorrect
        </p>
        <p>
          <Checkbox
            defaultChecked={mediaOffensive}
            onChange={e =>
              setFormData({
                ...formData,
                mediaOffensive: e.target.checked,
              })
            }
          />{' '}
          &nbsp;&nbsp;Media is offensive
        </p>

        <p>Add comment</p>
        <p>
          <Input
            onChange={e =>
              setFormData({ ...formData, description: e.target.value })
            }
            value={formData.description}
            type="text"
            name="description"
            id="description"
            placeholder="Comment"
          />
        </p>
      </Modal>
    </span>
  );

  if (fileType === 'image') {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={url} style={{ maxWidth: '100%' }} alt={file.description} />
        {file.description && (
          <p style={{ marginTop: 16 }}>
            <b>Description: </b>
            {file.description}
          </p>
        )}

        {file.time && (
          <p>
            <b>Time: </b>
            {file.time} minutes
          </p>
        )}

        <p style={{ marginTop: 16 }}>
          <b>Url: </b>
          <a href={url} target="_blank" rel="noreferrer">
            Get file
          </a>
          &nbsp;&nbsp;&nbsp;
          <span>
            <Popover
              content={
                <span>
                  <b>Objective name -&nbsp;&nbsp;</b>
                  {modalTitle}
                </span>
              }
              title="Report/ Feedback"
            >
              <img
                onClick={() => showModal(file)}
                src={objectiveIcon}
                className="objective-icone-img"
                alt="Newlane University"
              />
            </Popover>
            {modelHtml}
          </span>
        </p>
      </div>
    );
  }

  if (fileType === 'youtube' || fileType === 'screencast') {
    return (
      <div style={{ textAlign: 'center' }}>
        <Embedly data={{ url, description: url }} />
        {file.description && (
          <p style={{ textAlign: 'center' }}>
            <b>Description: </b>
            {file.description}
          </p>
        )}
        {file.time && (
          <p style={{ textAlign: 'center' }}>
            <b>Time: </b>
            {file.time} minutes
          </p>
        )}
        <p style={{ textAlign: 'center' }}>
          <a href={url} target="_blank" rel="noreferrer">
            Visit url
          </a>
          &nbsp;&nbsp;&nbsp;
          {objectiveDetails && (
            <span>
              <Popover
                content={
                  <span>
                    <b>Objective name -&nbsp;&nbsp;</b>
                    {modalTitle}
                  </span>
                }
                title="Report/ Feedback"
              >
                <img
                  onClick={() => showModal(file)}
                  src={objectiveIcon}
                  className="objective-icone-img"
                  alt="Newlane University"
                />
                {/* <img
                onClick={showModal}
                src={objectiveIcon}
                className="objective-icone-img"
                alt="Newlane University"
              /> */}
              </Popover>
              {modelHtml}
            </span>
          )}
        </p>
      </div>
    );

    // return (
    //   <>
    //     <YouTubeEmbed id={url} width={450} aspectRatio={} />
    //     {file.description && (
    //       <p style={{ marginTop: 16 }}>
    //         <b>Description: </b>
    //         {file.description}
    //       </p>
    //     )}
    //     {file.time && (
    //       <p>
    //         <b>Time: </b>
    //         {file.time} minutes
    //       </p>
    //     )}
    //   </>
    // );
  }

  if (fileType === 'pdf' || fileType === 'excel' || fileType === 'word') {
    return (
      <div style={{ textAlign: 'center' }}>
        {/* <GoogleDocsViewer width="100%" height="780px" fileUrl={url} />{' '} */}
        {file.description && (
          <p>
            <b>Description: </b>
            {file.description}
          </p>
        )}
        {file.time && (
          <p>
            <b>Time: </b>
            {file.time} minutes
          </p>
        )}
        <p>
          <a href={url} target="_blank" rel="noreferrer">
            Get file
          </a>
          &nbsp;&nbsp;&nbsp;
          <Popover
            content={
              <span>
                <b>Objective name -&nbsp;&nbsp;</b>
                {modalTitle}
              </span>
            }
            title="Report/ Feedback"
          >
            <img
              onClick={() => showModal(file)}
              src={objectiveIcon}
              className="objective-icone-img"
              alt="Newlane University"
            />
          </Popover>
          <span>{modelHtml}</span>
        </p>
      </div>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Embedly data={{ url, description: url }} />
      {file.description && (
        <p>
          <b>Description: </b>
          {file.description}
        </p>
      )}
      <p>
        <a href={url} target="_blank" rel="noreferrer">
          Get file
        </a>
        &nbsp;&nbsp;&nbsp;
        <Popover
          content={
            <span>
              <b>Objective name -&nbsp;&nbsp;</b>
              {modalTitle}
            </span>
          }
          title="Report/ Feedback"
        >
          <img
            onClick={() => showModal(file)}
            src={objectiveIcon}
            className="objective-icone-img"
            alt="Newlane University"
          />
        </Popover>
      </p>
      {file.time && (
        <p>
          <b>Time: </b>
          {file.time} minutes
        </p>
      )}
      <span>{modelHtml}</span>
    </div>
  );
};

const selectObjectiveState = (state: AppState) => ({
  // user: state.user.user,
  // markCompletedLoading: state.user.markCompletedLoading,
});

const ConnectedFilePreview = connect(selectObjectiveState, {
  // markCompleted,
  objectiveMedia,
})(FilePreview);

export default ConnectedFilePreview;
