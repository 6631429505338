import React, { FunctionComponent, useState } from 'react';
import ReactQuill from 'react-quill';
import { Input, Button, PageHeader, Row, Card, Col, Form } from 'antd';
import { toast } from 'react-toastify';
import DegreeService from '@services/degreeService';
import { useHistory } from 'react-router';
import GoalsForm from '@components/forms/GoalsForm';
import ChildrenItemsForm from '@components/forms/ChildrenItemsForm';
import ProjectsForm from '@components/forms/ProjectsForm';
import { normalizeProjects } from '@components/forms/helpers';

interface DegreeFormProps {
  degree: Partial<Degree>;
}

const DegreeForm: FunctionComponent<DegreeFormProps> = ({ degree }) => {
  const history = useHistory();
  const [goals, setGoals] = useState<Goal[]>(degree.goals || []);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [courses, setCourses] = useState<Course[]>(degree.courses || []);
  const [projects, setProjects] = useState<Project[]>(degree.projects || []);
  const [longDescription, setLongDescription] = useState(
    degree.longDescription,
  );
  const [lessonDemoAI, setLessonDemoAI] = useState<any>([]);
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const [topicsAILessons, setTopicsAILessons] = useState<any>({
    prompt: {
      courseName: '',
      lessonInfo: '',
      lessonName: '',
      userIdentifier: '',
    },
    topics: [],
  });
  const handleSubmit = (e: any) => {
    const { validateFields } = form;

    e.preventDefault();

    validateFields().then(values => {
      if (degree.id) {
        if (!values.credit || values.credit < 0) {
          values.credit = 0;
        }
        setUpdateLoading(true);

        const normalizedProjects = projects ? normalizeProjects(projects) : [];

        const savePromise =
          degree.id === -1
            ? DegreeService.create({
                ...values,
                longDescription,
                courses,
                goals: goals as Goal[],
                projects: normalizedProjects as Project[],
              })
            : DegreeService.update(degree.id, {
                ...values,
                longDescription,
                courses,
                id: degree.id,
                goals: goals as Goal[],
                projects: normalizedProjects as Project[],
              });

        savePromise
          .then(d => {
            const successMessage = degree.id === -1 ? 'Update' : 'Create';

            toast.success(`${successMessage} successful!`);
            history.push(`/degrees/${d.id}`);
          })
          .catch(() => {
            toast.error('Update failed!');
            setUpdateLoading(false);
          });
      }
    });
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Row gutter={[16, { xs: 16, sm: 16 }]}>
        <Col lg={16} md={14}>
          <PageHeader
            style={{
              background: '#fff',
              padding: '24px 16px 16px',
            }}
            extra={[
              <Button
                key="save"
                type="primary"
                loading={updateLoading}
                onClick={handleSubmit}
              >
                Save
              </Button>,
            ]}
            title={
              <>
                <h2 style={{ marginBottom: 0 }}>
                  {degree.id === -1 ? 'Create ' : 'Edit '}Degree
                </h2>
              </>
            }
          />
          <div
            style={{
              background: '#fff',
              padding: '16px 16px',
            }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
              initialValue={degree.name}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="introVideo"
              label="Intro video"
              initialValue={degree.introVideo}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item>
              {getFieldDecorator('forCredit', {
                initialValue: degree.forCredit,
              })(
                <Checkbox defaultChecked={degree.forCredit}>
                  For credit
                </Checkbox>,
              )}
            </Form.Item>
            <Form.Item label="Visibility">
              {getFieldDecorator('visibility', {
                initialValue: degree.visibility || 'public',
              })(
                <Select>
                  <Option value="private">Private</Option>
                  <Option value="comingSoon">Coming soon</Option>
                  <Option value="public">Public</Option>
                </Select>,
              )}
            </Form.Item> */}
            <Form.Item
              name="shortDescription"
              label="Short description"
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
              initialValue={degree.shortDescription}
            >
              <Input.TextArea />
            </Form.Item>
            <div className="ant-col ant-form-item-label">
              <label
                className="ant-form-item-required"
                title="Long description"
              >
                Long description
              </label>
            </div>
            <ReactQuill
              value={longDescription || ''}
              onChange={(value: string) => {
                setLongDescription(value);
              }}
              style={{ marginBottom: 16 }}
            />
            <Form.Item
              name="credit"
              label="Credit"
              initialValue={degree.credit || 0}
            >
              <Input type="number" min={0} />
            </Form.Item>
            <GoalsForm goals={goals} onUpdate={setGoals} />

            <ChildrenItemsForm<Course>
              type="course"
              items={courses}
              setItems={setCourses}
              setFieldsValue={setFieldsValue}
              showCreate
              lessionAI={lessonDemoAI}
            />

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={updateLoading}>
                Save
              </Button>
            </Form.Item>
          </div>
        </Col>
        <Col lg={8} md={10}>
          <Card
            size="small"
            title="Capstone project"
            className="sidebar-widget"
          >
            <ProjectsForm
              topicsAILessons={topicsAILessons}
              projects={projects}
              setProjects={setProjects}
            />
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default DegreeForm;
