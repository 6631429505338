import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import CourseForm from '@components/courseForm/CourseForm';
interface EditCourseProps {
  user?: User;
}

const EditCourse: React.FC<EditCourseProps> = ({ user }) => {
  if (!user) {
    return null;
  }

  return <CourseForm course={{ id: -1 }} />;
};

const selectEditCourseState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedEditCourse = connect(selectEditCourseState)(EditCourse);

const Page = () => <ConnectedEditCourse />;

export default Page;
