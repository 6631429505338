import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';

import LessonForm from '@components/lessonForm/LessonForm';
import withLesson from '@hoc/withLesson';

interface EditLessonProps {
  user?: User;
  lesson: Lesson;
}

const EditLesson: React.FC<EditLessonProps> = ({ user, lesson }) => {
  if (!user) {
    return null;
  }

  return <LessonForm lesson={lesson} />;
};

const selectEditLessonState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedEditLesson = withLesson(
  connect(selectEditLessonState)(EditLesson),
);

const Page = () => <ConnectedEditLesson />;

export default Page;
