import React, {
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { CheckOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Spin,
  Card,
  Divider,
  Progress,
  Tag,
  Modal,
  Button,
  PageHeader,
  Tabs,
  Alert,
  Popconfirm,
  Switch,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import YouTubeEmbed from 'react-youtube-embed';
import { AppState } from '@redux/reducers';
import { useQuery, useRouter } from '@utils/routerHooks';
import CourseList from '@components/coursesList/CourseList';
import Projects from '@components/projects/Projects';
import { getQuiz, submitQuiz, markCompleted } from '@redux/reducers/user';
import { start } from '@redux/reducers/videoCall';
import { toast } from 'react-toastify';
import UserService from '@services/userService';
// import { getCurrentUser } from '@redux/reducers/user';
import {
  isCourseExamPassed,
  getCourseCompletedPercentage,
  isCoursePassed,
  getSumByKey,
  getCourseObjectives,
} from '@utils/helpers';

import './SingleCourse.less';

import Goals from '@components/goals/Goals';
import LessonList from '@components/lessonList/LessonList';
import moment from 'moment';
import Discussions from '@components/discussions/Discussions';
import { isUserInstructor } from '@helpers';
import StudentList from '@components/students/StudentList';
import ScheduleWidget from '@components/schedule/ScheduleWidget';
import InstructorList from '@components/instructors/InstructorList';
import InfoList from '@components/infoList/InfoList';
import Notes from '@components/notes/Notes';
import ObjectiveList from '@components/objectives/ObjectiveList';
import VisibilityToggle from '@components/visibilityToggle/VisibilityToggle';
import CourseService from '@services/courseService';
import ApiService from '@services/apiService';
import { StudentProjectsTable } from '@components/projectReview';
import { StudentProjectReviewModal } from '@components/projectReview/StudentProjectReviewModal';
import Spinner from '@components/Spinner';
import { useGetStudentProjects } from '@hooks/useGetStudentProjects';
import { CourseReview } from '@components/courseReview';
import { UserProjectStatus } from '@types';
import ProjectService from '@services/projectService';

const { TabPane } = Tabs;

interface TParams {
  id?: number;
}

interface CourseProps {
  user?: User;
  quiz?: Quiz;
  isQuizLoading: boolean;
  getQuiz: typeof getQuiz;
  submitQuiz: typeof submitQuiz;
  markCompleted: typeof markCompleted;
  markCompletedLoading: boolean;
}

const Course: React.FC<CourseProps> = ({
  user,
  quiz,
  isQuizLoading,
  getQuiz,
  submitQuiz,
  markCompleted,
  markCompletedLoading,
}) => {
  const [course, setCourse] = useState<Course>();
  const [currentUserProject, setCurrentUserProject] = useState<UserProject>();
  const [quizModalOpen, setQuizModalOpen] = useState<boolean>(false);
  const [quizzes, setQuizzes] = useState<Quiz[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [userAnswers, setUserAnswers] = useState<any>({});
  const [quizReview, setQuizReview] = useState<Quiz>();
  const [quizError, setQuizError] = useState<string>();
  const { match, location, history } = useRouter();
  const [numberOfMedia, setNumberOfMedia] = useState<any>(0);
  const [numberOfAssessment, setNumberOfAssessment] = useState<any>(0);
  const [hideShowDetails, setHideShowDetails] = useState(false);
  const [startCourses, setStartCourses] = useState<any>(false);
  const [startCoursesCount, setStartCoursesCount] = useState<any>(0);
  const [userProjects, setUserProjects] = useState<UserProject[]>([]);
  const [projectCompleted, setProjectCompleted] = useState<boolean>(false);

  const userCourse =
    user &&
    user.userCourses &&
    user.userCourses.find(uc => uc.courseId === course?.id);

  const courseExamPassed = quizzes && isCourseExamPassed(quizzes);
  const query = useQuery<{ tab?: string }>();

  const courseId = useMemo(() => match.params.id || 0, [match.params.id]);

  const projectsExist = useMemo(
    () => course?.projects && course?.projects.length > 0,
    [course?.projects],
  );

  const fetchCourse = async () => {
    setLoading(true);
    const data = await CourseService.getOne(courseId);
    setCourse(data);
    setLoading(false);
  };

  const fetchUserProjects = useCallback(async () => {
    const result = await ProjectService.getUserProjects({
      courseId,
    });
    setUserProjects(result);
    const completed = !!course?.projects?.find(
      project =>
        !!result.find(
          up =>
            up.status === UserProjectStatus.Approved &&
            up.projectId === project.id,
        ),
    );
    setProjectCompleted(completed);
  }, [user, course]);

  useEffect(() => {
    fetchCourse();
  }, []);

  useEffect(() => {
    if (course) {
      fetchUserProjects();
    }
  }, [course]);

  const coursePassed = useMemo(
    () => user && course && isCoursePassed(course?.id, user.userCourses),
    [course?.id, user],
  );

  const [currentTab, setCurrentTab] = useState(query.tab || 'details');

  const {
    data,
    loading: studentProjectsLoading,
    fetchData,
  } = useGetStudentProjects(courseId);

  const objectives = useMemo(
    () => (course ? getCourseObjectives(course) : []),
    [course],
  );

  const lessons = useMemo(() => course?.lessons || [], [course]);

  const courseInfoData = useMemo(() => {
    let objectiveWithMedia = 0;
    let media = 0;
    let objectiveWithAssessment = 0;
    let assessment = 0;
    let timeObjectiveOfMedia = 0;
    objectives.map(objective => {
      if (objective.media && objective.media.length > 0) {
        media += objective.media.length;
        objectiveWithMedia += 1;
      }
      setNumberOfMedia(Number(media));
      if (objective.assessments && objective.assessments.length > 0) {
        assessment += objective.assessments.length;
        objectiveWithAssessment += 1;
      }
      setNumberOfAssessment(Number(assessment));
      // calculate time objective of media
      if (objective.media && objective.media.length > 0) {
        const total = getSumByKey(objective.media, 'time');
        timeObjectiveOfMedia += total;
      }
    });
    const totalObjectiveWithMedia = Number(objectiveWithMedia);
    const totalObjectiveWithAssessment = Number(objectiveWithAssessment);
    const totalTimeAllObjectiveOfMedia = Number(timeObjectiveOfMedia);

    let timeLessonOfExercises = 0;
    let totalReadings = 0;
    let timeLessonOfReadings = 0;
    let totalPriject = 0;
    for (let step = 0; step < lessons.length; step++) {
      // calculate time lessons of projects
      const lessonsPriject = lessons[step].projects;
      if (lessonsPriject && lessonsPriject.length > 0) {
        totalPriject = getSumByKey(lessonsPriject, 'time');
        timeLessonOfExercises += totalPriject;
      }
      // calculate time lessons of readings
      const lessonsReadings = lessons[step].readings;
      if (lessonsReadings && lessonsReadings.length > 0) {
        totalReadings = getSumByKey(lessonsReadings, 'time');
        timeLessonOfReadings += totalReadings;
      }
    }
    const totalTimeAllLessonOfExercises = Number(timeLessonOfExercises);
    const totalTimeAllLessonOfReadings = Number(timeLessonOfReadings);

    return {
      totalObjectiveWithMedia,
      totalObjectiveWithAssessment,
      totalTimeAllObjectiveOfMedia,
      totalTimeAllLessonOfExercises,
      totalTimeAllLessonOfReadings,
    };
  }, [objectives, lessons]);

  const getQuizzes = useCallback(async () => {
    const response = await ApiService.get(
      `/users/current-user/quizzes/${courseId}`,
    );
    setQuizzes(response.data);
  }, [courseId, setQuizzes]);

  useEffect(() => {
    if (!quizzes && user && !isUserInstructor(user)) {
      getQuizzes();
    }
    if (user && !isUserInstructor(user) && userCourse) {
      aaa();
    }
  }, [quizzes, getQuizzes, courseId, user]);

  if (!user) {
    return null;
  }

  const aaa = async () => {
    const response = await ApiService.get(`/users/current-user`);
    if (response.data && response.data.userCourses.length > 0) {
      const userCourseAll = response.data.userCourses;
      // calculate corse running
      const startCount = userCourseAll.filter(
        (c: any) => c.startCourse === 'started',
      ).length;
      setStartCoursesCount(startCount);
      const userCourseSingle = response.data.userCourses.filter(
        (c: any) => c.courseId == courseId,
      );
      const startC = userCourseSingle[0].startCourse == null;
      setStartCourses(startC);
    }
  };
  const percentCompleted = course
    ? getCourseCompletedPercentage(course, user)
    : 0;
  localStorage.setItem('scoi', `${percentCompleted}`);

  // start courese
  const startCourse1 = async (courseId: any) => {
    if (startCoursesCount && startCoursesCount >= 3) {
      toast.info('Please complete started course');
    } else {
      setLoading(true);
      await UserService.startCourseForStudent(
        Number(user.id),
        Number(courseId),
      );
      toast.success('Course started successfully!');
      setStartCourses(false);
      setLoading(false);
    }
  };

  const startQuiz = () => {
    getQuiz(Number(courseId));
    setQuizModalOpen(true);
  };

  const setAnswer = (assessmentId: number, answerId: number) => {
    setUserAnswers({
      ...userAnswers,
      [assessmentId]: answerId,
    });
  };

  const validateQuiz = () => {
    if (quiz) {
      const unansweredAssessment = quiz.assessments.filter(
        assessment => !userAnswers[assessment.id],
      );
      return unansweredAssessment.length === 0;
    }

    return false;
  };

  const onSubmitQuiz = async () => {
    const quizValid = validateQuiz();

    if (!quizValid) {
      setQuizError('Please answer all exam questions.');
      return false;
    }

    setQuizModalOpen(false);
    if (quiz) {
      const response = await ApiService.put(`/quizzes/${quiz.id}`, {
        userAnswers,
      });
      const newQuiz = response.data;

      setQuizzes(quizzes ? [newQuiz, ...quizzes] : [newQuiz]);
    }
    setUserAnswers({});
    // await getQuizzes(Number(courseId));
  };

  const onCancelQuiz = () => {
    setQuizModalOpen(false);
    setUserAnswers({});
  };

  const openQuizReview = (newQuizReview: Quiz) => {
    setQuizReview(newQuizReview);
  };

  const closeQuizReview = () => {
    setQuizReview(undefined);
  };

  const deleteExams = async () => {
    setLoading(true);
    if (!course) {
      return;
    }
    await CourseService.deleteExams(course.id);
    await getQuizzes();
    setLoading(false);
  };

  const handleChangeTab = (key: string) => {
    setCurrentTab(key);
    history.push(`?tab=${key}`);
  };

  // const { degreeId } = parsePath(location.pathname);

  let extra: ReactNode[] = [];

  if (isUserInstructor(user)) {
    extra = [
      <Button
        key="edit"
        onClick={() => history.push(`/courses/${course?.id}/edit`)}
      >
        Edit
      </Button>,
    ];
  }
  const calculateObjectMediaPercentage = (numberOfObject: any) => {
    // console.log('Media nagar', numberOfObject);
    // const percentageObjectMedia =((numberOfObject + numberOfMedia) / 200) * 100;
    // const percentageObjectMedia = Math.floor(
    //   (numberOfMedia * 100) / numberOfObject,
    // );
    const percentageObjectMedia = Math.round(
      (Number(courseInfoData.totalObjectiveWithMedia) * 100) /
        Number(numberOfObject),
    );
    if (percentageObjectMedia) {
      return percentageObjectMedia;
    }
    return 0;
  };
  const calculateObjectAssessmentPercentage = (numberOfObject: any) => {
    // const percentageObjectAssessment = ((numberOfObject + numberOfAssessment) / 200) * 100;
    // const percentageObjectAssessment = Math.floor(
    //   (numberOfAssessment * 100) / numberOfObject,
    // );
    const percentageObjectAssessment = Math.round(
      (Number(courseInfoData.totalObjectiveWithAssessment) * 100) /
        Number(numberOfObject),
    );
    if (percentageObjectAssessment) {
      return percentageObjectAssessment;
    }
    return 0;
  };
  const convertMinutesToHoursAndMinutes = (minutes: any) => {
    let h = 0;
    let m = 0;
    h = Number(Math.floor(minutes / 60));
    m = Number(minutes % 60);
    h = Number(h < 10 ? `0${h}` : h);
    m = Number(m < 10 ? `0${m}` : m);
    return `${h}:${m}`;
  };

  const QuizReviewModal = quizReview && (
    <Modal
      title="Quiz Review"
      width={720}
      visible={!!quizReview}
      onOk={closeQuizReview}
      onCancel={closeQuizReview}
      destroyOnClose
    >
      <h4>{moment(quizReview.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</h4>
      <h4>Score: {quizReview.score}%</h4>

      <Divider />

      {quizReview &&
        quizReview.assessments.map(assessment => (
          <div style={{ textAlign: 'center' }} key={assessment.id}>
            <div style={{ textAlign: 'left' }}>
              {assessment.objective && (
                <h3>
                  Objective:{' '}
                  <Link
                    to={`/courses/${courseId}/objectives/${assessment.objective.id}`}
                  >
                    {assessment.objective.name}
                  </Link>
                </h3>
              )}
            </div>
            <div style={{ textAlign: 'center' }}>
              <h3>{assessment.question}</h3>
            </div>
            {assessment.answers.map(answer => (
              <>
                <Button
                  key={answer.id}
                  type="default"
                  danger={quizReview.answers.find(
                    a => !a.correct && a.id === answer.id,
                  )}
                  style={{
                    marginBottom: '8px',
                    whiteSpace: 'normal',
                    height: 'auto',
                    padding: '8px 16px',
                  }}
                  className={
                    quizReview.answers.find(
                      a => a.correct && a.id === answer.id,
                    )
                      ? 'bg-success'
                      : ''
                  }
                >
                  {answer.text}
                </Button>
                <br />
              </>
            ))}
            <Divider />
          </div>
        ))}
    </Modal>
  );

  if (loading || !course) {
    return <Spin />;
  }

  return (
    <>
      <Row gutter={[16, { xs: 16, sm: 16, md: 0 }]}>
        <Col xs={24} sm={24} md={16}>
          <PageHeader
            style={{
              background: '#fff',
              padding: '16px 16px 0',
            }}
            extra={extra}
            title={
              <>
                <Tag color="green" style={{ fontWeight: 400 }}>
                  Course
                </Tag>
                <h2 style={{ margin: '8px 0' }}>{course.name}</h2>
              </>
            }
          />

          <Tabs
            defaultActiveKey="details"
            activeKey={currentTab}
            style={{ background: '#fff', padding: '8px 16px' }}
            onChange={handleChangeTab}
          >
            <TabPane tab="Course" key="details">
              {course.introVideo && <YouTubeEmbed id={course.introVideo} />}
              <div style={{ marginTop: '16px' }}>
                {/* {!isUserInstructor(user) && ( */}
                <div>
                  <h4>
                    Course # - {course.code} &nbsp;&nbsp;Semester Credit Hours -{' '}
                    {course.credit}
                  </h4>
                  <h4>
                    Course Professor -{' '}
                    {course.mainInstructor ? (
                      user.id != course?.mainInstructor?.id ? (
                        <Link to={`/chat?userId=${course?.mainInstructor?.id}`}>
                          {course.mainInstructor.firstName}{' '}
                          {course.mainInstructor.lastName}
                        </Link>
                      ) : (
                        <span style={{ color: '#46A5E5' }}>
                          {course.mainInstructor?.firstName}{' '}
                          {course.mainInstructor?.lastName}
                        </span>
                      )
                    ) : (
                      'None assigned yet'
                    )}
                  </h4>
                </div>
                {/* )} */}
                <div>
                  <h3>Short description</h3>
                  <p>{course.shortDescription}</p>
                </div>
                <Divider dashed />
                {course.longDescription && (
                  <div>
                    <h3>Long description</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: course.longDescription,
                      }}
                    />
                  </div>
                )}
                {course.goals && (
                  <div>
                    <Divider dashed />
                    <h3>Goals</h3>
                    <Goals goals={course.goals} />
                  </div>
                )}
                {course.lessons && (
                  <div>
                    <Divider dashed />
                    <div className="lessons-list">
                      <div className="lessons">
                        {percentCompleted > 0 || startCourses == false ? (
                          <Link to={`${location.pathname}/lessons-overview`}>
                            <h3>Lessons</h3>
                          </Link>
                        ) : (
                          <h3>Lessons</h3>
                        )}
                        {/* <Link to={`${location.pathname}/lessons-overview`}>
                        <h3>Lessons</h3>
                      </Link> */}
                      </div>
                      <div className="calculate">
                        {' '}
                        {isUserInstructor(user) && (
                          <div>
                            <div>
                              <h3>
                                <span
                                  style={{
                                    marginRight: '5px',
                                  }}
                                >
                                  Course Data
                                </span>
                                <Switch
                                  onClick={() =>
                                    setHideShowDetails(!hideShowDetails)
                                  }
                                />
                              </h3>
                            </div>
                            {hideShowDetails && (
                              <div
                                style={{
                                  textTransform: 'uppercase',
                                  color: '#2d3436',
                                }}
                              >
                                <h4>
                                  number of objectives:{' '}
                                  {objectives && objectives.length ? (
                                    <span
                                      style={{
                                        color: '#46A5E5',
                                        marginLeft: '6px',
                                      }}
                                    >
                                      {objectives.length}
                                    </span>
                                  ) : (
                                    '0'
                                  )}
                                </h4>
                                <h4>
                                  number of assessments:{' '}
                                  {numberOfAssessment ? (
                                    <span
                                      style={{
                                        color: '#46A5E5',
                                        marginLeft: '6px',
                                      }}
                                    >
                                      {numberOfAssessment}
                                    </span>
                                  ) : (
                                    '0'
                                  )}
                                </h4>
                                <h4>
                                  number of media:{' '}
                                  {numberOfMedia ? (
                                    <span
                                      style={{
                                        color: '#46A5E5',
                                        marginLeft: '6px',
                                      }}
                                    >
                                      {numberOfMedia}
                                    </span>
                                  ) : (
                                    '0'
                                  )}
                                </h4>
                                <h4>
                                  percentage of objectives with assessments:{' '}
                                  <span
                                    style={{
                                      color: '#46A5E5',
                                      marginLeft: '6px',
                                    }}
                                  >
                                    {calculateObjectAssessmentPercentage(
                                      objectives.length,
                                    )}
                                    %
                                  </span>
                                </h4>
                                <h4>
                                  percentage of objectives with media:{' '}
                                  <span
                                    style={{
                                      color: '#46A5E5',
                                      marginLeft: '6px',
                                    }}
                                  >
                                    {calculateObjectMediaPercentage(
                                      objectives.length,
                                    )}
                                    %
                                  </span>
                                </h4>
                                <h4>
                                  total media time (hh:mm)
                                  <span
                                    style={{
                                      color: '#46A5E5',
                                      marginLeft: '6px',
                                    }}
                                  >
                                    {convertMinutesToHoursAndMinutes(
                                      courseInfoData.totalTimeAllObjectiveOfMedia,
                                    )}
                                  </span>
                                </h4>
                                <h4>
                                  total reading time (hh:mm)
                                  <span
                                    style={{
                                      color: '#46A5E5',
                                      marginLeft: '6px',
                                    }}
                                  >
                                    {convertMinutesToHoursAndMinutes(
                                      courseInfoData.totalTimeAllLessonOfReadings,
                                    )}
                                  </span>
                                </h4>
                                <h4>
                                  total exercise time (hh:mm)
                                  <span
                                    style={{
                                      color: '#46A5E5',
                                      marginLeft: '6px',
                                    }}
                                  >
                                    {convertMinutesToHoursAndMinutes(
                                      courseInfoData.totalTimeAllLessonOfExercises,
                                    )}
                                  </span>
                                </h4>
                                <h4>
                                  Total course time (hh:mm)
                                  <span
                                    style={{
                                      color: '#46A5E5',
                                      marginLeft: '6px',
                                    }}
                                  >
                                    {convertMinutesToHoursAndMinutes(
                                      courseInfoData.totalTimeAllObjectiveOfMedia +
                                        courseInfoData.totalTimeAllLessonOfExercises +
                                        courseInfoData.totalTimeAllLessonOfReadings,
                                    )}
                                  </span>
                                </h4>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <LessonList
                      hideShowDetails={hideShowDetails}
                      lessons={course.lessons}
                      startCourses={startCourses}
                      percentCompleted={percentCompleted}
                    />
                  </div>
                )}
                {course.connections && course.connections.length ? (
                  <div>
                    <Divider dashed />
                    <h3>Course Connections</h3>
                    {course.code && (
                      <div>
                        <h3>Course Code</h3>
                        <p>{course.code}</p>
                      </div>
                    )}
                    <h3>Prerequisites</h3>
                    <CourseList courses={course.connections} />
                  </div>
                ) : null}
              </div>
            </TabPane>
            {isUserInstructor(user) && (
              <TabPane tab="Objectives" key="objectives">
                <ObjectiveList
                  objectives={objectives}
                  user={user}
                  markCompleted={markCompleted}
                  markCompletedLoading={markCompletedLoading}
                  baseUrl={location.pathname}
                />
              </TabPane>
            )}
            <TabPane tab="Discussions" key="discussions">
              <Discussions courseId={course.id} />
            </TabPane>
            <TabPane tab="Project" key="project">
              <Projects projects={course.projects} />
              {isUserInstructor(user) && (
                <>
                  <div>
                    <h3 style={{ margin: '8px 0' }}>
                      Student projects (Total: {data && data.length})
                    </h3>
                    {!data || studentProjectsLoading ? (
                      <Spinner />
                    ) : (
                      <StudentProjectsTable
                        studentProjects={data}
                        onClick={userProject =>
                          setCurrentUserProject(userProject)
                        }
                      />
                    )}
                  </div>
                  {currentUserProject && (
                    <StudentProjectReviewModal
                      visible={!!currentUserProject}
                      userProject={currentUserProject}
                      onClose={() => {
                        setCurrentUserProject(undefined);
                        fetchData();
                      }}
                    />
                  )}
                </>
              )}
            </TabPane>
            <TabPane tab="Info" key="info">
              <InfoList itemId={course.id} user={user} />
            </TabPane>
            {isUserInstructor(user) && (
              <TabPane tab="Admin" key="admin">
                <VisibilityToggle
                  item={course}
                  type="course"
                  onChange={(item: any) => {
                    history.go(0);
                  }}
                />
                <br />
                <InstructorList
                  type="course"
                  itemId={course.id}
                  item={course}
                  onUpdate={fetchCourse}
                />
              </TabPane>
            )}
          </Tabs>
        </Col>
        <Col xs={24} sm={24} md={8}>
          {isUserInstructor(user) && (
            <Card size="small" title="Students" className="sidebar-widget">
              <StudentList id={course.id} type="course" />
              {/* <Tabs
              defaultActiveKey="completed"
              style={{ background: '#fff', padding: '8px 16px' }}
            >
              <TabPane tab="Completed" key="completed">
                <StudentList id={course.id} type="course" />
              </TabPane>
              <TabPane tab="Started" key="started">
                <StudentList id={course.id} type="course" />
              </TabPane>
              <TabPane tab="Not Started" key="notstarted">
                <StudentList id={course.id} type="course" />
              </TabPane>
            </Tabs> */}
            </Card>
          )}
          {!isUserInstructor(user) && (
            <>
              {percentCompleted == 0 && startCourses ? (
                <Card>
                  <Button
                    onClick={() => startCourse1(course.id)}
                    type="primary"
                  >
                    <span>START</span>
                  </Button>
                </Card>
              ) : (
                <Card
                  size="small"
                  title="Course Completion Steps"
                  className="sidebar-widget"
                >
                  <h3>1. Self Assessment</h3>
                  <Progress percent={percentCompleted} />
                  <hr />
                  <h3>2. Course Exam</h3>
                  <div>
                    <Button
                      type="primary"
                      onClick={startQuiz}
                      disabled={percentCompleted < 100 || courseExamPassed}
                      style={{ marginBottom: 12 }}
                    >
                      {courseExamPassed ? 'Exam Passed' : 'Take Exam'}
                    </Button>
                  </div>
                  {percentCompleted < 100 && (
                    <div>
                      <p style={{ marginTop: '16px' }}>
                        You must self-assess mastery of all objectives before
                        taking the course exam.
                      </p>
                    </div>
                  )}
                  {quizzes && quizzes.length ? (
                    <span>Previous exams</span>
                  ) : null}
                  <div>
                    {quizzes &&
                      quizzes.map((quiz: any) =>
                        quiz.deletedAt == null ? (
                          <div key={quiz.id}>
                            <a onClick={() => openQuizReview(quiz)}>
                              {moment(quiz.createdAt).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              )}
                              : {quiz.score}%
                            </a>
                          </div>
                        ) : null,
                      )}
                  </div>
                  {quizzes && quizzes.length ? (
                    <Popconfirm
                      title="Are you sure you want to delete all exams for this course?"
                      onConfirm={deleteExams}
                      okText="Yes"
                      cancelText="No"
                      disabled={coursePassed || courseExamPassed}
                    >
                      <Button
                        ghost
                        danger
                        loading={loading}
                        style={{ marginTop: 12 }}
                        disabled={coursePassed || courseExamPassed}
                      >
                        Reset exams
                      </Button>
                    </Popconfirm>
                  ) : null}

                  <hr />
                  <h3>3. Course Project</h3>
                  {projectsExist ? (
                    <>
                      {projectCompleted ? (
                        <p className="text-success">
                          <CheckOutlined /> PROJECT APPROVED
                        </p>
                      ) : null}
                      <Button
                        type="primary"
                        onClick={() => handleChangeTab('project')}
                        style={{ marginBottom: 12 }}
                        disabled={
                          percentCompleted < 100 ||
                          !courseExamPassed ||
                          projectCompleted
                        }
                      >
                        Submit project
                      </Button>
                    </>
                  ) : (
                    <p>No projects.</p>
                  )}
                  <hr />
                  <h3>4. Course Hearing</h3>
                  <ScheduleWidget
                    courseId={course.id}
                    type="courseHearing"
                    disabled={
                      coursePassed ||
                      percentCompleted < 100 ||
                      !courseExamPassed ||
                      (projectsExist && !projectCompleted)
                    }
                  />
                  <hr />
                  {coursePassed && (
                    <Alert
                      message="COURSE PASSED"
                      type="success"
                      style={{
                        fontSize: 18,
                        color: 'green',
                        textAlign: 'center',
                      }}
                    />
                  )}
                </Card>
              )}
              {/* <Card size="small" title="Progress" className="sidebar-widget">
              {!isCoursePassed(course.id, user.userCourses) ? (
                <Progress percent={percentCompleted} />
              ) : (
                <Alert
                  message="PASSED"
                  type="success"
                  style={{
                    fontSize: 18,
                    color: 'green',
                    textAlign: 'center',
                  }}
                />
              )}
            </Card> */}
              {userCourse && userCourse.courseReviewId && (
                <Card
                  size="small"
                  title="Course review"
                  className="sidebar-widget"
                >
                  <CourseReview userCourseId={userCourse.id} />
                </Card>
              )}
              {/* {!isCoursePassed(course.id, user.userCourses) && (
              <ScheduleWidget
                courseId={course.id}
                type="courseHearing"
                disabled={percentCompleted < 100 || !courseExamPassed}
              />
            )}
            <Card size="small" title="Course Exam" className="sidebar-widget">
              <div>
                <Button
                  type="primary"
                  onClick={startQuiz}
                  disabled={percentCompleted < 100 || courseExamPassed}
                  style={{ marginBottom: 12 }}
                >
                  {courseExamPassed ? 'Exam Passed' : 'Take Exam'}
                </Button>
              </div>
              {percentCompleted < 100 && (
                <div>
                  <p style={{ marginTop: '16px' }}>
                    You must self-assess mastery of all objectives before
                    taking the course exam.
                  </p>
                </div>
              )}
              {quizzes && quizzes.length ? <span>Previous exams</span> : null}
              <div>
                {quizzes &&
                  quizzes.map(quiz => (
                    <div key={quiz.id}>
                      <a onClick={() => openQuizReview(quiz)}>
                        {moment(quiz.createdAt).format(
                          'MMMM Do YYYY, h:mm:ss a',
                        )}
                        : {quiz.score}%
                      </a>
                    </div>
                  ))}
              </div>
              {quizzes && quizzes.length ? (
                <Popconfirm
                  title="Are you sure you want to delete all exams for this course?"
                  onConfirm={deleteExams}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    ghost={true}
                    type="danger"
                    loading={loading}
                    style={{ marginTop: 12 }}
                  >
                    Reset exams
                  </Button>
                </Popconfirm>
              ) : null}
            </Card> */}
            </>
          )}
        </Col>
        {QuizReviewModal}
        <Modal
          title="Course Exam"
          width={720}
          visible={quizModalOpen}
          onOk={onSubmitQuiz}
          onCancel={onCancelQuiz}
          maskClosable={false}
          okText="Submit exam"
        >
          {isQuizLoading && (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )}

          {quiz &&
            quiz.assessments.map(assessment => (
              <div style={{ textAlign: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                  <h3>{assessment.question}</h3>
                </div>
                {assessment.answers.map(answer => (
                  <>
                    <Button
                      type={
                        userAnswers[assessment.id] &&
                        userAnswers[assessment.id] === answer.id
                          ? 'primary'
                          : 'default'
                      }
                      style={{
                        marginBottom: '8px',
                        whiteSpace: 'normal',
                        height: 'auto',
                        padding: '8px 16px',
                      }}
                      onClick={() => setAnswer(assessment.id, answer.id)}
                    >
                      {answer.text}
                    </Button>
                    <br />
                  </>
                ))}
                <Divider />
              </div>
            ))}

          {quizError && <Alert type="error" message={quizError} />}
        </Modal>
      </Row>
      <Notes
        relId={course.id}
        rel="course"
        canEdit={false}
        canRemove={false}
        canAdd={false}
      />
    </>
  );
};

const selectCourseState = (state: AppState) => ({
  user: state.user.user,
  quiz: state.user.quiz,
  isQuizLoading: state.user.isQuizLoading,
  courseExamPassed: state.user.courseExamPassed,
  markCompletedLoading: state.user.markCompletedLoading,
});

const ConnectedCourse = connect(selectCourseState, {
  getQuiz,
  submitQuiz,
  start,
  markCompleted,
})(Course);

const Page = () => <ConnectedCourse />;

export default Page;
