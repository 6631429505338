import React from 'react';
import { Checkbox, Collapse } from 'antd';
import { scriptChecks } from './constants';
import { ScriptCheck } from './types';

interface ChecksProps {
  checks: ScriptCheck[];
}

const Checks: React.FC<ChecksProps> = ({ checks }) => (
  <Collapse>
    {checks.map(check => (
      <Collapse.Panel
        key={check.title}
        header={<Checkbox>{check.title}</Checkbox>}
      >
        <p>{check.text}</p>
      </Collapse.Panel>
    ))}
  </Collapse>
);

interface Props {
  user: User;
}

export const CourseHearingScript: React.FC<Props> = ({ user }) => (
  <div>
    <h4>Intro</h4>
    <Checks checks={scriptChecks.intro} />

    <h4 style={{ marginTop: 16 }}>Main Body (Conduct Course hearing): </h4>
    <Checks checks={scriptChecks.main} />

    <h4 style={{ marginTop: 16 }}>Outro: </h4>
    <Checks checks={scriptChecks.outro} />
  </div>
);
