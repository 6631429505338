import React, { FunctionComponent, useState } from 'react';
import { Input, Button, PageHeader, Row, Col, Form } from 'antd';
import { toast } from 'react-toastify';
import NewlaneTutorialsService from '@services/newlaneTutorialsService';
import { useHistory } from 'react-router';
import { normalizeMedia } from '@components/forms/helpers';

toast.configure();

interface NewlaneTutorialsFormProps {
  newlaneTutorial: Partial<NewlaneTutorials>;
}

const NewlaneTutorialsForm: FunctionComponent<NewlaneTutorialsFormProps> = ({
  newlaneTutorial,
}) => {
  const history = useHistory();
  const [updateLoading, setUpdateLoading] = useState(false);
  // const [media, setMedia] = useState<ObjectiveMedia[]>(objective.media || []);
  // const [assessments, setAssessments] = useState<Assessment[]>(
  //   objective.assessments || [],
  // );
  const [form] = Form.useForm();
  const { validateFields } = form;

  const handleSubmit = (e: any) => {
    e.preventDefault();

    validateFields().then(values => {
      if (newlaneTutorial.id) {
        setUpdateLoading(true);

        // const normalizedMedia = values.media
        //   ? normalizeMedia(values.media)
        //   : [];

        const savePromise =
          newlaneTutorial.id === -1
            ? NewlaneTutorialsService.create({
                ...values,
              })
            : NewlaneTutorialsService.update(newlaneTutorial.id, {
                ...values,
                id: newlaneTutorial.id,
              });

        savePromise
          .then(d => {
            toast.success('Update successful!');
            history.push(`/newlane-tutorials`);
          })
          .catch(err => {
            toast.error(
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Error saving objective.',
            );
            setUpdateLoading(false);
          });
      }
    });
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row gutter={24}>
        <Col span={24}>
          <PageHeader
            style={{
              background: '#fff',
              padding: '16px 16px 0',
            }}
            extra={[
              <Button
                key="save"
                type="primary"
                loading={updateLoading}
                onClick={handleSubmit}
              >
                Save
              </Button>,
            ]}
            title={
              <>
                <h2 style={{ margin: '8px 0' }}>
                  {newlaneTutorial.id === -1 ? 'Create ' : 'Edit '}Newlane
                  Tutorials
                </h2>
              </>
            }
          />
          <div
            style={{
              background: '#fff',
              padding: '16px 16px',
            }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
              initialValue={newlaneTutorial.name}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
              initialValue={newlaneTutorial.description}
            >
              <Input.TextArea rows={2} />
            </Form.Item>
            <Form.Item
              name="url"
              label="Url"
              initialValue={newlaneTutorial.url}
            >
              <Input />
            </Form.Item>
            {/* <ObjectiveMediaForm
              media={media}
              setObjectiveMedia={setMedia}
              getFieldDecorator={getFieldDecorator}
            />

            <Divider />
           
            <AssessmentForm
              assessments={assessments}
              setAssessments={setAssessments}
              getFieldDecorator={getFieldDecorator}
              setFieldsValue={setFieldsValue}
            />

            <Divider /> */}

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={updateLoading}>
                Save
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default NewlaneTutorialsForm;
