import { gql } from '@apollo/client';

export const discussionsQuery = (degreeId?: number, courseId?: number) => gql`
  {
    discussions(condition: { ${
      courseId ? `courseId: ${courseId}` : `degreeId: ${degreeId}`
    } }) {
      nodes {
        id
        createdById
        title
        text
        createdAt
        createdBy {
          email
          firstName
          id
          lastName
          role
          avatar {
            id
            url
          }
        }
        replies(orderBy: NATURAL) {
          nodes {
            id
            text
            discussionId
            createdById
            createdAt
            createdBy {
              email
              firstName
              id
              lastName
              role
              avatar {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_DISCUSSION = gql`
  mutation createDiscussion(
    $title: String!
    $text: String!
    $createdById: Int!
    $degreeId: Int
    $courseId: Int
  ) {
    __typename
    createDiscussion(
      input: {
        discussion: {
          title: $title
          text: $text
          degreeId: $degreeId
          courseId: $courseId
          createdById: $createdById
        }
      }
    ) {
      clientMutationId
    }
  }
`;

export const UPDATE_DISCUSSION = gql`
  mutation updateDiscussion($title: String!, $text: String!, $id: Int!) {
    __typename
    updateDiscussion(
      input: { patch: { text: $text, title: $title }, id: $id }
    ) {
      clientMutationId
    }
  }
`;

export const DELETE_DISCUSSION = gql`
  mutation deleteDiscussion($id: Int!) {
    __typename
    deleteDiscussion(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export const CREATE_REPLY = gql`
  mutation createReply(
    $text: String!
    $createdById: Int!
    $discussionId: Int!
  ) {
    __typename
    createReply(
      input: {
        reply: {
          text: $text
          discussionId: $discussionId
          createdById: $createdById
        }
      }
    ) {
      clientMutationId
    }
  }
`;

export const UPDATE_REPLY = gql`
  mutation updateReply($text: String!, $id: Int!) {
    __typename
    updateReply(input: { patch: { text: $text }, id: $id }) {
      clientMutationId
    }
  }
`;

export const DELETE_REPLY = gql`
  mutation deleteReply($id: Int!) {
    __typename
    deleteReply(input: { id: $id }) {
      clientMutationId
    }
  }
`;
