import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Card, Button, Spin } from 'antd';
import CourseService from '@services/courseService';
import { getCurrentUser } from '@redux/reducers/user';
import UserService from '@services/userService';
import { connect } from 'react-redux';
import { DATETIME_FORMAT } from './constants';
import ScheduleModal from './ScheduleModal';

interface ScheduleWidgetProps {
  courseId?: number;
  getCurrentUser: typeof getCurrentUser;
  type: ScheduledCall['type'];
  disabled?: boolean;
}

const ScheduleWidget: React.FC<ScheduleWidgetProps> = ({
  courseId,
  getCurrentUser,
  type,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [calls, setCalls] = useState<ScheduledCall[]>();

  const loadCalls = () => {
    setLoading(true);
    if (courseId) {
      CourseService.getScheduledCalls(courseId).then(calls => {
        setCalls(calls);
        setLoading(false);
      });
    } else {
      UserService.getScheduledCalls().then(calls => {
        setCalls(calls);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!calls && !loading) {
      loadCalls();
    }
  });

  const handleSubmit = () => {
    loadCalls();
    getCurrentUser();
    setScheduleModalOpen(false);
  };

  const deleteCall = (id: string) => {
    setLoading(true);
    CourseService.deleteScheduledCall(id).then(() => {
      loadCalls();
      getCurrentUser();
    });
  };

  let widgetBody;

  if (type === 'onboarding') {
    widgetBody = (
      <div style={{ textAlign: 'center' }}>
        {calls && calls[0] && (
          <div>
            <p>
              <b>Scheduled for: </b>
              {moment.unix(calls[0].startTime).format(DATETIME_FORMAT)}
              <br />
              {calls[0].confirmed ? (
                <span style={{ color: '#52c41a' }}>CONFIRMED</span>
              ) : calls[0].rejected ? (
                <span style={{ color: 'red' }}>THIS CALL WAS REJECTED</span>
              ) : (
                <span style={{ color: '#faad14' }}>
                  WAITING FOR CONFIRMATION
                </span>
              )}
            </p>
            <Button danger ghost onClick={() => deleteCall(calls[0].id)}>
              Cancel call
            </Button>
          </div>
        )}
        {(!calls || (calls && !calls[0])) && (
          <Button
            type="primary"
            loading={loading}
            disabled={loading}
            onClick={() => setScheduleModalOpen(true)}
          >
            Schedule Onboarding Call
          </Button>
        )}
      </div>
    );
  } else {
    widgetBody = (
      // <Card
      //   size="small"
      //   title={`Schedule ${
      //     type === 'courseHearing' ? 'Course Hearing' : 'Onboarding Call'
      //   }`}
      //   className="sidebar-widget"
      // >
      <div>
        {loading && (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        )}
        {calls && calls[0] && (
          <div>
            <p>
              <b>Scheduled for: </b>
              {moment.unix(calls[0].startTime).format(DATETIME_FORMAT)}
              <br />
              <b>Confirmed: </b>
              {calls[0].confirmed ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                <CloseCircleTwoTone twoToneColor="#faad14" />
              )}
            </p>
            <Button danger ghost onClick={() => deleteCall(calls[0].id)}>
              Cancel call
            </Button>
          </div>
        )}
        {(!calls || (calls && !calls[0])) && (
          <Button
            type="primary"
            loading={loading}
            disabled={loading || disabled}
            onClick={() => setScheduleModalOpen(true)}
          >
            Schedule{' '}
            {type === 'courseHearing' ? 'Course Hearing' : 'Onboarding Call'}
          </Button>
        )}
      </div>
    );
  }

  return (
    <div>
      {widgetBody}
      <ScheduleModal
        courseId={courseId}
        visible={scheduleModalOpen}
        onSubmit={() => handleSubmit()}
        onCancel={() => setScheduleModalOpen(false)}
      />
    </div>
  );
};

export default connect(() => ({}), { getCurrentUser })(ScheduleWidget);
