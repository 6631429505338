import React, { useEffect } from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import './Home.less';
import { isUserInstructor } from '@helpers';
import { joinVideoCall } from '@redux/reducers/videoCall';
import { getChats } from '@redux/reducers/chat';
import { getScheduledCalls } from '@redux/reducers/user';
import StudentHome from './StudentHome';
import InstructorHome from './InstructorHome';

interface HomeProps {
  videoCalls: VideoCall[];
  user?: User;
  chats?: Chat[];
  isLoading: boolean;
  getChats: typeof getChats;
  getScheduledCalls: typeof getScheduledCalls;
  joinVideoCall: typeof joinVideoCall;
  scheduledCalls: ScheduledCall[];
}

const Home: React.FC<HomeProps> = ({
  user,
  videoCalls,
  chats,
  isLoading,
  getChats,
  scheduledCalls,
  getScheduledCalls,
  joinVideoCall,
}) => {
  useEffect(() => {
    // const script = document.createElement('script');
    // script.src = 'https://js.stripe.com/v3/';
    // script.async = true;
    // document.body.appendChild(script);
    if (!chats && user && !isUserInstructor(user)) {
      getChats();
    }
  }, [chats, getChats, user]);

  if (!user) {
    return null;
  }

  if (isUserInstructor(user)) {
    return (
      <InstructorHome
        user={user}
        videoCalls={videoCalls}
        getScheduledCalls={getScheduledCalls}
        scheduledCalls={scheduledCalls}
        joinVideoCall={joinVideoCall}
      />
    );
  }

  return (
    <StudentHome
      user={user}
      scheduledCalls={scheduledCalls}
      chats={chats}
      isLoading={isLoading}
      joinVideoCall={joinVideoCall}
    />
  );
};

const selectHomeState = (state: AppState) => ({
  user: state.user.user,
  scheduledCalls: state.user.scheduledCalls,
  videoCalls: state.videoCall.videoCalls,
  chats: state.chat.chats,
  isLoading: state.chat.isLoading,
});

const ConnectedHome = connect(selectHomeState, {
  getChats,
  getScheduledCalls,
  joinVideoCall,
})(Home);

const Page = () => <ConnectedHome />;

export default Page;
