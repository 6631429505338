import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import DegreeForm from '@components/degreeForm/DegreeForm';

interface EditDegreeProps {
  user?: User;
}

const EditDegree: React.FC<EditDegreeProps> = ({ user }) => {
  if (!user) {
    return null;
  }

  return <DegreeForm degree={{ id: -1 }} />;
};

const selectEditDegreeState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedEditDegree = connect(selectEditDegreeState)(EditDegree);

const Page = () => <ConnectedEditDegree />;

export default Page;
