import ApiService from './apiService';

const LessonService = {
  search: async (
    searchText: string,
    page = 1,
    excludeIds?: Number[],
  ): Promise<Lesson[]> => {
    const response = await ApiService.get('/lessons', {
      searchText,
      page,
      excludeIds,
    });
    return response.data;
  },

  getOne: async (id: number): Promise<Lesson> => {
    const response = await ApiService.get(`/lessons/${id}`);
    return response.data;
  },

  create: async (data: Lesson): Promise<Lesson> => {
    const response = await ApiService.post(`/lessons`, data);
    return response.data;
  },

  update: async (id: number, data: Lesson): Promise<Lesson> => {
    const response = await ApiService.put(`/lessons/${id}`, data);
    return response.data;
  },
};

export default LessonService;
