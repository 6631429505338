import ApiService from './apiService';

const ObjectiveService = {
  search: async (
    searchText: string,
    page = 1,
    excludeIds?: Number[],
  ): Promise<Objective[]> => {
    const response = await ApiService.get('/objectives', {
      searchText,
      page,
      excludeIds,
    });
    return response.data;
  },

  getOne: async (id: number): Promise<Objective> => {
    const response = await ApiService.get(`/objectives/${id}`);
    return response.data;
  },

  create: async (data: Objective): Promise<Objective> => {
    const response = await ApiService.post(`/objectives`, data);
    return response.data;
  },

  update: async (id: number, data: Objective): Promise<Objective> => {
    const response = await ApiService.put(`/objectives/${id}`, data);
    return response.data;
  },
};

export default ObjectiveService;
