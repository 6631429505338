import React, { useState, useEffect } from 'react';
import Spinner from '@components/Spinner';
import UserService from '@services/userService';

interface TParams {
  id?: number;
}

export default function withMediaReview(WrappedComponent: any) {
  return () => {
    const [loading, setLoading] = useState(false);
    const [mediaFeedback, setMediaFeedback] = useState<User[]>();

    useEffect(() => {
      if (!loading && !mediaFeedback) {
        setLoading(true);
        UserService.getMediaReview().then(d => {
          setMediaFeedback(d);
          setLoading(false);
        });
      }
    }, [mediaFeedback, loading]);

    if (loading) {
      return <Spinner />;
    }

    if (!mediaFeedback) {
      return null;
    }

    return <WrappedComponent mediaFeedback={mediaFeedback} />;
  };
}
