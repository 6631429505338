import * as React from 'react';
import { MessageOutlined } from '@ant-design/icons';
import { Dropdown, Badge } from 'antd';
import { connect } from 'react-redux';
import { AppState } from '@redux/reducers';
import {
  createChat,
  getChats,
  sendMessage,
  selectChat,
  getChat,
} from '@redux/reducers/chat';
import ChatList from './ChatList';
import _ from 'lodash';

export interface IChatDropdownProps {
  isLoading: boolean;
  getChats: typeof getChats;
  createChat: typeof createChat;
  sendMessage: typeof sendMessage;
  selectChat: typeof selectChat;
  getChat: typeof getChat;
  user?: User;
  chats?: Chat[];
  selectedChat?: Chat;
}

const ChatDropdown = ({
  user,
  chats,
  selectedChat,
  isLoading,
  getChats,
  getChat,
  createChat,
  sendMessage,
  selectChat,
}: IChatDropdownProps) => {
  // React.useEffect(() => {
  //   if (!chats && !isLoading) {
  //     fetchChat();
  //   }
  // });

  if (!user) {
    return null;
  }

  return (
    <Dropdown overlay={<ChatList chats={chats} user={user} />}>
      <a
        className="ant-dropdown-link"
        style={{ marginRight: '10px' }}
        onClick={e => e.preventDefault()}
      >
        <MessageOutlined style={{ fontSize: '20px' }} />

        {/* {newChat ? (
          <Badge dot>
            <Icon type="messages" />
          </Badge>
        ) : (
          <Icon type="messages" />
        )} */}
      </a>
    </Dropdown>
  );
};

const selectChatState = (state: AppState) => {
  return {
    user: state.user.user,
    chats: state.chat.chats,
    selectedChat: state.chat.selectedChat,
    isLoading: state.chat.isLoading,
  };
};

export default connect(selectChatState, {
  createChat,
  getChats,
  sendMessage,
  selectChat,
  getChat,
})(ChatDropdown);
