import React from 'react';
import _ from 'lodash';
import { Avatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import DegreeService from '@services/degreeService';

const extensionsMap = {
  jpg: 'image',
  png: 'image',
  pdf: 'pdf',
  doc: 'word',
  docx: 'word',
  xls: 'excel',
  xlsx: 'excel',
};

export const isUserEnrolled = (
  itemType: 'course' | 'degree',
  itemId: number,
  user: User,
) => {
  if (itemType === 'course') {
    return _.findIndex(user.userCourses, { courseId: itemId }) !== -1;
  }

  return (
    _.findIndex(
      user.enrolledDegrees,
      userDegree => userDegree.degreeId === itemId,
    ) !== -1
  );
};

export const isObjectiveCompleted = (id: number, user: User) =>
  _.findIndex(user.completedObjectives, { id }) !== -1;

export const getDegreeObjectives = (courses: Course[]) => {
  const degreeObjectives: Objective[] = [];

  courses.forEach(course => {
    course.lessons &&
      course.lessons.forEach((lesson: any) => {
        lesson.objectives &&
          lesson.objectives.forEach((o: any) => degreeObjectives.push(o));
      });
  });

  return degreeObjectives;
};

export const getCourseObjectives = (course: Course) => {
  const courseObjectives: Objective[] = [];

  course.lessons &&
    course.lessons.forEach(lesson => {
      lesson.objectives &&
        lesson.objectives.forEach(o =>
          courseObjectives.push({ ...o, lessonId: lesson.id }),
        );
    });

  return courseObjectives;
};

export const getLessonObjectives = (lesson: Lesson) => {
  const courseObjectives: Objective[] = [];

  lesson.objectives &&
    lesson.objectives.forEach(o =>
      courseObjectives.push({ ...o, lessonId: lesson.id }),
    );

  return courseObjectives;
};

export const getDegreeCompletedPercentage = async (
  degreeId: number,
  user: User,
): Promise<number> => {
  const degreeContent = await DegreeService.getDegreeContent(degreeId);
  const degreeObjectives = getDegreeObjectives(degreeContent);
  return getCompletedPercentage(degreeObjectives, user);
};

export const getCourseCompletedPercentage = (course: Course, user: User) => {
  const courseObjectives = getCourseObjectives(course);
  return getCompletedPercentage(courseObjectives, user);
};

export const getLessonCompletedPercentage = (lesson: Lesson, user: User) => {
  const lessonObjectives = getLessonObjectives(lesson);
  return getCompletedPercentage(lessonObjectives, user);
};

export const getCompletedPercentage = (objectives: Objective[], user: User) => {
  if (!objectives) {
    return 0;
  }

  const completed = objectives.map(
    objective =>
      _.findIndex(user.completedObjectives, { id: objective.id }) !== -1,
  );

  const n = _.round(
    (completed.filter(o => o).length / objectives.length) * 100,
    2,
  );

  return n;
};

export const getCourseObjectives11 = (course: Course) => {
  const courseObjectives: Objective[] = [];

  course.lessons &&
    course.lessons.forEach(lesson => {
      lesson.objectives &&
        lesson.objectives.forEach(o =>
          courseObjectives.push({ ...o, lessonId: lesson.id }),
        );
    });

  return courseObjectives;
};

export const getCourseCompletedPercentage11 = (course: Course, user: User) => {
  const courseObjectives = getCourseObjectives11(course);
  return getCompletedPercentage11(courseObjectives, user);
};

export const getCompletedPercentage11 = (
  objectives: Objective[],
  user: User,
) => {
  if (!objectives) {
    return 0;
  }

  const completed = objectives.map(
    objective =>
      _.findIndex(user.completedObjectives, { objectiveId: objective.id }) !==
      -1,
  );

  const n = _.round(
    (completed.filter(o => o).length / objectives.length) * 100,
    0,
  );

  return n;
};

export const isCourseExamPassed = (quizzes: Quiz[]) =>
  !!quizzes.find(q => q.score === 100);

export const isCoursePassed = (courseId: number, userCourses: UserCourse[]) => {
  if (!userCourses) return false;
  const userCourse = getUserCourse(courseId, userCourses);
  return userCourse && userCourse.passed;
};

export const getUserCourse = (courseId: number, userCourses: UserCourse[]) =>
  userCourses.find(uc => uc.courseId === courseId);

export const getUserReadingFiles = (user: User, readingId: number) =>
  user.userReadings
    ? user.userReadings.filter(up => up.readingId === readingId)
    : [];

export const isYoutube = (url: string) =>
  url.match('^(http(s)?://)?((w){3}.)?youtu(be|.be)?(.com)?/.+');

export const isScreencast = (url: string) =>
  url.match('^(http(s)?://)?((w){3}.)?screencast?(.com)?/.+');

export const isGoogleDocs = (url: string) =>
  url.match('^(http(s)?://)?((w){3}.)?docs.google?(.com)?/.+');

export const getFileType = (url: string) => {
  const extension = getUrlExtension(url);

  if (isYoutube(url)) {
    return 'youtube';
  }

  if (isScreencast(url)) {
    return 'screencast';
  }

  if (extension) {
    const fileType = extensionsMap[extension as keyof typeof extensionsMap];

    if (fileType) {
      return fileType;
    }
  }

  return 'unknown';
};

export const getUrlExtension = (url: string) => {
  const urlSplit = url.split(/\#|\?/)[0];

  if (urlSplit) {
    const extension = urlSplit.split('.').pop();

    if (extension) {
      return extension.trim();
    }
  }

  return undefined;
};

export const getUserAvatar = (
  user: User,
  size: AvatarProps['size'] = 'default',
) => (
  <Avatar
    size={size}
    src={
      user.avatar
        ? user.avatar.url
        : `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`
    }
    alt={`${user.firstName} ${user.lastName}`}
  />
);

export const isUserOnboarded = (onboarding: Onboarding) => {
  const {
    confirmedAge,
    confirmedSecondarySchoolCompletion,
    signedStudentAgreement,
    signedTermsOfUse,
    watchedVideo,
    completed,
    scheduledCall,
  } = onboarding;
  return (
    confirmedAge &&
    confirmedSecondarySchoolCompletion &&
    // signedStudentAgreement &&
    // signedTermsOfUse &&
    watchedVideo
    // scheduledCall &&
    // !scheduledCall.rejected
    // completed
  );
};

export const hasUnsignedAgreements = (userAgreements: UserAgreement[]) =>
  !!userAgreements.find(ua => !ua.signedAt);

export const getUnsignedAgreements = (
  agreements: Agreement[],
  userAgreements: UserAgreement[],
) =>
  agreements.filter(a => {
    const userAgreement = userAgreements.find(ua => ua.agreementId === a.id);

    if (!userAgreement || !userAgreement.signedAt) {
      return true;
    }

    return false;
  });

export const getSumByKey = (arr: any, key: any) =>
  arr.reduce(
    (accumulator: any, current: any) =>
      accumulator + Number(current[key] != '' ? current[key] : 0),
    0,
  );
