import ApiService from './apiService';

export interface ISearchData {
  degrees: Degree[];
  courses: Course[];
  lessons: Lesson[];
  objectives: Objective[];
}

const SearchService = {
  search: async (searchText: string, limit = 5): Promise<ISearchData> => {
    const response = await ApiService.get('/search', {
      searchText,
      limit,
    });
    return response.data;
  },
};

export default SearchService;
