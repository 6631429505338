import React, { useEffect } from 'react';
import { Button, Card } from 'antd';
import { useGetAffiliateData } from '@hooks/useGetAffiliateData';
import ApiService from '@services/apiService';

export const AffiliateProgramPage = () => {
  const { data, loading, fetchData } = useGetAffiliateData();
  const createAffiliateAccount = async () => {
    await ApiService.post('/affiliates', {});
    fetchData();
  };

  return (
    <Card title="Affiliate program" loading={loading}>
      {!data ? (
        <div style={{ textAlign: 'center' }}>
          <h4>
            Become an affiliate and earn $100 per user that subscribes through
            your link
          </h4>
          <Button type="primary" onClick={createAffiliateAccount}>
            Create affiliate account
          </Button>
        </div>
      ) : null}
      {data && (
        <div>
          <h3>Your affilate url:</h3>
          <h2 className="text-success">{data.affiliate_url}</h2>

          <p>Total leads: {data.total_leads}</p>
          <p>Total purchases: {data.total_purchases}</p>

          <div style={{ marginTop: '16px' }}>
            <a href={data.affiliate_dashboard_url} target="_blank">
              Check your affiliate dashboard
            </a>
          </div>
        </div>
      )}
    </Card>
  );
};
