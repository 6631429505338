import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';

import ObjectiveForm from '@components/objectives/ObjectiveForm';

interface CreateObjectiveProps {
  user?: User;
}

const CreateObjective: React.FC<CreateObjectiveProps> = ({ user }) => {
  if (!user) {
    return null;
  }

  return <ObjectiveForm objective={{ id: -1 }} />;
};

const selectCreateObjectiveState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedCreateObjective = connect(selectCreateObjectiveState)(
  CreateObjective,
);

const Page = () => <ConnectedCreateObjective />;

export default Page;
