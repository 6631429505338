import React, { useState, useEffect } from 'react';
import { Alert, Spin } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { connect } from 'react-redux';
import { confirmEmail } from '@redux/reducers/auth';
import { AppState } from '@redux/reducers';
import { useQuery } from '@utils/routerHooks';
import { useHistory } from 'react-router';

interface StateProps {
  message?: string;
  errors?: ApiError['errors'];
  isLoading: boolean;
}

interface DispatchProps {
  confirmEmail: typeof confirmEmail;
}

interface ConfirmEmailQuery {
  token: string;
}

type ConfirmEmailProps = StateProps & DispatchProps;

const ConfirmEmail: React.FC<ConfirmEmailProps> = ({
  confirmEmail,
  message,
  errors,
  isLoading,
}) => {
  const history = useHistory();
  const query = useQuery<any>();

  useEffect(() => {
    if (query && query.token) {
      confirmEmail({ token: query.token });
    }
  }, [confirmEmail, query, query.token]);

  if (message && !isLoading && query.passwordResetToken) {
    history.push(`/reset-password?token=${query.passwordResetToken}`);
  }

  return (
    <section className="form-v1-container">
      <h2>Confirming your email...</h2>
      <div style={{ textAlign: 'center' }}>{isLoading && <Spin />}</div>
      {message && (
        <div>
          <Alert message={message} type="success" />
          <p className="additional-info">
            <a href="/login">Login</a>
          </p>
        </div>
      )}
      {errors &&
        errors.map(error => (
          <Alert key={error.key} message={error.message} type="error" />
        ))}
    </section>
  );
};

const selectConfirmEmailState = (state: AppState) => {
  return {
    message: state.auth.message,
    errors: state.auth.errors,
    isLoading: state.auth.isLoading,
  };
};

const ConnectedConfirmEmail = connect(selectConfirmEmailState, {
  confirmEmail,
})(ConfirmEmail);

const Cover = () => (
  <div className="login">
    <section className="cover cover-page">
      <div className="cover-form-container">
        <div className="cover-form-wrap z-index">
          <ConnectedConfirmEmail />
        </div>
      </div>
      <div className="cover-bg-img" />
    </section>
  </div>
);

const Page = () => (
  <QueueAnim type="bottom" className="ui-animate">
    <div key="1">
      <Cover />
    </div>
  </QueueAnim>
);

export default Page;
