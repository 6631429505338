import ApiService from './apiService';

const AnnouncementService = {
  getOne: async (id: number): Promise<Announcement> => {
    const response = await ApiService.get(`/announcements/${id}`);
    return response.data;
  },

  getAll: async (courseId?: number): Promise<Announcement[]> => {
    const response = await ApiService.get(`/announcements`, { courseId });
    return response.data;
  },

  create: async (data: Partial<Announcement>): Promise<Announcement> => {
    const response = await ApiService.post(`/announcements`, data);
    return response.data;
  },

  update: async (id: number, data: Announcement): Promise<Announcement> => {
    const response = await ApiService.put(`/announcements/${id}`, data);
    return response.data;
  },

  delete: async (id: number): Promise<{ success: true }> => {
    const response = await ApiService.delete(`/announcements/${id}`);
    return response.data;
  },

  getCourses: async (): Promise<Course[]> => {
    const response = await ApiService.get(`/announcements/courses`);
    return response.data;
  },
};

export default AnnouncementService;
