export enum Role {
  Admin = 'admin',
  Instructor = 'instructor',
  Student = 'student',
}

export enum VideoCallType {
  OnboardingCall = 'onboarding',
  CourseCall = 'course',
}

export type SubscriptionStatus = 'trialing' | 'active' | 'canceled' | 'past_due';

export enum UserProjectStatus {
  Pending = 'pending',
  Approved = 'approved',
  NotYet = 'notYet',
}
