import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '@redux/reducers';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useQuery } from '@apollo/client';
import Spinner from '@components/Spinner';
import { discussionsQuery } from './queries';
import NewDiscussionForm from './NewDiscussion';
import DiscussionComponent from './Discussion';

type SerializeResponse = (data: any) => Discussion[];
const serializeResponse: SerializeResponse = data => {
  const discussions = data.discussions.nodes.map(
    (discussion: any) =>
      ({
        ...discussion,
        replies: discussion.replies.nodes,
      } as Discussion),
  );

  return discussions as Discussion[];
};

interface DiscussionsProps {
  user?: User;
  degreeId?: number;
  courseId?: number;
}

const DiscussionsComponent: React.FC<DiscussionsProps> = ({
  user,
  degreeId,
  courseId,
}) => {
  const [newDiscussion, setNewDiscussion] = useState<Partial<Discussion>>();
  const query = courseId
    ? discussionsQuery(degreeId, courseId)
    : discussionsQuery(degreeId);
  const { loading, error, data } = useQuery(query);

  if (loading || !data) {
    return <Spinner />;
  }

  if (!user) {
    return null;
  }

  const discussions = serializeResponse(data);

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button
          size="small"
          type="ghost"
          icon={<PlusOutlined />}
          onClick={() => setNewDiscussion({})}
        >
          New discussion
        </Button>
      </div>
      {newDiscussion && (
        <NewDiscussionForm
          degreeId={degreeId}
          courseId={courseId}
          user={user}
          onCreate={() => setNewDiscussion(undefined)}
        />
      )}
      {!discussions.length && (
        <p style={{ textAlign: 'center', marginTop: 16 }}>No discussions.</p>
      )}
      {discussions.length > 0 && (
        <div>
          {discussions.map(discussion => (
            <DiscussionComponent
              discussion={discussion}
              user={user}
              query={query}
            />
          ))}
        </div>
      )}
    </>
  );
};

const selectState = (state: AppState) => ({
  user: state.user.user,
});

const Discussions = connect(selectState, {})(DiscussionsComponent);

export default Discussions;
