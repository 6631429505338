import { useCallback, useEffect, useState } from "react";
import ApiService from "@services/apiService";

export interface AffiliateData {
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  affiliate_url: string,
  affiliate_dashboard_url: string,
  total_leads: string,
  total_visitors: string,
  total_purchases: string,
}

export const useGetAffiliateData = () => {
  const [data, setData] = useState<AffiliateData>();
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(() => ApiService.get('/affiliates/current-user').then((response) => {
    setData(response.data);
    setLoading(false);
  }), []);

  useEffect(() => {
    setLoading(true);
    fetchData()
  }, [fetchData]);

  return {data, loading, fetchData};
}