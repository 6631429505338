import { takeLatest, put } from 'redux-saga/effects';

import ApiService from '@services/apiService';

import { PayloadAction } from '@reduxjs/toolkit';
import { fetchNotificationsSuccess } from '../reducers/notifications';

function* fetchNotifications(): Generator<any, any, any> {
  try {
    const response = yield ApiService.get(
      `/users/current-user/notifications`,
    ) as Promise<unknown>;

    const json = response.data as ApiNotification[];

    yield put(fetchNotificationsSuccess(json));
  } catch (err) {
    console.error(err);
    // yield put(startError(err));
  }
}

function* updateNotification({
  payload: notificationId,
}: PayloadAction<number>): Generator<any, any, any> {
  try {
    const response = yield ApiService.put(
      `/users/current-user/notifications/${notificationId}`,
      {},
    ) as Promise<unknown>;

    const json = response.data as ApiNotification[];

    yield put(fetchNotificationsSuccess(json));
  } catch (err) {
    console.error(err);
    // yield put(startError(err));
  }
}

function* watchForRequestData() {
  yield takeLatest('notifications/fetchNotifications', fetchNotifications);
  yield takeLatest('notifications/updateNotification', updateNotification);
}

export default watchForRequestData;
