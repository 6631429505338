import { takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import ApiService from '@services/apiService';
import {
  enrollDegreeSuccess,
  enrollDegreeFail,
  getCurrentUserSuccess,
  getQuizSuccess,
  getQuizzesSuccess,
  getSubmitQuizSuccess,
  getBillingInfoSuccess,
  getScheduledCallsSuccess,
} from '../reducers/user';

import { getCurrentUserFail } from '../reducers/auth';

function* enrollDegree({
  payload: degreeId,
}: PayloadAction<number>): Generator<any, any, any> {
  try {
    const response = yield ApiService.post(
      `/degrees/${degreeId}/enroll`,
      {},
    ) as Promise<unknown>;

    const json = response.data;

    yield put(enrollDegreeSuccess(json));
  } catch (err: any) {
    // console.error(err);
    yield put(enrollDegreeFail(err.response.data));
  }
}

function* getCurrentUser({
  payload: data,
}: PayloadAction<any>): Generator<any, any, any> {
  const token = localStorage.getItem('accessToken');
  if (token) {
    try {
      const response = yield ApiService.get(
        '/users/current-user',
        data,
      ) as Promise<unknown>;

      const json = response.data;
      localStorage.setItem('loginUserRole', json.role);
      yield put(getCurrentUserSuccess(json));
    } catch (err) {
      // console.error(err);
      yield put(getCurrentUserFail());
    }
  } else {
    yield put(getCurrentUserFail());
  }
}

function* getQuiz({
  payload: courseId,
}: PayloadAction<any>): Generator<any, any, any> {
  try {
    const response = yield ApiService.get(
      `/courses/${courseId}/quiz`,
    ) as Promise<unknown>;

    const json = response.data;

    yield put(getQuizSuccess(json));
  } catch (err) {
    // console.error(err);
    // yield put(getQuizFail());
  }
}

function* getQuizzes({
  payload: courseId,
}: PayloadAction<Quiz[]>): Generator<any, any, any> {
  try {
    const response = yield ApiService.get(
      `/users/current-user/quizzes/${courseId}`,
    ) as Promise<unknown>;

    const json = response.data;

    yield put(getQuizzesSuccess(json));
  } catch (err) {
    // console.error(err);
    // yield put(getQuizFail());
  }
}

function* markCompleted({
  payload: objectiveId,
}: PayloadAction<number>): Generator<any, any, any> {
  try {
    const response = yield ApiService.post(
      `/users/complete-objective/${objectiveId}`,
      {},
    ) as Promise<unknown>;

    const json = response.data as User;

    yield put(getCurrentUserSuccess(json));
  } catch (err) {
    console.error(err);
    // yield put(getQuizFail());
  }
}

function* objectiveMedia({
  payload: data,
}: PayloadAction<any>): Generator<any, any, any> {
  try {
    // console.log('data API', data);
    const response = yield ApiService.post(
      `/users/objective-media-feedback/${data.objectiveId}`,
      data,
    ) as Promise<unknown>;

    const json = response.data as User;
    yield put(getCurrentUserSuccess(json));
  } catch (err) {
    console.error(err);
  }
}
function* submitQuiz({
  payload,
}: PayloadAction<any>): Generator<any, any, any> {
  try {
    const response = yield ApiService.put(
      `/quizzes/${payload.quizId}`,
      payload,
    ) as Promise<Quiz>;

    yield put(getSubmitQuizSuccess(response.data));
  } catch (err) {
    console.error(err);
    // yield put(getQuizFail());
  }
}

function* getBillingInfo(): Generator<any, any, any> {
  try {
    const response = yield ApiService.get(
      `/users/current-user/billing-info`,
    ) as Promise<unknown>;

    const json = response.data;

    yield put(getBillingInfoSuccess(json));
  } catch (err) {
    console.error(err);
    // yield put(getQuizFail());
  }
}

function* getScheduledCalls(): Generator<any, any, any> {
  try {
    const response = yield ApiService.get(
      `/users/current-user/scheduled-calls`,
    ) as Promise<ScheduledCall[]>;

    const json = response.data;

    yield put(getScheduledCallsSuccess(json));
  } catch (err) {
    console.error(err);
    // yield put(getQuizFail());
  }
}

function* watchForRequestData() {
  yield takeLatest('user/enrollDegree', enrollDegree);
  yield takeLatest('user/getCurrentUser', getCurrentUser);
  yield takeLatest('user/getQuiz', getQuiz);
  yield takeLatest('user/markCompleted', markCompleted);
  yield takeLatest('user/objectiveMedia', objectiveMedia);
  yield takeLatest('user/getQuizzes', getQuizzes);
  yield takeLatest('user/submitQuiz', submitQuiz);
  yield takeLatest('user/getBillingInfo', getBillingInfo);
  yield takeLatest('user/getScheduledCalls', getScheduledCalls);
}

export default watchForRequestData;
