import { takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import ApiService from '@services/apiService';
import {
  signupSuccess,
  signupFail,
  confirmEmailSuccess,
  confirmEmailFail,
  loginSuccess,
  loginFail,
  getCurrentUserSuccess,
  getCurrentUserFail,
} from '../reducers/auth';

function* signup({
  payload: data,
}: PayloadAction<any>): Generator<any, any, any> {
  try {
    const response = yield ApiService.post(
      '/auth/signup',
      data,
    ) as Promise<unknown>;

    const json = response.data;

    yield put(signupSuccess(json));
  } catch (err: any) {
    console.error(err);
    yield put(signupFail(err.data));
  }
}

function* confirmEmail({
  payload: data,
}: PayloadAction<any>): Generator<any, any, any> {
  try {
    const response = yield ApiService.post(
      '/auth/confirm-email',
      data,
    ) as Promise<unknown>;

    const json = response.data;

    yield put(confirmEmailSuccess(json));
  } catch (err: any) {
    console.error(err);
    yield put(confirmEmailFail(err.response));
  }
}

function* login({
  payload: data,
}: PayloadAction<any>): Generator<any, any, any> {
  try {
    const response = yield ApiService.post(
      '/auth/login',
      data,
    ) as Promise<unknown>;

    const json = response.data;

    yield put(loginSuccess(json));
  } catch (err: any) {
    console.error(err);
    yield put(loginFail(err.data));
  }
}

function* getCurrentUser({
  payload: data,
}: PayloadAction<any>): Generator<any, any, any> {
  const token = localStorage.getItem('accessToken');
  if (token) {
    try {
      const response = yield ApiService.get(
        '/users/current-user',
        data,
      ) as Promise<unknown>;

      const json = response.data;
      yield put(getCurrentUserSuccess({ ...json }));
    } catch (err) {
      console.error(err);
      yield put(getCurrentUserFail());
    }
  } else {
    yield put(getCurrentUserFail());
  }
}

function* watchForRequestData() {
  yield takeLatest('auth/signup', signup);
  yield takeLatest('auth/login', login);
  yield takeLatest('auth/confirmEmail', confirmEmail);
  yield takeLatest('auth/getCurrentUser', getCurrentUser);
}

export default watchForRequestData;
