import ApiService from './apiService';

const UserNoteService = {
  getAllForObjective: async (objectiveId: number): Promise<UserNote> => {
    const response = await ApiService.get(`/user-notes/${objectiveId}`);
    return response.data;
  },

  create: async (data: UserNote): Promise<UserNote> => {
    const response = await ApiService.post(`/user-notes`, data);
    return response.data;
  },

  update: async (id: number, data: UserNote): Promise<UserNote> => {
    const response = await ApiService.put(`/user-notes/${id}`, data);
    return response.data;
  },
  
  remove: async (id: number): Promise<UserNote> => {
    //console.log('Remove user note', id);
    const response = await ApiService.delete(`/user-notes/${id}`);
    //console.log('Remove user note res', response);
    return response.data;
  },

  getAllForLesson: async (lessonId: number): Promise<UserNote> => {
    const response = await ApiService.get(`/user-notes/lesson/${lessonId}`);
    return response.data;
  },

  getAllForCourse: async (course: number): Promise<UserNote> => {
    const response = await ApiService.get(`/user-notes/course/${course}`);
    return response.data;
  },
};

export default UserNoteService;
