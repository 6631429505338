import { Reducer, combineReducers } from 'redux';

import { default as authReducer, AuthState } from './auth';
import { default as userReducer, UserState } from './user';
import { default as videoCallReducer, VideoCallState } from './videoCall';
import { default as chatReducer, ChatState } from './chat';
import {
  default as notificationReducer,
  NotificationsState,
} from './notifications';
import { default as userNotesReducer, UserNoteState } from './userNotes';

export interface AppState {
  auth: AuthState;
  user: UserState;
  videoCall: VideoCallState;
  chat: ChatState;
  notifications: NotificationsState;
  userNotes: UserNoteState;
}

const rootReducer: Reducer<AppState> = combineReducers({
  auth: authReducer,
  user: userReducer,
  videoCall: videoCallReducer,
  chat: chatReducer,
  notifications: notificationReducer,
  userNotes: userNotesReducer,
});

export default rootReducer;
