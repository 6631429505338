import ApiService from './apiService';

const AgreementService = {
  getAll: async (): Promise<Agreement[]> => {
    const response = await ApiService.get(`/agreements`);
    return response.data;
  },

  getOne: async (type: Agreement['type']): Promise<Agreement> => {
    const response = await ApiService.get(`/agreements/${type}`);
    return response.data;
  },

  update: async (data: Partial<Agreement>): Promise<Agreement> => {
    const response = await ApiService.put(`/agreements/${data.type}`, data);
    return response.data;
  },
};

export default AgreementService;
