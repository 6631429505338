import React, { useState, useEffect } from 'react';
import { useRouter } from '@utils/routerHooks';
import Spinner from '@components/Spinner';
import UserService from '@services/userService';

interface TParams {
  id?: number;
}

export default function withStudent(WrappedComponent: any) {
  return () => {
    const { match, history } = useRouter<TParams>();
    const [loading, setLoading] = useState(false);
    const [student, setStudent] = useState<User>();

    const id = match.params.id || 0;

    const fetchStudent = () => {
      setLoading(true);
      /*UserService.getOne(id).then((c: any) => {
        setStudent(c);
        setLoading(false);
      });*/
      UserService.getOneStudent(id).then((c: any) => {
        setStudent(c);
        setLoading(false);
      });
    };

    useEffect(() => {
      if (!loading && !student) {
        fetchStudent();
      }
    }, [student, id, loading]);

    if (!id) {
      history.push('/students');
    }

    if (loading) {
      return <Spinner />;
    }

    if (!student) {
      return null;
    }

    return <WrappedComponent student={student} reloadStudent={fetchStudent} />;
  };
}
