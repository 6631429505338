import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useQuery, gql } from '@apollo/client';
import { Breadcrumb, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

const query = (
  degreeId?: string,
  courseId?: string,
  lessonId?: string,
  objectiveId?: string,
) => gql`
  {
    ${
      degreeId
        ? `degree(id: ${degreeId}) {
      id
      name
    }`
        : `
        degree {
          id
          name
        }`
    }

    ${
      courseId
        ? `course(id: ${courseId}) {
      id
      name
    }`
        : ''
    }

    ${
      lessonId
        ? `lesson(id: ${lessonId}) {
      id
      name
    }`
        : ''
    }

    ${
      objectiveId
        ? `objective(id: ${objectiveId}) {
      id
      name
    }`
        : ''
    }
  }
`;

export interface ParsedPath {
  degreeId?: string;
  courseId?: string;
  lessonId?: string;
  objectiveId?: string;
}

export const parsePath = (path: string): ParsedPath => {
  let objectiveId;
  let lessonId;
  let courseId;
  let degreeId;

  if (
    path.indexOf('/students') === -1 &&
    path.indexOf('/edit') === -1 &&
    path.indexOf('/create') === -1
  ) {
    let paths = path.split('/objectives/');

    if (paths[1]) {
      objectiveId = paths[1];
    }

    paths = paths[0].split('/lessons/');

    if (paths[1]) {
      lessonId = paths[1];
    }

    paths = paths[0].split('/lessons');

    if (paths[1] && paths[1] !== '-overview') {
      lessonId = paths[1];
    }

    paths = paths[0].split('/courses/');

    if (paths[1]) {
      courseId = paths[1];
    }

    paths = paths[0].split('/degrees/');

    if (paths[1]) {
      degreeId = paths[1];
    }
  }

  return {
    degreeId,
    courseId,
    lessonId,
    objectiveId,
  };
};

const Breadcrumbs = () => {
  const location = useLocation();

  const { degreeId, courseId, lessonId, objectiveId } = parsePath(
    location.pathname,
  );

  const gqlQuery = query(degreeId, courseId, lessonId, objectiveId);

  const { loading, error, data } = useQuery(gqlQuery);

  if (!degreeId) {
    return null;
  }

  if (!data) {
    return null;
  }

  const { degree, course, lesson, objective } = data;

  return (
    <Row>
      <Col span={24} style={{ marginBottom: '16px' }}>
        <Breadcrumb>
          {degree && course && (
            <Breadcrumb.Item>
              <Link to={`/degrees/${degree.id}`}>{degree.name}</Link>
            </Breadcrumb.Item>
          )}

          {degree && course && (
            <Breadcrumb.Item>
              <Link to={`/degrees/${degree.id}/courses/${course.id}`}>
                {course.name}
              </Link>
            </Breadcrumb.Item>
          )}

          {degree && course && lesson && (
            <Breadcrumb.Item>
              <Link
                to={`/degrees/${degree.id}/courses/${course.id}/lessons/${lesson.id}`}
              >
                {lesson.name}
              </Link>
            </Breadcrumb.Item>
          )}

          {degree && course && lesson && objective && (
            <Breadcrumb.Item>
              <Link
                to={`/degrees/${degree.id}/courses/${course.id}/lessons/${lesson.id}/objectives/${objective.ids}`}
              >
                {objective.name}
              </Link>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </Col>
    </Row>
  );
};

export default Breadcrumbs;
