import React, { useState, useEffect } from 'react';
import { useRouter } from '@utils/routerHooks';
import Spinner from '@components/Spinner';
import CourseService from '@services/courseService';

interface TParams {
  id?: number;
}

export default function withCourse(WrappedComponent: any) {
  return () => {
    const { match, history } = useRouter<TParams>();
    const [loading, setLoading] = useState(false);
    const [course, setCourse] = useState<Course>();

    const id = match.params.id || 0;

    useEffect(() => {
      if (
        !loading &&
        (!course || (course && course.id !== Number(match.params.id)))
      ) {
        setLoading(true);
        CourseService.getOne(id).then((c: any) => {
          setCourse(c);
          setLoading(false);
        });
      }
    }, [course, id, loading, match.params.id]);

    if (!id) {
      history.push('/courses');
    }

    if (loading) {
      return <Spinner />;
    }

    if (!course) {
      return null;
    }

    return <WrappedComponent course={course} />;
  };
}
