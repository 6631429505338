import React, { useState } from 'react';
import { DocumentNode, useMutation } from '@apollo/client';
import { Alert, Input, Button, Avatar, Comment, Form } from 'antd';
import { CREATE_REPLY } from './queries';

const NewReplyForm: React.FC<{
  refetchQuery: string | DocumentNode;
  discussionId: number;
  user: User;
  onCreate: () => void;
}> = ({ user, discussionId, refetchQuery, onCreate }) => {
  const [text, setText] = useState<string>();
  const [error, setError] = useState<string>();
  const [createReply, { data, loading }] = useMutation(CREATE_REPLY);

  const handleCreateReply = () => {
    if (!text) {
      return setError('Please fill out all the fields.');
    }
    createReply({
      variables: { text, discussionId, createdById: user.id },
      refetchQueries: [
        {
          query: refetchQuery as any,
        },
      ],
    }).then(() => {
      setText(undefined);
      onCreate();
    });
  };

  return (
    <div style={{ margin: '16px 0' }}>
      <Comment
        author={<a>{`${user.firstName} ${user.lastName}`}</a>}
        avatar={
          <Avatar
            src={
              user.avatar
                ? user.avatar.url
                : `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`
            }
            alt={`${user.firstName} ${user.lastName}`}
          />
        }
        content={
          <div>
            {error && <Alert type="error" message={error} />}
            <Form.Item style={{ margin: '4px 0' }}>
              <Input.TextArea
                autoFocus
                rows={2}
                placeholder="Reply text..."
                onChange={(e: any) => {
                  setText(e.target.value);
                  setError(undefined);
                }}
                value={text}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                onClick={handleCreateReply}
                type="primary"
                loading={loading}
              >
                Reply
              </Button>
            </Form.Item>
          </div>
        }
      />
    </div>
  );
};

export default NewReplyForm;
