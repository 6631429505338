import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Input, Upload, Button, Divider, Modal, Form } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import ApiService from '@services/apiService';
import ProjectService from '@services/projectService';
import { toast } from 'react-toastify';
import FilePreview from '@components/files/FilePreview';
// import { getFileUrl, isUserInstructor, isUserAdmin } from '@helpers';
import AIIcon from '@assets/AI_with_notes.webp';
import ReactQuill from 'react-quill';
import CourseService from '@services/courseService';
import GoalsForm from './GoalsForm';

interface ProjectsFormProps {
  projects: Project[];
  setProjects: any;
  topicsAILessons: {
    prompt: {
      courseName: string;
      lessonInfo: string;
      lessonName: string;
      userIdentifier: string;
    };
    topics: [];
  };
}

const ProjectsForm: React.FC<ProjectsFormProps> = ({
  projects,
  setProjects,
  topicsAILessons,
}) => {
  const handleAddProject = () => {
    setProjects([
      ...projects,
      {
        id: -1,
        goals: [],
        files: [],
      },
    ]);
  };

  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [shortDesLoading, setShortDesLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [fileId, setFileId] = useState<any>();
  const [projectId, setProjectId] = useState<any>();
  const loginUserRole = localStorage.getItem('loginUserRole');
  const fileRemoveProject = () => {
    setLoading(true);
    const aaa = ProjectService.deleteFileProject(projectId, fileId)
      .then(d => {
        setDeleteModalVisible(false);
        toast.success('Delete file successful!');
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 2000);
        // history.push(`/lessons/${d.id}`);
      })
      .catch(err => {
        console.log('err', err);
        // toast.error(err.message);
      });
  };
  const handleRemoveProject = (index: number) => {
    const newData = projects;
    if (newData[index]) {
      newData.splice(index, 1);
    }

    setProjects([...newData]);
  };

  const handleUpdateGoals = (index: number) => (goals: Goal[]) => {
    projects[index].goals = goals;
    setProjects([...projects]);
  };

  const onUploadChange = (
    { fileList, file }: UploadChangeParam,
    index: number,
  ) => {
    if (file.percent === 100) {
      const fileObject = file.response;
      const newData = projects;

      if (newData[index] && fileObject) {
        newData[index].files.push(fileObject);
      }

      setProjects([...newData]);
    }
  };

  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpdateDescription = (value: string, index: number) => {
    projects[index].description = value;
    setProjects([...projects]);
  };
  /* const test = (value: string, index: number) => {
    console.log('ASAD', value, index);
    projects[index].description = value;
    // setProjects([...projects]);
  }; */

  // const handleChange = (value: string) => {
  //   console.log('ASAD', value);
  //   // console.log('projects', projects);
  //   // projects[0].description = value;
  // };
  // const handleChange = (a: any) => {};
  const handleUpdateExmapleFileUrl = (value: string, index: number) => {
    projects[index].exampleFileUrl = value;
    setProjects([...projects]);
  };

  const handleUpdateName = (value: string, index: number) => {
    projects[index].name = value;
    setProjects([...projects]);
  };
  const handleUpdateTime = (value: string, index: number) => {
    projects[index].time = value;
    setProjects([...projects]);
  };
  const texthandleChange = (value: string) => {
    projects[0].description = value;
  };
  const handleAIProject = () => {
    const sroreList1: {
      id: number;
      name: string;
      description: string;
      exampleFileUrl: string;
      link: string;
      time: any;
      goals: [];
      files: [];
    }[] = [];
    if (topicsAILessons.topics.length > 0) {
      topicsAILessons.topics.map((AItopic: any) => {
        sroreList1.push({
          id: -1,
          name: AItopic,
          description: '',
          exampleFileUrl: '',
          link: '',
          time: null,
          goals: [],
          files: [],
        });
      });
      // setProjects(sroreList1);
      setProjects([...projects, ...sroreList1]);
    }
  };
  const AILessonTopicDesGet = async (project: any, index: any) => {
    console.log('project', project);

    try {
      if (topicsAILessons.topics.length == 0) {
        return;
      }
      if (
        topicsAILessons.prompt.lessonName == '' ||
        topicsAILessons.prompt.lessonName == undefined
      ) {
        return;
      }
      if (project.name == '' || project.name == undefined) {
        return;
      }
      const sendData = {
        courseName: '',
        lessonName: topicsAILessons.prompt.lessonName,
        topicName: project.name,
        userIdentifier: '',
      };
      setShortDesLoading(true);
      const LessonCourseTopicDes =
        await CourseService.getCourseLessonTopicDesAI(sendData);
      if (LessonCourseTopicDes.success) {
        setShortDesLoading(false);
        // console.log('Get response if', LessonCourseTopicDes.data.completion[0]);
        projects[index].description = LessonCourseTopicDes.data.completion[0];
        setProjects([...projects]);
      } else {
        // console.log('Get response else');
      }
    } catch (err) {
      // console.log('catch err', err);
    }
  };
  return (
    <>
      {topicsAILessons.topics.length > 0 && (
        <Button className="ai-btn-lession-topic" type="primary">
          <img
            style={{
              height: '35px',
              width: '35px',
            }}
            onClick={() => {
              handleAIProject();
            }}
            src={AIIcon}
          />
        </Button>
      )}
      {projects.map((project, index) => (
        <>
          <Form.Item
            name={`projects[${index}].id`}
            initialValue={project.id}
            hidden
          >
            <Input hidden />
          </Form.Item>
          <Form.Item
            name={`projects[${index}].name`}
            label="Name"
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
            ]}
            initialValue={project.name}
          >
            <Input onChange={e => handleUpdateName(e.target.value, index)} />
          </Form.Item>

          <Form.Item
            name={`projects[${index}].time`}
            label="Time (in minutes)"
            initialValue={project.time}
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
            ]}
          >
            <Input
              type="number"
              min={1}
              onChange={e => handleUpdateTime(e.target.value, index)}
            />
          </Form.Item>

          {/* <Form.Item label="Description">
          {getFieldDecorator(`projects[${index}].description`, {
            rules: [
              {
                required: true,
                message: 'This field is required!',
              },
            ],
            initialValue: project.description,
          })(
            <Input.TextArea
              rows={7}
              onChange={e => handleUpdateDescription(e.target.value, index)}
            />,
          )}
        </Form.Item> */}

          <div className="ant-col ant-form-item-label">
            <label className="ant-form-item-required" title="Description">
              Description
            </label>
            {topicsAILessons.topics.length > 0 && (
              <span>
                <Button
                  loading={shortDesLoading}
                  onClick={() => {
                    AILessonTopicDesGet(project, index);
                  }}
                  type="primary"
                  className="ai-btn-topic-des"
                >
                  <img
                    style={{
                      height: '35px',
                      width: '35px',
                    }}
                    src={AIIcon}
                  />
                </Button>
              </span>
            )}
          </div>
          <div>
            <ReactQuill
              value={project.description || ''}
              onChange={(value: string) => {
                projects[index].description = value;
              }}
            />
          </div>
          <GoalsForm
            goals={project.goals}
            onUpdate={handleUpdateGoals(index)}
          />

          <Form.Item
            name="exampleFileUrl"
            label="Example file url"
            initialValue={project.exampleFileUrl}
          >
            <Input
              onChange={e => handleUpdateExmapleFileUrl(e.target.value, index)}
            />
          </Form.Item>
          <Form.Item
            name={`projects[${index}].files]`}
            label="Example file"
            {...{
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              initialValue: project.files
                ? project.files.map(file => ({
                    uid: file.id,
                    name: file.pid || file.url,
                    status: 'done',
                    ...file,
                  }))
                : [],
            }}
          >
            <>
              <Upload
                name="file"
                action={ApiService.getUrl('/files')}
                headers={ApiService.getHeaders()}
                listType="picture"
                onChange={e => onUploadChange(e, index)}
              >
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </>
          </Form.Item>
          <span>
            {project.files.map((file: any) => (
              <>
                {loginUserRole == 'admin' && (
                  <div style={{ textAlign: 'end', marginBottom: '8px' }}>
                    <Button
                      style={{ textAlign: 'left', marginBottom: '8px' }}
                      danger
                      onClick={() => {
                        setDeleteModalVisible(true);
                        setProjectId(project.id);
                        setFileId(file.id);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                )}
                <FilePreview file={file} />
              </>
            ))}
          </span>
          <Button
            style={{ textAlign: 'left' }}
            danger
            onClick={() => handleRemoveProject(index)}
          >
            Delete
          </Button>
          <Divider />
        </>
      ))}
      <Button type="primary" onClick={() => handleAddProject()}>
        Add new
      </Button>
      <Modal
        title="Are you sure you want to delete file?"
        width={550}
        visible={deleteModalVisible}
        okText="Delete file"
        okButtonProps={{ loading, danger: true }}
        onOk={() => fileRemoveProject()}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <p>You will not be able to revert this action</p>
      </Modal>
    </>
  );
};

export default ProjectsForm;
