import React from 'react';

import {
  DatabaseOutlined,
  LogoutOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const AvatarDropdown = (user: User) => {
  const logout = () => {
    localStorage.removeItem('accessToken');
  };
  return (
    <Menu className="app-header-dropdown">
      <Menu.Item key="4">
        Signed in as <strong>{`${user.firstName} ${user.lastName}`}</strong>{' '}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="0">
        <Link to="/edit-profile">
          <UserOutlined /> Edit Profile
        </Link>
      </Menu.Item>

      <Menu.Item key="0">
        <Link to="/newlane-tutorials">
          {/*  <Icon type="user" /> Newlane Tutorials */}
          <span className="nav-text">Newlane Tutorials</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="0">
        <Link to="/chat">
          <MessageOutlined /> Chat
        </Link>
      </Menu.Item>
      <Menu.Item key="0">
        <a
          href="https://proxy.lirn.net/Newlane"
          target="_blank"
          rel="noreferrer"
        >
          <DatabaseOutlined /> Library
        </a>
      </Menu.Item>
      {/* <Menu.Item key="affiliate-program">
        <Link to="/affiliate-program">
          <Icon type="share-alt" /> Affiliate program
        </Link>
      </Menu.Item> */}
      {/* <Menu.Item key="1" disabled={true}>
        {' '}
        <Icon type="setting" /> Settings{' '}
      </Menu.Item> */}
      <Menu.Item key="2">
        {/* <Link to="/contact-us">
          <Icon type="question-circle-o" /> Contact us
        </Link> */}
        <a
          target="_blank"
          href="https://newlaneuniversity.activehosted.com/f/14"
          rel="noreferrer"
        >
          <QuestionCircleOutlined /> Contact us
        </a>
      </Menu.Item>
      <Menu.Divider />
      {/* <Menu.Item key="/agreements/privacyPolicy">
        <Link to="/agreements/privacyPolicy">
          <span className="nav-text">Privacy Policy</span>
        </Link>
      </Menu.Item> */}

      <Menu.Item key="/agreements/termsOfUse">
        <Link to="/agreements/termsOfUse">
          <span className="nav-text">Terms of Use</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/agreements/userAgreement">
        <Link to="/agreements/userAgreement">
          <span className="nav-text">User Agreement</span>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        {' '}
        <a href="/login" onClick={logout}>
          <LogoutOutlined /> Sign out
        </a>{' '}
      </Menu.Item>
    </Menu>
  );
};

export default AvatarDropdown;
