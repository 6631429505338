import React from 'react';

interface GoalsProps {
  goals: Goal[];
}

const Goals: React.FC<GoalsProps> = ({ goals }) => {
  return (
    <>
      {!goals.length && <p>No goals.</p>}
      {goals.length > 0 && (
        <ul style={{ paddingLeft: 18 }}>
          {goals.map(goal => (
            <li key={goal.id}>{goal.text}</li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Goals;
