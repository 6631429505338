import { all } from 'redux-saga/effects';

import { default as authSagas } from './auth';
import { default as userSagas } from './user';
import { default as videoCallSagas } from './videoCall';
import { default as chatSagas } from './chat';
import { default as notificationsSagas } from './notifications';
import { default as userNotesSagas } from './userNotes';

export default function* appSaga() {
  yield all([
    authSagas(),
    userSagas(),
    videoCallSagas(),
    chatSagas(),
    notificationsSagas(),
    userNotesSagas(),
  ]);
}
