import React, { useState, useEffect } from 'react';
import { Input, Checkbox, Button, Alert, Form } from 'antd';
import { signup } from '@redux/reducers/auth';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '@redux/reducers';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const FormItem = Form.Item;

const mapState = (state: AppState) => ({
  isLoading: state.auth.isLoading,
  message: state.auth.message,
  errors: state.auth.errors,
  success: state.auth.success,
});

const mapDispatch = {
  signup,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SignupFormProps = PropsFromRedux;

const SignupForm: React.FC<SignupFormProps> = ({
  isLoading,
  signup: signupAction,
  message,
  errors,
  success,
}) => {
  const history = useHistory();
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [form] = Form.useForm<SignupData>();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const handleSubmit = (values: SignupData) => {
    signupAction(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const checkPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  useEffect(() => {
    if (success && message) {
      toast.success(message);
      history.push('/login');
    }
  }, [success, message, history]);

  return (
    <section className="form-v1-container">
      <h2>{message ? 'Account created!' : 'Create an Account'}</h2>
      {!message &&
        errors?.map(error => <Alert message={error.message} type="error" />)}
      {message && <Alert message={message} type="success" />}
      {!message && (
        <Form
          name="signup-form"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          className="form-v1"
        >
          <FormItem
            {...formItemLayout}
            style={{ textAlign: 'left' }}
            label="First Name"
            hasFeedback
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Please input your first name!',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            {...formItemLayout}
            style={{ textAlign: 'left' }}
            label="Last Name"
            hasFeedback
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please input your last name!',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="E-mail"
            hasFeedback
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Password"
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input type="password" />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Confirm Password"
            name="confirm"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                validator: checkPassword,
              },
            ]}
          >
            <Input type="password" onBlur={handleConfirmBlur} />
          </FormItem>
          <FormItem
            name="agreement"
            style={{ textAlign: 'center' }}
            valuePropName="checked"
          >
            <Checkbox>
              I have read the{' '}
              <a href="/agreements/termsOfUse" target="_blank">
                TERMS OF USE
              </a>
            </Checkbox>
          </FormItem>
          <FormItem style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-cta"
              disabled={isLoading}
              loading={isLoading}
            >
              Sign Up
            </Button>
          </FormItem>
          <p className="additional-info">
            Already have an account? <Link to="/login">Log in</Link>
          </p>
        </Form>
      )}
    </section>
  );
};

export default connect(mapState, mapDispatch)(SignupForm);
