import { Role, SubscriptionStatus } from '@types';
import moment from 'moment';

export const isUserInstructor = (user: User) => {
  return user.role === Role.Instructor || user.role === Role.Admin;
};

export const isUserAdmin = (user: User) => {
  return user.role === Role.Admin;
};

export const isUserNew = (user: User) => {
  return moment.duration(moment().diff(moment(user.createdAt))).asDays() < 4;
};
export const isUserStudent = (user: User) => {
  return user.role === Role.Student;
};

export const isUserSubscriptionCanceled = (user: User) => user.subscriptionStatus === 'canceled';


export const isUserSubscribed = (user: User) => {
  const subscribedStatuses: SubscriptionStatus[] = ['active', 'trialing', 'past_due'];

  if (user.role !== Role.Student) {
    return true;
  }

  if (!user.subscriptionStatus) {
    return false;
  }

  return (
    user.subscriptionStatus &&
    subscribedStatuses.indexOf(user.subscriptionStatus) !== -1
  );
};

export const scrollToRef = (ref: any) => {
  window.scrollTo(0, ref.current.offsetTop);
};

export const getFileUrl = (fileUrl: string) => {
  if(!fileUrl){
    return fileUrl="";
  }
  if (fileUrl.startsWith('http')) {
    return fileUrl;
  }

  return `https://${fileUrl}`;
}
