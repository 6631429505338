import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Home from '@screens/home/Home';
import EditProfile from '@screens/profile/EditProfile';
import NewlaneTutorials from '@screens/newlane-tutorials/NewlaneTutorials';
import Degrees from '@screens/degrees/Degrees';
import SingleDegree from '@screens/degrees/SingleDegree';
import SingleCourse from '@screens/courses/SingleCourse';
import Courses from '@screens/courses/Courses';
import CourseLessons from '@screens/courses/CourseLessons';

import SingleLesson from '@screens/lessons/SingleLesson';

import SingleObjective from '@screens/objectives/SingleObjective';
import ObjectiveMediaReview from '@screens/objectives/ObjectiveMediaReview';

import Login from '@screens/auth/login/Login';
import Signup from '@screens/auth/signup/Signup';
import ConfirmEmail from '@screens/auth/confirmEmail/ConfirmEmail';
import ForgotPassword from '@screens/auth/ForgotPassword';
import ResetPassword from '@screens/auth/ResetPassword';
import StudentCourseProgress from '@screens/instructor/studentCourseProgress/StudentCourseProgress';
import OnboardingPage from '@screens/onboarding/Onboarding';
import SubscribePage from '@screens/subscription/SubscribePage';
import ChatPage from '@screens/chat/Chat';
import DegreeEdit from '@screens/degrees/DegreeEdit';
import DegreeCreate from '@screens/degrees/DegreeCreate';

import CourseEdit from '@screens/courses/CourseEdit';
import CourseCreate from '@screens/courses/CourseCreate';

import LessonEdit from '@screens/lessons/LessonEdit';
import LessonCreate from '@screens/lessons/LessonCreate';

import ObjectiveEdit from '@screens/objectives/ObjectiveEdit';
import ObjectiveCreate from '@screens/objectives/ObjectiveCreate';
import NewlaneTutorialsCreate from '@screens/newlane-tutorials/NewlaneTutorialsCreate';
import NewlaneTutorialsEdit from '@screens/newlane-tutorials/NewlaneTutorialsEdit';
import StudentsPage from '@screens/students/Students';
import SingleStudentPage from '@screens/students/SingleStudent';

import SearchPage from '@screens/search/SearchPage';

import AdminCoupons from '@screens/admin/AdminCoupons';
import AdminUsers from '@screens/admin/AdminUsers';
import AdminAgreements from '@screens/admin/AdminAgreements';

import SingleAgreement from '@screens/agreements/SingleAgreement';
import SignAgreement from '@screens/agreements/SignAgreement';

import ContactPage from '@screens/ContactPage';

import AnnouncementsScreen from '@screens/announcements/AnnouncementsScreen';
import { AffiliateProgramPage } from '@screens/affiliateProgram/AffiliateProgramPage';

import configureStore from '../redux/store';
import { ConnectedAppLayout } from '../layout';

const store = configureStore({}); // TODO localstorage

const publicPaths = [
  { exact: true, path: '/login', component: Login },
  { exact: true, path: '/signup', component: Signup },
  { exact: true, path: '/confirm-email', component: ConfirmEmail },
  { exact: true, path: '/forgot-password', component: ForgotPassword },
  { exact: true, path: '/reset-password', component: ResetPassword },
  { exact: true, path: '/agreements/:type', component: SingleAgreement },
];

const privatePaths = [
  { exact: true, path: '/', component: Home },
  { exact: true, path: '/search', component: SearchPage },
  { exact: true, path: '/edit-profile', component: EditProfile },
  { exact: true, path: '/newlane-tutorials', component: NewlaneTutorials },
  {
    exact: true,
    path: '/create-newlane-tutorials',
    component: NewlaneTutorialsCreate,
  },
  {
    exact: true,
    path: '/newlane-tutorials/:id/edit',
    component: NewlaneTutorialsEdit,
  },
  { exact: true, path: '/chat', component: ChatPage },
  { exact: true, path: '/contact-us', component: ContactPage },
  { exact: true, path: '/announcements', component: AnnouncementsScreen },
  { exact: true, path: '/admin/coupons', component: AdminCoupons },
  { exact: true, path: '/admin/users', component: AdminUsers },
  { exact: true, path: '/admin/agreements', component: AdminAgreements },

  { exact: true, path: '/user-agreements', component: SignAgreement },

  // DEGREE ROUTES
  { exact: true, path: '/degrees', component: Degrees },
  { exact: true, path: '/degrees/:id', component: SingleDegree },
  { exact: true, path: '/degrees/:id/edit', component: DegreeEdit },
  {
    exact: true,
    path: '/degrees/:id/courses/:id',
    component: SingleCourse,
  },
  { exact: true, path: '/new-degree', component: DegreeCreate },
  {
    exact: true,
    path: '/degrees/:id/courses/:id/lessons/:id',
    component: SingleLesson,
  },
  {
    exact: true,
    path: '/degrees/:id/courses/:id/lessons/:id/objectives/:id',
    component: SingleObjective,
  },
  {
    exact: true,
    path: '/degrees/:id/courses/:id/lessons-overview',
    component: CourseLessons,
  },

  // COURSE ROUTES
  { exact: true, path: '/courses', component: Courses },
  {
    exact: true,
    path: '/courses/:courseId/students/:studentId',
    component: StudentCourseProgress,
  },
  { exact: true, path: '/courses/:id', component: SingleCourse },
  {
    exact: true,
    path: '/courses/:id/lessons-overview',
    component: CourseLessons,
  },
  { exact: true, path: '/courses/:id/edit', component: CourseEdit },
  {
    exact: true,
    path: '/courses/:id/lessons/:id',
    component: SingleLesson,
  },
  {
    exact: true,
    path: '/courses/:id/lessons/:id/objectives/:id',
    component: SingleObjective,
  },
  {
    exact: true,
    path: '/courses/:id/objectives/:id',
    component: SingleObjective,
  },
  { exact: true, path: '/new-course', component: CourseCreate },

  // LESSON ROUTES
  { exact: true, path: '/lessons/:id', component: SingleLesson },
  { exact: true, path: '/lessons/:id/edit', component: LessonEdit },
  {
    exact: true,
    path: '/lessons/:id/objectives/:id',
    component: SingleObjective,
  },
  { exact: true, path: '/new-lesson', component: LessonCreate },

  // OBJECTIVE ROUTES
  { exact: true, path: '/objectives/:id', component: SingleObjective },
  { exact: true, path: '/objectives/:id/edit', component: ObjectiveEdit },
  { exact: true, path: '/new-objective', component: ObjectiveCreate },
  {
    exact: true,
    path: '/objective-media-review',
    component: ObjectiveMediaReview,
  },

  // STUDENT ROUTES
  { exact: true, path: '/students', component: StudentsPage },
  { exact: true, path: '/students/:id', component: SingleStudentPage },

  // { exact: true, path: '/students/:id', component: SingleStudentPage },

  // OTHER ROUTES
  {
    exact: true,
    path: '/onboarding',
    component: OnboardingPage,
  },
  {
    exact: true,
    path: '/subscribe',
    component: SubscribePage,
  },
  {
    exact: true,
    path: '/affiliate-program',
    component: () => <AffiliateProgramPage />,
  },
];

const publicRoutes = publicPaths.map(({ path, ...props }) => (
  <Route key={path} path={path} {...props} />
));

const privateRoutes = privatePaths.map(({ path, ...props }) => (
  <Route key={path} path={path} {...props} />
));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

export const Routes = () => (
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Switch>
          {publicRoutes}
          <ConnectedAppLayout>{privateRoutes}</ConnectedAppLayout>
          {/* <Route component={NotFound} /> */}
          <ToastContainer position={toast.POSITION.TOP_CENTER} />
        </Switch>
      </BrowserRouter>
    </Elements>
  </Provider>
);

export default Routes;
