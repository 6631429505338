import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';

import NewlaneTutorialsForm from '@components/newlane-tutorials/NewlaneTutorialsForm';
import withNewlaneTutorials from '@hoc/withNewlaneTutorials';
interface EditNewlaneTutorialsProps {
  user?: User;
  newlaneTutorial: NewlaneTutorials;
}

const EditNewlaneTutorials: React.FC<EditNewlaneTutorialsProps> = ({
  user,
  newlaneTutorial,
}) => {
  if (!user) {
    return null;
  }
  return <NewlaneTutorialsForm newlaneTutorial={newlaneTutorial} />;
};

const selectEditNewlaneTutorialsState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedEditNewlaneTutorials = withNewlaneTutorials(
  connect(selectEditNewlaneTutorialsState)(EditNewlaneTutorials),
);

const Page = () => <ConnectedEditNewlaneTutorials />;

export default Page;
