import React from 'react';
import { Row, Col, Button, Tag } from 'antd';
import YouTube from 'react-youtube-embed';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { enrollDegree } from '@redux/reducers/user';
import './degreeList.less';
import { isUserEnrolled } from '@utils/helpers';
import { isUserSubscribed, isUserInstructor } from '@helpers';
import SubscribeMessage from '@components/subscribeMessage/SubscribeMessage';
import { useHistory } from 'react-router';
import { DegreeProgress } from '@components/degreeProgress/DegreeProgress';

interface DegreeListProps {
  degrees: Degree[];
  user?: User;
  enrollDegree: typeof enrollDegree;
}

const DegreeList: React.FC<DegreeListProps> = ({
  degrees,
  user,
  enrollDegree,
}) => {
  const history = useHistory();

  if (!user) {
    return null;
  }

  return (
    <>
      <Row>
        <Col
          xs="24"
          sm="24"
          md="24"
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 16, offset: 4 }}
        >
          {degrees.map((degree: Degree, i: number) => (
            <article key={(i + 1).toString()} className="degree-card-list mb-4">
              <Row className="mb-4" justify="center">
                {degree.introVideo && (
                  <Col xs={24} sm={24} md={12}>
                    <YouTube id={degree.introVideo} />
                  </Col>
                )}
                <Col xs={24} sm={24} md={degree.introVideo ? 12 : 24}>
                  <div style={{ paddingLeft: 16, paddingTop: 16 }}>
                    {degree.visibility === 'private' && (
                      <Tag color="orange" style={{ fontWeight: 400 }}>
                        Private
                      </Tag>
                    )}
                    {degree.visibility === 'comingSoon' && (
                      <Tag color="#2db7f5" style={{ fontWeight: 400 }}>
                        Coming Soon
                      </Tag>
                    )}
                  </div>
                  <div className="degree-card__body">
                    {degree.visibility === 'public' ||
                    isUserInstructor(user) ? (
                      <Link
                        to={`/degrees/${degree.id}`}
                        className="no-link-style"
                      >
                        <h2 className="degree-card__title">{degree.name}</h2>
                      </Link>
                    ) : (
                      <h2 className="degree-card__title">{degree.name}</h2>
                    )}
                    {degree.visibility === 'public' && (
                      <div>
                        {!isUserEnrolled('degree', degree.id, user) && (
                          <div>
                            <div className="degree-card__content">
                              <p>{degree.shortDescription}</p>
                            </div>
                            {!isUserInstructor(user) && (
                              <>
                                <div style={{ textAlign: 'center' }}>
                                  <Button
                                    type="primary"
                                    disabled={!isUserSubscribed(user)}
                                    onClick={(e: any) => {
                                      enrollDegree(degree.id);
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 1000);
                                    }}
                                  >
                                    Enroll
                                  </Button>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                  <SubscribeMessage user={user} />
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        {isUserEnrolled('degree', degree.id, user) && (
                          <div style={{ textAlign: 'center' }}>
                            <DegreeProgress
                              degreeId={degree.id}
                              user={user}
                              circle
                            />
                            <div style={{ marginTop: 10 }}>
                              <Button
                                type="default"
                                onClick={() =>
                                  history.push(`/degrees/${degree.id}`)
                                }
                              >
                                Continue
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {/* <div className="degree-card__author">Author: John Doe</div> */}
                  </div>
                </Col>
              </Row>
            </article>
          ))}
        </Col>
      </Row>
    </>
  );
};

const selectDegreeListState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedDegreeList = connect(selectDegreeListState, { enrollDegree })(
  DegreeList,
);

export default ConnectedDegreeList;
