import React, { useState, Fragment } from 'react';
import { Row, Col, Card, List, Divider, Progress } from 'antd';
import { getChatUserName, getChatUser } from '@screens/chat/helpers';
import { getUserAvatar, getCourseCompletedPercentage } from '@utils/helpers';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ScheduleCalendar from '@components/schedule/ScheduleCalendar';
import ScheduleDayAgenda from '@components/schedule/ScheduleDayAgenda';
import { getDayCalls } from '@components/schedule/helpers';
import { DATE_FORMAT } from '@components/schedule/constants';
import { joinVideoCall } from '@redux/reducers/videoCall';
import AnnouncementList from '@components/announcements/AnnouncementList';
//import { acTrackEvent } from '@services/ActiveCampaign';

interface StudentHomeProps {
  user: User;
  isLoading: boolean;
  chats?: Chat[];
  joinVideoCall: typeof joinVideoCall;
  scheduledCalls: ScheduledCall[];
}

const StudentHome: React.FC<StudentHomeProps> = ({
  user,
  chats,
  isLoading,
  scheduledCalls,
  joinVideoCall,
}) => {
  const [selectedDay, setSelectedDay] = useState(moment());

  return (
    <Fragment>
      <Row gutter={[16, { xs: 16, sm: 16, md: 0 }]}>
        <Col lg={14} md={12}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="Announcements" size="small">
                <AnnouncementList user={user} />
              </Card>
            </Col>

            <Col lg={12} md={24} sm={12}>
              <Card
                title="Messages"
                size="small"
                style={{ maxHeight: 400, overflow: 'auto' }}
              >
                {chats && !chats.length ? (
                  <h4 style={{ textAlign: 'center', marginTop: 8 }}>
                    No chats
                  </h4>
                ) : (
                  <>
                    <List
                      itemLayout="horizontal"
                      dataSource={chats}
                      grid={{ gutter: 0 }}
                      split={true}
                      renderItem={chat => {
                        const chatUser = getChatUser(user, chat.users);

                        let avatar = null;

                        if (chatUser) {
                          avatar = getUserAvatar(chatUser, 60);
                        }

                        return (
                          <>
                            <List.Item style={{ margin: 0 }}>
                              <List.Item.Meta
                                avatar={
                                  <div style={{ paddingTop: '4px' }}>
                                    {avatar}
                                  </div>
                                }
                                title={
                                  <Link to={`/chat?chatId=${chat.id}`}>
                                    <b>{getChatUserName(user, chat.users)}</b>
                                  </Link>
                                }
                                description={
                                  <p style={{ margin: 0 }}>
                                    {
                                      chat.messages[chat.messages.length - 1]
                                        .text
                                    }
                                    <br />
                                    <small>
                                      {moment(
                                        chat.messages[chat.messages.length - 1]
                                          .createdAt,
                                      ).fromNow()}
                                    </small>
                                  </p>
                                }
                              />
                            </List.Item>
                            <Divider style={{ margin: '4px 0' }} />
                          </>
                        );
                      }}
                    />
                  </>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Link to="/chat">All Chats</Link>
                </div>
              </Card>
            </Col>

            <Col lg={12} md={24} sm={12}>
              <Card
                title="Courses"
                size="small"
                style={{ maxHeight: 400, overflow: 'auto' }}
              >
                {user.enrolledCourses && user.enrolledCourses.length === 0 ? (
                  <h4 style={{ textAlign: 'center', marginTop: 8 }}>
                    No enrolled courses
                  </h4>
                ) : (
                  <List
                    itemLayout="horizontal"
                    dataSource={user.enrolledCourses}
                    grid={{ gutter: 0 }}
                    renderItem={course => (
                      <>
                        <List.Item style={{ margin: 0 }}>
                          <List.Item.Meta
                            avatar={
                              <div>
                                <Progress
                                  type="dashboard"
                                  width={52}
                                  percent={getCourseCompletedPercentage(
                                    course,
                                    user,
                                  )}
                                />
                              </div>
                            }
                            title={
                              <Link to={`/courses/${course.id}`}>
                                <b>{course.name}</b>
                              </Link>
                            }
                            description={
                              <p style={{ margin: 0 }}>
                                Teacher:{' '}
                                {course.creator
                                  ? `${course.creator.firstName} ${course.creator.lastName}`
                                  : 'N/A'}
                              </p>
                            }
                          />
                        </List.Item>
                        <Divider style={{ margin: '4px 0' }} />
                      </>
                    )}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Col>
        <Col lg={10} md={12}>
          <Card title="Calendar" size="small" className="sidebar-widget">
            <ScheduleCalendar
              calls={scheduledCalls}
              onChangeDay={(day: moment.Moment) => setSelectedDay(day)}
            />
          </Card>
          <Card
            title={`Agenda for ${selectedDay.format(DATE_FORMAT)}`}
            size="small"
          >
            <ScheduleDayAgenda
              user={user}
              joinVideoCall={joinVideoCall}
              calls={getDayCalls(selectedDay, scheduledCalls)}
            />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StudentHome;
