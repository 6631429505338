import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Card, Button } from 'antd';
import { AppState } from '@redux/reducers';
import { markCompleted } from '@redux/reducers/user';
import { connect } from 'react-redux';
import { useRouter } from '@utils/routerHooks';
import { useLocation } from 'react-router';
import { isCoursePassed, isObjectiveCompleted } from '@utils/helpers';
import { default as ObjectiveDetails } from '@components/objectives/Objective';
import { isUserSubscribed, isUserInstructor } from '@helpers';
import withObjective from '@hoc/withObjective';
import Notes from '@components/notes/Notes';

import { parsePath } from '@components/breadcrumbs/Breadcrumbs';

interface ObjectiveProps {
  user?: User;
  objective: Objective;
  markCompletedLoading: boolean;
  markCompleted: typeof markCompleted;
}

const Objective: React.FC<ObjectiveProps> = ({
  user,
  objective,
  markCompleted,
  markCompletedLoading,
}) => {
  const { history } = useRouter();

  const location = useLocation();
  const { courseId } = parsePath(location.pathname);
  if (!user) {
    return null;
  }
  /* if (!courseId) {
    return null;
  } */
  if (!isUserSubscribed(user)) {
    history.goBack();
  }
  const isCompleted = isObjectiveCompleted(objective.id, user);
  const coursePassed = '';
  if (courseId) {
    const coursePassed = isCoursePassed(parseInt(courseId), user.userCourses);
  }

  return (
    <>
      <Row gutter={[16, { xs: 16, sm: 16, md: 0 }]}>
        <Col xs={24} sm={24} md={isUserInstructor(user) ? 24 : 16}>
          <ObjectiveDetails objective={objective} />
        </Col>
        {!isUserInstructor(user) && (
          <Col xs={24} sm={24} md={8}>
            <Card size="small" title="Progress" className="sidebar-widget">
              {isCompleted && (
                <>
                  <h3 style={{ textAlign: 'center', color: '#52c41a' }}>
                    <CheckCircleOutlined
                      style={{ fontSize: 20, color: '#52c41a' }}
                    />{' '}
                    COMPLETED
                  </h3>
                  {!coursePassed && (
                    <>
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          type="primary"
                          loading={markCompletedLoading}
                          onClick={() => markCompleted(objective.id)}
                        >
                          Mark not complete
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
              {!isCompleted && (
                <>
                  <h3 style={{ textAlign: 'center', color: '#333' }}>
                    <CloseCircleOutlined
                      style={{ fontSize: 20, color: '#333' }}
                    />{' '}
                    NOT COMPLETED
                  </h3>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type="primary"
                      loading={markCompletedLoading}
                      onClick={() => markCompleted(objective.id)}
                    >
                      Mark completed
                    </Button>
                  </div>
                </>
              )}
            </Card>
          </Col>
        )}
      </Row>
      <Notes relId={objective.id} rel="objective" canEdit canRemove canAdd />
    </>
  );
};

const selectObjectiveState = (state: AppState) => ({
  user: state.user.user,
  markCompletedLoading: state.user.markCompletedLoading,
});

const ConnectedObjective = withObjective(
  connect(selectObjectiveState, { markCompleted })(Objective),
);

const Page = () => <ConnectedObjective />;

export default Page;
