import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
// import {
//   Chart,
//   ChartTitle,
//   ChartSeries,
//   ChartSeriesItem,
//   ChartCategoryAxis,
//   ChartCategoryAxisItem,
//   ChartLegend,
// } from '@progress/kendo-react-charts';
import {
  Row,
  Col,
  PageHeader,
  Select,
  Tabs,
  Avatar,
  Divider,
  Tag,
  Menu,
  Button,
  Popconfirm,
  Input,
  Tooltip,
  Modal,
  Form,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AppState } from '@redux/reducers';
import withStudent from '@hoc/withStudent';
import OnboardingComponent from '@components/onboarding/Onboarding';
import DocumentsForm from '@components/forms/DocumentsForm';
import StudentNotesForm from '@components/forms/StudentNotesForm';
import { CreditsForm } from '@components/credits';
import UserService from '@services/userService';
import { DATETIME_FORMAT, DATE_FORMAT } from '@components/schedule/constants';
import { SubscriptionStatus } from '@components/subscription';
import './SingleStudent.less';
import { isUserSubscribed, isUserAdmin, isUserInstructor } from '@helpers';
import { getCourseCompletedPercentage11 } from '@utils/helpers';

const { TabPane } = Tabs;

interface SingleStudentPageProps {
  reloadStudent: () => void;
  student: User;
  user?: User;
}
const SingleStudentPage: React.FC<SingleStudentPageProps> = ({
  student,
  user,
  reloadStudent,
}) => {
  const [userCourses, setUserNotes] = useState<UserCourse[]>();
  const [selectedCourse, setSelectedCourse] = useState<UserCourse>();
  const [loading, setLoading] = useState(false);
  // const [billingInfoLoading, setBillingInfoLoading] = useState(false);
  const [billingInfo, setBillingInfo] = useState<BillingInfo>();
  const [subscription, setSubscription] = useState<StripeSubscription>();
  const [registeredUser, setRegisteredUser] = useState<any>('');
  const [payingTypeUser, setPayingTypeUser] = useState<any>('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deletedUserCourse, setDeletedUserCourse] = useState<any>();
  const [addedUserCourse, setAddedUserCourse] = useState<any>();
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [assignCourseStudent, setAssignCourseStudent] = useState<any>();
  const [allCourseStore, setAllCourseStore] = useState<any>();
  const [patFullTimeUser, setPatFullTimeUser] = useState<any>('');
  // const [payingUser, setPayingUser] = useState<any>('');
  const [passedCoursesCount, setPassedCoursesCount] = useState<any>('0');
  const [passedCoursesPast30Days, setPassedCoursesPast30Days] =
    useState<any>('0');
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [updateUserCourse, setUpdateUserCourse] = useState<any>();
  const [updateStatusMsg, setUpdateStatusMsg] = useState('start');

  const [projectsCompletedPast30Days, setProjectsCompletedPast30Days] =
    useState<any>('0');
  const [objectivesSelfAssessedTotal, setObjectivesSelfAssessedTotal] =
    useState<any>('0');
  const [objectivesSelfAssessed7Days, setObjectivesSelfAssessed7Days] =
    useState<any>('0');

  // const [current12Month,setCurrent12Month]= useState<any>();
  // const [categories, setCategories] = useState<Array<string>>([]);
  // const [series, setSeries] = useState<Tsssss>();
  const [ChartContainer, setChartContainer] = useState<any>();
  const [userActivityLog30Days, setUserActivityLog30Days] = useState<any>('0');
  const [userActivityLog60Days, setUserActivityLog60Days] = useState<any>('0');
  const [userActivityLog90Days, setUserActivityLog90Days] = useState<any>('0');
  const [selectedMonth, setSelectedMonth] = useState<any>('12');
  const [enrollmentAgreementDate, setEnrollmentAgreementDate] =
    useState<any>('');
  const [monthsEnrolled, setMonthsEnrolled] = useState<any>('');
  const [latestGraduationDate, setLatestGraduationDate] = useState<any>('');
  const [creditsTransferred, setCreditsTransferred] = useState<any>('0');
  const [totalCreditsPassCourse, setTotalCreditsPassCourse] =
    useState<any>('0');
  const monthCalculateStarEndDate = (d1: any, d2: any) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  // const { getFieldDecorator, setFieldsValue } = form;
  const [documents, setDocuments] = useState<UserNotesCreateAdminForStudent[]>(
    student.notesCreateAdminForStudents,
  );
  const [editNote, seteditNote] = useState<any>('');
  // const handleSubmit = (e: any) => {
  //   const { validateFields } = form;

  //   e.preventDefault();

  //   validateFields((err: any) => {});
  // };

  useEffect(() => {
    // nagar
    const kendoLink = document.createElement('link');
    kendoLink.href =
      'https://kendo.cdn.telerik.com/themes/5.0.1/default/default-main.css';
    kendoLink.rel = 'stylesheet';
    document.body.appendChild(kendoLink);
    // nagar

    if (!loading && !userCourses) {
      setLoading(true);
      // allCourseGet();
      UserService.getAllUserNotes(student.id).then(data => {
        if (data.length > 0) {
          const sroteCourseA = data.map(c => c.courseId);
          setAssignCourseStudent(sroteCourseA);
        }
        const sortedData = sortBy(data, 'passed').reverse();
        setUserNotes(sortedData);
        setSelectedCourse(sortedData[0]);
        setLoading(false);
        setSelectedMonth(12);
        studentCalculationData(sortedData, 12);
        const datePast30Days = moment().subtract(30, 'days');
        const datePast60Days = moment().subtract(60, 'days');
        const datePast90Days = moment().subtract(90, 'days');
        const todayDate = new Date();
        // calculate user activity log
        let userPast30Days: any = [];
        let userPast60Days: any = [];
        let userPast90Days: any = [];
        if (student.userActivityLog) {
          userPast30Days = student.userActivityLog.filter(
            (uu: any) =>
              moment(uu.userLogIn).format(DATE_FORMAT) >=
                moment(datePast30Days).format(DATE_FORMAT) &&
              moment(uu.userLogIn).format(DATE_FORMAT) <=
                moment(todayDate).format(DATE_FORMAT) &&
              uu.userLogIn,
          );
          userPast60Days = student.userActivityLog.filter(
            (uu: any) =>
              moment(uu.userLogIn).format(DATE_FORMAT) >=
                moment(datePast60Days).format(DATE_FORMAT) &&
              moment(uu.userLogIn).format(DATE_FORMAT) <=
                moment(todayDate).format(DATE_FORMAT) &&
              uu.userLogIn,
          );
          userPast90Days = student.userActivityLog.filter(
            (uu: any) =>
              moment(uu.userLogIn).format(DATE_FORMAT) >=
                moment(datePast90Days).format(DATE_FORMAT) &&
              moment(uu.userLogIn).format(DATE_FORMAT) <=
                moment(todayDate).format(DATE_FORMAT) &&
              uu.userLogIn,
          );
        }
        setUserActivityLog30Days(userPast30Days.length);
        setUserActivityLog60Days(userPast60Days.length);
        setUserActivityLog90Days(userPast90Days.length);

        // calculate projects Completed
        if (student.userProjects) {
          const completedProjects = student.userProjects.filter(
            (p: any) =>
              p.status == 'approved' &&
              moment(p.updatedAt).format(DATE_FORMAT) >
                datePast30Days.format(DATE_FORMAT),
          );
          if (completedProjects.length > 0) {
            setProjectsCompletedPast30Days(completedProjects.length);
          }
        }
      });

      if (student.agreements.length > 0 && student.agreements[1].signedAt) {
        setEnrollmentAgreementDate(student.agreements[1].signedAt);
        const d1 = new Date(student.agreements[1].signedAt);
        const d2 = new Date();
        setMonthsEnrolled(monthCalculateStarEndDate(d1, d2));
      }
      if (student.credits) {
        const arr = student.credits;
        const total = getSumByKey(arr, 'amount');
        setCreditsTransferred(total);
      }
      if (student.studentEnrolledType) {
        setRegisteredUser(student.studentEnrolledType);
      }
      if (student.studentPayingType) {
        setPayingTypeUser(student.studentPayingType);
      }
      if (student.studyType) {
        calculatePartFullTime(student.studyType);
      }
    }
  }, [loading, userCourses, student.id]);
  const getSumByKey = (arr: any, key: any) =>
    arr.reduce(
      (accumulator: any, current: any) => accumulator + Number(current[key]),
      0,
    );

  useEffect(() => {
    UserService.getBillingInfo(student.id).then(setBillingInfo);
  }, [student.id]);

  useEffect(() => {
    if (!subscription && billingInfo) {
      setSubscription(billingInfo.customer.subscriptions.data[0]);
    }
  }, [subscription, billingInfo]);

  const reloadUser = () => {
    window.location.reload();
  };

  const handleDeleteCredit = async (creditId: number) => {
    await UserService.deleteCredit(student.id, creditId);
    reloadStudent();
  };
  const handleDeleteNotes = async (noteId: number) => {
    await UserService.deleteNotesCreateAdminForStudent(
      Number(student.id),
      noteId,
    );
    toast.success('Notes deleted successfully!');
    setTimeout(() => {
      reloadStudent();
    }, 2000);
  };
  const updateNotesCreateAdminForStudent = async (notes: any) => {
    UserService.updateNotesCreateAdminForStudent(Number(student.id), {
      noteId: notes.id,
      description: notes.description,
    })
      .then(d => {
        toast.success('Notes updated successfully!');
        seteditNote('');
      })
      .catch(() => {
        toast.error('Update failed!');
      });
  };
  const descriptionChange = (e: any, index: any) => {
    documents[index].description = e.target.value;
    setDocuments([...documents]);
  };
  const editNotes = (id: any) => {
    seteditNote(id);
  };
  if (!user) {
    return null;
  }

  const openAddCoursePopup = (assignCS: any) => {
    allCourseGet(assignCS);
    setAddModalVisible(true);
  };
  const userCoursesRunning = (course: any) => {
    const percentage = getCourseCompletedPercentage11(course.course, student);
    // if(percentage== NaN){
    //  console.log('NaNNaNNaN', percentage);
    // }
    if (percentage < 100 && percentage > 0) {
      return true;
    }
    if (course.startCourse == 'started') {
      return true;
    }
    return false;
  };
  // Get all course

  const allCourseGet = (assignCS: any) => {
    const storeData: any = [];
    UserService.getAllCourse().then((data: any) => {
      // Filter UnAssign course
      if (data && data.length > 0) {
        data.filter((c: any) => {
          if (assignCS && assignCS.length > 0) {
            if (assignCS.indexOf(c.id) == -1) {
              if (c.visibility == 'public') {
                storeData.push(c);
              }
            }
          }
        });
      }
      setAllCourseStore(storeData);
    });
  };

  const deleteStudentCourse1 = (course: any) => {
    setDeletedUserCourse(course);
    setDeleteModalVisible(true);
  };

  const updateStudentCourse1 = (course: any, status: string) => {
    setUpdateUserCourse(course);
    setUpdateStatusMsg(status);
    setUpdateModalVisible(true);
  };
  const deleteStudentCourse = () => {
    if (!deletedUserCourse) {
      return false;
    }

    setLoading(true);
    if (!deletedUserCourse.passed) {
      UserService.courseDeleteStudent(
        deletedUserCourse.userId,
        deletedUserCourse.courseId,
      )
        .then(() => {
          setDeleteModalVisible(false);
          // deletedUserCourse(undefined);
          setLoading(false);
          toast.success('Delete course successfully!');
          setTimeout(() => {
            reloadStudent();
          }, 1000);
        })
        .catch(err => {
          setLoading(false);
          // setError(err.message);
        });
    } else {
      console.log('else');
    }
  };

  const upateStudentCourse = () => {
    if (!updateUserCourse) {
      return false;
    }

    setLoading(true);
    if (!updateUserCourse.passed) {
      let statusUpdate;
      if (updateStatusMsg == 'start') {
        statusUpdate = 'started';
      } else {
        statusUpdate = 'initial';
      }
      const sendData = {
        id: updateUserCourse.userId,
        courseId: updateUserCourse.courseId,
        status: statusUpdate,
      };
      UserService.userCourseStatus(sendData)
        .then(() => {
          setUpdateModalVisible(false);
          // deletedUserCourse(undefined);
          setLoading(false);
          toast.success('Update course status successfully!');
          setTimeout(() => {
            reloadStudent();
          }, 1000);
        })
        .catch(err => {
          setLoading(false);
          // setError(err.message);
        });
    } else {
      console.log('else');
    }
  };
  const addStudentCourse = () => {
    if (!addedUserCourse) {
      return false;
    }
    setLoading(true);
    UserService.courseAddStudent(student.id, addedUserCourse)
      .then(() => {
        setAddModalVisible(false);
        // deletedUserCourse(undefined);
        setLoading(false);
        toast.success('Add course successfully!');
        setTimeout(() => {
          reloadStudent();
        }, 1000);
      })
      .catch(err => {
        setLoading(false);
        // setError(err.message);
      });
  };
  const updateUserStudyType = (studyType: any) => {
    student.studyType = studyType;
    setLoading(true);
    UserService.updateStudent(student.id, student)
      .then(() => {
        setLoading(false);
        toast.success('User updated successfully!');
        calculatePartFullTime(studyType);
      })
      .catch(err => {
        // console.error(err);
        setLoading(false);
        // setError(err.message);
      });
  };
  const updateUserEnrolledType = (EnrolledType: any) => {
    student.studentEnrolledType = EnrolledType;
    setLoading(true);
    UserService.updateStudent(student.id, student)
      .then(() => {
        setLoading(false);
        toast.success('User updated successfully!');
        setRegisteredUser(EnrolledType);
      })
      .catch(err => {
        // console.error(err);
        setLoading(false);
        // setError(err.message);
      });
  };
  const updateUserPayingType = (PayingType: any) => {
    student.studentPayingType = PayingType;
    setLoading(true);
    UserService.updateStudent(student.id, student)
      .then(() => {
        setLoading(false);
        toast.success('User updated successfully!');
        setPayingTypeUser(PayingType);
      })
      .catch(err => {
        // console.error(err);
        setLoading(false);
        // setError(err.message);
      });
  };
  const calculatePartFullTime = (studyType: any) => {
    student.studyType = studyType;
    if (student.studyType == 'part_time') {
      setPatFullTimeUser('Part Time');
      if (student.agreements.length > 0 && student.agreements[1].signedAt) {
        var agreementsDate = moment(student.agreements[1].signedAt);
        setLatestGraduationDate(moment(agreementsDate).add(72, 'M'));
      }
    } else if (student.studyType == 'full_time') {
      setPatFullTimeUser('Full Time');
      if (student.agreements.length > 0 && student.agreements[1].signedAt) {
        var agreementsDate = moment(student.agreements[1].signedAt);
        setLatestGraduationDate(moment(agreementsDate).add(36, 'M'));
      }
    } else {
      setPatFullTimeUser('');
    }
  };
  const getSumByKeyCoursesPass = (completedCourses: any, key: any) =>
    completedCourses.reduce(
      (accumulator: any, current: any) =>
        accumulator +
        Number(current.course[key] != null ? current.course[key] : 3),
      0,
    );
  const creditsNeededForDegree = (
    degreeCredit: any,
    creditsEarned: any,
    transferCredits: any,
  ) => {
    // credits in the degree - (credits earned + transfer credits(if any))
    const creditDegree = degreeCredit != null ? degreeCredit : 100;
    const calculation =
      Number(creditDegree) - (Number(creditsEarned) + Number(transferCredits));
    // console.log('calculation', calculation);
    return calculation;
  };
  const projectedMonthsToCompletion = (
    neededCredit: any,
    creditsEarnedPerMonth: any,
  ) => {
    const neededCredit1 = neededCredit != -1 ? neededCredit : 0;
    let calculation;
    if (Number(creditsEarnedPerMonth) != 0) {
      calculation = Number(neededCredit1) / Number(creditsEarnedPerMonth);
    } else {
      calculation = 0;
    }
    return calculation;
  };
  const creditsEarnedPerMonth = (totalCredit: any, monthsE: any) => {
    // (Total credits divided by months enrolled)
    const divided = Number(totalCredit) / Number(monthsE);
    return divided;
  };
  const studentCalculationData = (sortedData: any, setDefaultMonth: any) => {
    setSelectedMonth(setDefaultMonth);
    const datePast7Days = moment().subtract(7, 'days');
    const datePast30Days = moment().subtract(30, 'days');
    // calculate Courses passed
    const completedCourses = sortedData.filter((c: any) => c.passed == true);
    if (completedCourses) {
      const passCoursesCredit = getSumByKeyCoursesPass(
        completedCourses,
        'credit',
      );

      setTotalCreditsPassCourse(passCoursesCredit);
      setPassedCoursesCount(completedCourses.length);
      const past30Days = completedCourses.filter(
        (c: any) =>
          moment(c.passedAt).format(DATE_FORMAT) >
          datePast30Days.format(DATE_FORMAT),
      );

      if (past30Days.length > 0) {
        setPassedCoursesPast30Days(past30Days.length);
      }
      // calculate Courses passed for graph
      const current12Month: any = [];
      const monthName = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const d = new Date();
      d.setDate(1);
      for (let i = 0; i <= setDefaultMonth; i++) {
        d.setMonth(d.getMonth() - 1);
        const getMonthName =
          monthName[new Date(d.getFullYear(), d.getMonth() + 1).getMonth()];
        const getMonthIndex = monthName.indexOf(getMonthName);
        const getYear = new Date(
          d.getFullYear(),
          d.getMonth() + 1,
        ).getFullYear();
        const endD = new Date(getYear, getMonthIndex + 1, 0);
        const startD = new Date(d.getFullYear(), d.getMonth() + 1);
        current12Month.push({
          start: startD,
          end: endD,
          name: `${getMonthName} ${startD.getFullYear()}`,
        });
      }
      const categories = [];
      const series = [
        {
          name: 'logged in',
          data: Array<number>(),
          color: '#57c22d',
        },
        {
          name: 'Courses passed',
          data: Array<number>(),
          color: '#f8ca30',
        },
        {
          name: 'Objectives marked',
          // data: [2, 3, 5, 11, 12],
          data: Array<number>(),
          color: '#27c2c1',
        },
      ];
      const seriesMonthUserLog = [];
      const seriesMonthCourse = [];
      const seriesMonthObjectives = [];
      for (let i = 0; i < current12Month.length; i++) {
        const completedcccc = completedCourses.filter(
          (cc: any) =>
            moment(cc.passedAt).format(DATE_FORMAT) >=
              moment(current12Month[i].start).format(DATE_FORMAT) &&
            moment(cc.passedAt).format(DATE_FORMAT) <=
              moment(current12Month[i].end).format(DATE_FORMAT),
        );
        categories.push(current12Month[i].name);
        seriesMonthCourse.push(
          completedcccc.length > 0 ? completedcccc.length : 0,
        );
        // let sumCourse = seriesMonthCourse.reduce(
        //   (r: number, n: number) => r + n,
        // );
        series[1].data.push(completedcccc.length);

        let completedooo: any = [];
        if (student.completedObjectivesDate) {
          completedooo = student.completedObjectivesDate.filter(
            (oo: any) =>
              moment(oo.completeAt).format(DATE_FORMAT) >=
                moment(current12Month[i].start).format(DATE_FORMAT) &&
              moment(oo.completeAt).format(DATE_FORMAT) <=
                moment(current12Month[i].end).format(DATE_FORMAT) &&
              oo.completeAt,
          );
          seriesMonthObjectives.push(
            completedooo.length > 0 ? completedooo.length : 0,
          );
        } else {
          seriesMonthObjectives.push(0);
        }
        // console.log('seriesMonthObjectives', seriesMonthObjectives);
        // let sumObjectives = seriesMonthObjectives.reduce(
        //   (r: number, n: number) => r + n,
        // );
        series[2].data.push(completedooo.length);
        // calculate user activity log
        let completeduuu: any = [];
        if (student.userActivityLog) {
          completeduuu = student.userActivityLog.filter(
            (uu: any) =>
              moment(uu.userLogIn).format(DATE_FORMAT) >=
                moment(current12Month[i].start).format(DATE_FORMAT) &&
              moment(uu.userLogIn).format(DATE_FORMAT) <=
                moment(current12Month[i].end).format(DATE_FORMAT) &&
              uu.userLogIn,
          );
          seriesMonthUserLog.push(
            completeduuu.length > 0 ? completeduuu.length : 0,
          );
        } else {
          seriesMonthUserLog.push(0);
        }
        series[0].data.push(completeduuu.length);
      }
      series[1].data.reverse();
      series[2].data.reverse();
      series[0].data.reverse();
      // console.log('series', series);
      const categoriesReverse = categories.reverse();
      // console.log('categoriesReverse', categories);
      // calculate Objectives Completed
      if (student.completedObjectives) {
        setObjectivesSelfAssessedTotal(student.completedObjectives.length);
        if (student.completedObjectivesDate) {
          const past7Days = student.completedObjectivesDate.filter(
            (o: any) =>
              moment(o.completeAt).format(DATE_FORMAT) >
                datePast7Days.format(DATE_FORMAT) && o.completeAt,
          );
          if (past7Days.length > 0) {
            setObjectivesSelfAssessed7Days(past7Days.length);
          }
        }
      }
      // setChartContainer(
      //   <Chart>
      //     <ChartTitle text="" />
      //     <ChartLegend position="top" orientation="horizontal" />
      //     <ChartCategoryAxis>
      //       <ChartCategoryAxisItem
      //         title={{
      //           text: '',
      //         }}
      //         categories={categoriesReverse}
      //       />
      //     </ChartCategoryAxis>
      //     <ChartSeries>
      //       {series.map((item, idx) => (
      //         <ChartSeriesItem
      //           key={idx}
      //           type="line"
      //           tooltip={{ visible: true }}
      //           data={item.data}
      //           name={item.name}
      //           color={item.color}
      //         />
      //       ))}
      //     </ChartSeries>
      //   </Chart>,
      // );
    }
  };
  const cancelSubscription = async () => {
    await UserService.cancelSubscriptionTest(student.id);
    toast.success('Subscription cancelled!');
    // getBillingInfo();
    // getCurrentUser();
    reloadUser();
  };
  return (
    <Row gutter={16}>
      <Col span={24}>
        <PageHeader
          style={{
            background: '#fff',
            padding: '16px 16px 0',
          }}
          title={
            <>
              <h2 style={{ margin: '8px 0' }}>
                <Avatar
                  src={
                    student.avatar
                      ? student.avatar.url
                      : `https://eu.ui-avatars.com/api/?name=${student.firstName}+${student.lastName}`
                  }
                  alt={`${student.firstName} ${student.lastName}`}
                />
                {student.firstName} {student.lastName}
              </h2>
            </>
          }
        />

        <Tabs
          defaultActiveKey="1"
          style={{ background: '#fff', padding: '8px 16px' }}
        >
          <TabPane tab="Profile" key="1">
            <Row>
              <Col span={12}>
                <div style={{ marginTop: '16px' }}>
                  <p>
                    <b>Student id:</b> {student.studentId}
                  </p>
                  <p>
                    <b>Email:</b> {student.email}
                  </p>
                  <p>
                    <b>Last active:</b>{' '}
                    {student.lastActive
                      ? moment(student.lastActive).fromNow()
                      : '-'}
                  </p>
                  <p>
                    <b>Enrolled degrees:</b>{' '}
                    {student.enrolledDegrees
                      ? student.enrolledDegrees.length
                      : 0}
                  </p>
                  <p>
                    <b>Completed objectives:</b>{' '}
                    {student.completedObjectives.length}
                  </p>
                  <Divider />
                  <h3>Documents</h3>
                  {student.documents.map(document => (
                    <>
                      <p>
                        {/* <b>Description:</b>{' '} */}
                        {document.file && document.file.url ? (
                          <a
                            href={document.file.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {document.description}
                          </a>
                        ) : (
                          <span>{document.description}</span>
                        )}
                      </p>
                      {/* <p>
                        <b>Link:</b>{' '}
                        {document.file && (
                          <a
                            href={document.file.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {document.file.url}
                          </a>
                        )}
                      </p> */}
                      <Divider />
                    </>
                  ))}
                  <DocumentsForm user={student} onSubmit={reloadUser} />
                  <Divider />
                  <h3>Credits</h3>
                  {student.credits
                    ? student.credits.map(credit => (
                        <Row>
                          <Col span={8}>
                            <p>
                              <b>Institution name:</b> {credit.institution}
                            </p>
                          </Col>
                          <Col span={6}>
                            <p>
                              <b>Number of credits:</b> {credit.amount}
                            </p>
                          </Col>
                          <Col span={6}>
                            <p>
                              <b>File:</b>{' '}
                              {credit.file && (
                                <a
                                  href={credit.file.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Link
                                </a>
                              )}
                            </p>
                          </Col>
                          <Col span={4} style={{ textAlign: 'right' }}>
                            <Button
                              danger
                              size="small"
                              onClick={() => handleDeleteCredit(credit.id)}
                            >
                              Delete credit
                            </Button>
                          </Col>
                          <Divider />
                        </Row>
                      ))
                    : null}
                  <CreditsForm user={student} onSubmit={reloadUser} />
                  <Divider />
                  {isUserInstructor(user) ? (
                    <span>
                      <h3>Notes</h3>
                      {/* onSubmit={handleSubmit} */}
                      <Form>
                        {documents.map((notes, index) => (
                          <>
                            <Row>
                              <Col span={20}>
                                {editNote && editNote == notes.id ? null : (
                                  <p>
                                    <span>{notes.description}</span>
                                  </p>
                                )}
                              </Col>
                              <Col span={4} style={{ textAlign: 'right' }}>
                                {editNote && editNote == notes.id ? (
                                  <span>
                                    <Button
                                      type="primary"
                                      size="small"
                                      style={{ marginRight: '10px' }}
                                      onClick={() =>
                                        updateNotesCreateAdminForStudent(notes)
                                      }
                                    >
                                      Save
                                    </Button>
                                  </span>
                                ) : (
                                  <span>
                                    <Button
                                      type="primary"
                                      size="small"
                                      style={{ marginRight: '10px' }}
                                      onClick={() => editNotes(notes.id)}
                                    >
                                      Edit
                                    </Button>
                                  </span>
                                )}
                                <Button
                                  danger
                                  size="small"
                                  onClick={() => handleDeleteNotes(notes.id)}
                                >
                                  Delete
                                </Button>
                              </Col>
                            </Row>
                            {editNote && editNote == notes.id ? (
                              <span>
                                <Row>
                                  <Col span={24}>
                                    <Form.Item label="Description">
                                      <Input.TextArea
                                        onChange={e =>
                                          descriptionChange(e, index)
                                        }
                                        value={notes.description}
                                        rows={2}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </span>
                            ) : null}

                            <Divider />
                          </>
                        ))}
                      </Form>
                      <StudentNotesForm user={student} onSubmit={reloadUser} />
                    </span>
                  ) : null}
                </div>
              </Col>
              <Col span={12}>
                <h2>Subscription data</h2>
                <SubscriptionStatus
                  user={student}
                  subscription={subscription}
                />
                <div style={{ marginBottom: '16px' }}>
                  {isUserAdmin(user) &&
                  isUserSubscribed(student) &&
                  !(subscription && subscription.cancel_at_period_end) ? (
                    <Popconfirm
                      title="Are you sure to cancel your subscription?"
                      onConfirm={cancelSubscription}
                      okText="Pause subscription"
                      cancelText="No"
                    >
                      <Button danger size="small">
                        Pause subscription
                      </Button>
                    </Popconfirm>
                  ) : null}
                </div>
                <div>
                  <a
                    href={`https://dashboard.stripe.com/customers/${student.stripeId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on Stripe
                  </a>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Courses" key="notes">
            <div style={{ marginTop: '16px' }}>
              {userCourses && !userCourses.length && <p>No courses</p>}
              {userCourses && (
                <Row>
                  <Col span={6}>
                    <h3>Student enrolled courses</h3>
                    {isUserAdmin(user) && (
                      <Button
                        type="primary"
                        onClick={() => openAddCoursePopup(assignCourseStudent)}
                      >
                        <PlusOutlined style={{ margin: 0 }} />
                        <span className="xs--hidden">ADD</span>
                      </Button>
                    )}
                    <Menu
                      onClick={({ key }) => {
                        setSelectedCourse(
                          userCourses.find(uc => uc.id === Number(key)),
                        );
                      }}
                      selectedKeys={
                        selectedCourse ? [String(selectedCourse.id)] : []
                      }
                      mode="inline"
                    >
                      {userCourses.map(course => (
                        <Menu.Item key={course.id}>
                          {course.passed ? (
                            <>
                              <span style={{ visibility: 'hidden' }}>pas</span>
                              <CheckOutlined style={{ color: 'green' }} />
                            </>
                          ) : (
                            <>
                              {isUserAdmin(user) ? (
                                <>
                                  {userCoursesRunning(course) == true ? (
                                    <EditOutlined
                                      onClick={() => {
                                        updateStudentCourse1(course, 'stop');
                                      }}
                                    />
                                  ) : (
                                    <EditOutlined
                                      onClick={() => {
                                        updateStudentCourse1(course, 'start');
                                      }}
                                    />
                                  )}
                                  {userCoursesRunning(course) == true ? (
                                    <span className="sssss" />
                                  ) : (
                                    <Tooltip
                                      placement="bottom"
                                      title="Delete this course"
                                    >
                                      <CloseOutlined
                                        onClick={() => {
                                          deleteStudentCourse1(course);
                                        }}
                                        style={{ color: 'red' }}
                                      />
                                    </Tooltip>
                                  )}
                                </>
                              ) : (
                                <>
                                  {' '}
                                  <CloseOutlined style={{ color: 'red' }} />
                                </>
                              )}
                            </>
                          )}
                          {course.course ? course.course.name : ''} (
                          {course.notes ? course.notes.length : 0})
                        </Menu.Item>
                      ))}
                    </Menu>
                  </Col>
                  <Col span={18}>
                    {selectedCourse && (
                      <div>
                        <h3>
                          Course:{' '}
                          {selectedCourse.course
                            ? selectedCourse.course.name
                            : ''}
                        </h3>
                        {selectedCourse.passedAt && (
                          <p>
                            Passed at:{' '}
                            {moment(selectedCourse.passedAt).format(
                              DATETIME_FORMAT,
                            )}
                          </p>
                        )}
                        {selectedCourse.notes &&
                          !selectedCourse.notes.length && <p>No notes</p>}
                        {selectedCourse.notes &&
                          selectedCourse.notes.map(note => (
                            <div>
                              {note.approved && (
                                <Tag color="green">Approved</Tag>
                              )}
                              {!note.approved && (
                                <Tag color="red">Not Approved</Tag>
                              )}
                              <p style={{ marginTop: 16 }}>{note.text}</p>
                              <p>
                                Approver: {note.approver.firstName}{' '}
                                {note.approver.lastName}
                              </p>
                              <Divider />
                            </div>
                          ))}
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          </TabPane>
          {/*  <TabPane tab="Progress" key="2">
            {student.enrolledDegrees &&
              student.enrolledDegrees.map((degree: any) => (
                <>
                  <h2>{degree.degree.name}</h2>
                  <div>
                    <h3>Short description</h3>
                    <p>{degree.degree.shortDescription}</p>
                  </div>
                  <h4>Courses</h4> */}
          {/* {degree.courses && (
                    <CourseList
                      degree={degree}
                      courses={degree.courses}
                      user={student}
                      hideVideo={true}
                    />
                  )} */}
          {/* <Divider dashed />
                </>
              ))} */}
          {/* </TabPane> */}
          <TabPane tab="Progress" key="4">
            <Row>
              <Col span={12}>
                <div style={{ marginTop: '16px' }} className="tab-progress">
                  <p className="data-row">
                    <div className="title">
                      <b>Registered/Enrolled:</b>
                    </div>
                    <div className="description">
                      <Select
                        className="select-option"
                        placeholder="User subscriptionStatus"
                        value={registeredUser}
                        onChange={(value: any) => {
                          updateUserEnrolledType(value);
                        }}
                      >
                        <Select.Option key="registered" value="registered">
                          Registered
                        </Select.Option>
                        <Select.Option key="enrolled" value="enrolled">
                          Enrolled
                        </Select.Option>
                        <Select.Option key="trial" value="trial">
                          Trial
                        </Select.Option>
                        <Select.Option key="on_leave" value="on_leave">
                          On leave
                        </Select.Option>
                        <Select.Option key="archived" value="archived">
                          Archived asa
                        </Select.Option>
                        <Select.Option key="graduate" value="graduate">
                          Graduate
                        </Select.Option>
                      </Select>
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Paying/non-paying/scholarship:</b>
                    </div>
                    <div className="description">
                      <Select
                        className="select-option"
                        value={payingTypeUser}
                        onChange={(value: any) => {
                          updateUserPayingType(value);
                        }}
                      >
                        <Select.Option key="paying" value="paying">
                          Paying
                        </Select.Option>
                        <Select.Option key="non_paying" value="non_paying">
                          Non-paying
                        </Select.Option>
                        <Select.Option key="scholarship" value="scholarship">
                          Scholarship
                        </Select.Option>
                      </Select>
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Degree working towards:</b>
                    </div>
                    <div className="description">
                      {student.enrolledDegrees.length &&
                        student.enrolledDegrees.map((e: any, i: any) => (
                          <span>
                            {i + 1}: {e.degree.name}
                            <br />
                          </span>
                        ))}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Part-Time/full-time:</b>
                    </div>
                    <div className="description">
                      <Select
                        className="select-option"
                        value={patFullTimeUser}
                        onChange={(value: any) => {
                          updateUserStudyType(value);
                        }}
                      >
                        <Select.Option key="part_time" value="part_time">
                          Part time
                        </Select.Option>
                        <Select.Option key="full_time" value="full_time">
                          Full time
                        </Select.Option>
                      </Select>
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Credits Transferred:</b>
                    </div>
                    <div className="description">{creditsTransferred}</div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Credits earned at Newlane:</b>
                    </div>
                    <div className="description">
                      {totalCreditsPassCourse}
                      {/* {student.credits &&
                        student.credits.map(credit => (
                          <span>{credit.id == 2 ? credit.amount : ''}</span>
                        ))} */}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Leave of Absence Months(if any):</b>
                    </div>
                    <div className="description" />
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Last log in:</b>
                    </div>
                    <div className="description">
                      {student.lastActive
                        ? moment(student.lastActive).fromNow()
                        : '-'}
                    </div>
                  </p>
                  {/* <p className="data-row">
                    <div className="title">
                      <b>How many days logged in past 30/60/90:</b>
                    </div>
                    <div className="description">
                      {userActivityLog30Days} {' /'}
                      {userActivityLog60Days}
                      {' /'}
                      {userActivityLog90Days}
                    </div>
                  </p> */}

                  {/* <p className="data-row">
                    <div className="title">
                      <b>How many days logged in past 30:</b>
                    </div>
                    <div className="description">{userActivityLog30Days}</div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>How many days logged in past 60:</b>
                    </div>
                    <div className="description">{userActivityLog60Days}</div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>How many days logged in past 90:</b>
                    </div>
                    <div className="description">{userActivityLog90Days}</div>
                  </p> */}

                  <p className="data-row">
                    <div className="title">
                      <b>How many days logged in past:</b>
                    </div>
                    <div className="description">
                      <div>
                        <b>30:</b>&nbsp;&nbsp;&nbsp;{userActivityLog30Days}
                      </div>
                      <div>
                        <b>60:</b>&nbsp;&nbsp;&nbsp;{userActivityLog60Days}
                      </div>
                      <div>
                        <b>90:</b>&nbsp;&nbsp;&nbsp;{userActivityLog90Days}
                      </div>
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Credits needed for degree:</b>
                    </div>
                    <div className="description">
                      {/* = credits in the degree - (credits earned + transfer
                      credits(if any)) */}
                      {student.enrolledDegrees.length &&
                        student.enrolledDegrees.map((e: any, i: any) => (
                          <div>
                            <span>
                              {i + 1}: {e.degree.name} &nbsp;(
                              Credits:&nbsp;&nbsp;
                              {creditsNeededForDegree(
                                e.degree.credit,
                                totalCreditsPassCourse,
                                creditsTransferred,
                              )}{' '}
                              )
                            </span>
                            <br />
                            {/* <span style={{ marginLeft: '10px' }}>
                                <b>Credits:</b>&nbsp;&nbsp;&nbsp;
                                {creditsNeededForDegree(
                                  e.degree.credit,
                                  totalCreditsPassCourse,
                                  creditsTransferred,
                                )}
                                <br></br>
                              </span> */}
                          </div>
                        ))}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Date registered:</b>{' '}
                    </div>
                    <div className="description">
                      {student.createdAt
                        ? moment(student.createdAt).format(DATE_FORMAT)
                        : ''}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Onboarded:</b>
                    </div>
                    <div className="description">
                      {student.scheduledCalls &&
                        student.scheduledCalls.map((scheduled: any, i: any) => (
                          <div>
                            <span>
                              {i + 1}:{' '}
                              {scheduled.course ? scheduled.course.name : ''} (
                              Date:{' '}
                              {scheduled.updatedAt
                                ? moment(scheduled.updatedAt).format(
                                    DATE_FORMAT,
                                  )
                                : ''}{' '}
                              )
                            </span>
                            <br />
                            {/* <span style={{ marginLeft: '20px' }}>
                                {scheduled.updatedAt
                                  ? moment(scheduled.updatedAt).format(
                                      DATE_FORMAT,
                                    )
                                  : ''}
                                <br></br>
                              </span> */}
                          </div>
                        ))}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Date enrolled:</b>
                    </div>
                    <div className="description">
                      {student.agreements &&
                        student.agreements.map(userAgreement => (
                          <span>
                            {userAgreement.signedAt &&
                            userAgreement.agreementId == 3
                              ? moment(userAgreement.signedAt).format(
                                  DATE_FORMAT,
                                )
                              : ''}
                          </span>
                        ))}
                    </div>
                  </p>

                  <p className="data-row">
                    <div className="title">
                      <b>Courses completed:</b>
                    </div>
                    <div className="description">{passedCoursesCount}</div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Months enrolled:</b>
                    </div>
                    <div className="description">{monthsEnrolled} Months</div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Credits earned per month:</b>
                    </div>
                    <div className="description">
                      {/*= (Total credits divided by months enrolled) */}
                      {creditsEarnedPerMonth(
                        totalCreditsPassCourse,
                        monthsEnrolled,
                      ).toFixed(2)}{' '}
                      Months
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Projected Months to Completion:</b>
                    </div>
                    <div className="description">
                      {/*= (credits needed for degree divided by credits earned per
                      month) */}

                      {student.enrolledDegrees.length &&
                        student.enrolledDegrees.map((e: any, i: any) => (
                          <div>
                            <span>
                              {i + 1}: {e.degree.name} &nbsp;(
                              Months:&nbsp;&nbsp;
                              {projectedMonthsToCompletion(
                                creditsNeededForDegree(
                                  e.degree.credit,
                                  totalCreditsPassCourse,
                                  creditsTransferred,
                                ),
                                creditsEarnedPerMonth(
                                  totalCreditsPassCourse,
                                  monthsEnrolled,
                                ),
                              )}{' '}
                              )
                            </span>
                            <br />
                          </div>
                        ))}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Projected graduation date:</b>
                    </div>
                    <div className="description">
                      {/* today’s date + projected months to completion */}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Latest Graduation Date:</b>
                    </div>
                    <div className="description">
                      {latestGraduationDate
                        ? moment(latestGraduationDate).format(DATE_FORMAT)
                        : ' '}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Credits needed to enroll:</b>
                    </div>
                    <div className="description" />
                  </p>

                  <p className="data-row">
                    <div className="title">
                      <b>Default Enrollment date:</b>
                    </div>
                    <div className="description" />
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Projected Enrollment date:</b>
                    </div>
                    <div className="description" />
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Objectives self-assessed (total):</b>
                    </div>
                    <div className="description">
                      {objectivesSelfAssessedTotal}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Objectives self-assessed (in the past 7 days):</b>
                    </div>
                    <div className="description">
                      {objectivesSelfAssessed7Days}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Course exams completed (in the past 30 days):</b>
                    </div>
                    <div className="description">{passedCoursesPast30Days}</div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Projects completed (in the past 30 days):</b>
                    </div>
                    <div className="description">
                      {projectsCompletedPast30Days}
                    </div>
                  </p>
                  <p className="data-row">
                    <div className="title">
                      <b>Last active:</b>
                    </div>
                    <div className="description">
                      {student.lastActive
                        ? moment(student.lastActive).format(DATE_FORMAT)
                        : '-'}
                    </div>
                  </p>
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
              <Col span={24} className="selected-month-button">
                <span>Months</span>
                <a
                  className={
                    selectedMonth == 3 ? 'active-month' : 'inactive-month'
                  }
                  onClick={() => studentCalculationData(userCourses, 3)}
                >
                  3
                </a>
                <a
                  className={
                    selectedMonth == 12 ? 'active-month' : 'inactive-month'
                  }
                  onClick={() => studentCalculationData(userCourses, 12)}
                >
                  12
                </a>
                <a
                  className={
                    selectedMonth == 24 ? 'active-month' : 'inactive-month'
                  }
                  onClick={() => studentCalculationData(userCourses, 24)}
                >
                  24
                </a>
              </Col>
              <Col span={24}>{ChartContainer}</Col>
            </Row>
          </TabPane>
          <TabPane tab="Onboarding" key="3">
            {student.onboarding && (
              <OnboardingComponent
                onboarding={student.onboarding}
                user={user}
                studentId={student.id}
              />
            )}
          </TabPane>
        </Tabs>
      </Col>
      <>
        {deletedUserCourse && (
          <Modal
            title="Are you sure you want to delete course?"
            width={450}
            visible={deleteModalVisible}
            okText="Delete course"
            okButtonProps={{ loading, danger: true }}
            onOk={() => deleteStudentCourse()}
            onCancel={() => setDeleteModalVisible(false)}
          >
            <p>You will not be able to revert this action</p>
          </Modal>
        )}

        {updateUserCourse && (
          <Modal
            title={
              updateStatusMsg == 'start'
                ? 'Confirmation'
                : `Are you sure you want to change course status progress to initial level?`
            }
            width={450}
            visible={updateModalVisible}
            okText="Update Course Status"
            okButtonProps={{ loading }}
            onOk={() => upateStudentCourse()}
            onCancel={() => setUpdateModalVisible(false)}
          >
            {updateStatusMsg == 'start' ? (
              <h3>Are you sure you want to {updateStatusMsg} course?</h3>
            ) : (
              <p>
                You will not be able to revert this action. This course All in
                progress. delete user this course objetive if attend this user.
              </p>
            )}
          </Modal>
        )}
        <Modal
          title="Add course "
          width={550}
          visible={addModalVisible}
          okText="Add course"
          okButtonProps={{ loading }}
          onOk={() => addStudentCourse()}
          onCancel={() => setAddModalVisible(false)}
        >
          <div style={{ marginTop: 8, marginBottom: 16 }}>
            <p style={{ margin: '0 0 8px 0' }}>Course:</p>
            <Select
              placeholder="Select course"
              style={{ width: '300px' }}
              onSelect={(value: any) => {
                setAddedUserCourse(value);
              }}
            >
              {allCourseStore &&
                allCourseStore.map((c: any) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </Modal>
      </>
    </Row>
  );
};

const selectStudentsPageState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedSingleStudentPage = withStudent(
  connect(selectStudentsPageState, {})(SingleStudentPage),
);

const Page = () => <ConnectedSingleStudentPage />;

export default Page;
