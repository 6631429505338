import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  Table,
  Input,
  Select,
  Card,
  Alert,
  PageHeader,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactQuill from 'react-quill';
import { AppState } from '@redux/reducers';
import AgreementService from '@services/AgreementService';
import { ColumnProps } from 'antd/lib/table';
import NewlaneTutorialsList from '@components/newlane-tutorials/NewlaneTutorialsList';
import { useHistory } from 'react-router';
import { isUserAdmin } from '@helpers';
interface NewlaneTutorialsProps {
  user?: User;
  NewlaneTutorials?: NewlaneTutorials;
}
const NewlaneTutorials: React.FC<NewlaneTutorialsProps> = ({
  user,
  NewlaneTutorials,
}) => {
  const history = useHistory();
  if (!user) {
    return null;
  }
  return (
    <>
      <Row gutter={[16, { sm: 16 }]}>
        <Col md={{ offset: 5, span: 14 }}>
          <div>
            {isUserAdmin(user) && (
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  onClick={() => history.push('/create-newlane-tutorials')}
                >
                  Create Newlane Tutorials
                </Button>
              </div>
            )}
            <h1 style={{ margin: '15px 0' }}>Newlane Tutorials</h1>
            <NewlaneTutorialsList></NewlaneTutorialsList>
          </div>
        </Col>
      </Row>
    </>
  );
};

const newlaneTutorialsState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedNewlaneTutorials = connect(
  newlaneTutorialsState,
  {},
)(NewlaneTutorials);
const Page = () => <ConnectedNewlaneTutorials />;

export default Page;
