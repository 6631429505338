import React, { useState, FunctionComponent, useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Typography } from 'antd';
import { connect } from 'react-redux';

import { AppState } from '@redux/reducers';
import { getUserNotes } from '@redux/reducers/userNotes';

import { COLLAPSED_ON, DRAWER_WIDTH, DRAWER_MD_WIDTH } from './constants';
import NotesForm from './NotesForm';
import SingleNote from './SingleNote';

import './Notes.less';

const { Title, Text } = Typography;

interface Note {
  id?: number;
  name?: string;
  userNotes?: UserNote[];
  objectives?: {
    id: number;
    name: string;
    userNotes: UserNote[];
  }[];
  lessons?: {
    id: number;
    name: string;
    userNotes: UserNote[];
  }[];
}

interface StateProps {
  notes: Note;
}

interface DispatchProps {
  getUserNotes: typeof getUserNotes;
}

interface Props {
  relId: number;
  rel: string;
  canAdd?: boolean;
  canEdit?: boolean;
  canRemove?: boolean;
}

type NotesProps = Props & StateProps & DispatchProps;

const Notes: FunctionComponent<NotesProps> = ({
  rel,
  relId,
  getUserNotes: getNotes,
  notes,
  canAdd = false,
  canEdit = false,
  canRemove = false,
}) => {
  // Drawer
  const [drawerOn, setDrawerOn] = useState(false);
  const deviceWidth = window.innerWidth;
  const drawerWidth =
    deviceWidth <= COLLAPSED_ON ? DRAWER_WIDTH : DRAWER_MD_WIDTH;
  const drawerClasses = `notes ${drawerOn ? 'notes--open' : ''}`;
  const drawerRight = {
    right: !drawerOn
      ? 0
      : deviceWidth <= COLLAPSED_ON
      ? DRAWER_WIDTH
      : DRAWER_MD_WIDTH,
  };

  const closeDrawer = () => {
    setDrawerOn(false);
  };

  const toggleDrawer = () => {
    setDrawerOn(!drawerOn);
  };

  const handleUpdate = () => getNotes({ relId, rel });

  // Notes for objective id
  useEffect(() => {
    getNotes({ relId, rel });
  }, [getNotes, relId, rel]);

  // Notes list
  const prepareNotesList = (userNotes: UserNote[] = []) => (
    <>
      {userNotes.length > 0 &&
        userNotes.map((n: any) => (
          <SingleNote
            note={n}
            key={n.id}
            canEdit={canEdit}
            canRemove={canRemove}
            onUpdate={handleUpdate}
          />
        ))}
    </>
  );

  const getNotesList = () => {
    if (rel === 'course') {
      const noteList = (
        <>
          {notes.lessons &&
            notes.lessons.length > 0 &&
            notes.lessons.map((l: any) => (
              <div className="notes--notes-box" key={l.name}>
                <h5 className="notes--notes-subheading">{l.name}</h5>
                {prepareNotesList(l.userNotes)}
              </div>
            ))}
        </>
      );
      return noteList;
    }
    if (rel === 'lesson') {
      const noteList = (
        <>
          {notes.objectives &&
            notes.objectives.length > 0 &&
            notes.objectives.map((o: any) => (
              <div className="notes--notes-box" key={o.id}>
                <h5 className="notes--notes-subheading">{o.name}</h5>
                {prepareNotesList(o.userNotes)}
              </div>
            ))}
        </>
      );
      return noteList;
    }
    const noteList = <>{prepareNotesList(notes.userNotes)}</>;
    return noteList;
  };

  return (
    <div className={drawerClasses} style={drawerRight}>
      <Button
        type="primary"
        onClick={toggleDrawer}
        className="notes--btn"
        size="large"
      >
        <EditOutlined className="notes--icon" />
      </Button>
      <Drawer
        title={
          <>
            <Title level={3} className="drawer--title">
              Notes
            </Title>
            {notes && (
              <Text className="drawer--subtitle">
                Personal notes for {notes.name}
              </Text>
            )}
          </>
        }
        width={drawerWidth}
        bodyStyle={{ padding: '16px 24px' }}
        onClose={closeDrawer}
        visible={drawerOn}
        className="notes--drawer"
      >
        <div className="notes--notes">{getNotesList()}</div>
        {canAdd && (
          <>
            <h4 style={{ margin: 0 }}>Add new note</h4>
            <NotesForm />
          </>
        )}
      </Drawer>
    </div>
  );
};

const selectUserNotesState = (state: AppState) => {
  const { notes, errors, isLoading } = state.userNotes;
  return {
    notes,
    errors,
    isLoading,
  };
};

export default connect(selectUserNotesState, { getUserNotes })(Notes);
