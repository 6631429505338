import * as React from 'react';

class ContactPage extends React.Component {
  render() {
    return (
      <div>
        <div className="_form_14" />
        <script
          src="https://newlaneuniversity.activehosted.com/f/embed.php?id=14"
          type="text/javascript"
          charSet="utf-8"
        />
      </div>
    );
  }
}

export default () => <ContactPage />;
