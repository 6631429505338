import { io } from 'socket.io-client';

const connectToSockets = (
  fetchVideoCalls: any,
  fetchScheduledCalls: any,
  addNotification: any,
) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    return false;
  }

  const config = {
    path: '/socket.io',
    query: {
      token,
    },
    timeout: 3000,
  };

  const socket = io(
    process.env.REACT_APP_SOCKETS_URL || 'localhost:3000',
    config,
  );

  socket.on('newNotification', (notification: Notification) => {
    addNotification(notification);
  });

  socket.on('newVideoCall', () => {
    fetchVideoCalls();
  });

  socket.on('updatedScheduledCall', () => {
    fetchScheduledCalls();
  });

  socket.on('reconnect_attempt', () => {
    // debugger;
    socket.io.opts.transports = ['websocket'];
  });
};

export default connectToSockets;
