import { takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import ApiService from '@services/apiService';

import {
  createChatError,
  createChatSuccess,
  getChatsError,
  getChatsSuccess,
  sendMessageSuccess,
  sendMessageError,
  getChatSuccess,
  getChatError,
  CreateChatPayload,
  SendMessagePayload,
} from '../reducers/chat';

function* createChat({
  payload,
}: PayloadAction<CreateChatPayload>): Generator<any, any, any> {
  try {
    const response = yield ApiService.post(`/chats`, payload) as Promise<
      Chat[]
    >;

    const json = response.data;

    yield put(createChatSuccess(json));
  } catch (err: any) {
    console.error(err);
    yield put(createChatError(err));
  }
}

function* getChats(): Generator<any, any, any> {
  try {
    const response = yield ApiService.get(`/chats`) as Promise<Chat[]>;

    const json = response.data;

    yield put(getChatsSuccess(json));
  } catch (err: any) {
    console.error(err);
    yield put(getChatsError(err));
  }
}

function* sendMessage({
  payload: { chatId, text, sendChatUserId },
}: PayloadAction<SendMessagePayload>): Generator<any, any, any> {
  try {
    const response = yield ApiService.post(`/chats/${chatId}/messages`, {
      text,
      sendChatUserId,
    }) as Promise<Chat>;

    const json = response.data;

    yield put(sendMessageSuccess(json));
  } catch (err: any) {
    console.error(err);
    yield put(sendMessageError(err));
  }
}

function* getChat({
  payload: id,
}: PayloadAction<number>): Generator<any, any, any> {
  try {
    const response = yield ApiService.get(`/chats/${id}`) as Promise<Chat>;

    const json = response.data;

    yield put(getChatSuccess(json));
  } catch (err: any) {
    console.error(err);
    yield put(getChatError(err));
  }
}

function* watchForRequestData() {
  yield takeLatest('chat/createChat', createChat);
  yield takeLatest('chat/getChats', getChats);
  yield takeLatest('chat/getChat', getChat);
  yield takeLatest('chat/sendMessage', sendMessage);
}

export default watchForRequestData;
