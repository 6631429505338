import React, { useEffect } from 'react';
import { Progress } from 'antd';
import { getDegreeCompletedPercentage } from '@utils/helpers';

type DegreeProgressProps = {
  degreeId: number;
  user: User;
  circle?: boolean;
};

export const DegreeProgress: React.FC<DegreeProgressProps> = ({
  degreeId,
  user,
  circle = false,
}) => {
  const [percent, setPercent] = React.useState(0);

  const getCompletedPercentage = async () => {
    const degreePercent = await getDegreeCompletedPercentage(degreeId, user);
    setPercent(degreePercent);
  };

  useEffect(() => {
    getCompletedPercentage();
  }, []);

  return (
    <Progress type={circle ? 'circle' : 'line'} percent={percent} width={80} />
  );
};
