import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

export interface ChatState {
  isLoading: boolean;
  error: boolean;
  chats?: Chat[];
  token?: string;
  selectedChat?: Chat;
  errors?: ApiError['errors'];
}

export interface SendMessagePayload {
  chatId: number;
  text: string;
  sendChatUserId: any;
}

export interface CreateChatPayload {
  userId: number;
  text: string;
}

const initialState: ChatState = {
  isLoading: false,
  error: false,
};

const UserSlice = createSlice({
  initialState,
  name: 'chat',
  reducers: {
    createChat(state, action: PayloadAction<CreateChatPayload>) {
      return state;
    },
    createChatSuccess(state, action: PayloadAction<Chat[]>) {
      const sortedChats = _.sortBy(action.payload, 'updatedAt');
      return {
        ...state,
        isLoading: false,
        chats: sortedChats,
        selectedChat: _.last(sortedChats),
      };
    },
    createChatError(state, action: PayloadAction<ApiError['errors']>) {
      return { ...state, ...action.payload, isLoading: false, error: true };
    },
    getChats(state) {
      return state;
    },
    getChatsSuccess(state, action: PayloadAction<Chat[]>) {
      const sortedChats = _.sortBy(action.payload, 'updatedAt');
      return {
        ...state,
        isLoading: false,
        chats: sortedChats,
        selectedChat: state.selectedChat || sortedChats[0],
      };
    },
    getChatsError(state, action: PayloadAction<ApiError['errors']>) {
      return { ...state, ...action.payload, isLoading: false, error: true };
    },
    sendMessage(state, action: PayloadAction<SendMessagePayload>) {
      return state;
    },
    sendMessageSuccess(state, action: PayloadAction<Chat>) {
      const chat = action.payload;
      const newChats = _.cloneDeep(state.chats || []);
      const index = _.findIndex(newChats, c => c.id === chat.id);
      if (index !== -1) {
        newChats[index] = chat;
      }
      return {
        ...state,
        isLoading: false,
        chats: newChats,
        selectedChat: chat,
      };
    },
    sendMessageError(state, action: PayloadAction<ApiError['errors']>) {
      return { ...state, ...action.payload, isLoading: false, error: true };
    },
    selectChat(state, action: PayloadAction<Chat>) {
      const chat = action.payload;
      return {
        ...state,
        selectedChat: chat,
      };
    },
    getChat(state, action: PayloadAction<number>) {
      return state;
    },
    getChatSuccess(state, action: PayloadAction<Chat>) {
      return {
        ...state,
        isLoading: false,
        selectedChat: action.payload,
      };
    },
    getChatError(state, action: PayloadAction<ApiError['errors']>) {
      return { ...state, ...action.payload, isLoading: false, error: true };
    },
  },
});

const { actions, reducer } = UserSlice;

export const {
  createChat,
  createChatSuccess,
  createChatError,
  getChats,
  getChatsSuccess,
  getChatsError,
  getChat,
  getChatSuccess,
  getChatError,
  sendMessage,
  sendMessageSuccess,
  sendMessageError,
  selectChat,
} = actions;
export default reducer;
