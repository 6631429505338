import React, { useEffect, useState } from 'react';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  QuestionCircleTwoTone,
} from '@ant-design/icons';
import { List, Button, Modal, Layout, Input, Menu } from 'antd';
import ApiService from '@services/apiService';
import { sortBy } from 'lodash';
import moment from 'moment';
import './ScheduleDayAgenda.less';
import { Link } from 'react-router-dom';
import { isUserInstructor } from '@helpers';
import CourseService from '@services/courseService';
import Message from '@components/Message';
import { joinVideoCall } from '@redux/reducers/videoCall';
import { getChatUserName } from '@screens/chat/helpers';
import NewChatCourseHearingModal from '@screens/chat/NewChatCourseHearingModal';
import { createChat, CreateChatPayload } from '@redux/reducers/chat';
import UserService from '@services/userService';
import { TIME_FORMAT } from './constants';

const { Header, Footer, Sider, Content } = Layout;

interface ScheduleDayAgendaProps {
  calls: ScheduledCall[];
  user: User;
  loadCalls?: any;
  joinVideoCall: typeof joinVideoCall;
}

const ScheduleDayAgenda: React.FC<ScheduleDayAgendaProps> = ({
  calls: dayCalls,
  user,
  loadCalls,
  joinVideoCall,
}) => {
  const [loading, setLoading] = useState(false);
  // const calls = sortBy(dayCalls, 'startTime');
  const calls1 = sortBy(dayCalls, 'startTime');
  const calls = calls1.filter(
    (obj, index, self) =>
      index ===
      self.findIndex(
        t => t.courseId === obj.courseId && t.studentId === obj.studentId,
      ),
  );
  const [instructorDetials, setInstructor] = useState('');
  const [instructorDetialsAll, setInstructorDetialsAll] = useState([]);
  const [studentInstructorChat, setStudentInstructorChat] = useState(false);
  const [message, setMessage] = useState<string>();
  const [interval, setIntervalRef] = useState<any>();
  const [selectedChat, setSelectedChat] = useState([]);
  const [chatId, setChatId] = useState<any>();
  const [chatsUsers, setChatsUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [newChatVisible, setNewChatVisible] = useState(false);
  const [chatUserId, setChatUserId] = useState();
  const [chatTitle, setChatTitle] = useState('');
  const [allInstructorOfCourse, setAllInstructorOfCourse] = useState([]);
  const [allStudentOfCourse, setAllStudentOfCourse] = useState([]);

  useEffect(() => {
    if (user.role == 'instructor') {
      setChatTitle('Select Student to chat about course hearing');
    } else {
      setChatTitle('Select Professor to chat about course hearing');
    }
  }, []);

  if (calls.length) {
    if (chats.length == 0) {
      UserService.getChats(user.id).then((d: any) => {
        if (d.length > 0) {
          // setChats(d);
          // setSelectedChat(d[0].messages);
          // setChatId(d[0].id);
          // setChatsUsers(d[0].users);
          if (user.role == 'instructor') {
            const storeId: any = [];
            CourseService.getStudentsCourseHearing(calls[0].course.id).then(
              (student: any) => {
                setAllStudentOfCourse(student);
                if (student.length > 0) {
                  const newArray: any = [];
                  for (var i = 0; i < d.length; i++) {
                    const found = student.find(
                      (s: any) =>
                        s.studentId === d[i].users[0].id ||
                        s.studentId === d[i].users[1].id,
                    );
                    if (found != undefined) {
                      newArray.push(d[i]);
                    }
                  }
                  setChats(newArray);
                  setSelectedChat(newArray[0].messages);
                  setChatId(newArray[0].id);
                  setChatsUsers(newArray[0].users);
                }
              },
            );
          } else {
            CourseService.getInstructors(calls[0].course.id).then(
              (teachur: any) => {
                setAllInstructorOfCourse(teachur);
                if (teachur.length > 0) {
                  const newArrayTeachur: any = [];
                  for (var i = 0; i < d.length; i++) {
                    const found = teachur.find(
                      (s: any) =>
                        s.id === d[i].users[0].id || s.id === d[i].users[1].id,
                    );
                    if (found != undefined) {
                      newArrayTeachur.push(d[i]);
                    }
                  }
                  setChats(newArrayTeachur);
                  setSelectedChat(newArrayTeachur[0].messages);
                  setChatId(newArrayTeachur[0].id);
                  setChatsUsers(newArrayTeachur[0].users);
                }
              },
            );
          }

          // for (var i = 0; i < d.length; i++) {
          //   let chat = d[i];
          //   //const chatUser = d[i].users.find((u: any) => u.id == 381);
          //   setSelectedChat(chat.messages);
          //   setChatId(chat.id);
          //   setChatsUsers(chat.users);
          //   //   if (
          //   //     d[i].users[0].id == videoCall.student.id &&
          //   //     d[i].users[1].id == videoCall.instructor.id
          //   //   ) {
          //   //     if (chat.messages.length > 0) {
          //   //       setSelectedChat(chat.messages);
          //   //       setChatId(chat.id);
          //   //     }
          //   //     return;
          //   //   }
          //   //   /* if (chatUser) {
          //   //     if (chat.messages.length > 0) {
          //   //       setSelectedChat(chat.messages);
          //   //       setChatId(chat.id);
          //   //     }
          //   //     return;
          //   //   }*/
          // }
        }
      });
    }
  }

  const isMyMessage = (message: Message) => message.userId === user.id;

  const send = (chat: CreateChatPayload) => {
    createChatNew(chat);
    setNewChatVisible(false);
  };
  const createChatNew = async (chat: any) => {
    try {
      const aa = await ApiService.post(`/chats`, chat);
      const chatRRR = aa.data;
      if (user.role == 'instructor') {
        if (allStudentOfCourse.length > 0) {
          const newArray: any = [];
          for (var i = 0; i < chatRRR.length; i++) {
            const found = allStudentOfCourse.find(
              (s: any) =>
                s.studentId === chatRRR[i].users[0].id ||
                s.studentId === chatRRR[i].users[1].id,
            );
            if (found != undefined) {
              newArray.push(chatRRR[i]);
            }
          }
          const foundCurruntUser = newArray.find(
            (s: any) =>
              chat.userId === s.users[0].id || chat.userId === s.users[1].id,
          );
          setChats(newArray);
          setSelectedChat(foundCurruntUser.messages);
          setChatId(foundCurruntUser.id);
          setChatsUsers(foundCurruntUser.users);
          if (interval) {
            clearInterval(interval);
            setIntervalRef(undefined);
          }
        }
      } else if (allInstructorOfCourse.length > 0) {
        const newArrayTeachur: any = [];
        for (var i = 0; i < chatRRR.length; i++) {
          const found = allInstructorOfCourse.find(
            (s: any) =>
              s.id === chatRRR[i].users[0].id ||
              s.id === chatRRR[i].users[1].id,
          );
          if (found != undefined) {
            newArrayTeachur.push(chatRRR[i]);
          }
        }
        setChats(newArrayTeachur);
        const foundCurruntUser = newArrayTeachur.find(
          (s: any) =>
            chat.userId === s.users[0].id || chat.userId === s.users[1].id,
        );
        setSelectedChat(foundCurruntUser.messages);
        setChatId(foundCurruntUser.id);
        setChatsUsers(foundCurruntUser.users);
        if (interval) {
          clearInterval(interval);
          setIntervalRef(undefined);
        }
      }
    } catch (err) {
      // toast.error('An error occured!');
    }
  };
  const handleSendMessage = (value: string) => {
    if (selectedChat) {
      if (chatsUsers && chatsUsers.length == 2) {
        const newArray = chatsUsers.filter((el: any) => el.id != user.id);
        const sendChatUserId = user.id; // newArray[0].id;
        setMessage(undefined);
        UserService.sendMessage(chatId, value, sendChatUserId).then(
          (d: any) => {
            getUserChats();
          },
        );
      } else {
        // toast.error('Please select user!');
      }
    }
  };
  if (chatId && !interval) {
    const int = setInterval(() => {
      getUserChats();
    }, 5000);
    setIntervalRef(int);
  }

  const getUserChats = () => {
    UserService.getUserChats(chatId).then((d: any) => {
      if (d && d.messages) {
        setSelectedChat(d.messages);
      }
    });
  };
  const handleSelectChat = ({ key }: { key: string }) => {
    // const newChat = chats && chats.find((c: any) => c.id === Number(key));
    // // console.log('newChat', newChat);
    // if (interval) {
    //   clearInterval(interval);
    //   setIntervalRef(undefined);
    // }
    // if (newChat) {
    //   console.log('newChat', newChat);
    //   //selectChat(newChat);
    //   //setSelectedChat(newChat);

    //   // setSelectedChat(newChat.messages);
    //   // setChatId(newChat.id);
    //   // setChatsUsers(newChat.users);
    // }
    const newChat = chats.filter((e: any) => {
      if (e.id == Number(key)) {
        setSelectedChat(e.messages);
        setChatId(e.id);
        setChatsUsers(e.users);
        if (interval) {
          clearInterval(interval);
          setIntervalRef(undefined);
        }
      }
    });
  };
  const acceptCall = (call: ScheduledCall) => {
    setLoading(true);
    CourseService.updateScheduledCall(call.id, {
      confirmed: true,
      rejected: false,
      instructorId: user.id,
    })
      .then(() => {
        loadCalls && loadCalls();
        setLoading(false);
      })
      .catch(err => setLoading(false));
  };

  const rejectCall = (call: ScheduledCall) => {
    setLoading(true);
    CourseService.updateScheduledCall(call.id, {
      confirmed: false,
      rejected: true,
      instructorId: user.id,
    })
      .then(() => {
        loadCalls && loadCalls();
        setLoading(false);
      })
      .catch(err => setLoading(false));
  };

  if (calls.length === 0) {
    return (
      <p style={{ textAlign: 'center', marginTop: 16 }}>
        Nothing Scheduled Today
      </p>
    );
  }
  const fetchInstructors = async () => {
    UserService.getInstructors().then((aaaa: any) => {
      setInstructorDetialsAll(aaaa);
    });
    // setInstructorDetialsAll(allData);
    // allData.map(u => {
    //   if (calls[0].instructorId != null && u.id == calls[0].instructorId) {
    //     var name = u.firstName + ' ' + u.lastName;
    //     setInstructor(name);
    //   }
    //   if (calls[0].instructorId == null) {
    //     setInstructor('');
    //   }
    // });
  };
  if (calls) {
    // fetchInstructors();
  }

  return (
    <List
      dataSource={calls}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <div className="schedule-day-agenda-avatar">
                <span className="schedule-day-agenda-hours">
                  {moment.unix(item.startTime).format(TIME_FORMAT)}
                </span>
                <br />
                <span className="schedule-day-agenda-confirmed">
                  {item.confirmed ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : item.rejected ? (
                    <CloseCircleTwoTone twoToneColor="red" />
                  ) : (
                    <QuestionCircleTwoTone twoToneColor="#faad14" />
                  )}
                </span>
              </div>
            }
            title={
              item.type === 'onboarding' ? (
                <span>
                  <b>Onboarding call</b>
                  <br />
                  {item.confirmed ? (
                    <span style={{ color: '#52c41a' }}>CONFIRMED</span>
                  ) : item.rejected ? (
                    <span style={{ color: 'red' }}>THIS CALL WAS REJECTED</span>
                  ) : (
                    <span style={{ color: '#faad14' }}>
                      WAITING FOR CONFIRMATION
                    </span>
                  )}
                </span>
              ) : (
                <Link to={`/courses/${item.course.id}`}>
                  <b>Course Hearing</b>: {item.course.name}
                </Link>
              )
            }
            description={
              <div>
                {isUserInstructor(user) && (
                  <div>
                    <div style={{ marginBottom: 6 }}>
                      {item.type === 'courseHearing' ? (
                        <Link
                          to={`/courses/${item.course.id}/students/${item.student.id}`}
                        >
                          <span>
                            <b>Student:</b> {item.student.firstName}{' '}
                            {item.student.lastName}
                          </span>
                        </Link>
                      ) : (
                        <span>
                          <b>Student:</b> {item.student.firstName}{' '}
                          {item.student.lastName}{' '}
                        </span>
                      )}
                    </div>
                    {/* <div
                        style={{ marginBottom: 8, marginTop: 2, color: '#333' }}
                      >
                        {instructorDetials != '' ? (
                          <span>Professor: {instructorDetials}</span>
                        ) : (
                          <span></span>
                        )}
                      </div> */}
                    {!item.confirmed ? (
                      <Button
                        type="primary"
                        ghost
                        size="small"
                        style={{ marginRight: '8px' }}
                        onClick={() => acceptCall(item)}
                        disabled={loading}
                        loading={loading}
                      >
                        Accept
                      </Button>
                    ) : (
                      <Message type="success" text="Accepted" />
                    )}
                    {!item.rejected ? (
                      <Button
                        danger
                        ghost
                        size="small"
                        style={{ marginLeft: item.confirmed ? '8px' : 0 }}
                        onClick={() => rejectCall(item)}
                        disabled={loading}
                        loading={loading}
                      >
                        Reject
                      </Button>
                    ) : (
                      <Message type="danger" text="Rejected" />
                    )}
                  </div>
                )}
                {/* {!isUserInstructor(user) && (
                    <div
                      style={{ marginBottom: 8, marginTop: 2, color: '#333' }}
                    >
                      {instructorDetials != '' ? (
                        <span>Professor: {instructorDetials}</span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  )} */}

                {instructorDetialsAll.map((u: any) => (
                  <div style={{ marginBottom: 8, marginTop: 2, color: '#333' }}>
                    {u.id == item.instructorId ? (
                      <span>
                        Professor: {u.firstName} {u.lastName}
                      </span>
                    ) : (
                      <span />
                    )}
                  </div>
                ))}
                {item.confirmed && item.videoCallId && (
                  <div>
                    <Button
                      type="primary"
                      ghost
                      size="small"
                      style={{ marginRight: '8px' }}
                      onClick={() =>
                        item.videoCallId && joinVideoCall(item.videoCallId)
                      }
                      disabled={loading}
                      loading={loading}
                    >
                      Join video call
                    </Button>
                  </div>
                )}
                {item.confirmed ? (
                  <div>
                    <Button
                      style={{ marginTop: '10px' }}
                      type="primary"
                      size="small"
                      ghost
                      onClick={() => setStudentInstructorChat(true)}
                    >
                      Chats
                    </Button>
                  </div>
                ) : (
                  ''
                )}

                <Modal
                  className="chat-course-hearing"
                  title={chatTitle}
                  centered
                  footer={false}
                  visible={studentInstructorChat}
                  onOk={() => setStudentInstructorChat(false)}
                  onCancel={() => setStudentInstructorChat(false)}
                  width={1000}
                >
                  <Layout style={{ minHeight: '85vh' }}>
                    <Sider
                      style={{
                        background: '#fff',
                        borderRight: '1px solid #ddd',
                        overflow: 'auto',
                      }}
                    >
                      {/* <div style={{ padding: 12 }}>
                          <h4 style={{ textAlign: 'center', marginTop: 8 }}>
                            No chats
                          </h4>
                        </div> */}
                      <div style={{ padding: 12 }}>
                        <Button
                          type="default"
                          block
                          onClick={() => {
                            setNewChatVisible(true);
                          }}
                        >
                          {/* {chatTitle} */}
                          Start new chat
                        </Button>
                        {chats && !chats.length && (
                          <h4 style={{ textAlign: 'center', marginTop: 8 }}>
                            No chats
                          </h4>
                        )}
                      </div>
                      <Menu
                        mode="inline"
                        onSelect={handleSelectChat}
                        selectedKeys={selectedChat && [String(chatId)]}
                      >
                        {chats &&
                          chats.map((chat: any) => (
                            <Menu.Item key={chat.id}>
                              <span>{getChatUserName(user, chat.users)}</span>
                            </Menu.Item>
                          ))}
                      </Menu>
                    </Sider>
                    <Layout>
                      <Header
                        style={{
                          background: '#fff',
                          paddingLeft: 12,
                          borderBottom: '1px solid #ddd',
                          position: 'fixed',
                          zIndex: 1,
                          width: '42%',
                        }}
                      >
                        {selectedChat && (
                          <h2>{getChatUserName(user, chatsUsers)}</h2>
                        )}
                      </Header>
                      <Content
                        style={{
                          padding: 12,
                          paddingTop: 72,
                          background: '#fff',
                          overflow: 'auto',
                        }}
                      >
                        {!selectedChat && (
                          <div
                            style={{
                              textAlign: 'center',
                              marginTop: '150px',
                            }}
                          >
                            <h3>
                              Please select chat on the left to see messages.
                            </h3>
                          </div>
                        )}
                        {selectedChat &&
                          selectedChat &&
                          selectedChat.map((message: any) => (
                            <div
                              key={message.id}
                              style={{
                                margin: '6px 0',
                                textAlign: isMyMessage(message)
                                  ? 'right'
                                  : 'left',
                              }}
                            >
                              <div
                                style={{
                                  padding: '6px 12px',
                                  border: '1px solid #ddd',
                                  background: isMyMessage(message)
                                    ? '#46A5E4'
                                    : '#bbb',
                                  display: 'inline-block',
                                  borderRadius: 8,
                                  color: '#fff',
                                }}
                              >
                                <p style={{ margin: 0 }}>{message.text}</p>
                                <span>
                                  <small>
                                    {moment(message.createdAt).fromNow()}
                                  </small>
                                </span>
                              </div>
                            </div>
                          ))}
                      </Content>
                      <Footer
                        style={{
                          background: '#fff',
                          paddingLeft: 12,
                          borderTop: '1px solid #ddd',
                          zIndex: 1,
                          width: '100%',
                        }}
                      >
                        {selectedChat && (
                          <Input.Search
                            placeholder="Enter message"
                            enterButton="Send"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            onSearch={handleSendMessage}
                            width="100%"
                          />
                        )}
                      </Footer>
                    </Layout>
                    {newChatVisible && (
                      <NewChatCourseHearingModal
                        send={send}
                        onClose={() => {
                          setNewChatVisible(false);
                          setMessage(undefined);
                        }}
                        chatUserId={chatUserId}
                        courseId={item.course.id}
                        userRole={user.role}
                      />
                    )}
                  </Layout>
                </Modal>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default ScheduleDayAgenda;
