import React, { useMemo, useState } from 'react';
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Input, Upload, Button, Divider, Form, Space } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { toast } from 'react-toastify';
import ApiService from '@services/apiService';
import UserService from '@services/userService';

interface CreditsFormProps {
  user: User;
  onSubmit: () => void;
}

export const CreditsForm: React.FC<CreditsFormProps> = ({ user, onSubmit }) => {
  const [credits, setCredits] = useState<Credit[]>([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [form] = Form.useForm<{ credits: Credit[] }>();
  const { validateFields } = form;

  const handleSubmit = () => {
    validateFields().then(values => {
      setUpdateLoading(true);

      const normalizedCredits = values.credits.map(credit => ({
        ...credit,
        file: (credit.file as any)?.[0].response,
      }));

      UserService.updateCredits(user.id, normalizedCredits)
        .then(d => {
          setUpdateLoading(false);
          toast.success('Update successful!');
          // onSubmit();
        })
        .catch(() => {
          toast.error('Update failed!');
          setUpdateLoading(false);
        });
    });
  };

  const onUploadChange = (
    { fileList, file }: UploadChangeParam,
    index: number,
  ) => {
    if (fileList.length > 1) {
      fileList.splice(0, 1);
    }

    if (file.percent === 100) {
      const uploadedFile = file.response;

      const newData = credits;
      if (newData[index]) {
        newData[index].file = uploadedFile;
      }

      setCredits([...newData]);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const initialValues = useMemo(
    () =>
      credits.map(credit => ({
        ...credit,
        file: credit.file
          ? [
              {
                uid: credit.file.id,
                name: credit.file.pid || credit.file.url,
                status: 'done',
                ...credit.file,
              },
            ]
          : [],
      })),
    [credits],
  );

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.List name="credits" initialValue={initialValues}>
        {(fields, { add, remove }) => (
          <div>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} direction="vertical">
                <Form.Item
                  {...restField}
                  name={[name, 'institution']}
                  label="Institution name"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'amount']}
                  label="Number of credits"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required!',
                    },
                  ]}
                >
                  <Input type="number" min={0} />
                </Form.Item>
                <Form.Item
                  isListField
                  label="File"
                  name={[name, 'file']}
                  {...{
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                  }}
                >
                  <Upload
                    name="file"
                    action={ApiService.getUrl('/files')}
                    headers={ApiService.getHeaders()}
                    listType="picture"
                    maxCount={1}
                    onChange={e => onUploadChange(e, key)}
                  >
                    <Button>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </Form.Item>
                <Button danger onClick={() => remove(name)}>
                  Delete credit
                </Button>
                <Divider />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Credit
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={updateLoading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreditsForm;
