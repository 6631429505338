import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import client from '@services/graphqlService';

export interface AuthPayload {
  success: boolean;
  message?: string;
  errors?: ApiError['errors'];
  accessToken?: string;
}

export interface AuthState {
  isLoading: boolean;
  message?: string;
  errors?: ApiError['errors'];
  accessToken?: string;
  user?: User;
  authError: boolean;
  success?: boolean;
}

interface LoginData {
  email: string;
  password: string;
}

const initialState: AuthState = {
  isLoading: false,
  errors: [],
  authError: false,
};

const AuthSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    signup(state, action: PayloadAction<SignupData>) {
      state.isLoading = true;
      state.authError = false;
      return state;
    },
    signupSuccess(state, action: PayloadAction<AuthPayload>) {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        authError: false,
      };
    },
    signupFail(state, action: PayloadAction<ApiError['errors']>) {
      return { ...state, ...action.payload, authError: true, isLoading: false };
    },
    confirmEmail(state, action: PayloadAction<ConfirmEmailData>) {
      state.isLoading = true;
      return state;
    },
    confirmEmailSuccess(state, action: PayloadAction<AuthPayload>) {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        authError: false,
      };
    },
    confirmEmailFail(state, action: PayloadAction<ApiError['errors']>) {
      return { ...state, ...action.payload, authError: true, isLoading: false };
    },
    login(state, action: PayloadAction<LoginData>) {
      state.isLoading = true;
      return state;
    },
    loginSuccess(state, action: PayloadAction<AuthPayload>) {
      const { accessToken } = action.payload;
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        client.defaultContext.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        authError: false,
      };
    },
    loginFail(state, action: PayloadAction<ApiError['errors']>) {
      return { ...state, ...action.payload, authError: true, isLoading: false };
    },
    getCurrentUser(state) {
      state.isLoading = true;
      return state;
    },
    getCurrentUserSuccess(state, action: PayloadAction<User>) {
      return { ...state, user: action.payload, isLoading: false };
    },
    getCurrentUserFail(state) {
      return { ...state, authError: true, isLoading: false };
    },
  },
});

const { actions, reducer } = AuthSlice;

export const {
  signup,
  signupFail,
  signupSuccess,
  confirmEmail,
  confirmEmailSuccess,
  confirmEmailFail,
  login,
  loginFail,
  loginSuccess,
  getCurrentUser,
  getCurrentUserFail,
  getCurrentUserSuccess,
} = actions;
export default reducer;
