import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { List, Spin } from 'antd';
import { isObjectiveCompleted } from '@utils/helpers';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { markCompleted } from '@redux/reducers/user';
import { VideoCallType, Role } from '@types';
import { isUserInstructor } from '@helpers';
import { parsePath } from '@components/breadcrumbs/Breadcrumbs';
const ObjectiveList: React.FC<{
  objectives: Objective[];
  user: User;
  markCompleted: typeof markCompleted;
  markCompletedLoading: boolean;
  baseUrl?: string;
  hideShowDetails?: any;
}> = ({
  objectives,
  user,
  markCompleted,
  markCompletedLoading,
  baseUrl,
  hideShowDetails,
}) => {
  const location = useLocation();
  const { courseId } = parsePath(location.pathname);
  return (
    <List
      size="small"
      dataSource={objectives}
      renderItem={(objective, index) => {
        let url = baseUrl || '';

        if (
          url.includes('/courses') &&
          !url.includes('/lessons') &&
          objective.lessonId
        ) {
          url = `${baseUrl}/lessons/${objective.lessonId}`;
        }
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                isObjectiveCompleted(objective.id, user) ? (
                  <>
                    {user.role !== Role.Student && (
                      <div
                        style={{
                          width: 8,
                          height: 8,
                          color: '#000000',
                          backgroundColor: '#000000',
                          borderRadius: 4,
                          marginTop: 6,
                          marginBottom: 6,
                        }}
                      />
                    )}
                    {user.role == Role.Student && (
                      <CheckCircleOutlined
                        style={{
                          fontSize: 20,
                          color: '#52c41a',
                        }}
                        onClick={() => {
                          markCompleted(objective.id);
                        }} />
                    )}
                  </>
                ) : markCompletedLoading ? (
                  <Spin size="small" />
                ) : (
                  <>
                    {user.role !== Role.Student && (
                      <div
                        style={{
                          width: 8,
                          height: 8,
                          color: '#000000',
                          backgroundColor: '#000000',
                          borderRadius: 4,
                          marginTop: 6,
                          marginBottom: 6,
                        }}
                      />
                    )}
                    {user.role == Role.Student && (
                      <CloseCircleOutlined
                        style={{
                          fontSize: 20,
                          color: '#333',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          markCompleted(objective.id);
                        }} />
                    )}
                  </>
                )
              }
              title={
                <>
                  {baseUrl ? (
                    <Link
                      style={{ paddingRight: 120 }}
                      to={`/courses/${courseId}/objectives/${objective.id}`}
                      className="no-link-style"
                    >
                      {/* <Link
                      style={{ paddingRight: 120 }}
                      to={`${baseUrl}/objectives/${objective.id}`}
                      className="no-link-style"
                    >*/}
                      <div className="objectives-list">
                        {' '}
                        <div className="objectives-tittie">
                          {objective.name}{' '}
                        </div>
                        {hideShowDetails && isUserInstructor(user) && (
                          <div className="calculate">
                            <div className="tittie">
                              M:&nbsp;&nbsp;
                              <span>
                                {objective.media &&
                                  (objective.media.length > 0 ? (
                                    <span>{objective.media.length}</span>
                                  ) : (
                                    '0'
                                  ))}
                              </span>
                            </div>
                            <div className="tittie">
                              A:&nbsp;&nbsp;
                              <span>
                                {objective.assessments &&
                                  (objective.assessments.length > 0 ? (
                                    <span>{objective.assessments.length}</span>
                                  ) : (
                                    '0'
                                  ))}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </Link>
                  ) : (
                    <Link
                      style={{ paddingRight: 120 }}
                      to={`${
                        location.pathname.indexOf('/lessons') !== -1
                          ? location.pathname
                          : ''
                      }/objectives/${objective.id}`}
                      className="no-link-style"
                    >
                      {objective.name}
                    </Link>
                  )}
                </>
              }
            />
          </List.Item>
        );
      }}
    />
  );
};

export default ObjectiveList;
