import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import ApiService from '@services/apiService';
import { Button } from 'antd';

const CreditCardForm: React.FC<{
  salesForm?: boolean;
  onSubmit?: any;
}> = ({ salesForm, onSubmit }) => {
  const [error, setError] = useState();
  const stripe = useStripe();
  const elements = useElements();

  if (!elements || !stripe) {
    return null;
  }

  const addCard = async () => {
    const cardElement = elements.getElement('card');

    if (!cardElement) {
      return null;
    }

    try {
      const response = await stripe.createToken(cardElement);

      if (response.error) {
        toast.error(response.error.message);
        return null;
      }

      if (!response.token) {
        return null;
      }

      if (!salesForm) {
        await ApiService.post('/users/current-user/cards', {
          token: response.token.id,
        });
        toast.success('You successfully added a card!');
        window.location.reload();
        return false;
      }
      onSubmit && onSubmit(response.token.id);
    } catch (err: any) {
      if (err.message) {
        setError(err.message);
        toast.error(err.message);
      }
    }
  };
  return (
    <div style={{ margin: '16px 0' }}>
      <CardElement />
      <Button type="primary" onClick={addCard} style={{ marginTop: '16px' }}>
        {salesForm ? 'Check' : 'Add'} card
      </Button>
    </div>
  );
};

export default CreditCardForm;
