import React, { FunctionComponent, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, Form, Space } from 'antd';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import { removeUserNote, updateUserNote } from '@redux/reducers/userNotes';

const { TextArea } = Input;

interface DispatchProps {
  removeUserNote: typeof removeUserNote;
  updateUserNote: typeof updateUserNote;
}

interface Props {
  canEdit: boolean;
  canRemove: boolean;
  note: UserNote;
  onUpdate: () => void;
}

type SingleNoteProps = Props & DispatchProps;

const SingleNote: FunctionComponent<SingleNoteProps> = ({
  canEdit,
  canRemove,
  note,
  removeUserNote,
  updateUserNote,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const { resetFields } = form;

  // Toggle note edit
  const [isEditable, setIsEditable] = useState(false);
  const setNoteEdit = () => {
    setIsEditable(true);
  };

  const offNoteEdit = () => {
    setIsEditable(false);
  };

  // Delete a note
  const deleteNote = () => {
    removeUserNote(note.id);
    onUpdate();
  };

  const formatDate = (d: Date | undefined) => {
    if (d) {
      const date = new Date(d);
      return `${date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })} @ ${date.toLocaleTimeString('en-US')}`;
    }
    return d;
  };

  const handleSubmit = async (values: any) => {
    try {
      await updateUserNote({ ...values, id: note.id });
      resetFields();
      offNoteEdit();
      onUpdate();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  if (isEditable) {
    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="w-100"
      >
        <Form.Item
          name="note"
          label="Edit note"
          required
          hasFeedback
          initialValue={note.note}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please input a note',
            },
          ]}
        >
          <TextArea
            placeholder="Write note"
            autoSize={{ minRows: 2, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" className="mr-2">
              Save
            </Button>
            <Button danger htmlType="button" onClick={offNoteEdit}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  }

  return (
    <div className="notes--note">
      <div>
        <span style={{ fontSize: 12 }}>{formatDate(note.createdAt)}</span>
        {canEdit && (
          <Button type="link" onClick={setNoteEdit} icon={<EditOutlined />} />
        )}
        {canRemove && (
          <Button type="link" onClick={deleteNote} icon={<DeleteOutlined />} />
        )}
      </div>
      <p className="notes--note-text" style={{ marginBottom: 0 }}>
        {note.note}
      </p>
    </div>
  );
};

export default connect(null, {
  removeUserNote,
  updateUserNote,
})(SingleNote);
