import React from 'react';

import logoIcon from '@assets/logo-icon.webp';
import { Spin } from 'antd';

export default ({ showLogo = false }) => (
  <div className="spinner-container">
    {showLogo && (
      <img
        src={logoIcon}
        alt="Newlane university"
        style={{ maxHeight: 60, marginBottom: 12 }}
      />
    )}
    <div>
      <Spin size="default" />
    </div>
  </div>
);
