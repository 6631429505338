import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import CourseList from '@components/coursesList/CourseList';
import { getCurrentUser } from '@redux/reducers/user';
import Spinner from '@components/Spinner';
import CourseService from '@services/courseService';
interface CoursesProps {
  user?: User;
  getCurrentUser: typeof getCurrentUser;
}

const Courses: React.FC<CoursesProps> = ({ user, getCurrentUser }) => {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState<Course[]>();
  const [assignCourses, setAssignCourses] = useState<any>(0);
  const [passedCoursesCount, setPassedCoursesCount] = useState<any>(0);
  const [startCoursesCount, setStartCoursesCount] = useState<any>(0);
  const [notStartCoursesCount, setNotStartCoursesCount] = useState<any>(0);

  useEffect(() => {
    getCurrentUser();
    if (!courses && !loading) {
      setLoading(true);
      CourseService.getAll().then(d => {
        if (d && d.length > 0) {
          setAssignCourses(d.length);
        }
        setCourses(d);
        setLoading(false);
      });
    }
    if (user && user.userCourses.length > 0) {
      const completedCourses = user.userCourses.filter(
        (c: any) => c.passed == true,
      );
      setPassedCoursesCount(completedCourses.length);
      const startCount = user.userCourses.filter(
        (c: any) => c.startCourse === 'started',
      ).length;
      setStartCoursesCount(startCount);
      if (courses && courses.length > 0) {
        const totalNotStat = courses.length - (passedCoursesCount + startCount);
        setNotStartCoursesCount(totalNotStat);
      }
    }
  }, [courses, loading]);

  if (loading) {
    return <Spinner />;
  }
  if (!user || !courses) {
    return null;
  }

  return (
    <div>
      <Row gutter={16} style={{ marginTop: 15 }}>
        <Col offset={5} span={14}>
          <div>
            <h1 style={{ margin: '0px 0' }}>Courses</h1>
            <CourseList
              courses={courses}
              assignCourses={assignCourses}
              passedCoursesCount={passedCoursesCount}
              startCoursesCount={startCoursesCount}
              notStartCoursesCount={notStartCoursesCount}
            />
            {courses.length === 0 && <p>No enrolled courses.</p>}
          </div>
        </Col>
        <Col span={12} />
      </Row>
    </div>
  );
};

const selectCoursesState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedCourses = connect(selectCoursesState, {
  getCurrentUser,
})(Courses);

const Page = () => <ConnectedCourses />;

export default Page;
