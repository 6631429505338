import { ScriptCheck } from './types';

const introChecks: ScriptCheck[] = [
  {
    title:
      'Confirm Identity of the student and take a photo (first ask permission)',
    text:
      'E.g.,: “Hi is this ________? [Get verbal confirmation.] I’m checking your profile pictures as well, [verify that student resembles profile pictures.] Is it ok if I take your picture? [get confirmation] [take picture]',
  },
  {
    title: 'Identify the course',
    text: 'This is a course hearing for [Course]',
  },
  {
    title: 'Identify yourself and expertise for course hearing',
    text:
      'My name is [Your name]. I’m acting as the host and judge for this course hearing. I hold [your advanced degree in relevant field]. You can view my profile by clicking on my name.',
  },
  {
    title: 'Ask if student has any questions (and answer as warranted)',
    text:
      'Are you all clear on what a course hearing is? Do you have any questions before we begin?',
  },
];

const mainChecks = [
  {
    text: 'E.g., One of the course goals is Y (e.g., Explain how Ancient Western philosophy emerged in Greece), could you do that for me? ',
    title:
      'Explicitly assess and check off each course goal.',
  },

  {
    text:
      'E.g., One of the objectives reads Z (e.g., summarize Plato’s Apology), could you do that? etc. (be sure to ask about any objective the student has missed in previous assessments)',
    title:
      'Assess enough objectives, including any you have reason to doubt the student’s mastery to determine your overall assessment.',
  },

  {
    text: 'Thank you for your participation in this course hearing. My overall assessment is [Pass/not yet]. I’m satisfied you’ve mastered all the course goals and objectives/I’m not satisfied you’ve mastered all the course goals and objectives. I would recommend that you review X and Y objectives.',
    title:
      'Communicate assessment outcome and rationale (Pass; or not yet)',
  },
];

const outroChecks = [
  // {
  //   text: 'Identify goals or objectives to review (if applicable)',
  //   title:
  //     '(If applicable) The objectives/goals you should review are X, Y, and Z.',
  // },

  // {
  //   text: 'Give guidance for next steps.',
  //   title:
  //     'Congratulate student (even if they did not yet pass, it’s a big deal to do a course hearing). Congratulations for participating in this course hearing. Completing a course hearing is a major step in your degree program (regardless of the outcome). Give guidance for next steps.',
  // },

  // {
  //   text:
  //     'Do you know which course you will be completing next? If you’re not sure, I would recommend that you work on X course.',
  //   title:
  //     'Also please take a minute while the hearing is fresh to complete the End of Course Evaluation Form. (It should take about 1-2 minutes).',
  // },

  // { text: 'Close', title: 'Thank you and Good bye.' },
  {
    text: '(If applicable) The objectives/goals you should review are X, Y, and Z.',
    title:
      'Identify goals or objectives to review (if applicable)',
  },
  {
    text: 'Congratulations for participating in this course hearing. Completing a course hearing is a major step in your degree program (regardless of the outcome). ',
    title:
      'Congratulate student (even if they did not yet pass, it’s a big deal to do a course hearing)',
  },
  {
    text:
      'Do you know which course you will be completing next? If you’re not sure, I would recommend that you work on X course. [or if student isn’t sure, and you are not sure, recommend they reach out to student support.] Also please take a minute while the hearing is fresh to complete the End of Course Evaluation Form. (It should take about 1-2 minutes).',
    title:
      'Give guidance for next steps.',
  },

  { text: 'Thank you and Good bye.', title: 'CloseI have explicitly assessed the student’s mastery of each course goal.' },
];

export const scriptChecks = {
  intro: introChecks,
  main: mainChecks,
  outro: outroChecks,
};
