import React, { useEffect, useState } from 'react';
import AgreementService from '@services/AgreementService';
import { useRouteMatch } from 'react-router';
import moment from 'moment';
import Spinner from '@components/Spinner';

interface AgreementProps {
  type: Agreement['type'];
}

const Agreement: React.FC<AgreementProps> = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState<Agreement>();

  useEffect(() => {
    if (!agreement && !loading) {
      AgreementService.getOne(type).then(data => {
        setAgreement(data);
        setLoading(false);
      });
    }
  }, [agreement, loading, type]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      {agreement && (
        <div dangerouslySetInnerHTML={{ __html: agreement.text }} />
      )}
      {agreement && (
        <div>
          <p>Last updated: {moment(agreement.updatedAt).format('L')}</p>
        </div>
      )}
    </div>
  );
};

export default Agreement;
