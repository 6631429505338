import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NotificationsState {
  isLoading: boolean;
  error: boolean;
  notifications?: ApiNotification[];
  newNotifications: boolean;
}

const initialState: NotificationsState = {
  isLoading: false,
  error: false,
  newNotifications: false,
};

const UserSlice = createSlice({
  initialState,
  name: 'notifications',
  reducers: {
    addNotification(state, action: PayloadAction<ApiNotification>) {
      if (state.notifications) {
        return {
          ...state,
          notifications: [action.payload, ...state.notifications],
          newNotifications: true,
        };
      }
      return {
        ...state,
        notifications: [action.payload],
        newNotifications: true,
      };
    },
    fetchNotifications(state) {
      return { ...state, isLoading: true };
    },
    fetchNotificationsSuccess(state, action: PayloadAction<ApiNotification[]>) {
      const unreadNotifications = action.payload.filter(n => !n.read);
      return {
        ...state,
        notifications: action.payload,
        newNotifications: unreadNotifications.length > 0,
        isLoading: false,
      };
    },
    updateNotification(state, action: PayloadAction<number>) {
      return { ...state };
    },
  },
});

const { actions, reducer } = UserSlice;

export const {
  addNotification,
  fetchNotifications,
  fetchNotificationsSuccess,
  updateNotification,
} = actions;
export default reducer;
