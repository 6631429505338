import ApiService from './apiService';

const DegreeService = {
  getAll: async (): Promise<Degree[]> => {
    const response = await ApiService.get(`/degrees`);
    return response.data;
  },

  getOne: async (id: number): Promise<Degree> => {
    const response = await ApiService.get(`/degrees/${id}`);
    return response.data;
  },

  create: async (data: Degree): Promise<Degree> => {
    const response = await ApiService.post(`/degrees`, data);
    return response.data;
  },

  update: async (id: number, data: Degree): Promise<Degree> => {
    const response = await ApiService.put(`/degrees/${id}`, data);
    return response.data;
  },

  getDegreeContent: async (id: number): Promise<Course[]> => {
    const response = await ApiService.get(`/degrees/${id}/content`);
    return response.data;
  },

  getInstructors: async (id: number): Promise<User[]> => {
    const response = await ApiService.get(`/degrees/${id}/instructors`);
    return response.data;
  },

  addInstructor: async (
    id: number,
    data: { userId: number },
  ): Promise<User> => {
    const response = await ApiService.post(`/degrees/${id}/instructors`, data);
    return response.data;
  },

  deleteInstructor: async (id: number, userId: number): Promise<User> => {
    const response = await ApiService.delete(
      `/degrees/${id}/instructors/${userId}`,
    );
    return response.data;
  },

  getEnrolledDegrees: async (): Promise<Degree[]> => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      const response = await ApiService.get(`/enrolled-degrees`);
      return response.data;
    }
    const dataSend: Degree[] = [];
    return dataSend;
  },

  getStudents: async (id: number): Promise<User[]> => {
    const response = await ApiService.get(`/degrees/${id}/students`);
    return response.data;
  },
};

export default DegreeService;
