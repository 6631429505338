import React from 'react';
import { Calendar, Badge } from 'antd';
import moment from 'moment';
import { getHex } from '@components/Message';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { getDayCalls } from './helpers';

interface ScheduleCalendarProps {
  calls: ScheduledCall[];
  onChangeDay: any;
  fullscreen?: boolean;
}

const getDateCell = (day: moment.Moment, calls: ScheduledCall[]) => {
  const dayCalls = getDayCalls(day, calls);
  return dayCalls.map(call => {
    if (call.confirmed) {
      return (
        <CheckCircleOutlined
          style={{ marginTop: '2px', color: getHex('success') }}
        />
      );
    }

    if (call.rejected) {
      return (
        <CloseCircleOutlined
          style={{ marginTop: '2px', color: getHex('danger') }}
        />
      );
    }

    return (
      <QuestionCircleOutlined
        style={{ marginTop: '2px', color: getHex('warning') }}
      />
    );
  });
};

const getFullscreenDateCell = (day: moment.Moment, calls: ScheduledCall[]) => {
  const dayCalls = getDayCalls(day, calls);
  const notRespondedCount = dayCalls.filter(c => !c.confirmed && !c.rejected);
  const isResponded = notRespondedCount.length === 0;
  return (
    <div>
      {!isResponded && (
        <div
          style={{
            position: 'absolute',
            top: 8,
          }}
        >
          <Badge
            count={notRespondedCount.length}
            style={{
              backgroundColor: getHex('warning'),
              color: '#fff',
            }}
          />
        </div>
      )}
      {dayCalls.map(call => (
        <div>
          <Badge
            status={
              call.confirmed ? 'success' : call.rejected ? 'error' : 'warning'
            }
          />
          <b>{call.type === 'onboarding' ? 'Onboarding' : 'Course hearing'} </b>{' '}
          {`${call.student.firstName} ${call.student.lastName}`}
        </div>
      ))}
    </div>
  );
};

const ScheduleCalendar: React.FC<ScheduleCalendarProps> = ({
  calls,
  onChangeDay,
  fullscreen = false,
}) => (
  <Calendar
    fullscreen={fullscreen}
    onSelect={value => onChangeDay(value)}
    dateCellRender={(day: moment.Moment) =>
      fullscreen ? getFullscreenDateCell(day, calls) : getDateCell(day, calls)
    }
  />
);

export default ScheduleCalendar;
