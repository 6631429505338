import React, { useState, useEffect } from 'react';
import { useRouter } from '@utils/routerHooks';
import Spinner from '@components/Spinner';
import NewlaneTutorialsService from '@services/newlaneTutorialsService';

interface TParams {
  id?: number;
}

export default function withNewlaneTutorials(WrappedComponent: any) {
  return () => {
    const { match, history } = useRouter<TParams>();
    const [loading, setLoading] = useState(false);
    const [newlaneTutorial, setNewlaneTutorials] = useState<NewlaneTutorials>();

    const id = match.params.id || 0;

    useEffect(() => {
      if (
        !loading &&
        (!newlaneTutorial ||
          (newlaneTutorial && newlaneTutorial.id !== Number(match.params.id)))
      ) {
        setLoading(true);
        NewlaneTutorialsService.getOne(id).then((c: any) => {
          setNewlaneTutorials(c);
          setLoading(false);
        });
      }
    }, [newlaneTutorial, id, loading, match.params.id]);

    if (!id) {
      history.push('/newlane-tutorials');
    }

    if (loading) {
      return <Spinner />;
    }

    if (!newlaneTutorial) {
      return null;
    }

    return <WrappedComponent newlaneTutorial={newlaneTutorial} />;
  };
}
