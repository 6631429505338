import React from 'react';
import moment from 'moment';
import { Modal, Divider, Button } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { Link } from 'react-router-dom';

interface QuizReviewProps extends ModalProps {
  quiz: Quiz;
}

const QuizReviewModal: React.FC<QuizReviewProps> = ({
  quiz,
  visible,
  onOk,
  onCancel,
}) => (
  <Modal
    title="Quiz Review"
    width={720}
    visible={visible}
    onOk={onOk}
    onCancel={onCancel}
    destroyOnClose
  >
    <h4>Score: {quiz.score}%</h4>
    <h4>{moment(quiz.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</h4>

    <Divider />

    {quiz.assessments.map(assessment => (
      <div style={{ textAlign: 'center' }} key={assessment.id}>
        <div style={{ textAlign: 'left' }}>
          {assessment.objective && (
            <h3>
              Objective:{' '}
              <Link to={`/objectives/${assessment.objective.id}`}>
                {assessment.objective.name}
              </Link>
            </h3>
          )}
        </div>
        <div style={{ textAlign: 'center' }}>
          <h3>{assessment.question}</h3>
        </div>
        {assessment.answers.map(answer => (
          <>
            <Button
              key={answer.id}
              danger={
                !!quiz.answers.find(a => !a.correct && a.id === answer.id)
              }
              style={{
                marginBottom: '8px',
                whiteSpace: 'normal',
                height: 'auto',
                padding: '8px 16px',
              }}
              className={
                quiz.answers.find(a => a.correct && a.id === answer.id)
                  ? 'bg-success'
                  : ''
              }
            >
              {answer.text}
            </Button>
            <br />
          </>
        ))}
        <Divider />
      </div>
    ))}
  </Modal>
);

export default QuizReviewModal;
