import { takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import ApiService from '@services/apiService';

import {
  startError,
  startSuccess,
  stopCallSuccess,
  fetchVideoCallsSuccess,
  StartVideoCallResponse,
  StartVideoCallPayload,
} from '../reducers/videoCall';

function* startVideoCall({
  payload,
}: PayloadAction<StartVideoCallPayload>): Generator<any, any, any> {
  const token = localStorage.getItem('accessToken');
  if (token) {
    try {
      const response = yield ApiService.post(
        `/video-calls`,
        payload,
      ) as Promise<unknown>;

      const json = response.data as StartVideoCallResponse;

      yield put(startSuccess(json));
    } catch (err: any) {
      console.error(err);
      yield put(startError(err));
    }
  }
}

function* joinVideoCall({
  payload: callId,
}: PayloadAction<string>): Generator<any, any, any> {
  try {
    const response = yield ApiService.post(
      `/video-calls/${callId}/join`,
      {},
    ) as Promise<unknown>;

    const json = response.data as StartVideoCallResponse;

    yield put(startSuccess(json));
  } catch (err: any) {
    console.error(err);
    yield put(startError(err));
  }
}

function* acceptVideoCall({
  payload: callId,
}: PayloadAction<string>): Generator<any, any, any> {
  try {
    const response = yield ApiService.post(
      `/video-calls/${callId}/accept`,
      {},
    ) as Promise<unknown>;

    const json = response.data as StartVideoCallResponse;

    yield put(startSuccess(json));
  } catch (err: any) {
    console.error(err);
    yield put(startError(err));
  }
}

function* fetchVideoCalls(): Generator<any, any, any> {
  const token = localStorage.getItem('accessToken');
  if (token) {
    try {
      const response = yield ApiService.post(
        `/video-calls`,
        {},
      ) as Promise<unknown>;

      const json = response.data as VideoCall[];

      yield put(fetchVideoCallsSuccess(json));
    } catch (err) {
      console.error(err);
      // yield put(startError(err));
    }
  }
}

function* stopVideoCall({
  payload: videoCall,
}: PayloadAction<VideoCall>): Generator<any, any, any> {
  try {
    yield ApiService.post(
      `/video-calls/${videoCall.id}/end`,
      {},
    ) as Promise<unknown>;

    yield put(stopCallSuccess());
  } catch (err: any) {
    console.error(err);
    yield put(startError(err));
  }
}

function* watchForRequestData() {
  yield takeLatest('videoCall/start', startVideoCall);
  yield takeLatest('videoCall/acceptVideoCall', acceptVideoCall);
  yield takeLatest('videoCall/fetchVideoCalls', fetchVideoCalls);
  yield takeLatest('videoCall/stopCall', stopVideoCall);
  yield takeLatest('videoCall/joinVideoCall', joinVideoCall);
}

export default watchForRequestData;
