import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { UserProjectStatus } from '@types';

interface StudentProjectsTableProps {
  studentProjects: UserProject[];
  onClick: (userProject: UserProject) => void;
}

export const StudentProjectsTable: React.FC<StudentProjectsTableProps> = ({
  studentProjects,
  onClick,
}) => {
  const columns: ColumnProps<UserProject>[] = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Link
          to={`/student-projects/${record.id}`}
          onClick={e => {
            e.preventDefault();
            onClick(record);
          }}
        >
          {`${record.project.name || record.project.description}`}
        </Link>
      ),
    },
    {
      title: 'Student',
      dataIndex: 'subscriptionStatus',
      key: 'subscriptionStatus',
      render: (_, record) =>
        `${record.user.firstName} ${record.user.lastName} (${record.user.email})`,
    },
    {
      title: 'Review status',
      dataIndex: 'review',
      key: 'review',
      render: (_, record) => {
        if (record.status === UserProjectStatus.Approved) {
          return 'APPROVED';
        }

        if (record.status === UserProjectStatus.NotYet) {
          return 'NOT YET';
        }

        return 'REVIEW PENDING';
      },
    },
  ];
  return (
    <Table<UserProject>
      className="ant-table-responsive"
      dataSource={studentProjects}
      columns={columns}
    />
  );
};
