import React from 'react';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import CourseForm from '@components/courseForm/CourseForm';
import withCourse from '@hoc/withCourse';

interface EditCourseProps {
  user?: User;
  course: Course;
}

const EditCourse: React.FC<EditCourseProps> = ({ user, course }) => {
  if (!user) {
    return null;
  }

  return <CourseForm course={course} />;
};

const selectEditCourseState = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

const ConnectedEditCourse = withCourse(
  connect(selectEditCourseState)(EditCourse),
);

const Page = () => <ConnectedEditCourse />;

export default Page;
