// In the future react-router will come with these out of the box
// Refer to the react-router docs to see original usage
import { useMemo, useCallback } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import uriTemplate, { URITemplate } from 'uri-templates';

export const useRouter = <T>() => {
  const history = useHistory();
  const location = useLocation<T>();
  const match = useRouteMatch();

  return { history, location, match: match as any };
};

export const useQuery = <T>(): T => {
  const { search } = useLocation();
  const query = useMemo(() => queryString.parse(search), [search]);
  return query as T;
};

interface UpdateQueryOptions {
  replace: boolean;
}

type UpdateQuery<T> = (patch: Partial<T>) => void;

type Visit<T> = (params: T) => void;

const USE_PUSH = { replace: false };

export const useUpdateQuery = <T extends ParsedQuery>(
  options: UpdateQueryOptions = USE_PUSH,
): UpdateQuery<T> => {
  const history = useHistory();
  const query = useQuery<T>();
  const { replace } = options;
  const updateQuery = useCallback(
    (patch: Partial<T>): void => {
      const newQuery = { ...query, ...patch };
      const newSearch = queryString.stringify(newQuery);
      if (replace) {
        history.replace({ search: newSearch });
      } else {
        history.push({ search: newSearch });
      }
    },
    [history, query, replace],
  );
  return updateQuery;
};
