import { ApolloClient, InMemoryCache } from '@apollo/client';

const API_URL = process.env.REACT_APP_API_URL;

const GRAPHQL_URL = `${API_URL}/graphql`;

const client = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

export default client;
