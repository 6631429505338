import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AnnouncementService from '@services/announcementService';
import { isUserSubscribed, isUserNew } from '@helpers';
import { Link } from 'react-router-dom';

export interface IAnnouncementListProps {
  user: User;
  courseId?: number;
}

const AnnouncementWrap = styled.div`
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 10px 0;
`;

const getStudentAnnouncement = (user: User) => {
  if (!isUserSubscribed(user)) {
    return (
      <AnnouncementWrap>
        <p>
          Welcome to Newlane University! <br />
          You can currently explore any of our courses by clicking on the course
          to open it. This will show you an overview of each course, including
          the course description, course goals, course lessons, and course
          project(s) (but not lesson objectives or instructional resources). You
          can also update your profile (e.g., adding a picture) by clicking
          ‘edit profile’ in the drop-down menu under your username in the upper
          right-hand corner.
          <br />
          To get the full Newlane experience, please{' '}
          <Link to="subscribe">register</Link>
          . <br /> To register, you’ll add your payment and billing information.
          Once you register, you can view all learning objectives and
          instructional resources, chat with experts, and complete courses for
          college credit.
        </p>
      </AnnouncementWrap>
    );
  }

  if (isUserNew(user)) {
    return (
      <AnnouncementWrap>
        <p>
          Congratulations and Welcome to Newlane University! You can explore any
          of our courses by clicking on the course to open it. <br />
          This will show you an overview of each course, including the course
          description, course goals, course lessons, and course project(s).{' '}
          <br /> To view the lesson objectives, simply click on any lesson and
          the objectives will be displayed.
          <br /> You can also explore the instructional resources in any
          objective by clicking on the objective.
          <br /> If this is your first time, we recommend you start with the
          course “Newlane Basic Orientation”. This course gives you a great
          overview of how Newlane works, and you’ll go through the entire
          process to complete a course in only 20 minutes or so. <br /> You can
          also update your profile (e.g., adding a picture) by clicking ‘edit
          profile’ in the drop-down menu under your username in the upper
          right-hand corner.
        </p>
      </AnnouncementWrap>
    );
  }

  return (
    <AnnouncementWrap>
      <p>
        Welcome back to Newlane University! <br />
        One of the keys to success at Newlane is consistent work toward your
        degree. If you want to complete your degree in a timely fashion, make
        sure that you put in time on a regular basis. This doesn’t mean you need
        to work every day, just that you deliberately schedule time, and work to
        stick to your schedule. If you would like support, it’s easy to reach us
        or to schedule a meeting.
      </p>
    </AnnouncementWrap>
  );
};

const AnnouncementList = ({ courseId, user }: IAnnouncementListProps) => {
  const [announcements, setAnnouncements] = useState<Announcement[]>();
  const [loading, setLoading] = useState(false);
  // const [announcement, setAnnouncement] = useState<Announcement>();

  const getAnnouncements = async () => {
    setLoading(true);
    const announcementsData = await AnnouncementService.getAll(courseId);
    setAnnouncements(announcementsData);
    setLoading(false);
  };

  useEffect(() => {
    if (!announcements && !loading) {
      getAnnouncements();
    }
  });

  if (loading || !announcements) {
    return null;
  }

  return (
    <div>
      {getStudentAnnouncement(user)}
      {!announcements.length ? (
        <p />
      ) : (
        // <p style={{ textAlign: 'center' }}>No announcements.</p>
        announcements.map(announcement => (
          <AnnouncementWrap>
            {announcement.course && (
              <p>
                <b>Course Announcement: </b>
                {announcement.course.name}
              </p>
            )}
            {!announcement.course && (
              <p>
                <b>Global announcement</b>
              </p>
            )}
            <h3>{announcement.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: announcement.text }} />
            {/* <Button type="link" onClick={() => { setAnnouncement(announcement) }}>View announcement</Button> */}
          </AnnouncementWrap>
        ))
      )}
    </div>
  );
};

export default AnnouncementList;
