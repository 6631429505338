import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, Divider, Col, Row, Card, Modal } from 'antd';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import { getCurrentUser } from '@redux/reducers/user';
import Goals from '@components/goals/Goals';
import ApiService from '@services/apiService';
import FilePreview from '@components/files/FilePreview';
import { getFileUrl, isUserInstructor, isUserAdmin } from '@helpers';
import { UploadChangeParam } from 'antd/lib/upload';
import ProjectService from '@services/projectService';
import { StudentProjectReview } from '@components/projectReview';
import { UserProjectStatus } from '@types';
import { toast } from 'react-toastify';
import moment from 'moment';

interface ProjectProps {
  user: User;
  project: Project;
  isLesson?: boolean;
  getCurrentUser: typeof getCurrentUser;
}

interface ProjectsProps {
  user?: User;
  projects?: Project[];
  isLesson?: boolean;
  getCurrentUser: typeof getCurrentUser;
}

const ProjectItem: React.FC<ProjectProps> = ({
  project,
  user,
  isLesson,
  getCurrentUser,
}) => {
  const [loading, setLoading] = useState(false);
  const localStoragePercentage = localStorage.getItem('scoi') || '0';
  const [courseParsant, setCourseParsant] = useState(
    parseInt(localStoragePercentage, 10),
  );
  const [userProjects, setUserProjects] = useState<UserProject[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [fileId, setFileId] = useState<any>();

  const fetchUserProjects = useCallback(() => {
    ProjectService.getAllUserProjectsForProject(user.id, project.id).then(
      result => setUserProjects(result),
    );
  }, [user, project]);

  const onUploadChange = async ({ file }: UploadChangeParam) => {
    setLoading(true);

    if (file.status === 'done') {
      const uploadedFile = file.response as ApiFile;
      await ProjectService.saveUserProject(project.id, uploadedFile);
      await fetchUserProjects();
      await getCurrentUser();
    }

    setLoading(false);
  };

  const projectApproved = useMemo(
    () => !!userProjects.find(up => up.status === UserProjectStatus.Approved),
    [userProjects],
  );

  const fileRemoveProject = () => {
    setLoading(true);
    ProjectService.deleteFileProject(project.id, fileId)
      .then(d => {
        setDeleteModalVisible(false);
        toast.success('Delete file successful!');
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 2000);
      })
      .catch(err => {
        // console.log('err', err);
        // toast.error(err.message);
      });
  };

  useEffect(() => {
    fetchUserProjects();
  }, []);

  console.log(userProjects);

  return (
    <Row>
      <Col span={24}>
        {project.name && <h3>{project.name}</h3>}
        <h4>{isLesson ? 'Exercise' : 'Project'} description</h4>

        {project.description && (
          <div dangerouslySetInnerHTML={{ __html: project.description }} />
        )}

        {project.goals && project.goals.length > 0 ? (
          <>
            <h3>Goals</h3>
            <Goals goals={project.goals} />
          </>
        ) : null}
        {!isUserInstructor(user) && (
          <>
            <h3>Your {isLesson ? 'Exercise' : 'Project'}</h3>

            <p>Submit Your {isLesson ? 'Exercise' : 'Project'}</p>
            <Upload
              name="file"
              action={ApiService.getUrl('/files')}
              headers={ApiService.getHeaders()}
              onChange={onUploadChange}
            >
              {isLesson ? (
                <Button
                  style={{ marginBottom: '16px' }}
                  loading={loading}
                  // disabled={projectApproved}
                  disabled={projectApproved || courseParsant === 100}
                >
                  <UploadOutlined /> Click to Upload
                </Button>
              ) : (
                <Button
                  style={{ marginBottom: '16px' }}
                  loading={loading}
                  // disabled={projectApproved}
                  disabled={projectApproved || courseParsant < 100}
                >
                  <UploadOutlined /> Click to Upload
                </Button>
              )}
            </Upload>
            {userProjects.map(userProject => (
              <Card>
                <div style={{ marginBottom: '16px' }}>
                  {userProject && (
                    <a
                      key={userProject.id}
                      href={getFileUrl(userProject.file.url)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {userProject.file.pid}
                    </a>
                  )}
                  <p>
                    Submitted on: {moment(userProject.file.createdAt).format()}
                  </p>
                </div>
                {userProject && userProject.projectReviewId ? (
                  <StudentProjectReview userProjectId={userProject.id} />
                ) : (
                  <p>Review pending.</p>
                )}
              </Card>
            ))}
          </>
        )}
      </Col>
      <Col span={24}>
        {project.files && (
          <>
            <h3>Files</h3>
            {project.exampleFileUrl && (
              <FilePreview
                file={{
                  id: -1,
                  url: project.exampleFileUrl,
                }}
              />
            )}
            {project.files.map(file => (
              <>
                {isLesson && isUserAdmin(user) && (
                  <div style={{ textAlign: 'end', marginBottom: '8px' }}>
                    <Button
                      style={{ textAlign: 'left', marginBottom: '8px' }}
                      danger
                      onClick={() => {
                        setDeleteModalVisible(true);
                        setFileId(file.id);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                )}

                {/* {isUserAdmin(user) && (
                  <div style={{ textAlign: 'end', marginBottom: '8px' }}>
                    <Button
                      style={{ textAlign: 'left', marginBottom: '8px' }}
                      type="danger"
                      onClick={() => fileRemoveProject(file)}
                    >
                      Delete
                    </Button>
                  </div>
                )} */}
                <FilePreview file={file} />
              </>
            ))}
            {!project.exampleFileUrl && project.files.length === 0 ? (
              <p>No project files.</p>
            ) : null}
          </>
        )}
      </Col>
      <Divider />
      <Modal
        title="Are you sure you want to delete file?"
        width={550}
        visible={deleteModalVisible}
        okText="Delete file"
        okButtonProps={{ loading, danger: true }}
        onOk={() => fileRemoveProject()}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <p>You will not be able to revert this action</p>
      </Modal>
    </Row>
  );
};

const Projects: React.FC<ProjectsProps> = ({
  projects,
  user,
  isLesson,
  getCurrentUser,
}) => {
  if (!user) {
    return null;
  }
  return (
    <>
      {(!projects || !projects.length) && (
        <p>No {isLesson ? 'exercises' : 'projects'}.</p>
      )}
      {projects &&
        projects.map(project => (
          <ProjectItem
            key={project.id}
            project={project}
            user={user}
            isLesson={isLesson}
            getCurrentUser={getCurrentUser}
          />
        ))}
    </>
  );
};

const selectProjectsState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedProjects = connect(selectProjectsState, { getCurrentUser })(
  Projects,
);

export default ConnectedProjects;
